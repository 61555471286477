import Constants from "@/resources/Constants";
import _ from "lodash";
import moment from "moment";

export const prefilAdjustment = (adjustment) => {
  const adjustmentType = _.find(
    Constants.listOfAdjustmentTypes,
    (at) => at.code === adjustment.type
  );
  if (adjustmentType) {
    adjustment.type = adjustmentType;
  }
  const reason = _.find(
    Constants.listOfAdjustmentReasons,
    (ar) => ar.code === adjustment.reason
  );
  if (reason) {
    adjustment.reason = reason;
  }
  const mode = _.find(
    Constants.listOfAdjustmentModes,
    (am) => am.code === adjustment.mode
  );
  if (mode) {
    adjustment.mode = mode;
  }
};

export const prefilDisposal = (disposal) => {
  const type = _.find(
    Constants.listOfDisposalTypes,
    (dt) => dt.code === disposal.type
  );
  if (type) {
    disposal.type = type;
  }
  const reason = _.find(
    Constants.listOfDisposalReasons,
    (dr) => dr.code === disposal.reason
  );
  if (reason) {
    disposal.reason = reason;
  }
  const method = _.find(
    Constants.listOfDisposalMethods,
    (dm) => dm.code === disposal.method
  );
  if (method) {
    disposal.method = method;
  }
  if (disposal.disposalDate) {
    disposal.disposalDate = moment(disposal.disposalDate).format(
      Constants.dates.defaultDateFormat
    );
  }
};

export const prefillEntityBeforeRendering = (entity) => {
  if (entity.selectedAdjustment) {
    prefilAdjustment(entity.selectedAdjustment);
  }
  if (entity.adjustments) {
    entity.adjustments.forEach((adjustment) => {
      prefilAdjustment(adjustment);
    });
  }
  if (entity.selectedDisposal) {
    prefilDisposal(entity.selectedDisposal);
  }
  if (entity.disposals) {
    entity.disposals.forEach((disposal) => {
      prefilDisposal(disposal);
    });
  }
  return entity;
};
