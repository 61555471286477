import _ from "lodash";

export const validateEntityBeforeSaving = (entity, options) => {
  let validationResult = {
    succeeded: true,
    message: "",
    isWarning: false,
  };

  if (entity.isForResale || entity.isService) {
    try {
      if (!entity.salesAccountCode.accountNo.startsWith(4)) {
        validationResult.message =
          "You should select a Sales account # that begins with the number 4. Are you sure you wish to save the record?";
        validationResult.isWarning = true;
        return validationResult;
      }
    } catch (error) {}
  }

  if (entity.isInventoried) {
    try {
      if (!entity.inventoryAccountCode.accountNo.startsWith(1)) {
        validationResult.message =
          "You should select an Inventory account # that begins with the number 1. Are you sure you wish to save the record?";
        validationResult.isWarning = true;
        return validationResult;
      }
    } catch (error) {}

    try {
      if (entity.isForResale) {
        if (!entity.expenseAccountCode.accountNo.startsWith(5)) {
          validationResult.message =
            "You should select a Cost of Goods account # that begins with the number 5. Are you sure you wish to save the record?";
          validationResult.isWarning = true;
          return validationResult;
        }
      } else {
        if (!entity.expenseAccountCode.accountNo.startsWith(6)) {
          validationResult.message =
            "You should select an Expense account # that begins with the number 6. Are you sure you wish to save the record?";
          validationResult.isWarning = true;
          return validationResult;
        }
      }
    } catch (error) {}
  }

  return validationResult;
};
