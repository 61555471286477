<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Staffing Projection" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesOrderStatusStaffing',
          label: 'Staffing Projection',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <div v-for="[dateId, date] in Object.entries(reportData.list)" :key="dateId"
            class="report-body__employee-container">
            <table id="table-report">
              <thead>
                <tr>
                  <th align="left">Day</th>
                  <th align="left">Hour</th>
                  <th align="right">Total</th>
                  <th align="right">% of Day Total</th>
                  <th align="right">Order Count</th>
                  <th align="right">% of Day Order Count</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in date.hours" :key="index">
                  <td>{{ record.dateId }}</td>
                  <td>{{ record.dateHour }}</td>
                  <td align="right">
                    {{ Utilities.formatPrice(record.total) }}
                  </td>
                  <td align="right">
                    {{
                      Utilities.formatPercent(
                        (record.total / date.total.total) * 100
                      )
                    }}
                  </td>
                  <td align="right">
                    {{ record.ordersCount }}
                  </td>
                  <td align="right">
                    {{
                      Utilities.formatPercent(
                        (record.ordersCount / date.total.ordersCount) * 100
                      )
                    }}
                  </td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="2">Totals</td>
                <td align="right">
                  {{ Utilities.formatPrice(date.total.total) }}
                </td>
                <td colspan="1"></td>
                <td align="right">
                  {{ date.total.ordersCount }}
                </td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Staffing Projection");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "SalesOrderStatusStaffing");
    },
    performGetReport(filter) {
      let reportType = "salesOrderStatusStaffing";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesOrderStatusStaffing.scss";
</style>
