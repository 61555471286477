// Online Store Group Editor
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as groupColumnHeaders,
  filterCriteria as groupFilterCriteria,
} from "../Group/inputs";

// Page Header
export const pageHeader = {
  title: "Online Store Group",
  isEditorView: true,
  cancelPath: "onlineStoreGroups",
  addPath: "onlineStoreGroupEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Online Store Groups",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "onlineStoreGroupEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  {
    label: "Description",
    code: "description",
    field: { type: "input", label: "Description" },
    isPartial: true,
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Description",
    id: "description",
    size: "large",
  },
  {
    title: "Created By",
    id: "createdBy",
    size: "large",
  },
  {
    title: "Updated By",
    id: "updatedBy",
    size: "large",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Description",
    id: "description",
  },
  {
    title: "Created By",
    id: "createdBy",
  },
  {
    title: "Updated By",
    id: "updatedBy",
  },
];

// Online Store Group Information - Main Section
export const onlineStoreInputs = [
  {
    id: "name",
    type: "input",
    title: "Online Store Group Name",
    focus: true,
  },
  {
    id: "slug",
    type: "input",
    title: "Slug",
    disabled: true,
  },
  {
    id: "isPublishedToOnlineStore",
    type: "checkBox",
    title: "Published to Online Store",
  },
  {
    id: "description",
    type: "input",
    title: "Description",
  },
  {
    id: "nameOrder",
    type: "input",
    title: "# Order",
    disabled: true,
  },
];

// Associated Groups Section
export const associatedGroupsInputs = [
  {
    id: "_id",
    type: "text",
    title: "Group ID",
  },
  {
    id: "name",
    type: "text",
    title: "Group Name",
  },
];

export const groupSearchInput = {
  id: "groupToAdd",
  type: "search",
  title: "Group",
  entity: Entities.GROUP,
  columnHeaders: groupColumnHeaders,
  filterCriteria: groupFilterCriteria,
  buttonOnly: true,
};

export const defaultSelectedOnlineStoreGroup = {
  _id: "NEW",
  name: "",
  description: "",
  isPublishedToOnlineStore: true,
  groups: [],
  createdBy: {},
  updatedBy: {},
  groupToAdd: undefined,
};
