import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { reject } from "lodash";

export const DEFAULT_SCHEMA_CASH_RECONCILIATION = {
  _id: "NEW",
  cashAtOpening: 0,
  isPostedToJournal: false,
  isPostedToJournalAt: null,
  startDate: null,
  endDate: null,
  employeesData: [],
  reconciliationType: null,
  workstation: null,
  employee: null,
  notes: "",
  cashOneDollar: 0,
  cashTwoDollar: 0,
  cashFiveDollar: 0,
  cashTenDollar: 0,
  cashTwentyDollar: 0,
  cashFiftyDollar: 0,
  cashOneHundredDollar: 0,
  cashOneCent: 0,
  cashFiveCent: 0,
  cashTenCent: 0,
  cashTwentyFiveCent: 0,
  cashFiftyCent: 0,
  cashOther: 0,
  drawerAmountStarting: 0,
  cashCollected: 0,
  payoutTotal: 0,
  cashTotalExpected: 0,
  userCashCountTotal: 0,
  cashTotalDifference: 0,
  saleTotal: 0,
  refundTotal: 0,
  totalSales: 0,
  debitcreditCardCollected: 0,
  creditmemoCollected: 0,
  giftcardCollected: 0,
  totalCollected: 0,
  isArchived: false,
  archivedAt: null,
  store: null,
  organization: null,
  createdBy: null,
  updatedBy: null,
  enteredDate: null,
  createdAt: new Date(),
  customerCountTotal: 0,
  discountTotal: 0,
};

const state = {
  cashReconciliations: [],
  cashReconciliation: { ...DEFAULT_SCHEMA_CASH_RECONCILIATION },
  workstationWithActivty: [],
  loading: {
    getStationsWithActivity: false
  }
};

const mutations = {
  setCashReconciliations: (state, payload) => {
    state.cashReconciliations = payload;
  },
  setCashReconciliation: (state, payload) => {
    if (payload._id == "NEW") {
      state.cashReconciliation = { ...DEFAULT_SCHEMA_CASH_RECONCILIATION };
    } else {
      state.cashReconciliation = payload;
    }
  },
  setWorkstationsWithActivity: (state, payload) => {
    state.workstationWithActivty = payload;
  }
};

const getters = {
  getCashReconciliations: (state) => state.cashReconciliations,
  getCashReconciliation: (state) => state.cashReconciliation,
  getStationsWithActivity: (state) => state.workstationWithActivty,
  getLoading: (state) => state.loading
};

const actions = {
  saveCashReconciliation: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.cashReconciliation.save,
      payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getCashReconciliationById: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl +
          Apis.cashReconciliation.getCashReconciliationById +
          "?id=" +
          payload._id,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit("setCashReconciliation", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  getStationsWithActivity: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      state.loading.getStationsWithActivity = true;
      HttpService.get(
        Apis.backendBaseUrl +
          Apis.cashReconciliation.getStationsWithActivity,
        function (response, error) {
          state.loading.getStationsWithActivity = false;
          if (response) {
            const data = response.data.body;
            commit("setWorkstationsWithActivity", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  setCashReconciliation: ({ commit, state }, payload) => {
    commit("setCashReconciliation", payload);
  },
  getCashReconciliationInfo: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.cashReconciliation.getCashReconciliationInfo,
        payload,
        function (response, error) {
          if (response) {
            const data = response.data;
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  postCashReconciliation: ({ commit }, payload) => {
    const id = payload.id;
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.cashReconciliation.post,
        { id },
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit("setCashReconciliation", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  updateCashReconciliationUserCashCount: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.updateCashReconciliationUserCashCount,
      payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Cash Reconciliation Updated", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
          commit("setCashReconciliation", response.data.body);
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
