<template>
  <div class="cash-reconciliations responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'inventoryCounts', label: 'Inventory Counts' },
      ]" :entity="entity" />
      <SearchTable :entity="entity" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "inventoryCounts",
  data() {
    return {
      listPageHeader,
      entity: Entities.INVENTORYCOUNT,
    };
  },
  mounted: function () {
    this.setPageTitle("InventoryCount");
  },
  components: {
    PageHeader,
    SearchTable,
  },
  computed: {
    ...mapGetters({
      getCurrentStore: "storeModule/currentStore",
    }),
    store() {
      return this.getCurrentStore;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
    }),
  },
};
</script>
