import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedChartOfAccount } from "@/views/ChartOfAccount/inputs";

const state = {
  chartOfAccounts: [],
  chartOfAccount: { ...defaultSelectedChartOfAccount },
  loadingStatus: {
    saveChartOfAccount: false,
    getChartOfAccounts: false,
    getChartOfAccountById: false,
    deleteChartOfAccount: false,
  },
  chartOfAccountPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setChartOfAccounts: (state, payload) => {
    state.chartOfAccounts = payload;
  },
  setChartOfAccount: (state, payload) => {
    if (payload._id === "NEW")
      state.chartOfAccount = { ...defaultSelectedChartOfAccount };
    else state.chartOfAccount = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setChartOfAccountPopUp: (state, payload) => {
    state.chartOfAccountPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  chartOfAccounts: (state) => state.chartOfAccounts,
  chartOfAccount: (state) => state.chartOfAccount,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  chartOfAccountPopUp: (state) => state.chartOfAccountPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveChartOfAccount: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "saveChartOfAccount",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveChartOfAccount,
      payload.chartOfAccount,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveChartOfAccount",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteChartOfAccount: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteChartOfAccount",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteChartOfAccount,
      payload.chartOfAccount,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteChartOfAccount",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getChartOfAccountById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getChartOfAccountById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getChartOfAccountById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getChartOfAccountById",
          status: false,
        });
        if (response) {
          commit("setChartOfAccount", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setChartOfAccountPopUp: ({ commit, state }, payload) => {
    commit("setChartOfAccountPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
