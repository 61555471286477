<template>
  <div class="customer-note">
    <div
      class="
        two-columns-layout
        two-columns-layout--responsive
        two-columns-layout--responsive-horizontal-space-10-forced
      "
    >
      <div class="space-between space-between--allow-wrapping">
        <inline-button
          v-if="_events['delete-note']"
          label="Delete"
          class="desktop-only-inline-block"
          type="plain-danger responsive-column form-height wide"
          @click="$emit('delete-note')"
          :disabled="!isEdit"
        />
        <div
          v-for="input in notesInputs"
          :key="input.id"
          :class="'input-text ' + input.class"
        >
          <Input
            :model="note[input.id]"
            @updateModel="note[input.id] = $event"
            :inputData="input"
            :isEdit="isEdit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notesInputs } from "@/views/Customer/inputs";
import Input from "@/components/Input/Input.vue";

export default {
  name: "CustomerNote",
  props: {
    isEdit: {
      type: Boolean,
    },
    note: {
      type: Object,
    },
  },
  components: { Input },
  data() {
    return {
      notesInputs,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.customer-note {
}
</style>
