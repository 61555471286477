<template>
  <div>
    <PageHeader v-if="!isComponent" title="Reports" :links="[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'financeReport', label: 'Finance' },
    ]" />
    <div class="home responsive-menu">
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Cash</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <!-- <inline-button
              :to="{ name: 'financeAccountsReceivable' }"
              type="plain-primary responsive-column"
              label="Accounts Receivable"
            /> -->
            <inline-button :to="{ name: 'financeDepositSlip' }" type="plain-primary responsive-column"
              label="Deposit Slip" />
            <!-- <inline-button :to="{ name: 'financeDepositSlipGreenCheck' }" type="plain-primary responsive-column"
              label="Deposit Slip GC" /> -->
            <inline-button :to="{ name: 'cashReconciliationByEmployee' }" type="plain-primary responsive-column"
              label="Cash Rec by Employee" />
            <inline-button :to="{ name: 'cashReconciliationByWorkstation' }" type="plain-primary responsive-column"
              label="Cash Rec by Workstation" />
            <!-- <inline-button :to="{ name: 'cashCountByWorkstation' }" type="plain-primary responsive-column"
              label="Cash Count by Workstation" /> -->
            <!-- <inline-button
              :to="{ name: 'financeJournalLedger' }"
              type="plain-primary responsive-column"
              label="JournalLedger"
            /> -->
          </div>
        </div>
      </div>

      <!-- reports NEVER used and also EXTREMLY SLOW -->
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Inventory</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <!-- Report not working and never used by store -->
            <inline-button :to="{ name: 'inventoryOnHandAsOf' }" type="plain-primary responsive-column"
              label="On Hand As Of" />
            <inline-button :to="{ name: 'inventoryOnHandGroupAsOf' }" type="plain-primary responsive-column"
              label="On Hand by Group As Of" />
          </div>
        </div>
      </div>

      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Purchases</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'inventoryPurchase' }" type="plain-primary responsive-column" label="Purchases" />
            <inline-button :to="{ name: 'inventoryPurchaseGroup' }" type="plain-primary responsive-column"
              label="Purchases By Group" />
            <inline-button :to="{ name: 'inventoryPurchaseSupplier' }" type="plain-primary responsive-column"
              label="Purchases By Supplier" />
            <inline-button :to="{ name: 'inventoryPurchaseSupplierGroup' }" type="plain-primary responsive-column"
              label="Purchases By Supplier And Group" />
          </div>
        </div>
      </div>

      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Taxes</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'salesTaxBreakdownDayReport' }" type="plain-primary responsive-column"
              label="Tax Breakdown by Day" />
            <inline-button :to="{ name: 'salesTaxSummaryReport' }" type="plain-primary responsive-column"
              label="Tax Summary" />
            <inline-button :to="{ name: 'salesTaxScheduleReport' }" type="plain-primary responsive-column"
              label="Tax Schedule" />
          </div>
        </div>
      </div>

      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Uploads</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'financePurchaseOrderUpload' }" type="plain-primary responsive-column"
              label="Purchase Order" />
            <inline-button :to="{ name: 'financeCreditMemoUpload' }" type="plain-primary responsive-column"
              label="Credit Memo" />
            <inline-button :to="{ name: 'financeJournalLedgerUpload' }" type="plain-primary responsive-column"
              label="Journal Ledger Upload" />
            <inline-button :to="{ name: 'financeJournalBatchCheck' }" type="plain-primary responsive-column"
              label="Journal Batch Balance Check" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { reportRoles } from "@/constants/roles";
import PageHeader from "@/components/Header/Header.vue";

export default {
  name: "reports",
  data() {
    return {
      reportRoles,
    };
  },
  components: { PageHeader },
  created: function () {
    this.setPageTitle("Reports");
  },
};
</script>

<style lang="scss"></style>
