import TaxSetupTypes from "@/constants/TaxSetupTypes";
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as chartOfAccountColumnHeaders,
  filterCriteria as chartOfAccountFilterCriteria,
} from "../ChartOfAccount/inputs";
import Constants from "@/resources/Constants";
import INVENTORY_TYPES from "@/constants/InventoryTypes";
// Page Header
export const pageHeader = {
  title: "Tax Setup",
  isEditorView: true,
  cancelPath: "taxSetups",
  addPath: "taxSetupEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Tax Setup",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "taxSetupEditor",
};

export const filterCriteria = [
  {
    label: "Tax Type",
    code: "taxType",
    numCharsToSearch: 1,
    field: { type: "input", label: "taxType" },
    isPartial: true,
  },
  {
    label: "Name",
    code: "name",
    numCharsToSearch: 1,
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  // {
  //   label: "Effective Date",
  //   code: "effectiveDate",
  //   numCharsToSearch: 1,
  //   field: { type: "date", label: "effectiveDate" },
  // },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    id: "duplicate",
    type: "button",
    action: "cloneEntity",
    iconName: "clone",
    ariaLabel: "Duplicate",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Cumulative",
    id: "isCumulative",
    type: "checkbox",
  },
  {
    title: "Tax Type",
    id: "taxType",
    size: "medium",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Rate",
    id: "taxRate",
    size: "medium",
  },
  {
    title: "Effective",
    id: "effectiveDate",
    type: "date",
  },
  {
    title: "Ends",
    id: "endDate",
    type: "date",
    size: "medium",
  },
  {
    title: "Order #",
    id: "taxOrder",
    size: "large",
  },
  {
    title: "Group #",
    id: "taxGroup",
    size: "medium",
  },
  {
    title: "THC",
    id: "thcOperator",
  },
  {
    title: "THC %",
    id: "thcPercent",
  },
  {
    title: "Invt Type",
    id: "thcInventoryTypes",
    size: "medium",
  },
  {
    title: "A/C#",
    id: "accountNo.accountNo",
    size: "medium",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "City",
    id: "city",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Name Code",
    id: "nameCode",
  },
  {
    title: "Store",
    id: "store",
  },
];

export const taxSetupInfoInputs = [
  {
    id: "_id",
    placeholder: "ID #",
    type: "input",
    title: "ID #",
  },
  {
    id: "name",
    placeholder: "Name",
    type: "input",
    title: "Name",
    focus: true,
    required: true,
  },
  {
    id: "taxType",
    placeholder: "Tax Type",
    type: "select",
    label: "label",
    options: Constants.listOfTaxTypes,
    title: "Tax Type",
    required: true,
  },
  {
    id: "isActive",
    type: "checkbox",
    title: "Active",
  },
  {
    id: "isCumulative",
    type: "checkbox",
    title: "Cumulative",
  },
  {
    id: "taxOrder",
    placeholder: "",
    type: "number",
    title: "Tax Order",
    precision: 0,
    prefix: "",
  },
  {
    id: "effectiveDate",
    placeholder: "Effective Date",
    type: "calendar",
    title: "Effective Date",
    required: true,
  },
  {
    id: "endDate",
    placeholder: "End Date",
    type: "calendar",
    title: "End Date",
  },
  {
    id: "taxRate",
    placeholder: "",
    type: "percentage",
    title: "Tax Rate",
    precision: 6,
    prefix: "",
    suffix: "%",
    required: true,
  },
  {
    id: "taxGroup",
    placeholder: "",
    title: "Tax Group",
    required: false,
  },
  {
    id: "accountNo",
    placeholder: "Tax A/C #",
    type: "search",
    title: "Tax A/C #",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.CHARTOFACCOUNT,
    columnHeaders: chartOfAccountColumnHeaders,
    filterCriteria: chartOfAccountFilterCriteria,
    fieldsToDisplay: [
      { label: "Name", id: "name" },
      { label: "Account ID", id: "accountNo" },
    ],
    required: true,
  },
  {
    id: "thcOperator",
    placeholder: "",
    type: "select",
    options: ["", ">", ">=", "=", "<", "<="],
    title: "THC Operator",
  },
  {
    id: "thcPercent",
    placeholder: "",
    type: "percentage",
    title: "THC Percent",
    precision: 2,
    prefix: "",
    suffix: "%",
  },
  {
    id: "thcInventoryTypes",
    placeholder: "Inventory Type",
    type: "select",
    title: "Inventory Type",
    options: INVENTORY_TYPES,
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const defaultSelectedTaxSetup = {
  _id: "NEW",
  taxType: "",
  name: "",
  taxRate: 0,
  isCumulative: false,
  taxOrder: 0,
  thcOperator: "",
  thcPercent: 0,
  thcInventoryTypes: [],
  effectiveDate: new Date(),
  endDate: null,
  isActive: false,
  notes: "",
  taxGroup: "",
  isDeleted: false,
  accountNo: {},
  store: {},
  organization: {},
  createdBy: {},
  updatedBy: {},
};
