<template>
  <div>
    <div class="supplier-editor responsive-menu" id="page-body">
      <div class="responsive-menu__child">
        <PageHeader
          :pageHeader="pageHeader"
          :selectedEntity="selectedSupplier"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'suppliers', label: 'Suppliers' },
            {
              name: 'supplierEditor',
              label: selectedSupplier.name,
              query: $route.query,
            },
          ]"
          :isEdit="isEdit"
          :isCollapsed="isCollapsed"
          :entity="entity"
          @updateIsEdit="isEdit = $event"
          @toggle-edit="toggleEdit"
          @toggle-sections="forceToggle"
          @show-search-modal="() => (showSearchModal = !showSearchModal)"
          @menu-option-click="menuOptionClick"
        />
        <div class="section-base">
          <div class="padded elevated-0 bordered">
            <div class="two-columns-layout two-columns-layout--responsive">
              <div v-for="inputs in supplierInputs" :key="inputs.id">
                <div
                  class="
                    grid-two-column
                    two-columns-layout--responsive-horizontal-space-10
                  "
                >
                  <div
                    v-for="input in inputs.inputs"
                    :key="input.id"
                    :class="'input-text' + ' ' + input.class"
                  >
                    <Input
                      :model="selectedSupplier[input.id]"
                      @updateModel="selectedSupplier[input.id] = $event"
                      :inputData="input"
                      :isEdit="isEdit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Supplier Delivery Agents Begin  -->
        <EditorSection
          title="Supplier Delivery Agents"
          sectionType="supplierDeliveryAgents"
          :bus="bus"
          @select-item-from-search="selectItemFromSearch"
        >
          <div
            class="
              space-between
              space-between--allow-wrapping
              space-between--border-bottom
            "
          >
            <Input
              :model="selectedAgent"
              @updateModel="selectedAgent = $event"
              :inputData="supplierAgentSearchInput"
              :isEdit="isEdit"
              customStyle="width: 100%;"
            />
          </div>
          <div
            v-for="(agent, i) in selectedSupplier.agents"
            :key="agent._id"
            class="two-columns-layout--border-bottom"
          >
            <div class="space-between space-between--allow-wrapping">
              <div
                v-for="input in supplierDeliveryAgentInputs"
                :key="input.id"
                class="input-text"
              >
                <Input
                  :model.sync="agent[input.id]"
                  @updateModel="agent[input.id] = $event"
                  :inputData="input"
                  :isEdit="isEdit"
                />
              </div>
              <div class="flex-right">
                <inline-button
                  :disabled="!isEdit"
                  label="Delete"
                  type="plain-danger responsive-column form-height wide"
                  @click="() => deleteAgent(i)"
                />
              </div>
            </div>
          </div>
        </EditorSection>
        <EditorSection
          title="Credit Memos"
          v-if="selectedSupplier._id !== 'NEW'"
          :bus="bus"
        >
          <SearchTable
            :entity="Entities.CREDITMEMO"
            :hideFilters="true"
            :filter="preFilterCreditMemos"
            :sort="sort"
          />
        </EditorSection>
        <EditorSection
          :title="`About ${selectedSupplier.name || ''}`"
          sectionType="about"
          v-if="selectedSupplier._id !== 'NEW'"
          :bus="bus"
        >
          <About :entity="selectedSupplier" />
        </EditorSection>
      </div>
    </div>
    <JournalEntries
      :showSearchModal="showJournalEntries"
      @toggle-modal="() => (showJournalEntries = !showJournalEntries)"
      type="supplier"
      :filter="filterJournalEntries"
      :hideFilter="true"
    />
    <Maintenance
      v-if="showMaintenance"
      @execute="executeMaintenance"
      @close="closeMaintenance"
      maintenanceType="duplicateProductWithNewSupplier"
    />
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";

import Constants from "@/resources/Constants";
import router from "@/router";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";

import JournalEntries from "@/components/JournalEntries/JournalEntries.vue";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import Maintenance from "@/components/Maintenance/Maintenance.vue";
import SearchTable from "@/components/SearchTable";

import {
  supplierInputs,
  supplierDeliveryAgentInputs,
  columnHeaders,
  filterCriteria,
  supplierAgentSearchInput,
  pageHeader,
} from "./inputs.js";

export default {
  name: "supplierEditor",
  data() {
    return {
      Entities,
      Constants: Constants,
      moment: moment,
      Utilities: Utilities,
      selectedAgent: undefined,
      supplierInputs,
      supplierDeliveryAgentInputs,
      specialPricings: [],
      supplierResults: [],
      supplierAgentResults: [],
      filterOption: undefined,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      cardinality: undefined,
      showSupplierSearchModal: false,
      supplierAgentFilterOptions: [
        { name: "SRN", id: "srn" },
        { name: "Name", id: "name" },
        { name: "Phone", id: "phone" },
      ],
      supplierAgentColumns: [
        { label: "Email", key: "email" },
        { label: "Name", key: "name" },
        { label: "SRN", key: "srn" },
      ],
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      supplierAgentSearchInput,
      entity: Entities.SUPPLIER,
      pageHeader,
      showJournalEntries: false,
      showMaintenance: false,
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    Input,
    JournalEntries,
    Maintenance,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("Supplier");

    if (this.$route.query.id) {
      this.getSupplierById({
        _id: this.$route.query.id,
      });
    } else {
      // new element
      this.isEdit = true;
    }
    this.getSupplierAgents();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getSupplierById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    preFilterCreditMemos() {
      return {
        supplier: this.id,
      };
    },
    sort() {
      return { createdAt: 1 };
    },
    filterJournalEntries() {
      return {
        supplier: this.id,
      };
    },
    ...mapGetters({
      loadingStatus: "supplierModule/loadingStatus",
      supplierAgents: "supplierModule/supplierAgents",
      supplier: "supplierModule/supplier",
    }),
    selectedSupplier: {
      get() {
        this.setPageTitle(`Supplier ${this.supplier.name}`);
        return this.supplier;
      },
      set(entity) {
        return entity;
      },
    },
    supplierAgentsFiltered: function () {
      var filteredSupplierAgents = [];

      this.supplierAgents.forEach((s) => {
        var result = _.filter(
          this.selectedSupplier.agents,
          (sa) => sa._id === s._id
        );
        if (result.length === 0) {
          filteredSupplierAgents.push(s);
        }
      });

      return filteredSupplierAgents;
    },
  },
  methods: {
    ...mapActions({
      getSupplierById: "supplierModule/getSupplierById",
      saveSupplier: "supplierModule/saveSupplier",
      deleteSupplier: "supplierModule/deleteSupplier",
      getSupplierAgents: "supplierModule/getSupplierAgents",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getAdjacentSupplier: "supplierModule/getAdjacentSupplier",
      getCardinalitySupplier: "supplierModule/getCardinalitySupplier",
      getSuppliers: "supplierModule/getSuppliers",
    }),
    performSave(verifyIt = false) {
      if (this.loadingStatus.saveSupplier) {
        return;
      }

      this.selectedSupplier.verifyIt = verifyIt;

      const supplierToSave = this.normalizeEntityBeforeSaving(
        this.selectedSupplier
      );

      this.saveSupplier({
        supplier: supplierToSave,
        positiveCallback: (supplier) => {
          this.setPageTitle(`Supplier ${supplier.name}`);
          this.selectedSupplier = supplier;
          if (this.selectedSupplier._id === "NEW") {
            router.push({
              name: "supplierEditor",
              query: { id: supplier._id },
            });
          }
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(supplier) {
      const entity = JSON.parse(JSON.stringify(supplier));
      if (entity.expirationDate === "") {
        delete entity.expirationDate;
      }
      if (entity.verificationDate === "") {
        delete entity.verificationDate;
      }
      delete entity.verifiedBy;
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteSupplier) {
        return;
      }

      const supplierToDelete = this.normalizeEntityBeforeSaving(
        this.selectedSupplier
      );

      this.deleteSupplier({
        supplier: supplierToDelete,
        positiveCallback: () => {
          router.push({
            name: "suppliers",
          });
        },
      });
    },
    addAgent() {
      if (!this.selectedAgent) {
        this.setMessage({
          text: "Select an agent.",
          type: "error",
        });
        return;
      }
      this.selectedSupplier.agents.unshift(this.selectedAgent);
      this.selectedAgent = undefined;
    },
    deleteAgent(i) {
      this.selectedSupplier.agents.splice(i, 1);
    },
    toggleSearchModal: function () {
      if (this.showSupplierSearchModal) this.getSupplierAgents();
      this.showSupplierSearchModal = !this.showSupplierSearchModal;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedSupplier._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedSupplier._id === "NEW") {
        this.isEdit = true;
      }
    },
    prefillItem: function (item) {
      const itemSubsetToCopy = _.pick(item, ["name"]);
      function mergeEmptyItems(objValue, srcValue) {
        if (objValue) return objValue;
        return srcValue;
      }
      _.mergeWith(this.selectedSupplier, itemSubsetToCopy, mergeEmptyItems);
    },
    selectItemFromSearch: function (entity, item) {
      if (entity === "selectedAgent") {
        this.selectedSupplier.agents.unshift(item);
      } else {
        this.selectedSupplier[entity] = item;
      }
      if (
        entity === Entities.MSOSUPPLIER &&
        this.selectedSupplier._id === "NEW"
      )
        this.prefillItem(item);
    },
    menuOptionClick(entity, key) {
      if (key === "journal-entries-all")
        this.showJournalEntries = !this.showJournalEntries;
      if (key === "duplicateProductWithNewSupplier")
        this.showMaintenance = true;
      if (key === "supplier-listing") {
        window.open(`/reports/inventory/inventoryListSupplier`);
      }
      if (key === "supplier-promotion-supplier") {
        window.open(`/reports/sales/salesPromoSupplier`);
      }

      if (key === "supplier-promotion-supplier-group") {
        window.open(`/reports/sales/salesPromoSupplierGroup?supplierId=${this.supplier._id}`);
      }
      if (key === "supplier-promotion-supplier-group-product") {
        window.open(`/reports/sales/salesPromoSupplierGroupProduct?supplierId=${this.supplier._id}`);
      }
    },
    closeMaintenance() {
      this.showMaintenance = false;
    },
    executeMaintenance() {},
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  width: 100%;
  border-radius: 2px;
}
</style>
