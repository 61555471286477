<template>
  <div class="suppliers responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'suppliers', label: 'Suppliers' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter"/>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";

import { listPageHeader } from "./inputs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "suppliers",
  data() {
    return {
      Entities,
      entity: Entities.SUPPLIER,
      listPageHeader,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("Suppliers");
  },
  computed: {
    ...mapGetters({
      sort: "supplierModule/sort",
      pagination: "supplierModule/pagination",
      initialFilter: "supplierModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "supplierModule/updateSort",
      updatePagination: "supplierModule/updatePagination",
      updateInitialFilter: "supplierModule/updateInitialFilter"
    }),
  }
};
</script>
