<template>
  <div class="total-summary">
    <div v-for="row in  rows " :key="row.name + row.value" class="total-summary__row" :class="{
      'total-summary__row--highlighted': row.isHighlighted,
      'total-summary__row--bold': row.isBold,
      'total-summary__row--gray': row.value == '$0.00',
    }">
      <div>{{ row.name }}</div>
      <div class="total-summary__value-cell">
        <div v-if="row.label" class="total-summary__value-cell-tooltip">{{ row.label.text }}</div>
        <div v-if="row.label">
          <font-awesome-icon :class="`total-summary__icon--${row.label.color}`" :icon="['fas', row.label.icon]" />
        </div>
        <div>{{ row.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceSummary",
  data() {
    return {};
  },
  props: {
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "./_priceSummary.scss";
</style>
