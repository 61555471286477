<template>
  <div>
    <PageHeader
      v-if="!isComponent"
      title="Reports"
      :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
      ]"
    />
    <div class="responsive-menu" style="width: 100%">
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Reports</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button
              :to="{ name: 'todaysMenu' }"
              type="plain-primary responsive-column"
              label="Today's Menu"
            />
            <!-- <inline-button
              :to="{ name: 'stateInventory' }"
              type="plain-primary responsive-column"
              label="State Inventory"
            /> -->
            <inline-button
              :to="{ name: 'endOfDayClosingReport' }"
              type="plain-primary responsive-column"
              label="End of Day Closing"
            />
            <inline-button
              :to="{ name: 'customerReport' }"
              type="plain-primary responsive-column"
              label="Customer Reports"
            />
            <inline-button
              :to="{ name: 'financeReport' }"
              type="plain-primary responsive-column"
              label="Financial Report"
            />
            <inline-button
              :to="{ name: 'inventoryReport' }"
              type="plain-primary responsive-column"
              label="Inventory Reports"
            />
            <inline-button
              :to="{ name: 'salesReport' }"
              type="plain-primary responsive-column"
              label="Sales Reports"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { reportRoles } from "@/constants/roles";

export default {
  name: "reports",
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      reportRoles,
    };
  },
  mounted() {
    if (!this.isComponent) {
      this.setPageTitle("Reports");
    }
  },
};
</script>

<style lang="scss"></style>
