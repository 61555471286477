<template>
  <div class="time-clock">
    <Modal
      v-if="showTimeClock"
      @toggle-modal="$emit('close')"
      :isOpen="showTimeClock"
      modalClass="time-clock__modal"
    >
      <div slot="header">
        <div class="time-clock__header">
          <div>
            <h2>{{ title }}</h2>
            <div class="time-clock__header__date">{{ date }}</div>
          </div>
          <div>
            <BaseBtn
              data-cy="time-clock"
              :label="buttonLabel"
              :colorType="3"
              @click="$emit('swap-time-clock')"
            />
          </div>
        </div>
      </div>
      <div class="time-clock__body" slot="body">
        <div class="time-clock__inputs">
          <div class="reversed-flex-column">
            <BaseInput
              label="PIN"
              placeholder="PIN"
              controlType="password"
              v-model="data.pin"
              @focus="setFocus"
              ref="pin"
              :isPassword="true"
              :applyFocus="true"
            />
          </div>
          <!-- <div class="input-text">
            <div class="reversed-flex-column">
              <v-select
                v-model="data.cashDrawer"
                class="input-text__select"
                placeholder="Drawer"
                :options="drawersListComputed"
                :disabled="drawersListComputed.length === 0"
              />
              <div class="input-text__label">Drawer</div>
            </div>
          </div> -->
          <div class="desktop-only-block">
            <Keypad :input="input" />
          </div>
        </div>
        <div class="time-clock__button-wrapper">
          <BaseBtn
            data-cy="time-clock-save-button"
            label="Cancel"
            :colorType="3"
            @click="$emit('close')"
          />
          <BaseBtn
            :disabled="!data.pin"
            data-cy="time-clock-save-button"
            label="Save"
            @click="handleSave"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import BaseInput from "@/components/Base/Input/Base.vue";

import Modal from "@/components/Modal.vue";
import { checkInInputs } from "./inputs";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";
import Keypad from "@/components/Keypad";

export default {
  name: "TimeClock",
  props: {
    clockIn: Boolean,
    showTimeClock: Boolean,
  },
  data() {
    return {
      Constants,
      checkInInputs,
      data: {
        pin: "",
        name: "",
        cashDrawer: 1,
      },
      employee: undefined,
      date: moment(new Date()).format(this.dateFormat),
      dateFormat: Constants.dates.extendedCalendarDateFormat,
      input: {},
    };
  },
  components: {
    Modal,
    BaseBtn,
    Keypad,
    BaseInput,
  },
  mounted: function () {
    this.getNow();
    this.focusPin();
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore",
    }),
    employeeName() {
      if (this.employee)
        return (
          (this.employee.firstName || "") + " " + (this.employee.lastName || "")
        );
      else return "";
    },
    title() {
      return "Time Clock - " + (this.clockIn ? "Check In" : "Check Out");
    },
    buttonLabel() {
      return this.clockIn ? "Clock Out" : "Clock In";
    },
    drawersListComputed: function () {
      const drawers = [];

      for (var i = 0; i < this.currentStore.numberOfDrawers; i++) {
        drawers.push(i + 1);
      }

      return drawers;
    },
  },
  methods: {
    ...mapActions({
      clockInEmployee: "timeSheetModule/clockInEmployee",
      clockOutEmployee: "timeSheetModule/clockOutEmployee",
    }),
    setFocus(e) {
      this.input = e.target;
    },
    getInput(field) {
      return this.checkInInputs.find((i) => i.id == field);
    },
    handlePinChange: _.debounce(function (e) {
      this.data.pin = e;
      this.getEmployeeByPin({
        pin: e,
        positiveCallback: this.getEmployeeCallback,
      });
    }, Constants.debounce),
    getNow() {
      this.date = moment(new Date()).format(this.dateFormat);
    },
    tapNumber: function (tappedChar) {
      if (tappedChar === "c") {
        this.data.pin = undefined;
      } else {
        this.data.pin = this.data.pin + tappedChar;
      }
    },
    handleSave() {
      if (this.clockIn) {
        this.handleClockIn();
      } else {
        this.handleClockOut();
      }
    },
    handleClockIn() {
      const timeSheet = {
        _id: "NEW",
        pin: this.data.pin,
        stampedIn: new Date(),
      };
      this.clockInEmployee({
        timeSheet: timeSheet,
        positiveCallback: this.handleTimeSheetSaved,
      });
    },
    handleClockOut() {
      const timeSheet = {
        pin: this.data.pin,
        stampedOut: new Date(),
      };
      this.clockOutEmployee({
        timeSheet: timeSheet,
        positiveCallback: this.handleTimeSheetSaved,
      });
    },
    handleTimeSheetSaved() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "./_time-clock.scss";
</style>
