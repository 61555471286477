<template>
    <div>
      <PageHeader
        v-if="!isComponent"
        title="Reports"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'employeeReport', label: 'Employee' },
        ]"
      />
      <div class="home responsive-menu">
        <div class="responsive-menu__child responsive-menu__sub-section">
          <div class="section-base" v-canAccess="reportRoles">
            <div class="section-base__header space-between">
              <div class="section-base__header-name">
                <div>
                  <i class="fas fa-chart-line"></i>
                </div>
                <div>Employee</div>
              </div>
            </div>
            <div class="padded elevated-0 bordered spacer-responsive">
              <inline-button
                :to="{ name: 'employeeListActive' }"
                type="plain-primary responsive-column"
                label="Employee List"
              />
            </div>
            <div class="padded elevated-0 bordered spacer-responsive">
              <inline-button
                :to="{ name: 'employeeHours' }"
                type="plain-primary responsive-column"
                label="Employee Hours"
              />
            </div>
            <div class="padded elevated-0 bordered spacer-responsive">
              <inline-button
                :to="{ name: 'employeeNotes' }"
                type="plain-primary responsive-column"
                label="Notes"
              />
            </div>
          </div>
        </div>
        </div>
    </div>
  </template>

  <script>
  import { reportRoles } from "@/constants/roles";
  import PageHeader from "@/components/Header/Header.vue";

  export default {
    name: "reports",
    data() {
      return {
        reportRoles,
      };
    },
    components: { PageHeader },
    created: function () {
      this.setPageTitle("Reports");
    },
  };
  </script>

  <style lang="scss"></style>