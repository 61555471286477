<template>
  <div class="pos">
    <div>
      <PosHeader
        :order="order"
        @cancel-order="performCancelOrder"
        @new-order="performNewOrder"
        @change-employee="changeEmployee"
        @queue="showQueuePopUp"
        @loyalty-lookup="showLoyaltyLookup"
        @new-customer="showNewCustomerPopUp"
        @open-orders="showOrdersPopUp"
        @archive-order="performArchiveOrder"
        :orderLoadingStatus="orderLoadingStatus"
        @show-product-browser="showProductBrowser"
        @show-product-search="showProductSearch"
        :employee="employee"
        :workstation="selectedWorkstation"
        @process="performProcess"
        :loyaltySystem="loyaltySystem"
      />
    </div>
    <div class="pos__body">
      <div>
        <transition name="fade-fast" mode="out-in">
          <PosMainInput
            v-if="order && order._id"
            @customer-change="(customerId) => performGetCustomer(customerId)"
            @retail="createRetailOrder"
            @customer-popup="showCustomerPopUp"
            @search-product="searchProduct"
            :customerId="customerId"
            :order="order"
            :dfCannabisLimit="dfCannabisLimit"
            :stateCannabisLimit="stateCannabisLimit"
            :ref="'posMainInput'"
            :orderLoadingStatus="orderLoadingStatus"
            :customerLocationInformation="customerLocationInformation"
            @customer-name-click="showCustomerInfo"
          />
        </transition>
        <transition name="fade-fast" mode="out-in">
          <PosProductBrowser
            :isLoading="posLoadingStatus.getItemHierarchy"
            :isCannabis="isCannabis"
            v-if="order && order._id && (order.customer || order.isRetail)"
            :selectedLevel1="selectedLevel1"
            :selectedLevel2="selectedLevel2"
            :itemHierarchy="itemHierarchy"
            :order="order"
            @select-product="(e) => performSelectProductFromGroup(e)"
            @set-level="(e) => setLevel(e)"
            @cannabis-toggle="cannabisToggle"
          />
        </transition>
      </div>
      <div class="pos__divider pos__divider--mobile-only"></div>
      <div>
        <PosCart
          :order="order"
          @select-product="(e) => performSelectProductFromCart(e)"
        />
        <PosOrderDetails
          :order="order"
          :store="currentStore"
          @sell-options="handleSellOptions"
          @set-discount="setDiscountsPopUp({ isOpen: true })"
          @set-delivery="setDeliveryPopUp({ isOpen: true })"
          @set-note="performSetNotePopUp"
          @giftCard-lookup="
            setGiftCardPopUp({ isOpen: true, isBalanceLookUp: true })
          "
          @open-cash-drawer="openCashDrawer"
          @print="performOrderPrint"
          @fulfillment-print="performOrderFulfillmentPrint"
          :averageSpending="getAverageSpending"
        />
      </div>
    </div>
    <SearchModal
      v-if="searchModal.showSearchModal"
      :showSearchModal="searchModal.showSearchModal"
      @toggle-modal="toggleSearchModal"
      @select-item="searchSelectItem"
      @remove-item="removeItem"
      :entity="searchModal.entity"
      :id="searchModal.id"
      :title="searchModal.title"
      :forcedFilterCriteria="searchModal.filterCriteria"
      :columnHeaders="searchModal.columnHeaders"
      :searchColumnHeaders="searchModal.searchColumnHeaders"
      :isFiltersDisabled="false"
      :filter="searchModal.filter"
      :sort="searchModal.sort"
      :defaultFilter="searchModal.defaultFilter"
      :filterButtons="searchModal.filterButtons"
      :bigIcons="searchModal.bigIcons"
      :enableCardniality="searchModal.enableCardniality"
    >
      <div
        slot="header"
        class="pos__search-control-buttons"
        v-if="searchModal.entity == Entities.CUSTOMER"
      >
        <BaseBtn label="Retail Sale" @click="startRetailOrder" />
      </div>
    </SearchModal>
    <BaseModal
      v-if="isProductBrowserPopUpOpen"
      @toggle-modal="toggleProductBrowserModal"
      title="Product Browser"
    >
      <ProductBrowserWrapper
        :isLoading="posLoadingStatus.getItemHierarchy"
        :isCannabis="isCannabis"
        :selectedLevel1="selectedLevel1"
        :selectedLevel2="selectedLevel2"
        :itemHierarchy="itemHierarchy"
        :order="order"
        @set-level="(e) => setLevel(e)"
        @cannabis-toggle="cannabisToggle"
        :orderType="productBrowserOrderType"
        @set-order-type="setProductBrowserOrderType"
        :isProductFixedHeight="true"
      />
    </BaseModal>
    <BaseModal
      :maxHeight="true"
      v-if="isProductSearchPopUpOpen"
      @toggle-modal="isProductSearchPopUpOpen = false"
      title="Available Product Search"
    >
      <ProductSearch
        @select-product="(e) => performSelectProductFromGroup(e)"
        :isLoading="posLoadingStatus.getItemHierarchy"
        :itemHierarchy="itemHierarchy"
        :store="currentStore"
        @toggle="isProductSearchPopUpOpen = false"
      />
    </BaseModal>
    <BaseModal
      v-if="isScanPopUpOpen"
      @toggle-modal="toggleScanModal"
      :title="`Items Check for Order ${order.code}`"
      :maxHeight="true"
    >
      <PosScan
        @scan-done="handleScanDone"
        @fulfillment-print="performOrderFulfillmentPrint"
      />
    </BaseModal>
    <BaseModal
      v-if="isPaymentPopUpOpen"
      @toggle-modal="togglePaymentModal"
      title="Payments"
    >
      <Payment
        :order="order"
        @toggle-modal="togglePaymentModal"
        :PaymentAmount="PaymentAmount"
        @show-credit-memo="showCreditMemo"
        @redeem-rewards="setDiscountsPopUp({ isOpen: true, tab: 'loyalty' })"
      />
    </BaseModal>
    <BaseModal
      v-if="isLoyaltyLookupOpen"
      @toggle-modal="isLoyaltyLookupOpen = false"
      title="Clutch Lookup"
    >
      <div class="pos__clutch-pop-up-container">
        <LoyaltyLookup
          :selectCustomer="selectLoyaltyCustomer"
          :name="getCustomerName"
          :lastName="getCustomerLastName"
          :email="getCustomerEmail"
        />
      </div>
    </BaseModal>
    <BaseModal
      v-if="isNewCustomerPopUpOpen"
      @toggle-modal="isNewCustomerPopUpOpen = false"
      title="Create new Customer"
    >
      <div class="pos__clutch-pop-up-container">
        <CustomerForm
          :name="getCustomerName"
          :email="getCustomerEmail"
          :lastName="getCustomerLastName"
          :licenseNumber="getCustomerLicenseNumber"
          :state="getCustomerState"
          @customerCreation="customerCreation"
        />
      </div>
    </BaseModal>
    <CustomerNotes
      :key="order._id"
      :messages="popUpMessages"
      :CCPAmount="CCPAmount"
      :PointsAmount="PointsAmount"
      :PaymentAmount="PaymentAmount"
    />

    <BaseModal
      :maxHeight="true"
      v-if="isCustomerInfoPopUpOpen"
      @toggle-modal="isCustomerInfoPopUpOpen = false"
    >
      <CustomerInfo :customer="getCurrentOrderCustomer" />
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utilities from "@/lib/Utilities";
// import CheckBox from "@/components/CheckBox.vue";
import LocalStorageManager from "@/lib/LocalStorageManager";
import _ from "lodash";
import Constants from "@/resources/Constants";
import PosHeader from "@/views/Pos/components/PosHeader/PosHeader.vue";
import PosMainInput from "@/views/Pos/components/PosMainInput/PosMainInput.vue";
import PosProductBrowser from "@/views/Pos/components/PosProductBrowser/PosProductBrowser.vue";
import ProductBrowserWrapper from "@/views/Pos/components/ProductBrowserWrapper/ProductBrowserWrapper.vue";
import ProductSearch from "@/components/ProductSearch/ProductSearch.vue";
import PosOrderDetails from "@/views/Pos/components/PosOrderDetails/PosOrderDetails.vue";
import PosCart from "@/views/Pos/components/PosCart/PosCart.vue";
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as orderSearchColumnHeaders,
  filterCriteriaOpenOrders as orderFilterCriteria,
  columnHeadersPos as orderColumnHeaders,
} from "../Order/inputs";
import {
  searchColumnHeaders as queueSearchColumnHeaders,
  filterCriteria as queueFilterCriteria,
  columnHeadersPos as queueColumnHeaders,
  filterButtons as queueFilterButtons,
} from "../Queue/inputs";
import {
  searchColumnHeaders as customerSearchColumnHeaders,
  filterCriteria as customerFilterCriteria,
  columnHeadersPos as customerColumnHeaders,
} from "../Customer/inputs";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import CustomerNotes from "@/components/CustomerNotes";
import Payment from "@/components/Payment/Payment/Payment.vue";
import moment from "moment";
import SocketHelper from "@/lib/SocketHelper";
import { searchEntities } from "@/services/entityService";
import { parseSrn, isSrn } from "@/lib/srnHelper";
import LoyaltyLookup from "@/components/LoyaltyLookup/LoyaltyLookup.vue";
import CustomerInfo from "@/components/CustomerInfo/CustomerInfo.vue";
import PosScan from "@/components/PosScan/PosScan.vue";
import CustomerForm from "@/components/CustomerForm/CustomerForm.vue";

export default {
  name: "Pos",
  data() {
    return {
      CCPAmount: 0,
      PointsAmount: 0,
      PaymentAmount: 0,
      socket: undefined,
      Entities,
      isPreOrderSelected: true,
      customerId: undefined,
      Utilities: Utilities,
      selectedLevel1: undefined,
      selectedLevel2: undefined,
      isProductAddedByBarcodeLoading: false,
      productBarcode: "",
      dfCannabisLimit: 0,
      stateCannabisLimit: 0,
      loadingStatus: {},
      productHierarchy: {},
      flights: [],
      Constants: Constants,
      isCannabis: true,
      searchModal: {
        entity: undefined,
        showSearchModal: false,
        columnHeaders: [],
        searchColumnHeaders: [],
      },
      isProductBrowserPopUpOpen: false,
      isProductSearchPopUpOpen: false,
      productBrowserOrderType: "recreational",
      isPaymentPopUpOpen: false,
      popUpMessages: [],
      isLoyaltyLookupOpen: false,
      isScanPopUpOpen: false,
      spendingInformation: {},
      isCustomerInfoPopUpOpen: false,
      customerLocationInformation: {},
      isNewCustomerPopUpOpen: false,
    };
  },
  components: {
    PosHeader,
    PosMainInput,
    PosProductBrowser,
    PosOrderDetails,
    PosCart,
    BaseBtn,
    BaseModal,
    ProductBrowserWrapper,
    Payment,
    CustomerNotes,
    LoyaltyLookup,
    PosScan,
    ProductSearch,
    CustomerInfo,
    CustomerForm,
  },
  created: function () {
    this.setPageTitle("Point of Sale");
    const storedOrderId = LocalStorageManager.getLocalStorageKey("orderId");
    if (storedOrderId) {
      this.performGetOrderById(storedOrderId);
    }
    this.getStoreCurrentStore();
    const posEmployeeId = localStorage.getItem("employeeId");
    if (posEmployeeId) {
      this.getEmployeeById({ _id: posEmployeeId, isPosEmployee: true });
    }

    this.spendingInformation = {};
  },
  mounted() {
    // Connect to socket
    this.socket = SocketHelper.generateSocket();

    this.socket.on("connect", () => {
      // console.log(`Socket connected`);
    });

    this.socket.on("new_queue_customer", () => {
      this.setRefetchTableData(Math.random());
    });
  },
  computed: {
    ...mapGetters({
      order: "orderModule/posOrder",
      itemHierarchy: "posModule/itemHierarchy",
      posLoadingStatus: "posModule/loadingStatus",
      employee: "employeeModule/posEmployee",
      currentStore: "storeModule/currentStore",
      taxes: "taxSetupModule/allActive",
      orderLoadingStatus: "orderModule/loadingStatus",
      selectedWorkstation: "posModule/selectedWorkstation",
    }),
    loyaltySystem() {
      return this.currentStore?.loyaltySystem || 'dispense-first';
    },
    archiveMessage() {
      if (this.order.giftCardUsed) {
        return "A gift card on the order has been used and can't be archived. Would you like to archive all other items are the order?";
      }
      return Constants.strings.archiveConfirmation;
    },
    getCurrentOrderCustomer() {
      return this.order.customer || undefined;
    },
    getAverageSpending() {
      return this.spendingInformation?.avg || 0;
    },
    getCustomerName() {
      return this.order?.customer?.name;
    },
    getCustomerLastName() {
      return this.order?.customer?.lastName;
    },
    getCustomerEmail() {
      return this.order?.customer?.email;
    },
    getCustomerState() {
      if (this.order?.customer) {
        return this.order?.customer?.state;
      } else {
        return this.currentStore.state;
      }
    },
    getCustomerLicenseNumber() {
      return this.order?.customer?.licenseNumber;
    },
  },
  watch: {
    itemHierarchy(newItemHierarchy) {
      if (this.selectedLevel1) {
        this.selectedLevel1 = newItemHierarchy.children.find(
          (c) => c._id == this.selectedLevel1._id
        );
      }
      if (this.selectedLevel2) {
        newItemHierarchy.children.forEach((c1) => {
          this.selectedLevel2 = this.selectedLevel1.children.find(
            (c) => c._id == this.selectedLevel2._id
          );
        });
      }
    },
    order(order) {
      if (order.customer) {
        this.getCustomerSpendingInformation({
          customerId: order.customer._id,
          positiveCallback: (result) => {
            this.spendingInformation = result;
          },
        });

        this.getCustomerLocationInformation({
          customerId: order.customer._id,
          positiveCallback: (result) => {
            this.customerLocationInformation = result;
          },
        });
      }
    },
  },
  methods: {
    ...mapActions({
      setRefetchTableData: "stateModule/setRefetchTableData",
      addRemoveItemToOrder: "orderModule/addRemoveItemToOrder",
      unsetLoadingStatus: "orderModule/unsetLoadingStatus",
      getStoreCurrentStore: "storeModule/getCurrentStore",
      getCustomers: "customerModule/getCustomers",
      setCustomerPopUp: "customerModule/setCustomerPopUp",
      setQueuePopUp: "queueModule/setQueuePopUp",
      setOrderTypeSelectionPopUp: "orderModule/setOrderTypeSelectionPopUp",
      getOrderById: "orderModule/getOrderById",
      cancelOrder: "orderModule/cancelOrder",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getItemHierarchy: "posModule/getItemHierarchy",
      assignCustomer: "orderModule/assignCustomer",
      setSelectedProductPopUp: "posModule/setSelectedProductPopUp",
      addItemToOrderByBarcode: "orderModule/addItemToOrderByBarcode",
      setOrderPopUp: "orderModule/setOrderPopUp",
      saveOrder: "orderModule/saveOrder",
      updateOrder: "orderModule/updateOrder",
      setLoyaltyCustomer: "orderModule/setLoyaltyCustomer",
      deleteCurrentOrder: "orderModule/deleteCurrentOrder",
      setEmployeeConfirmationPopUp:
        "employeeModule/setEmployeeConfirmationPopUp",
      getCustomer: "posModule/getCustomer",
      getCustomerById: "customerModule/getCustomerById",
      getCardExpiration: "customerModule/getCardExpiration",
      removeHierarchy: "posModule/removeHierarchy",
      setSellOptionsPopUp: "posModule/setSellOptionsPopUp",
      setOrderTypePopUp: "posModule/setOrderTypePopUp",
      setOrderModePopUp: "posModule/setOrderModePopUp",
      setCaregiverPatientSelectionPopUp:
        "posModule/setCaregiverPatientSelectionPopUp",
      setRecreationalOrderTypePopUp: "posModule/setRecreationalOrderTypePopUp",
      archiveOrder: "orderModule/archiveOrder",
      setDiscountsPopUp: "posModule/setDiscountsPopUp",
      setGiftCardPopUp: "posModule/setGiftCardPopUp",
      setGiftCardCartPopUp: "posModule/setGiftCardCartPopUp",
      openCashDrawer: "posModule/openCashDrawer",
      getEmployeeById: "employeeModule/getEmployeeById",
      print: "orderModule/print",
      deleteQueueCustomer: "queueModule/deleteQueueCustomer",
      setNotePopUp: "setNotePopUp",
      processOrder: "orderModule/processOrder",
      getValidCreditMemoPerCustomerIdAndDate:
        "creditMemoModule/getValidCreditMemoPerCustomerIdAndDate",
      fulfillmentPrint: "posModule/fulfillmentPrint",
      getCustomerSpendingInformation:
        "customerModule/getCustomerSpendingInformation",
      getCustomerLocationInformation:
        "customerModule/getCustomerLocationInformation",
      processPrices: "orderModule/processPrices",
    }),
    async performGetValidCreditMemoPerCustomerIdAndDate(customerId) {
      const payload = {
        customerId: customerId,
        date: new Date(),
        memoType: "credit-memos-except-ccp-points",
      };

      this.getValidCreditMemoPerCustomerIdAndDate(payload)
        .then((res) => (this.PaymentAmount = res))
        .catch(() => {});

      const payloadPoints = {
        customerId: customerId,
        date: new Date(),
        memoType: "Points",
      };

      this.getValidCreditMemoPerCustomerIdAndDate(payloadPoints)
        .then((res) => (this.PointsAmount = res))
        .catch(() => {});

      const payloadCCP = {
        customerId: customerId,
        date: new Date(),
        memoType: "CCP",
      };

      this.getValidCreditMemoPerCustomerIdAndDate(payloadCCP)
        .then((res) => (this.CCPAmount = res))
        .catch(() => {});
    },
    performProcess() {
      this.processOrder({ order: this.order, isPos: true });
    },
    setLevel(levelInfo) {
      this[levelInfo.level] = levelInfo.value;
    },
    performArchiveOrder: function () {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.archiveMessage,
        positiveCallback: this.archiveOrder,
      });
    },
    postItemAddRemoveActions(options) {
      if (this.order.customer) {
        // TODO only if medical
        this.getCustomerCannabisLimit(this.order.customer);
      }
      this.getItemHierarchy({
        orderType: this.order.type,
        isCannabis: this.isCannabis,
      });
      if (options?.isScan) {
        this.productBarcode = "";
        this.focusBarcode();
      }
    },
    postItemAddRemoveNegativeActions(p, options) {
      if (p) {
        this.$set(p, "isLoading", false);
      }
      this.unsetLoadingStatus();
    },
    performSelectProductFromCart: function (p) {
      const isGiftCard = p?.externalFields?.group?.isGiftCard;
      if (isGiftCard) {
        this.setGiftCardCartPopUp({
          isOpen: true,
          product: p,
          positiveCallback: this.postItemAddRemoveActions,
          negativeCallback: () => {
            this.postItemAddRemoveNegativeActions(p);
          },
        });
        return;
      }

      if (p.flight_Id_No) {
        this.setFlightsPopUp({ isOpen: true });
      } else {
        this.setSelectedProductPopUp({
          isOpen: true,
          product: p,
          order: this.order,
          mode: "edit",
          positiveCallback: this.postItemAddRemoveActions,
          negativeCallback: () => {
            this.postItemAddRemoveNegativeActions(p);
          },
        });
      }
    },
    performSelectProductFromGroup: async function (p, forcedItem) {
      if (p.isLoading) {
        return;
      }
      if (!this.order.products) {
        return;
      }
      if (!this.order.customer && !this.order.isRetail) {
        return;
      }

      const isGiftCard = p?.externalFields?.group?.isGiftCard;
      var selectedProduct = p;

      if (isGiftCard) {
        this.setGiftCardPopUp({
          isOpen: true,
          isBalanceLookUp: false,
          positiveCallback: async (barcode, barcodeEnd) => {
            const count = barcodeEnd ? (barcodeEnd - barcode + 1) : 1;

            if(count > 1) {
              for(let i = 0; i < count; i++) {
                let startBarcode = parseInt(barcode) + i;
                await this.addRemoveItemToOrder({
                action: {
                  productId: selectedProduct._id,
                  mode: "add",
                  quantity: 1,
                  giftCardBarcode: startBarcode.toString(),
                },
                positiveCallback: () => {
                  this.setGiftCardPopUp({
                    isOpen: false,
                  });
                },
                negativeCallback: () => {
                  this.postItemAddRemoveNegativeActions(p);
                },
              });
              }
            } else {
              this.addRemoveItemToOrder({
                action: {
                  productId: selectedProduct._id,
                  mode: "add",
                  quantity: count,
                  giftCardBarcode: barcode,
                },
                positiveCallback: () => {
                  this.setGiftCardPopUp({
                    isOpen: false,
                  });
                },
                negativeCallback: () => {
                  this.postItemAddRemoveNegativeActions(p);
                },
              });
            }
          },
        });
        return;
      }

      const showPopUp =
        this.currentStore.isShowQuantityBoxEnabled ||
        selectedProduct.group?.unitOfMeasure?.toLowerCase() != "unit";

      if (!showPopUp) {
        this.$set(p, "isLoading", true);
      }

      var mode = "add";
      this.order.products.forEach((element) => {
        if (element._id === p._id) {
          selectedProduct = element;
          this.$set(selectedProduct, "isLoading", true);
          mode = "edit";
        }
      });
      if (showPopUp) {
        this.setSelectedProductPopUp({
          isOpen: true,
          product: selectedProduct,
          forcedItem,
          mode: mode,
          order: this.order,
          positiveCallback: this.postItemAddRemoveActions,
          negativeCallback: () => {
            this.postItemAddRemoveNegativeActions(p);
          },
        });
      } else {
        this.addRemoveItemToOrder({
          action: {
            productId: selectedProduct._id,
            mode: "add",
            quantity: 1,
          },
          positiveCallback: this.postItemAddRemoveActions,
          negativeCallback: () => {
            this.postItemAddRemoveNegativeActions(p);
          },
        });
      }
    },
    clearOrder() {
      this.cancelOrder();
      this.removeHierarchy();
      this.customerId = undefined;
      this.selectedLevel1 = undefined;
      this.selectedLevel2 = undefined;
      this.spendingInformation = {};
      (this.customerLocationInformation = {}), (this.PointsAmount = 0);
      this.CCPAmount = 0;
      this.PaymentAmount = 0;
      this.popUpMessages = []
    },
    performCancelOrder: function (askConfirmation = true) {
      this.setMessagePopUp({
        isOpen: true,
        title: "Warning!",
        message: "Do you really want to close this order?",
        positiveCallback: () => {
          this.clearOrder();
        },
      });
    },
    performGetCustomer: function (customerId) {
      this.customerId = customerId?.target?.value;

      if (!this.customerId || this.customerId.length == 0) {
        return;
      }

      // check queue first
      if (this.customerId.length == 8 && !this.customerId.includes(".")) {
        searchEntities({
          entity: Entities.QUEUE,
          filter: { code: this.customerId },
          positiveCallback: (result) => {
            if (result.length == 0) {
              this.setMessage({
                title: "Queue Customer not Found",
                text: `Queue Customer not Found`,
                type: "error",
              });
            } else {
              const queueCustomer = result[0];
              this.searchModal.entity = Entities.QUEUE;
              this.searchSelectItem(undefined, queueCustomer);
            }
          },
        });
        return;
      }

      // check customer id
      if (this.customerId.length == 24) {
        this.getCustomer({
          id: parseSrn(this.customerId),
          positiveCallback: (customer) => {
            if (!customer) {
              this.setMessage({
                title: "Customer not Found",
                text: `Customer ID not Found`,
                type: "error",
              });
            } else {
              this.performAssignCustomer(customer);
              this.focusBarcode();
            }
          },
          negativeCallback: (customer) => {
            this.setMessage({
              title: "Customer not Found",
              text: `Customer ID not Found`,
              type: "error",
            });
          },
        });
        return;
      }

      // check srn
      if (isSrn(parseSrn(this.customerId))) {
        this.getCustomer({
          id: parseSrn(this.customerId),
          positiveCallback: (customer) => {
            if (!customer) {
              this.setMessage({
                title: "Customer not Found",
                text: `Customer SRN not Found`,
                type: "error",
              });
            } else {
              this.performAssignCustomer(customer);
              this.focusBarcode();
            }
          },
          negativeCallback: (customer) => {
            this.setMessage({
              title: "Customer not Found",
              text: `Customer SRN not Found`,
              type: "error",
            });
          },
        });
      }
    },
    focusBarcode: function () {
      try {
        this.$refs.posMainInput.focusBarcode();
      } catch (error) {
        console.log(error);
      }
    },
    searchProduct: function (event) {
      let barcode = event.target.value;
      if (!barcode || barcode.length === 0) {
        return;
      }

      barcode = barcode.trim();
      barcode = barcode.replaceAll(' ', '');
      if (this.currentStore.isShowQuantityBoxEnabled) {
        this.addItemToOrderByBarcode({
          action: {
            barcode: barcode,
            queryItem: true,
          },
          positiveCallback: (item) => {
            const product = item.product;
            this.performSelectProductFromGroup(product, item);
          },
          negativeCallback: this.postItemAddRemoveNegativeActions,
        });
      } else {
        this.addItemToOrderByBarcode({
          action: {
            barcode: barcode,
            queryItem: true,
          },
          positiveCallback: (item) => {
            const product = item.product;
            if (product.externalFields.group.unitOfMeasure != "Unit") {
              this.performSelectProductFromGroup(product, item);
            } else {
              this.addItemToOrderByBarcode({
                action: {
                  barcode: barcode,
                },
                positiveCallback: () =>
                  this.postItemAddRemoveActions({ isScan: true }),
                negativeCallback: this.postItemAddRemoveNegativeActions,
              });
            }
          },
          negativeCallback: this.postItemAddRemoveNegativeActions,
        });
      }
    },
    performGetOrderById(orderId) {
      this.getOrderById({
        _id: orderId,
        isPos: true,
        positiveCallback: (order) => {
          if (order.type) {
            this.getItemHierarchy({
              orderType: order.type,
              isCannabis: this.isCannabis,
            });
          }
          if (order.customer) {
            this.performGetValidCreditMemoPerCustomerIdAndDate(
              order.customer._id
            );
            this.getCustomerCannabisLimit(order.customer);
            if (order.type == "medical") {
              this.performGetCardExpiration(order.customer);
            }
          } else {
            this.PaymentAmount = 0;
            this.PointsAmount = 0;
            this.CCPAmount = 0;
          }
          this.performPopUpMessage(order);
          // If order is not a preorder, then it cannot be manipulated as it is an ongoing order from online store
          // archived, pending, or an order without items is ok
          if (
            !order.isPreorder &&
            !order.isArchived &&
            !order.isPending &&
            order.products.length > 0
          ) {
            this.cancelOrder();
            this.setMessage({
              text: "The selected order cannot be edited.",
              type: "error",
            });
          }

          // Update pricing, this is needed to remove potential expired offers
          this.processPrices({ order: order });
        },
      });
    },
    showCustomerPopUp: function () {
      this.searchModal = {
        title: "Search Customer",
        showSearchModal: true,
        entity: Entities.CUSTOMER,
        searchColumnHeaders: customerSearchColumnHeaders,
        columnHeaders: customerColumnHeaders,
        filterCriteria: customerFilterCriteria,
        filterButtons: undefined,
        sort: { lastName: 1 },
      };
    },
    showQueuePopUp: function () {
      let filteredQueueFilterCriteria = queueFilterCriteria;
      if (!this.currentStore.printBarcodeOnCheckIn) {
        filteredQueueFilterCriteria = queueFilterCriteria.filter(
          (f) => f.code != "code"
        );
      }
      const payload = {
        isOpen: true,
        positiveCallback: () =>
          (this.searchModal = {
            title: "Search Customer in Queue",
            bigIcons: true,
            showSearchModal: true,
            entity: Entities.QUEUE,
            searchColumnHeaders: queueSearchColumnHeaders,
            columnHeaders: queueColumnHeaders,
            filterCriteria: filteredQueueFilterCriteria,
            defaultFilter: {
              createdAt: {
                $gte: moment().format("YYYY-MM-DDT00:00:00.000Z"),
                $lte: moment().format("YYYY-MM-DDT23:59:00.000Z"),
              },
              status: { $ne: "checkout" },
            },
            sort: {
              createdAt: -1,
            },
            filterButtons: queueFilterButtons,
          }),
      };

      payload.positiveCallback();
    },
    getCustomerCannabisLimit(customer) {
      this.getCustomerById({
        _id: customer._id,
        positiveCallback: (result) => {
          this.dfCannabisLimit =
            result.stateCannabisLimit - this.calculateAllotment();
          this.stateCannabisLimit = result.stateCannabisLimit;
        },
      });
    },
    calculateAllotment() {
      const order = this.order;
      let allotment = 0;
      if (order && order.products) {
        order.products.forEach((p) => {
          if (p.cannabisGramsUnit) {
            allotment += p.quantity * p.cannabisGramsUnit;
          }
        });
      }
      return allotment;
    },
    performGetCardExpiration(customer) {
      this.getCardExpiration({
        _id: customer._id,
        positiveCallback: (result) => {
          let diffFromToday = moment(result).diff(moment(), "days");
          if (diffFromToday <= 10) {
            let exprirationMessage = moment(result).from(moment());
            this.setMessage({
              title: "Medical Card Expires Soon",
              text: `Medical card expires ${exprirationMessage}`,
              type: "error",
            });
          }
        },
        negativeCallback: () => {
          // If check goes wrong, then cancel order
          this.cancelOrder();
        },
      });
    },
    performAssignCustomer(customer) {
      if (!customer.accountType || customer.accountType.length === 0) {
        this.setMessage({
          text: "No account type for this customer.",
          type: "error",
        });
        return;
      }

      if (!customer.isRetail) {
        this.order.isRetail = false;
        this.order.isInState = customer.state == this.currentStore.state;
        // Fallback to IL if customer state is not defined and it is not retail
        if (!customer.state) {
          this.order.isInState = true;
        }
      }

      // Clutch specific logic, it is ok to leave it here for now
      if (
        this.currentStore.loyaltySystem === "clutch" &&
        customer.loyaltyInfo?.cardNumber
      ) {
        this.order.loyaltyInfo = {
          cardNumber: customer.loyaltyInfo?.cardNumber,
        };
      }

      this.performGetValidCreditMemoPerCustomerIdAndDate(customer._id);

      if (
        customer.accountType === "medical-and-recreational" &&
        this.currentStore.isMedical &&
        this.currentStore.isRecreational
      ) {
        this.setOrderTypePopUp({
          isOpen: true,
          positiveCallback: (result) => {
            if (
              result.type === "recreational" &&
              customer.state != this.currentStore.state
            ) {
              this.order.isInState = false;
              this.customerAssignationWrapper(
                customer,
                result.type,
                this.order
              );
            } else {
              this.handleMedicalCustomer(customer, result.type, this.order);
            }
          },
        });
      } else {
        if (
          customer.accountType.includes("rec") &&
          this.currentStore.isRecreational
        ) {
          return this.customerAssignationWrapper(
            customer,
            "recreational",
            this.order
          );
        }

        if (
          customer.accountType.includes("med") &&
          this.currentStore.isMedical
        ) {
          return this.handleMedicalCustomer(customer, "medical", this.order);
        }

        let storeType = "";
        if (this.currentStore.isRecreational) {
          storeType += "recreational ";
        }
        if (this.currentStore.isMedical) {
          storeType += "medical ";
        }

        return this.setMessage({
          text: `Customer incompatible with store: customer account type: ${customer.accountType}, store type is ${storeType}`,
          type: "error",
        });
      }
    },
    performAssignUnregisteredCustomer(unregisteredCustomer) {
      // set order as in state if check in state matches with dispensary state
      const isInState =
        unregisteredCustomer.customerInfo.state == this.currentStore.state;
      this.order.isInState = isInState;
      this.customerAssignationWrapper(
        {
          isRetail: true,
          accountType: unregisteredCustomer.accountType,
        },
        unregisteredCustomer.accountType,
        this.order
      );
    },
    customerAssignationWrapper(customer, forceType, order, caregiver) {
      const clonedOrder = JSON.parse(JSON.stringify(order));
      if (caregiver) {
        clonedOrder.caregiver = caregiver;
      }
      clonedOrder.customer = customer;
      if (forceType) {
        clonedOrder.type = forceType;
      } else {
        clonedOrder.type = customer.accountType;
      }
      if (customer.isRetail) {
        clonedOrder.isRetail = true;
      }
      if (clonedOrder.type === "medical") {
        this.getCustomerCannabisLimit(clonedOrder.customer);
        this.performGetCardExpiration(clonedOrder.customer);
      }
      this.focusBarcode();

      this.updateOrder({
        order: clonedOrder,
      });
      this.getItemHierarchy({
        orderType: clonedOrder.type,
        isCannabis: this.isCannabis,
      });
      this.performPopUpMessage(clonedOrder);
    },
    handleMedicalCustomer(customer, forceType, order, mode, caregiver) {
      this.getCustomerCannabisLimit(customer);

      let type = customer.accountType;
      if (forceType) {
        type = forceType;
      }

      if (customer.isMinor && !caregiver) {
        this.setMessage({
          text: "Minor patient. This patient needs a caregiver.",
          type: "error",
        });
        return;
      }

      if (customer.isMinor && !caregiver) {
        this.setMessage({
          text: "This patient needs a caregiver.",
          type: "error",
        });
        return;
      }

      if (
        customer.isCaregiver &&
        (order.type == "medical" || forceType == "medical")
      ) {
        if (mode) {
          if (mode == "patient") {
            this.customerAssignationWrapper(customer, forceType, order);
          }
        } else {
          // Caregiver can be also a customer or rec. Ask choice
          this.setOrderModePopUp({
            isOpen: true,
            customer,
            positiveCallback: (result) => {
              switch (result.type) {
                case "patient":
                  this.customerAssignationWrapper(customer, type, order);
                  break;

                default:
                  this.getCustomerById({
                    _id: customer._id,
                    positiveCallback: (result) => {
                      this.setCaregiverPatientSelectionPopUp({
                        isOpen: true,
                        customer: result,
                        positiveCallback: (result) => {
                          this.customerAssignationWrapper(
                            result,
                            type,
                            order,
                            customer
                          );
                        },
                      });
                    },
                  });
                  break;
              }
            },
          });
        }
      } else {
        if (mode == "patient-with-caregiver") {
          this.customerAssignationWrapper(customer, type, order, caregiver);
        } else {
          this.customerAssignationWrapper(customer, type, order);
        }
      }
    },
    askForStateTypeOrder(options) {
      this.setRecreationalOrderTypePopUp({
        isOpen: true,
        positiveCallback: function (result) {
          const clonedOrder = JSON.parse(JSON.stringify(this.order));

          if (result.type === "in-state") {
            clonedOrder.isInState = true;
          } else {
            clonedOrder.isInState = false;
          }
          if (options.positiveCallback) {
            options.positiveCallback(clonedOrder);
          }
        }.bind(this),
      });
    },
    toggleSearchModal() {
      this.searchModal.showSearchModal = !this.searchModal.showSearchModal;
    },
    searchSelectItem(entity, selectedEntity, nestedId) {
      switch (this.searchModal.entity) {
        case Entities.ORDER:
          this.performGetOrderById(selectedEntity._id);
          this.getItemHierarchy({
            orderType: selectedEntity.type,
            isCannabis: this.isCannabis,
          });
          break;

        case Entities.QUEUE: {
          let payload = {
            isOpen: true,
            positiveCallback: () => {
              if (!selectedEntity.hasPreOrder) {
                this.saveOrder({
                  order: {
                    _id: "NEW" || this.order._id,
                    source: "pos",
                    setQueueCustomer: true,
                    queueCustomer: selectedEntity,
                    type: selectedEntity.orderType,
                  },
                  positiveCallback: () => {
                    this.order.setQueueCustomer = true;
                    this.order.queueCustomer = selectedEntity;
                    if (selectedEntity.customer) {
                      this.order.isInState =
                        selectedEntity.customerInfo?.state ==
                        this.currentStore?.state;

                      if (selectedEntity.orderType == "medical") {
                        if (selectedEntity.caregiver) {
                          this.handleMedicalCustomer(
                            selectedEntity.customer,
                            "medical",
                            this.order,
                            "patient-with-caregiver",
                            selectedEntity.caregiver
                          );
                        } else {
                          this.handleMedicalCustomer(
                            selectedEntity.customer,
                            "medical",
                            this.order,
                            "patient"
                          );
                        }
                      } else {
                        this.customerAssignationWrapper(
                          selectedEntity.customer,
                          "recreational",
                          this.order
                        );
                      }
                      this.performGetValidCreditMemoPerCustomerIdAndDate(
                        selectedEntity.customer._id
                      );
                    } else {
                      this.performAssignUnregisteredCustomer(selectedEntity);
                    }
                  },
                });
              } else {
                // TODO: replace this saveOrder with a dedicated api
                const order = {
                  _id: selectedEntity.preOrder,
                  setQueueCustomer: true,
                  queueCustomer: selectedEntity,
                  customer: selectedEntity.customer,
                  isInState:
                    selectedEntity.customerInfo?.state ==
                    this.currentStore?.state,
                };
                this.saveOrder({
                  order: order,
                  positiveCallback: () => {
                    // Update pricing, this is needed to remove potential expired offers
                    this.processPrices({ order: this.order });
                    this.getItemHierarchy({
                      orderType: this.order.type,
                      isCannabis: this.isCannabis,
                    });
                  },
                });
                this.deleteCurrentOrder();
              }
            },
          };

          if (this.currentStore.isPinAlwaysRequired) {
            return this.setEmployeeConfirmationPopUp(payload);
          }

          if (Utilities.isEmployeeConfirmationExpired(this.employee)) {
            this.setEmployeeConfirmationPopUp(payload);
          } else {
            payload.positiveCallback();
          }

          break;
        }

        case Entities.CUSTOMER:
          this.$nextTick(() => this.performAssignCustomer(selectedEntity));
          break;

        default:
          break;
      }
    },
    removeItem(entity, selectedEntity, nestedId) {
      switch (this.searchModal.entity) {
        case Entities.QUEUE:
          this.setMessagePopUp({
            isOpen: true,
            title: Constants.strings.warningMessageTitle,
            message: "Confirm queue customer deletion",
            positiveCallback: () =>
              this.performDeleteQueueCustomer(selectedEntity),
          });
          break;

        default:
          break;
      }
    },
    performDeleteQueueCustomer(customer) {
      this.deleteQueueCustomer({ customer });
    },
    showOrdersPopUp() {
      const payload = {
        isOpen: true,
        positiveCallback: () =>
          (this.searchModal = {
            title: "Search Open Orders",
            showSearchModal: true,
            entity: Entities.ORDER,
            searchColumnHeaders: orderSearchColumnHeaders,
            columnHeaders: orderColumnHeaders,
            filterCriteria: orderFilterCriteria,
            bigIcons: true,
            enableCardniality: false,
            filter: {
              isPostedToJournal: false,
              isPaid: false,
              isArchived: false,
              isVoided: false,
              $or: [
                { source: "pos" },
                { source: "online-store", isPreorder: true },
                { source: "kiosk", isPreorder: true },
              ],
            },
            sort: {
              createdAt: -1,
            },
          }),
      };

      if (this.currentStore.isPinAlwaysRequired) {
        return this.setEmployeeConfirmationPopUp(payload);
      }

      if (Utilities.isEmployeeConfirmationExpired(this.employee)) {
        this.setEmployeeConfirmationPopUp(payload);
      } else {
        payload.positiveCallback();
      }
    },
    performNewOrder() {
      this.categoryLevel1 = undefined;
      this.categoryLevel2 = undefined;
      const payload = {
        isOpen: true,
        positiveCallback: () =>
          this.saveOrder({
            order: {
              _id: "NEW",
              source: "pos",
            },
          }),
      };

      if (this.currentStore.isPinAlwaysRequired) {
        return this.setEmployeeConfirmationPopUp(payload);
      }

      if (Utilities.isEmployeeConfirmationExpired(this.employee)) {
        this.setEmployeeConfirmationPopUp(payload);
      } else {
        payload.positiveCallback();
      }
    },
    changeEmployee() {
      this.setEmployeeConfirmationPopUp({
        isOpen: true,
      });
    },
    performSetSellOptionsPopUp() {
      this.setSellOptionsPopUp({
        isOpen: true,
        positiveCallback: () => {
          this.clearOrder();
        },
      });
    },
    createRetailOrder() {
      this.setOrderTypeSelectionPopUp({
        isOpen: true,
        positiveCallback: function (type) {
          this.order.isInState = type === "in-state";
          this.performAssignCustomer({
            isRetail: true,
            accountType: "recreational",
          });
        }.bind(this),
      });
    },
    startRetailOrder() {
      this.toggleSearchModal();
      this.searchSelectItem(undefined, {
        isRetail: true,
        accountType: "recreational",
      });
    },
    cannabisToggle() {
      this.isCannabis = !this.isCannabis;
      this.getItemHierarchy({
        orderType: this.order.type,
        isCannabis: this.isCannabis,
      });
      this.selectedLevel1 = undefined;
      this.selectedLevel2 = undefined;
    },
    showProductBrowser() {
      this.isProductBrowserPopUpOpen = true;
      // TODO check default store type medical/recreational
      this.getItemHierarchy({
        orderType: this.productBrowserOrderType,
        isCannabis: this.isCannabis,
      });
    },
    showProductSearch() {
      this.isProductSearchPopUpOpen = true;
      // TODO check default store type medical/recreational
      this.getItemHierarchy({
        orderType: this.productBrowserOrderType,
        isCannabis: this.isCannabis,
      });
    },
    togglePaymentModal(event) {
      this.isPaymentPopUpOpen = event.isOpen;
    },
    toggleScanModal(event) {
      this.isScanPopUpOpen = event.isOpen;
    },
    toggleProductBrowserModal(event) {
      this.isProductBrowserPopUpOpen = event.isOpen;
      if (!event.isOpen) {
        this.selectedLevel1 = undefined;
        this.selectedLevel2 = undefined;
        this.isCannabis = true;
      }
    },
    setProductBrowserOrderType(event) {
      this.productBrowserOrderType = event.orderType;
      this.selectedLevel1 = undefined;
      this.selectedLevel2 = undefined;
      this.getItemHierarchy({
        orderType: this.productBrowserOrderType,
        isCannabis: this.isCannabis,
      });
    },
    performOrderPrint() {
      this.print({ isPos: true });
    },
    performOrderFulfillmentPrint() {
      this.fulfillmentPrint({ orderId: this.order?._id });
    },
    getCreditMemosForCustomer(cb) {
      searchEntities({
        entity: Entities.CREDITMEMO,
        filter: { customer: this.order.customer._id, memoBalance: { $gt: 0 } },
        positiveCallback: cb,
      });
    },
    performSetNotePopUp(forcedCreditMemos) {
      const payload = {
        isOpen: true,
        creditMemos: forcedCreditMemos,
        order: this.order,
      };
      if (!forcedCreditMemos) {
        this.getCreditMemosForCustomer((creditMemos) => {
          payload.creditMemos = creditMemos;
          this.setNotePopUp(payload);
        });
      } else {
        this.setNotePopUp(payload);
      }
    },
    performPopUpMessage(order) {
      this.popUpMessages = order?.customer?.noteList?.filter(
        (n) => n.noteType == "pop-up-message"
      );
    },
    postCustomerAssignationChecks() {
      try {
        if (
          this.order.customer.noteList.filter((n) => !n.isFollowedUp).length > 0
        ) {
          this.performSetNotePopUp();
        } else {
          this.getCreditMemosForCustomer((creditMemos) => {
            if (creditMemos.length > 0) {
              this.performSetNotePopUp(creditMemos);
            }
          });
        }
      } catch (error) {
        console.log("Error in postCustomerAssignationChecks");
      }
    },
    showCreditMemo(cm) {
      this.setMessagePopUp({
        isOpen: true,
        title: "Credit Memo Earned!",
        message: `This customer has just earned a $${cm.memoBalance.toFixed(
          2
        )} credit memo for future purchases!`,
        isAknowledge: true,
      });
    },
    showLoyaltyLookup() {
      this.isLoyaltyLookupOpen = true;
    },
    showNewCustomerPopUp() {
      this.isNewCustomerPopUpOpen = true;
    },
    selectLoyaltyCustomer(id, loyaltyCustomer, nestedId) {
      // TODO add also DF customer to the order
      this.setLoyaltyCustomer({
        orderId: this.order._id,
        customerId: this.order.customer?._id,
        cardNumber: loyaltyCustomer.cardNumber,
        updatedAt: this.order.updatedAt,
      });
      this.isLoyaltyLookupOpen = false;
    },
    handleSellOptions() {
      if (
        this.currentStore.isPOSScanningEnabled &&
        !this.order.isPaid &&
        !this.order.isArchived
      ) {
        this.toggleScanModal({ isOpen: true });
      } else {
        this.togglePaymentModal({ isOpen: true });
      }
    },
    handleScanDone() {
      this.toggleScanModal({ isOpen: false });
      this.togglePaymentModal({ isOpen: true });
    },
    showCustomerInfo() {
      this.isCustomerInfoPopUpOpen = true;
    },
    customerCreation(customer) {
      this.isNewCustomerPopUpOpen = false;

      // Assign user if this was an order with no user
      if (!this.order?.customer) {
        // This already assign the clutch card to the order
        this.performAssignCustomer(customer);
      } else {
        // Assign clutch card to order after customer creation
        this.selectLoyaltyCustomer(undefined, customer.loyaltyInfo);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.socket.disconnect();
    next(true);
  },
};
</script>

<style lang="scss">
@import "_pos.scss";
</style>
