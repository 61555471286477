<template>
  <div class="chemical-composition">
    <div v-if="title" class="section-base__header space-between">
      <div class="section-base__header-name">{{ title }}</div>
    </div>
    <div class="padded elevated-0 bordered">
      <div class="chemical-composition__row">
        <Input
          :inputData="getInput('totalCannabinoids')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.totalCannabinoids"
          @updateModel="chemicalComposition.totalCannabinoids = $event"
        />
      </div>
      <div class="chemical-composition__row">
        <Input
          :inputData="getInput('thc')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.thc"
          @updateModel="chemicalComposition.thc = $event"
        />
        <Input
          :inputData="getInput('thca')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.thca"
          @updateModel="chemicalComposition.thca = $event"
        />
        <Input
          :inputData="getInput('cbd')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.cbd"
          @updateModel="chemicalComposition.cbd = $event"
        />
        <Input
          :inputData="getInput('cbda')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.cbda"
          @updateModel="chemicalComposition.cbda = $event"
        />
      </div>
      <div class="chemical-composition__row">
        <Input
          :inputData="getInput('cbg')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.cbg"
          @updateModel="chemicalComposition.cbg = $event"
        />
        <Input
          :inputData="getInput('cbn')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.cbn"
          @updateModel="chemicalComposition.cbn = $event"
        />
        <Input
          :inputData="getInput('cbc')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.cbc"
          @updateModel="chemicalComposition.cbc = $event"
        />
      </div>
      <div class="chemical-composition__row">
        <Input
          :inputData="getInput('indicaPercentage')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.indicaPercentage"
          @updateModel="chemicalComposition.indicaPercentage = $event"
        />
        <Input
          :inputData="getInput('sativaPercentage')"
          :isEdit="!disabled"
          :disabled="disabled"
          :model="chemicalComposition.sativaPercentage"
          @updateModel="chemicalComposition.sativaPercentage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import { chemicalCompositionInputs } from "@/views/PurchaseOrder/inputs";
import Input from "@/components/Input/Input.vue";

export default {
  name: "ChemicalCompositionForm",
  components: { Input },
  props: {
    chemicalComposition: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      Utilities,
    };
  },
  computed: {},
  methods: {
    getInput(field) {
      return chemicalCompositionInputs.find((i) => i.id == field);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_chemicalCompositionForm.scss";
</style>
