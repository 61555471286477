<template>
  <div class="item-return-popUp">
    <div class="item-return-popUp__quantity">
      <p class="item-return-popUp__quantity__title">Quantity to Return:</p>
      <BaseInput
        :label="quantityLabel"
        placeholder="0"
        v-model="quantityToReturn"
        :controlType="'number'"
        min="0"
        step="1"
      />
    </div>
    <div class="item-return-popUp__type">
      <CheckBox
        sideLabel="Cash"
        :value="returnType == 'cash'"
        @click="() => (returnType = 'cash')"
      />
      <CheckBox
        sideLabel="Credit Memo"
        :value="returnType == 'credit-memo'"
        @click="() => (returnType = 'credit-memo')"
      />
    </div>
    <div class="item-return-popUp__actions">
      <BaseBtn @click="save" :label="'Save'" />
      <!-- <BaseBtn @click="performReturn" :label="'Process Return'" /> -->
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
    },
    order: {
      type: Object,
    },
  },
  data() {
    return {
      quantityToReturn: 1,
      returnType: "credit-memo",
    };
  },
  components: {
    BaseBtn,
    BaseInput,
    CheckBox,
  },
  computed: {
    quantityLabel() {
      return `max: ${this.item.quantity}`;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
    }),
    isValid() {
      if (this.order.isPostedToJournal == false) {
        this.setMessage({
          text: `Cannot return. Order is not posted!`,
          type: "error",
        });
        return false;
      }
      if (this.quantityToReturn === 0) {
        this.setMessage({
          text: `Quantity must be greater than 0!`,
          type: "error",
        });
        return false;
      }
      if (this.quantityToReturn > this.item.quantity) {
        this.setMessage({
          text: `Quantity to return cannot be greater than ${this.item.quantity}`,
          type: "error",
        });
        return false;
      }

      if (!this.returnType) {
        this.setMessage({
          text: `Please select Cash or Credit.`,
          type: "error",
        });
        return false;
      }
      return true;
    },
    save() {
      if (!this.isValid()) return;
      this.item._entity = 'returnItem'
      this.$emit("save-return-item", {
        item: this.item,
        quantity: this.quantityToReturn,
        type: this.returnType,
        originalQuantityOrdered: this.item.quantity,
      });
    },
    performReturn() {
      if (!this.isValid()) return;
      this.$emit("post-return-item", {
        item: this.item,
        quantity: this.quantityToReturn,
        type: this.returnType,
        originalQuantityOrdered: this.item.quantity,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
