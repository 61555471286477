<template>
  <div class="keypad" @click.prevent>
    <div class="numeric-keyboard-container" @click.prevent>
      <BaseBtn
        v-for="button in buttons"
        :label="button.label"
        :key="button.code"
        :colorType="button.colorType"
        @click.stop="eventHandler($event, button)"
      />
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "Keypad",
  props: {
    input: [HTMLInputElement, HTMLTextAreaElement, Object],
  },
  data() {
    return {
      isOpen: true,
      selectedOption: undefined,
      buttons: [
        { label: "1", code: 1, colorType: -1 },
        { label: "2", code: 2, colorType: -1 },
        { label: "3", code: 3, colorType: -1 },
        { label: "4", code: 4, colorType: -1 },
        { label: "5", code: 5, colorType: -1 },
        { label: "6", code: 6, colorType: -1 },
        { label: "7", code: 7, colorType: -1 },
        { label: "8", code: 8, colorType: -1 },
        { label: "9", code: 9, colorType: -1 },
        { label: ".", code: ".", colorType: 3 },
        { label: "0", code: 0, colorType: -1 },
        { label: "C", code: "c", colorType: 3 },
      ],
    };
  },
  components: { BaseBtn },
  computed: {},
  methods: {
    eventHandler(e, button) {
      const lastChar = this.input.value.slice(-1);
      if (button.code == "." && lastChar == ".") return;

      if (button.code == "c") {
        this.input.value = this.input.value.slice(0, -1);
      } else {
        this.input.value += button.code;
      }
      this.input.focus();
      this.input.dispatchEvent(new Event("input", { bubbles: true }));
    },
  },
};
</script>

<style lang="scss">
.numeric-keyboard-container {
  display: grid;
  grid-template-columns: repeat(3, 50px);
  grid-gap: 5px;
  justify-content: center;
}

@mixin numeric-key-mixin {
  display: inline-block;
  background-color: $dark-elevated-0;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 2px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.numeric-key {
  @include numeric-key-mixin;
  margin-top: 5px;
}

.numeric-key-first-row {
  @include numeric-key-mixin;
}

.numeric-key-first-row:not(:last-child) {
  margin-right: 5px;
}

.numeric-key:not(:last-child) {
  margin-right: 5px;
}

.light .numeric-key {
  background-color: $light-elevated-0;
}

.light .numeric-key-first-row {
  background-color: $light-elevated-0;
}
</style>
