import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedTimeSheet } from "@/views/TimeSheet/inputs";

const state = {
  timeSheets: [],
  timeSheet: { ...defaultSelectedTimeSheet },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {},
  setTimeSheets: (state, payload) => {
    state.timeSheets = payload;
  },
  setTimeSheet: (state, payload) => {
    if (payload._id === "NEW")
      state.timeSheet = { ...defaultSelectedTimeSheet };
    else state.timeSheet = payload;
  },
  setPosTimeSheet: (state, payload) => {
    state.posTimeSheet = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  timeSheets: (state) => state.timeSheets,
  timeSheet: (state) => state.timeSheet,
  loadingStatus: (state) => state.loadingStatus,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveTimeSheet: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveTimeSheet", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveTimeSheet,
      payload.timeSheet,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveTimeSheet", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteTimeSheet: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteTimeSheet", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteTimeSheet,
      payload.timeSheet,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteTimeSheet", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  undeleteTimeSheet: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "undeleteTimeSheet", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.undeleteTimeSheet,
      payload.timeSheet,
      function (response, error) {
        commit("setLoadingStatus", { api: "undeleteTimeSheet", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Undeleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getTimeSheets: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getTimeSheets", status: true });
    let url = Apis.backendBaseUrl + Apis.timeSheets.get + "?";
    if (payload?.criteria || payload) {
      url += `&filterCriteria=${payload.filterCriteria}`;
    }
    if (payload?.value || payload) {
      url += `&filterValue=${
        state.selectedFilter?.value || payload.filterValue
      }`;
    }
    if (payload.limit) {
      url += `&limit=${payload.limit}`;
    }
    if (payload.skip) {
      url += `&skip=${payload.skip}`;
    }

    if (payload.stampedIn) {
      url += `&stampedIn=${payload.stampedIn}`;
    }
    if (payload.stampedOut) {
      url += `&stampedOut=${payload.stampedOut}`;
    }

    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getTimeSheets", status: false });
      var timeSheets = {};

      if (response) {
        timeSheets = response.data.body;

        commit("setTimeSheets", timeSheets);
      }
    });
  },
  getTimeSheetById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getTimeSheetById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.timeSheets.getById + "?id=" + payload._id,
      function (response, error) {
        if (response) {
          state.timeSheet = response.data.body;
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getAdjacentTimeSheet: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentTimeSheet", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "timeSheet/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentTimeSheet",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  clockInEmployee: ({ commit, state }, payload) => {
    const url = Apis.backendBaseUrl + "timeSheet/clockIn";
    HttpService.post(url, payload.timeSheet, function (response, error) {
      if (response) {
        commit(
          "setMessage",
          { text: "Saved", type: "success" },
          { root: true }
        );
        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        console.log(error);
        commit(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    });
  },
  clockOutEmployee: ({ commit, state }, payload) => {
    const url = Apis.backendBaseUrl + "timeSheet/clockOut";
    HttpService.post(url, payload.timeSheet, function (response, error) {
      if (response) {
        commit(
          "setMessage",
          { text: "Saved", type: "success" },
          { root: true }
        );
        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        console.log(error);
        commit(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
