var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Inventory Count Differences","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
        {
          name: 'inventoryCountFeatureOff',
          label: 'Inventory Count Differences',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv,"printAll":function($event){_vm.isPrintAllModalOpen = true}}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"isLoading":_vm.inventoryTransactions},on:{"submit-filter":_vm.performGetReport}}),(_vm.isPrintAllModalOpen)?_c('BaseModal',{attrs:{"title":"","autoWidth":true},on:{"toggle-modal":_vm.togglePrintAllModal}},[_c('PrintAll',{attrs:{"items":_vm.reportData.records},on:{"closePrintModal":_vm.togglePrintAllModal}})],1):_vm._e(),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.employee))]),_c('td',[_vm._v(_vm._s(record.item))]),_c('td',[_vm._v(_vm._s(record.batchId))]),_c('td',[_vm._v(_vm._s(record.group))]),_c('td',[_vm._v(_vm._s(record.supplier))]),_c('td',[_vm._v(_vm._s(record.grams))]),_c('td',[_vm._v(_vm._s(record.product))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.handCount))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.currentValue))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.countDifference))])])})],2),_vm._m(1)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Item Id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Batch #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Group")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Grams")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Product")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Hand Count")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Expected")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Difference")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }