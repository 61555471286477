import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLogger from "vuejs-logger";
import "@/axiosLoader";
import vSelect from "vue-select";
// import 'font-awesome/css/font-awesome.css'
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import "@/assets/scss/vendors/prime-vue-theme.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import InlineButton from "@/components/InlineButton/InlineButton";
import ProductList from "@/views/Product/components/ProductList";
import CustomerList from "@/views/Customer/components/CustomerList";
import ChartOfAccountList from "@/views/ChartOfAccount/components/ChartOfAccountList";
import OrderList from "@/views/Order/components/OrderList";
import QueueList from "@/views/Queue/components/QueueList";
import StateInventoryList from "@/views/reports/StateInventory/components/StateInventoryList";
import BlankLinkIcon from "@/components/BlankLinkIcon/BlankLinkIcon";
import NavigationArrows from "@/components/NavigationArrows/NavigationArrows";
import CheckMark from "@/components/CheckMark";
import DisableAutocomplete from "vue-disable-autocomplete";
import Hotjar from 'vue-hotjar'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);
library.add(faUserSecret);
import VueProgressBar from "vue-progressbar";
import VueLazyload from 'vue-lazyload'

import titleMixin from "./mixins/titleMixin";
import SearchModal from "@/components/SearchModal.vue";
import IdleVue from "idle-vue";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.config.productionTip = false;

Vue.use(require("vue-shortkey"));
Vue.component("SearchModal", SearchModal);
Vue.use(VueLogger, options);
Vue.component("v-select", vSelect);
Vue.component("inline-button", InlineButton);
Vue.component("product-list", ProductList);
Vue.component("customer-list", CustomerList);
Vue.component("chartOfAccount-list", ChartOfAccountList);
Vue.component("order-list", OrderList);
Vue.component("queue-list", QueueList);
Vue.component("state-product-list", StateInventoryList);
Vue.component("blank-link-icon", BlankLinkIcon);
Vue.component("navigation-arrows", NavigationArrows);
Vue.component("check-mark", CheckMark);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(DisableAutocomplete);
Vue.use(VueLazyload)
Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "2px",
  transition: {
    speed: "0.1s",
    termination: 600,
  },
});
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_SITE_ID || '' // Hotjar Site ID
})
Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)

Vue.mixin(titleMixin);

Vue.directive("canAccess", {
  bind(el, binding, vnode) {
    let permissions = binding.value;
    let role = store.getters["loginModule/role"];

    if (permissions && !permissions.includes(role)) {
      el.style.display = "none";
    }
    const unwatch = store.watch(
      (state) => state.loginModule.role,
      (role) => {
        const perms = binding.value;
        if (perms && !perms.includes(role)) {
          el.style.display = "none";
        } else {
          if (el.className.includes("inline-button")) {
            el.style.display = "inline-block";
          } else {
            if (binding.arg == "flex") {
              el.style.display = "flex";
            } else {
              el.style.display = "block";
            }
          }
        }
      }
    );
    el.__role_unwatch__ = unwatch;
  },

  unbind(el) {
    if (el.__role_unwatch__) {
      el.__role_unwatch__();
    }
  },
});


const eventsHub = new Vue({
  router,
  store,
  render: (h) => h(App),
  created: function () {
    store.dispatch("readCookies");
  },
}).$mount("#app");

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000, // 1h of inactivity
  startAtIdle: false,
});
