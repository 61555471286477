const findItemInOrder = (item, order) => {
  if (!item) return null;
  if (!order) return null;

  let productId = null;
  let itemId = item._id;
  if (item.product) {
    if (typeof item.product == "string") {
      productId = item.product;
    } else {
      productId = item.product._id;
    }
  }

  if (item.products && item.products.length > 0) {
    productId = item.products[0]._id;
  }

  if (!order.products) return null;

  const product = order.products.find((p) => p._id == productId);
  if (!product) return null;

  const itemInOrder = product.items.find((i) => i._id == itemId);
  return itemInOrder;
};

export default {
  findItemInOrder,
};
