import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import LocalStorageManager from "../../lib/LocalStorageManager";

const state = {
  itemHierarchy: {},
  loadingStatus: {
    loadingStatus: false,
    getItemHierarchy: false,
    saveCustomerNote: false,
    removeClutchDiscountFromOrder: false
  },
  selectedProductPopUp: { isOpen: false },
  sellOptionsPopUp: { isOpen: false },
  orderTypePopUp: { isOpen: false },
  orderModePopUp: { isOpen: false },
  caregiverPatientSelectionPopUp: { isOpen: false },
  recreationalOrderTypePopUp: { isOpen: false },
  discountsPopUp: { isOpen: false, tab: '' },
  giftCardPopUp: { isOpen: false, isBalanceLookUp: true },
  giftCardCartPopUp: { isOpen: false, giftCardsCodes: [] },
  selectedWorkstation: undefined,
  workstationSelectionPopUp: {
    isOpen: false,
  },
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setItemHierarchy: (state, payload) => {
    state.itemHierarchy = payload;
  },
  setSelectedProductPopUp: (state, payload) => {
    state.selectedProductPopUp = payload;
  },
  setSellOptionsPopUp: (state, payload) => {
    state.sellOptionsPopUp = payload;
  },
  setOrderTypePopUp: (state, payload) => {
    state.orderTypePopUp = payload;
  },
  setCaregiverPatientSelectionPopUp: (state, payload) => {
    state.caregiverPatientSelectionPopUp = payload;
  },
  setOrderModePopUp: (state, payload) => {
    state.orderModePopUp = payload;
  },
  setRecreationalOrderTypePopUp: (state, payload) => {
    state.recreationalOrderTypePopUp = payload;
  },
  setDiscountsPopUp: (state, payload) => {
    state.discountsPopUp = payload;
  },
  setSelectedWorkstation: (state, payload) => {
    if (!payload) return

    state.selectedWorkstation = payload;
    LocalStorageManager.setLocalStorageKey(
      "workstation",
      JSON.stringify(payload)
    );
  },
  setGiftCardPopUp: (state, payload) => {
    state.giftCardPopUp = payload;
  },
  setGiftCardCartPopUp: (state, payload) => {
    state.giftCardCartPopUp = payload;
  },
  setWorkstationSelectionPopUp: (state, payload) => {
    state.workstationSelectionPopUp = payload;
  }
};

const getters = {
  loadingStatus: (state) => state.loadingStatus,
  itemHierarchy: (state) => state.itemHierarchy,
  selectedProductPopUp: (state) => state.selectedProductPopUp,
  sellOptionsPopUp: (state) => state.sellOptionsPopUp,
  orderTypePopUp: (state) => state.orderTypePopUp,
  orderModePopUp: (state) => state.orderModePopUp,
  caregiverPatientSelectionPopUp: (state) =>
    state.caregiverPatientSelectionPopUp,
  recreationalOrderTypePopUp: (state) => state.recreationalOrderTypePopUp,
  discountsPopUp: (state) => state.discountsPopUp,
  giftCardPopUp: (state) => state.giftCardPopUp,
  giftCardCartPopUp: (state) => state.giftCardCartPopUp,
  selectedWorkstation: (state) => state.selectedWorkstation,
  workstationSelectionPopUp: (state) => state.workstationSelectionPopUp,
};

const actions = {
  setSelectedWorkstation: ({ commit, state }, payload) => {
    commit("setSelectedWorkstation", payload);
  },
  getItemHierarchy: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getItemHierarchy", status: true });

    HttpService.get(
      `${Apis.backendBaseUrl}${Apis.getItemHierarchy}?orderType=${payload.orderType}&isCannabis=${payload.isCannabis}`,
      function (response, error) {
        commit("setLoadingStatus", { api: "getItemHierarchy", status: false });
        var itemHierarchy = {};

        if (response) {
          itemHierarchy = response.data.body;

          commit("setItemHierarchy", itemHierarchy);
        }
      }
    );
  },
  saveCustomerNote: ({ commit, state, rootGetters, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "saveCustomerNote", status: true });

    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.saveCustomerNote}`,
      {
        order: rootGetters["orderModule/posOrder"],
        note: payload.note,
        customer: payload.customer,
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "saveCustomerNote", status: false });
        if (response) {
          var order = response.data;
          dispatch("orderModule/setPosOrder", order, { root: true });
          const notePopUp = rootGetters["notePopUp"];
          notePopUp.order = order;
          dispatch("setNotePopUp", notePopUp, { root: true });
          if (payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        }
      }
    );
  },
  openCashDrawer: ({ commit, state }, payload) => {
    if (!state.selectedWorkstation) {
      return commit(
        "setMessage",
        {
          text: "No workstation selected",
          type: "error",
          errorType: "no-workstation",
        },
        { root: true }
      );
    }
    commit("setLoadingStatus", { api: "openCashDrawer", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.pos.openCashDrawer}`,
      { workstation: state.selectedWorkstation },
      function (response, error) {
        commit("setLoadingStatus", { api: "openCashDrawer", status: false });
        if (response) {
          // show message
        }
      }
    );
  },
  setSelectedProductPopUp: ({ commit, state }, payload) => {
    commit("setSelectedProductPopUp", payload);
  },
  setWorkstationSelectionPopUp: ({ commit, state }, payload) => {
    commit("setWorkstationSelectionPopUp", payload);
  },
  setSellOptionsPopUp: ({ commit, state }, payload) => {
    commit("setSellOptionsPopUp", payload);
  },
  setOrderTypePopUp: ({ commit, state }, payload) => {
    commit("setOrderTypePopUp", payload);
  },
  setCaregiverPatientSelectionPopUp: ({ commit, state }, payload) => {
    commit("setCaregiverPatientSelectionPopUp", payload);
  },
  setOrderModePopUp: ({ commit, state }, payload) => {
    commit("setOrderModePopUp", payload);
  },
  setDiscountsPopUp: ({ commit, state }, payload) => {
    commit("setDiscountsPopUp", payload);
  },
  setGiftCardPopUp: ({ commit, state }, payload) => {
    commit("setGiftCardPopUp", payload);
  },
  setGiftCardCartPopUp: ({ commit, state }, payload) => {
    commit("setGiftCardCartPopUp", payload);
  },
  setRecreationalOrderTypePopUp: ({ commit, state }, payload) => {
    commit("setRecreationalOrderTypePopUp", payload);
  },
  getCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCustomer", status: true });

    HttpService.get(
      `${Apis.backendBaseUrl}${Apis.getCustomer}?id=${payload.id}`,
      function (response, error) {
        commit("setLoadingStatus", { api: "getCustomer", status: false });

        if (response && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }

        if (error && payload.negativeCallback) {
          payload.negativeCallback(error);
        }
      }
    );
  },
  removeHierarchy: ({ commit, state }, payload) => {
    commit("setItemHierarchy", {});
  },
  fulfillmentPrint: ({ commit }, payload) => {

    commit("setLoadingStatus", { api: "fulfillmentPrint", status: true });

    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.pos.fulfillmentPrint}`,
      { orderId: payload?.orderId },
      function (response, error) {
        commit("setLoadingStatus", { api: "fulfillmentPrint", status: false });

        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback();
          }
          commit(
            "setMessage",
            { text: "Order printed in fulfillment", type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  scanItem: ({ commit }, payload) => {
    commit("scanItem", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
