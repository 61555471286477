<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Sales Profit Margins by Group & Product" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesProfitMarginsGroupProduct',
          label: 'Sales Profit Margins by Group & Product',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th colspan="3" align="left">Group</th>
                <th align="left">Supplier</th>
                <th align="left">Grams</th>
                <th align="left">Product</th>
                <th align="right">Quantity</th>
                <th align="right">%</th>
                <th align="right">Pre-Tax<br />Total</th>
                <th align="right">%</th>
                <th align="right">Wholesale<br />Cost</th>
                <th align="right">Profit Loss</th>
                <th align="right">
                  %<br />
                  Margin
                </th>
                <th align="right">Discount</th>
                <th align="right">
                  %<br />
                  Off
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[recordId, record] in Object.entries(reportData.list)" :key="recordId">
                <td colspan="3">{{ record.groupName }}</td>
                <td>{{ record.supplierName.substring(0, 7) }}</td>
                <td>{{ record.cannabisGramsUnit }}</td>
                <td>{{ record.productName }}</td>
                <td align="right">{{ record.quantity }}</td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (record.quantity / reportData.total.quantity) * 100
                    )
                  }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.totalPreTax) }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (record.totalPreTax / reportData.total.totalPreTax) * 100
                    )
                  }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.actualCost) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.profitLoss) }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (record.profitLoss / record.totalPreTax) * 100
                    )
                  }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.discount) }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (record.discount / record.totalPreTax) * 100
                    )
                  }}
                </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="6">Grand Totals</td>
              <td align="right">{{ reportData.total.quantity }}</td>
              <td align="right">
                {{
                  Utilities.formatPercent(
                    (reportData.total.quantity / reportData.total.quantity) *
                    100
                  )
                }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.totalPreTax) }}
              </td>
              <td align="right">
                {{
                  Utilities.formatPercent(
                    (reportData.total.totalPreTax /
                      reportData.total.totalPreTax) *
                    100
                  )
                }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.actualCost) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.profitLoss) }}
              </td>
              <td align="right">
                {{
                  Utilities.formatPercent(
                    (reportData.total.profitLoss /
                      reportData.total.totalPreTax) *
                    100
                  )
                }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.discount) }}
              </td>
              <td align="right">
                {{
                  Utilities.formatPercent(
                    (reportData.total.discount / reportData.total.totalPreTax) *
                    100
                  )
                }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import ReportHelper from "@/lib/report/index.js";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Sales Profit Margins by Group & Product");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "salesProfitMarginsGroupProduct");
    },
    performGetReport(filter) {
      let reportType = "salesProfitMarginsGroupProduct";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesProfitMarginsGroupProduct.scss";
</style>
