<template>
  <div class="inventory-info">
    <div class="inventory-info-record">
      <p class="inventory-info-record-type">Created At:</p>
      <p class="inventory-info-record-value">{{ formatDate(createdAt) }}</p>
    </div>
    <div class="inventory-info-record">
      <p class="inventory-info-record-type">Closed At:</p>
      <p class="inventory-info-record-value">{{ formatDate(closedAt) }}</p>
    </div>
    <div class="inventory-info-record">
      <p class="inventory-info-record-type">Room:</p>
      <p class="inventory-info-record-value">{{ room }}</p>
    </div>
    <div class="inventory-info-record">
      <p class="inventory-info-record-type">Status:</p>
      <p class="inventory-info-record-value">{{ status }}</p>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";

export default {
  props: {
    createdAt: {
      type: String
    },
    createdBy: {
      type: Object
    },
    closedAt: {
      type: String
    },
    status: {
      type: String
    },
    room: {
      type: String
    }
  },
  computed: {
    user() {
      const info = []
      const name = this.createdBy.name;
      const lastName = this.createdBy.lastName;
      if (name) {
        info.push(name)
      }

      if (lastName) {
        info.push(lastName)
      }
      return info.join(' ');
    },
  },
  methods: {
    formatDate(date) {
      return Utilities.formatDateWithHour(date);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_info';
</style>