<template>
  <div id="note-pop-up">
    <div
      class="overlay-div"
      v-if="notePopUp.isOpen"
      v-on:click.stop="handleNegativeAnswer"
    ></div>
    <div v-if="notePopUp.isOpen" class="default-pop-up-center-div bordered">
      <div v-if="mode == 'add-note'">
        <div>
          <span class="h1">New Note</span>
          <CustomerNote :showDeleteButton="false" :isEdit="true" :note="note" />
        </div>
      </div>

      <div v-if="mode == 'list'">
        <div v-if="noteList.length > 0">
          <span class="h1">Customer Notes</span>
          <div class="note-pop-up__list-container">
            <div
              @click="
                () => {
                  note = JSON.parse(JSON.stringify(customerNote));
                  mode = 'add-note';
                }
              "
              v-for="(customerNote, i) in noteList"
              :key="customerNote._id"
              class="note-pop-up__list-item"
              :class="{ 'elevated-0': i % 2 }"
            >
              <div>
                {{ customerNote.note }}
              </div>
              <div class="note-pop-up__list-item-footer">
                <div>
                  {{
                    moment(customerNote.createdAt).format(
                      Constants.dates.extendedCalendarDateFormat
                    )
                  }}
                </div>
                <div>
                  {{ customerNote.noteType }}
                </div>
                <div>
                  {{ Utilities.composeNameAndLastName(customerNote.createdBy) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <!-- <div v-if="creditMemos.length > 0">
          <span class="h1">Credit Memos</span>
          <div class="note-pop-up__list-container">
            <div
              v-for="(creditMemo, i) in creditMemos"
              :key="creditMemo._id"
              class="note-pop-up__list-item"
              :class="{ 'elevated-0': i % 2 }"
            >
              <div>
                Balance: {{ Utilities.formatPrice(creditMemo.memoBalance) }}
              </div>
              <div class="note-pop-up__list-item-footer">
                <div>
                  {{
                    moment(creditMemo.createdAt).format(
                      Constants.dates.extendedCalendarDateFormat
                    )
                  }}
                </div>
                <div>
                  {{ creditMemo.memoType }}
                </div>
                <div>
                  {{ Utilities.composeNameAndLastName(creditMemo.createdBy) }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="padded"></div>
      <div class="padded note-pop-up__footer">
        <BaseBtn v-if="mode == 'list'" @click="newNote" label="Add Note" />
        <BaseBtn
          v-if="mode == 'add-note'"
          @click="addNote"
          label="Save"
          :isLoading="posLoadingStatus.saveCustomerNote"
          :disabled="posLoadingStatus.saveCustomerNote"
        />
        <BaseBtn
          v-if="mode == 'add-note'"
          @click="mode = 'list'"
          label="Back"
          :colorType="3"
        />
        <BaseBtn
          v-if="mode == 'list'"
          @click="handleNegativeAnswer"
          label="Dismiss"
          :colorType="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Utilities from "@/lib/Utilities";
import moment from "moment";
import CustomerNote from "@/components/CustomerNote.vue";

export default {
  name: "NotePopUp",
  data() {
    return {
      Constants: Constants,
      mode: "list",
      note: {
        noteType: undefined,
        hours: 0,
        note: "",
        isFollowedUp: false,
        followUpDate: "",
        _id: "NEW",
      },
      Utilities,
      moment,
    };
  },
  components: {
    BaseBtn,
    CustomerNote,
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({
      notePopUp: "notePopUp",
      loadingStatus: "loadingStatus",
      posLoadingStatus: "posModule/loadingStatus",
    }),
    noteList() {
      try {
        return this.notePopUp.order.customer.noteList.filter(
          (n) => !n.isFollowedUp
        );
      } catch (error) {
        return [];
      }
    },
    creditMemos() {
      try {
        return this.notePopUp.creditMemos;
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      setCustomerNote: "setCustomerNote",
      setMessage: "setMessage",
      saveCustomerNote: "posModule/saveCustomerNote",
    }),
    handleNegativeAnswer: function () {
      this.notePopUp.isOpen = false;
    },
    selectNoteType: function (selectedNoteType) {
      this.note.noteType = selectedNoteType.label;
    },
    selectFollowUpDays: function (selectedFollowUpDays) {
      this.note.followUpDays = selectedFollowUpDays.value;
    },
    performSetCustomerNote: function () {
      if (this.loadingStatus.isSetCustomerNoteLoading) {
        return;
      }

      if (
        Constants.noteTypes === undefined ||
        Constants.noteTypes.length === 0
      ) {
        this.setMessage({ text: "No types in configuration", type: "error" });
        return;
      }

      if (this.note.noteType === "") {
        this.note.noteType = Constants.noteTypes[0].label;
      }

      this.setCustomerNote(this.note);
    },
    addNote() {
      const type =  this.note.noteType;
      const noteContent = this.note.note;

      if (!type || !noteContent) {
        this.setMessage({
          text: `Please insert note and type`,
          type: "error",
        });
        return;
      }

      this.saveCustomerNote({
        order: this.notePopUp.order,
        customer: this.notePopUp.order.customer,
        note: this.note,
        positiveCallback: () => {
          this.note = {
            noteType: undefined,
            hours: 0,
            note: "",
            isFollowedUp: false,
            followUpDate: "",
            _id: "NEW",
          };
          this.mode = "list";
          this.setMessage({ text: "Note Saved!", type: "success" });
        },
      });
    },
    newNote() {
      this.note = {
        noteType: undefined,
        hours: 0,
        note: "",
        isFollowedUp: false,
        followUpDate: "",
        _id: "NEW",
      };
      this.mode = "add-note";
    },
  },
};
</script>

<style lang="scss">
.note-pop-up {
  &__list-item {
    padding: 10px;
    border-radius: $border-radius-0;
  }

  &__list-item-footer {
    color: $dark-info-color;
    display: flex;
    justify-content: space-between;
    font-size: $text-size-1;
    margin-top: 5px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
</style>
