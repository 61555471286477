<template>
  <div class="return-base">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "returnBase",
};
</script>
