<template>
  <div class="side-menu no-print">
    <div class="side-menu__options" v-if="isOpen" :class="{ 'side-menu__options__showOptionsMobile': showMenuMobile }">
      <h2 class="side-menu__options__title">{{ selectedRoute.name }}</h2>
      <div class="side-menu__options__value-container">
        <router-link v-for="subRoute in selectedRoute.subRoutes" :key="subRoute.id"
          :to="{ name: subRoute.to.name, query: subRoute.to.query }" class="side-menu__options__value"
          v-canAccess:flex="subRoute.roles">
          <div>{{ subRoute.name }}</div>
          <Pill v-if="subRoute.pill" :text="subRoute.pill"></Pill>
        </router-link>
      </div>
    </div>
    <div class="side-menu__header">
      <router-link :to="{ name: 'home' }">
        <transition name="fade" mode="out-in">
          <img v-if="!theme || theme == 'dark'" src="@/assets/images/logo_small_white.svg" alt="DispenseFirst logo" />
          <img v-else src="@/assets/images/logo_small_black.svg" alt="DispenseFirst logo" />
        </transition>
      </router-link>
      <div class="side-menu__header__menu" @click="openSideBarHandler">
        <font-awesome-icon v-show="!showMenuMobile" :icon="['fas', 'bars']" />
        <font-awesome-icon v-show="showMenuMobile" :icon="['fas', 'times']" />
      </div>
    </div>
    <div class="body" :class="{ body__showMenuMobile: showMenuMobile }">
      <div class="body__main">
        <div v-for="route in routes" :key="route.id" class="menu-icon" v-canAccess:flex="route.roles"
          @click="menuSelectedHandler(route)" :class="{
            'menu-icon--active': isActive(route),
            'menu-icon--open': selectedRouteId == route.id && isOpen,
            'menu-icon--mobileOnly': route.mobileOnly,
          }">
          <font-awesome-icon :icon="route.icon" />
        </div>
      </div>
      <div class="body__footer">
        <div @click="showOfflineMessage" v-if="!isTraceabilityEnalbled" class="offline">Offline</div>
        <div class="menu-icon" @click="performSelectWorkstation">
          <font-awesome-icon :icon="['fas', 'desktop']" />
        </div>
        <router-link class="menu-icon" :to="{ name: 'settings' }">
          <font-awesome-icon :icon="['fas', 'cog']" />
        </router-link>
        <div class="menu-icon">
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" @click="performLogout" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from "@/resources/Constants";
import { mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  salesRoles,
  inventoryRoles,
  inventoryRolesWithoutState,
  managementRoles,
  financeRoles,
  reportRoles,
  managementRolesWithMso,
  globalAdminRoles,
  multiStoreRoles
} from "@/constants/roles";
import Pill from "@/components/Pill/Pill"

export default {
  name: "NavigationDrawer",
  data() {
    return {
      salesRoles,
      inventoryRolesWithoutState,
      inventoryRoles,
      financeRoles,
      managementRolesWithMso,
      globalAdminRoles,
      isExpanded: false,
      isOpen: false,
      selectedRoute: {},
      windowWidth: window.innerWidth,
      showMenuMobile: false,
      selectedRouteId: null,
      multiStoreRoles,
      routes: [
        {
          id: 0,
          name: "Sales",
          icon: ["fas", "cash-register"],
          roles: salesRoles,
          subRoutes: [
            {
              id: 0,
              name: "Point of Sale",
              to: { name: "pos" },
            },
            {
              id: 1,
              name: "Customers",
              to: { name: "customers" },
            },
            {
              id: 2,
              name: "Check In",
              to: { name: "customerCheckIn" },
            },
            {
              id: 3,
              name: "Queue",
              to: { name: "queues" },
            },
          ],
        },
        {
          id: 1,
          name: "Daily Tasks",
          icon: ["fas", "clock"],
          roles: salesRoles,
          subRoutes: [
            {
              id: 0,
              name: "Inventory Count",
              to: { name: "inventoryCounts" },
            },
            {
              id: 1,
              name: "Cash Reconciliation",
              to: { name: "cashReconciliations" },
              roles: financeRoles
            },
          ],
        },
        {
          id: 2,
          name: "Inventory",
          icon: ["fas", "boxes"],
          roles: inventoryRoles,
          subRoutes: [
            // {
            //   id: 0,
            //   name: "Flights",
            //   to: { name: "flights" },
            //   roles: inventoryRolesWithoutState,
            // },
            {
              id: 1,
              name: "Groups",
              to: { name: "groups" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 2,
              name: "Items",
              to: { name: "items" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 3,
              name: "Online Store Groups",
              to: { name: "onlineStoreGroups" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 4,
              name: "Products",
              to: { name: "products" },
              roles: inventoryRoles,
            },
            {
              id: 5,
              name: "Menu",
              to: { name: "menu" },
              roles: inventoryRoles,
            },
            {
              id: 6,
              name: "Purchase Orders",
              to: { name: "purchaseOrders" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 7,
              name: "Rooms",
              to: { name: "rooms" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 8,
              name: "Special Pricing",
              to: { name: "specialPricings" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 9,
              name: "Suppliers",
              to: { name: "suppliers" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 10,
              name: "Supplier Agents",
              to: { name: "supplierAgents" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 11,
              name: "Brands",
              to: { name: "brands" },
              roles: inventoryRolesWithoutState,
            },
            {
              id: 12,
              name: "Fulfillment",
              to: { name: "fulfillment" },
              roles: inventoryRolesWithoutState,
            },
          ],
        },
        {
          id: 3,
          name: "Administration",
          icon: ["fas", "building"],
          roles: managementRoles,
          subRoutes: [
            {
              id: 0,
              name: "Employees",
              to: { name: "employees" },
              roles: globalAdminRoles
            },
            {
              id: 1,
              name: "Time Sheets",
              to: { name: "timeSheets" },
            },
            {
              id: 2,
              name: "MSO Chart of Accounts",
              to: { name: "msoChartOfAccounts" },
              roles: managementRolesWithMso
            },
            {
              id: 3,
              name: "MSO Coupons",
              to: { name: "msoCoupons" },
              roles: managementRolesWithMso
            },
            {
              id: 4,
              name: "MSO Groups",
              to: { name: "msoGroups" },
              roles: managementRolesWithMso
            },
            {
              id: 5,
              name: "MSO Products",
              to: { name: "msoProducts" },
              roles: managementRolesWithMso
            },
            {
              id: 6,
              name: "MSO Suppliers",
              to: { name: "msoSuppliers" },
              roles: managementRolesWithMso
            },
            {
              id: 7,
              name: "MSO Menu",
              to: { name: "msoMenu" },
              roles: managementRolesWithMso
            },
            {
              id: 8,
              name: "Organization",
              to: {
                name: "organizationEditor",
                query: { id: localStorage.getItem("organizationId") },
                roels: multiStoreRoles
              },
            },
            {
              id: 9,
              name: "Stores",
              to: { name: "stores" },
            },
          ],
        },
        {
          id: 4,
          name: "Finance",
          //icon: ["fas", "file-alt"],
          icon: ["fas", "dollar-sign"],
          roles: financeRoles,
          subRoutes: [
            {
              id: 0,
              name: "Chart of Account",
              to: { name: "chartOfAccounts" },
            },
            {
              id: 1,
              name: "Coupons",
              to: { name: "coupons" },
            },
            {
              id: 2,
              name: "Credit Memos",
              to: { name: "creditMemos" },
            },
            {
              id: 3,
              name: "Gift Cards",
              to: { name: "giftCards" },
            },
            {
              id: 4,
              name: "Journal Entries",
              to: { name: "journals" },
            },
            {
              id: 5,
              name: "Orders",
              to: { name: "orders" },
            },
            {
              id: 6,
              name: "Order Returns",
              to: { name: "returnItems" },
            },
            {
              id: 7,
              name: "Payments",
              to: { name: "payments" },
            },
            {
              id: 8,
              name: "Tax Setup",
              to: { name: "taxSetups" },
            },
          ],
        },
        {
          id: 5,
          name: "Reports",
          icon: ["fas", "chart-line"],
          roles: reportRoles,
          mobileOnly: true,
          subRoutes: [
            {
              id: 0,
              name: "Today's Menu",
              to: { name: "todaysMenu" },
            },
            // {
            //   id: 1,
            //   name: "State Inventory",
            //   to: { name: "stateInventory" },
            // },
            {
              id: 1,
              name: "End of Day Closing",
              to: { name: "endOfDayClosingReport" },
            },
            {
              id: 2,
              name: "Customer Reports",
              to: { name: "customerReport" },
            },
            {
              id: 3,
              name: "Financial Reports",
              to: { name: "financeReport" },
            },
            {
              id: 4,
              name: "Inventory Reports",
              to: { name: "inventoryReport" },
            },
            {
              id: 5,
              name: "Sales Reports",
              to: { name: "salesReport" },
            },
            {
              id: 6,
              name: "Sales Dashboard",
              to: { name: "salesOverview" },
              pill: "new"
            },
          ],
        },
      ],
    };
  },
  components: {
    FontAwesomeIcon,
    Pill
  },
  watch: {
    $route(to, from) {
      this.showMenuMobile = false;
      this.isOpen = false;
      this.selectedRouteId = null;
    },
  },
  mounted() {
    this.selectedRoute = this.routes[0];
    this.selectedRouteId = this.selectedRoute.id;

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters({
      getUser: "loginModule/user",
      currentStore: "storeModule/currentStore",
      theme: "theme",
      getRole: 'loginModule/role'
    }),
    isTabPort() {
      return this.windowWidth < 900;
    },
    organizationId() {
      return this.getUser.organization;
    },
    role () {
      return this.getRole
    },
    isTraceabilityEnalbled() {
      if (!this.currentStore) return true;
      if (!this.currentStore.traceabilitySystem) return false;
      if (this.currentStore.traceabilitySystem == 'none') return false;

      if (this.currentStore.traceabilitySystem == 'biotrack' || this.currentStore.traceabilitySystem == 'metrc') {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions({
      logout: "loginModule/logout",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      setWorkstationSelectionPopUp: "posModule/setWorkstationSelectionPopUp",
    }),
    showOfflineMessage() {
      this.setMessage({ text: "Your store is not connected to the State Traceability System.\nYou must stop processing orders.\n\nPlease contact DF support.", type: "error" });
    },
    closeMenu() {
      this.isOpen = false;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    performLogout: function () {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.logoutTitle,
        message: Constants.strings.logoutConfirmation,
        positiveCallback: (data) => {
          this.logout();
        },
      });
    },
    performSelectWorkstation: function () {
      this.setWorkstationSelectionPopUp({ isOpen: true });
    },
    menuSelectedHandler(route) {
      if (this.selectedRouteId == route.id && !this.isTabPort && this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
      if (!this.isOpen) {
        this.selectedRoute = {};
        this.selectedRouteId = null;
        return;
      }
      this.selectedRoute = route;
      this.selectedRouteId = route.id;
    },
    openSideBarHandler() {
      this.showMenuMobile = !this.showMenuMobile;
      if (this.showMenuMobile) {
        this.isOpen = true;
      }
    },
    isActive(route) {
      const subRoutes = route.subRoutes;
      if (!subRoutes || subRoutes.length == 0) return false;

      let active = false;
      for (let i = 0; i < subRoutes.length; i++) {
        const subRoute = subRoutes[i];
        const nameSubRoute = subRoute.to.name;
        active = this.$route.matched.some(
          ({ path }) => path === `/${nameSubRoute}`
        );
        if (active) return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
@import "./navigationDrawer.scss";
</style>
