<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Credit Memo Upload" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'financeReport', label: 'Finance' },
        {
          name: 'financeCreditMemoUpload',
          label: 'Credit Memo Upload',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th align="left">id_no</th>
                <th align="left">enter_date</th>
                <th align="left">memo_tot_balance</th>
                <th align="left">invoice_item_id_no</th>
                <th align="left">pord_item_id_no</th>
                <th align="left">return_id_no</th>
                <th align="left">vend_id_no</th>
                <th align="left">company</th>
                <th align="left">bill_hist</th>
                <th align="left">pord_hist</th>
                <th align="left">accn</th>
                <th align="left">product_desc</th>
                <th align="left">batch_id</th>
                <th align="left">pord_date</th>
                <th align="left">product_id_no</th>
                <th align="left">store_id</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.records" :key="index">
                <td align="left">{{ record.id_no }}</td>
                <td align="left">{{ record.enter_date }}</td>
                <td align="left">{{ record.memo_tot_balance }}</td>
                <td align="left">{{ record.invoice_item_id_no }}</td>
                <td align="left">{{ record.pord_item_id_no }}</td>
                <td align="left">{{ record.return_id_no }}</td>
                <td align="left">{{ record.vend_id_no }}</td>
                <td align="left">{{ record.company }}</td>
                <td align="left">{{ record.bill_hist }}</td>
                <td align="left">{{ record.pord_hist }}</td>
                <td align="left">{{ record.accn }}</td>
                <td align="left">{{ record.product_desc }}</td>
                <td align="left">{{ record.batch_id }}</td>
                <td align="left">{{ record.pord_date }}</td>
                <td align="left">{{ record.product_id_no }}</td>
                <td align="left">{{ record.store_id }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Credit Memo Upload");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "financeCreditMemoUpload";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "BC_DF-import-CM");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_financeCreditMemoUpload.scss";
</style>