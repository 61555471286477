<template>
  <div class="onlineStoreGroup-editor responsive-menu">
    <Ordering v-if="orderingPopUp.isOpen" title="Online Controlling Group Categories" :items="onlineStoreGroups"
      propertyToSortBy="nameOrder" @close="closeHandler" @save="saveHandler" />
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :links="[
          { name: 'home', label: 'Home' },
          { name: 'onlineStoreGroups', label: 'Online Store Groups' },
          {
            name: 'onlineStoreGroupEditor',
            label: selectedOnlineStoreGroup.name,
            query: $route.query,
          },
        ]" @toggle-edit="() => {
      if (!(selectedOnlineStoreGroup._id === 'NEW')) isEdit = !isEdit;
    }
    " :isEdit="isEdit" :isCollapsed="isCollapsed" :entity="entity" :selectedEntity="selectedOnlineStoreGroup"
        @updateIsEdit="isEdit = $event" @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)" @menu-option-click="menuOptionClick" />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <MessageBox level="warning">Editing the online group name would make existing online store URLs unavailable.
          </MessageBox>
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="input in onlineStoreInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
              <Input :model="selectedOnlineStoreGroup[input.id]"
                @updateModel="selectedOnlineStoreGroup[input.id] = $event" :inputData="input" :isEdit="isEdit" />
            </div>
          </div>
        </div>
      </div>

      <EditorSection title="Associated Groups" sectionType="associatedGroups" :bus="bus">
        <div v-if="isEdit" class="
                          two-columns-layout
                          two-columns-layout--responsive
                          two-columns-layout--border-bottom
                        ">
          <Input :model="selectedOnlineStoreGroup.groupToAdd" @updateModel="selectedOnlineStoreGroup.groupToAdd = $event"
            :inputData="groupSearchInput" :isEdit="isEdit" />
        </div>
        <div v-for="group in selectedOnlineStoreGroup.groups" :key="group._id" class="space-between">
          <div v-for="input in associatedGroupsInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
            <Input :model="group[input.id]" @updateModel="group[input.id] = $event" :inputData="input" :isEdit="isEdit" />
          </div>
          <inline-button :disabled="!isEdit" label="Delete" class="desktop-only-inline-block"
            type="plain-danger responsive-column form-height wide" @click="() => removeGroup(group._id)" />
        </div>
      </EditorSection>
      <EditorSection :title="`About ${selectedOnlineStoreGroup.name || ''}`" sectionType="about"
        v-if="selectedOnlineStoreGroup._id !== 'NEW'" :bus="bus">
        <About :entity="selectedOnlineStoreGroup" />
      </EditorSection>
    </div>
    <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)" :entity="entity" :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders" />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import EditorSection from "@/components/EditorSection.vue";
import About from "@/components/About/About.vue";
import { mapActions, mapGetters } from "vuex";
import Input from "@/components/Input/Input.vue";
import moment from "moment";
import router from "@/router";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Entities from "@/resources/Entities";
import Ordering from "@/components/Modals/Ordering/Ordering.vue";
import MessageBox from "@/components/MessageBox/MessageBox.vue";

import {
  onlineStoreInputs,
  associatedGroupsInputs,
  columnHeaders,
  filterCriteria,
  groupSearchInput,
  pageHeader,
  defaultSelectedOnlineStoreGroup,
} from "./inputs";
export default {
  name: "onlineStoreGroupEditor",
  data() {
    return {
      Constants: Constants,
      moment: moment,
      groupToAdd: undefined,
      onlineStoreInputs,
      associatedGroupsInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      groupSearchInput,
      entity: Entities.ONLINESTOREGROUP,
      pageHeader,
      defaultSelectedOnlineStoreGroup,
      onlineStoreGroups: [],
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
    Ordering,
    MessageBox
  },

  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getOnlineStoreGroupById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      getOnlineStoreGroups: "onlineStoreGroupModule/onlineStoreGroups",
      loadingStatus: "onlineStoreGroupModule/loadingStatus",
      onlineStoreGroup: "onlineStoreGroupModule/onlineStoreGroup",
      orderingPopUp: "onlineStoreGroupModule/getOrderingPopUp",
    }),
    selectedOnlineStoreGroup: {
      get() {
        this.setPageTitle("Online Store Group " + this.onlineStoreGroup.name);
        return this.onlineStoreGroup;
      },
      set(entity) {
        return entity;
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  methods: {
    ...mapActions({
      getOnlineStoreGroupById: "onlineStoreGroupModule/getOnlineStoreGroupById",
      retrieveOnlineStoreGroups: "onlineStoreGroupModule/getOnlineStoreGroups",
      saveOnlineStoreGroup: "onlineStoreGroupModule/saveOnlineStoreGroup",
      deleteOnlineStoreGroup: "onlineStoreGroupModule/deleteOnlineStoreGroup",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "onlineStoreGroupModule/getAdjacent",
      setOrderingPopUp: "onlineStoreGroupModule/setOrderingPopUp",
      updateOnlineStoreGroupOrdering:
        "onlineStoreGroupModule/updateOnlineStoreGroupOrdering",
    }),
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    selectItemFromSearch: function (entity, item) {
      if (entity === "groupToAdd") {
        if (
          !this.selectedOnlineStoreGroup.groups.find(
            (group) => group._id === item._id
          )
        )
          this.selectedOnlineStoreGroup.groups.unshift(item);
      } else this.selectedOnlineStoreGroup[entity] = item;
    },
    menuOptionClick(entity, key) {
      if (key === "set-group-order") {
        this.setOrderingPopUp(true);
      }
      if (key === "group-listing-online") {
        window.open(`/reports/inventory/inventoryListGroupOnline`);
      }
    },
    removeGroup(id) {
      this.selectedOnlineStoreGroup.groups = this.selectedOnlineStoreGroup.groups.filter(g => g._id.toString() != id.toString());
    },
    closeHandler() {
      this.setOrderingPopUp(false);
    },
    saveHandler(updated) {
      this.updateOnlineStoreGroupOrdering(updated).then(() => {
        this.closeHandler();
      });
    },
  },
  async mounted() {
    this.setPageTitle("OnlineStoreGroup");
    if (this.$route.query.id) {
      this.getOnlineStoreGroupById({
        _id: this.$route.query.id,
      });
    } else {
      this.isEdit = true;
    }

    let onlineGroupsInStore = this.getOnlineStoreGroups;
    if (!onlineGroupsInStore || onlineGroupsInStore.length == 0) {
      const data = await this.retrieveOnlineStoreGroups()
      onlineGroupsInStore = data;
    }

    onlineGroupsInStore = onlineGroupsInStore.sort(
      (a, b) => a.nameOrder - b.nameOrder
    );
    this.onlineStoreGroups = JSON.parse(JSON.stringify(onlineGroupsInStore));
  },
};
</script>
