<template>
  <tr class="record">
    <td class="open">
      <BaseBtn
        :disabled="!canEdit"
        class="record-btn"
        iconGroup="fas"
        iconName="folder-open"
        @click="openRecordHandler"
      />
      <BaseBtn
        class="record-btn record-btn__print"
        iconGroup="fas"
        iconName="print"
        @click="printHandler"
      />
    </td>
    <td>{{ item.batchId }}</td>
    <td v-if="!isMobileView">{{ item.externalFields.group.name }}</td>
    <td v-if="!isMobileView">{{ item.externalFields.supplier.name }}</td>
    <td v-if="!isMobileView">{{ item.externalFields.product.name }}</td>
    <td v-if="!isMobileView" class="record-center">
      {{ item.externalFields.product.cannabisGramsUnit || "-" }}
    </td>
    <td class="record-center">{{ handQuantity }}</td>
    <td v-if="!isMobileView" v-html="employees"></td>
    <td v-if="showExtraColumns && !isMobileView" class="record-center">
      {{ showDifference ? currentValue : "" }}
    </td>
    <td
      v-else-if="doneCounting && !isMobileView"
      class="record-center"
      v-canAccess="managementRoles"
    >
      {{ showDifference ? currentValue : "" }}
    </td>
    <td v-if="showExtraColumns" class="record-center difference">
      <p :class="{ 'mark-difference': applyMarkDifference && showDifference }">
        {{ difference }}
      </p>
    </td>
    <td
      v-else-if="doneCounting"
      class="record-center difference"
      v-canAccess="managementRoles"
    >
      <p :class="{ 'mark-difference': applyMarkDifference && showDifference }">
        {{ difference }}
      </p>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { managementRoles } from "@/constants/roles";

const STATUS = {
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
};

export default {
  components: {
    BaseBtn,
  },
  props: {
    item: {
      type: Object,
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
    viewAll: {
      type: Boolean,
      default: false,
    },
    showExtraColumns: {
      type: Boolean,
      default: false,
    },
    doneCounting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      managementRoles,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "loginModule/user",
      getInventoryCount: "inventoryCountModule/getInventoryCount",
    }),
    isMobileView() {
      return window.innerWidth < 1000;
    },
    userId() {
      return this.getUser._id;
    },
    canEdit() {
      if (this.getInventoryCount.isClosed) return false;
      if (this.viewAll) {
        return true;
      }

      const employee = this.item.employees.find((e) => e._id == this.userId);
      if (!employee) return false;
      return employee.countCompleted == false || !employee.countCompleted;
    },
    difference() {
      if (this.showDifference) {
        return this.handQuantity - this.currentValue;
      } else {
        return "";
      }
    },
    handQuantity() {
      const employees = this.item.employees;
      let handQuantity = 0;
      employees.forEach((e) => (handQuantity += e.handQuantity));
      return handQuantity;
    },
    employees() {
      const employees = this.item.employees;
      let list = [];
      employees.forEach((e) => list.push(this.getEmployeeText(e)));
      return list.join("</br>");
    },
    applyMarkDifference() {
      return this.difference;
    },
    currentValue() {
      const employees = this.item.employees;
      let currentValue = 0;
      employees.forEach((e) => (currentValue += e.currentValue || 0));
      return currentValue;
    },
    showDifference() {
      const employees = this.item.employees;
      if (!employees || employees.length == 0) return false;

      const stillInProgress = employees.some(
        (e) => e.status == STATUS.IN_PROGRESS
      );
      if (stillInProgress) return false;
      return true;
    },
  },
  methods: {
    openRecordHandler() {
      this.$emit("editRecord", this.item);
    },
    printHandler() {
      this.$emit("printRecord", this.item);
    },
    getEmployeeText(employee) {
      const text = [`${employee.name}`];

      if (this.viewAll && !this.getInventoryCount.isClosed) {
        text.push(`(${employee.status})`);
      }

      if (
        this.item.employees &&
        this.item.employees.length > 1 &&
        this.getInventoryCount.isClosed
      ) {
        text.push(`(${employee.handQuantity})`);
      }

      return text.join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_record";
</style>
