import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as supplierColumnHeaders,
  filterCriteria as supplierFilterCriteria,
} from "../Supplier/inputs";

import {
  searchColumnHeaders as customerColumnHeaders,
  filterCriteria as customerFilterCriteria,
} from "../Customer/inputs";

import {
  searchColumnHeaders as chartOfAccountColumnHeaders,
  filterCriteria as chartOfAccountFilterCriteria,
} from "../ChartOfAccount/inputs";

import {
  searchColumnHeaders as orderColumnHeaders,
  filterCriteria as orderFilterCriteria,
} from "../Order/inputs";

import {
  searchColumnHeaders as purchaseOrderColumnHeaders,
  filterCriteria as purchaseOrderFilterCriteria,
} from "../PurchaseOrder/inputs";
// Page Header

export const MEMO_TYPES = [
  "Refund",
  "Points",
  "Gift Card",
  "Reward",
  "Promotion",
  "CCP",
  "Cash Mistake",
  "Disposal",
];
export const MEMO_TYPES_NEW_CREDIT = [
  "Reward",
  "Promotion",
  "CCP",
  "Cash Mistake",
  "Disposal",
];

export const pageHeader = {
  title: "Credit Memo",
  isEditorView: true,
  cancelPath: "creditMemos",
  addPath: "creditMemoEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Credit Memos",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "creditMemoEditor",
};

export const filterCriteria = [
  {
    label: "Customer Full Name",
    code: "fullName",
    field: { type: "input", label: "Customer Name" },
    isPartial: false,
  },
  // {
  //   label: "Supplier Name",
  //   code: "supplier.name",
  //   field: { type: "input", label: "Supplier Name" },
  //   isPartial: true,
  // },
  {
    label: "Gift Card #",
    code: "giftCard",
    field: { type: "input", label: "Gift Card #" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Memo Type",
    id: "memoType",
    size: "medium",
  },
  {
    title: "Date Created",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Deleted",
    id: "isDeleted",
    type: "checkbox",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Customer",
    id: "customer.fullName",
    size: "large",
  },
  {
    title: "Memo Total",
    id: "memoTotal",
  },
  {
    title: "Memo Amount Used",
    id: "memoAmountUsed",
  },
  {
    title: "Memo Balance",
    id: "memoBalance",
  },
  {
    title: "Gift Card #",
    id: "giftCard",
    size: "large",
  },
  {
    title: "CCP Start Date",
    id: "ccpStartDate",
    type: "date",
  },
  {
    title: "CCP End Date",
    id: "ccpExpirationDate",
    type: "date",
  },
  {
    title: "Supplier Name",
    id: "supplier.name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Order",
    id: "order._id",
    size: "large",
  },
  {
    title: "Order Item",
    id: "order.products.items._id",
    size: "large",
  },
  {
    title: "Customer",
    id: "customer._id",
    size: "large",
  },
  {
    title: "Supplier",
    id: "supplier._id",
    size: "large",
  },
  {
    title: "Purchase Order",
    id: "purchaseOrder._id",
    size: "large",
  },
  {
    title: "Purchase Order Item",
    id: "item._id",
    size: "large",
  },
  {
    title: "Store",
    id: "store._id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Customer",
    id: "customer",
    size: "large",
  },
  {
    title: "Memo Type",
    id: "memoType",
    size: "medium",
  },
  {
    title: "Gift Card #",
    id: "giftCard",
    size: "large",
  },
];

export const creditMemoInfoInputs = [
  {
    id: "name",
    placeholder: "Supplier Name",
    type: "input",
    title: "Supplier Name",
    focus: true,
  },
  {
    id: "giftCard",
    placeholder: "Gift Card #",
    type: "input",
    title: "Gift Card #",
  },
  {
    id: "createdAt",
    placeholder: "Entered Date",
    type: "calendar",
    title: "Entered Date",
  },
  {
    id: "memoTotal",
    placeholder: "Original Total",
    type: "money",
    title: "Original Total",
    precision: 2,
    prefix: "$",
  },
  {
    id: "memoAmountUsed",
    placeholder: "Amount Used",
    type: "money",
    title: "Amount Used",
    precision: 2,
    prefix: "$",
  },
  {
    id: "memoBalance",
    placeholder: "Balance",
    type: "money",
    title: "Balance",
    precision: 2,
    prefix: "$",
  },
  {
    id: "isDeleted",
    type: "checkbox",
    title: "Deleted",
  },
  {
    id: "isPostedToJournal",
    type: "checkbox",
    title: "Posted",
  },
  {
    id: "ccpStartDate",
    placeholder: "CCP Starts on",
    type: "calendar",
    title: "CCP Starts on",
  },
  {
    id: "ccpExpirationDate",
    placeholder: "CP Expires on",
    type: "calendar",
    title: "CP Expires on",
  },
  {
    id: "memoType",
    placeholder: "Memo Type",
    type: "select",
    options: MEMO_TYPES,
    title: "Memo Type",
  },
  {
    id: "customer",
    placeholder: "Customer",
    type: "search",
    title: "Customer",
    entity: Entities.CUSTOMER,
    columnHeaders: customerColumnHeaders,
    filterCriteria: customerFilterCriteria,
    isClearable: true,
  },
  {
    id: "supplier",
    type: "search",
    title: "Supplier",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.SUPPLIER,
    columnHeaders: supplierColumnHeaders,
    filterCriteria: supplierFilterCriteria,
    isClearable: true,
  },
  {
    id: "pointQuantity",
    placeholder: "Level Point 1 Used",
    type: "money",
    title: "Level Point 1 Used",
    precision: 0,
    prefix: "",
  },
  {
    id: "pointQuantity2",
    placeholder: "Level Point 2 Used",
    type: "money",
    title: "Level Point 2 Used",
    precision: 0,
    prefix: "",
  },
  {
    id: "discountAmountRefund",
    placeholder: "Refund Discount",
    type: "money",
    title: "Refund Discount",
    precision: 0,
    prefix: "",
  },
  {
    id: "expenseAccountCode",
    placeholder: "Expense A/C",
    type: "search",
    title: "Expense A/C",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.CHARTOFACCOUNT,
    columnHeaders: chartOfAccountColumnHeaders,
    filterCriteria: chartOfAccountFilterCriteria,
    fieldsToDisplay: [
      { label: "Name", id: "name" },
      { label: "Account ID", id: "accountNo" },
    ],
  },
  {
    id: "accountNo",
    placeholder: "Account",
    title: "Account",
  },
  {
    id: "order",
    type: "search",
    title: "Order",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.ORDER,
    columnHeaders: orderColumnHeaders,
    filterCriteria: orderFilterCriteria,
    isClearable: true,
  },
  // {
  //   id: "creditMemoItemId",
  //   placeholder: "item",
  //   title: "Item",
  // },
  {
    id: "purchaseOrder",
    type: "search",
    title: "PO",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.PURCHASEORDER,
    columnHeaders: purchaseOrderColumnHeaders,
    filterCriteria: purchaseOrderFilterCriteria,
    isClearable: true,
  },
  {
    id: "orderRefund",
    title: "Refund/Return",
  },
  {
    id: "notes",
    type: "textarea",
    placeholder: "Notes here",
    title: "Notes",
  },
];

export const defaultSelectedCreditMemo = {
  _id: "NEW",
  name: "",
  nameCode: "",
  isDeleted: false,
  notes: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
};
