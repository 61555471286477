// Inventory Editor
import * as Entities from "@/resources/Entities";
import Constants from "@/resources/Constants";
import {
  filterCriteria as groupFilterCriteria,
  searchColumnHeaders as groupColumnHeaders,
} from "../Group/inputs";
import {
  filterCriteria as supplierFilterCriteria,
  searchColumnHeaders as supplierColumnHeaders,
} from "../Supplier/inputs";

import {
  filterCriteria as brandFilterCriteria,
  searchColumnHeaders as brandColumnHeaders,
} from "../Brand/inputs";
import {
  filterCriteria as msoProductFilterCriteria,
  searchColumnHeaders as msoProductColumnHeaders,
} from "../MSOProduct/inputs";
import productExtraInfo from "@/constants/productExtraInfo";

// Page Header
export const pageHeader = {
  title: "Product",
  isEditorView: true,
  cancelPath: "products",
  addPath: "productEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Products",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "productEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  {
    label: "Group Name",
    code: "externalFields.group.name",
    field: { type: "input", label: "Group Name" },
    isPartial: true,
  },
  {
    label: "Supplier",
    code: "externalFields.supplier.name",
    field: { type: "input", label: "Supplier" },
    isPartial: true,
  },
  {
    label: "Brand",
    code: "externalFields.brand.name",
    field: { type: "input", label: "Brand" },
    isPartial: true,
  },
  {
    label: "Online Store Name",
    code: "onlineStoreName",
    field: { type: "input", label: "Online Store Name" },
    isPartial: true,
  },
  {
    label: "Product Id",
    code: "_id",
    field: { type: "input", label: "Product Id" },
  },
  {
    label: "Slug",
    code: "slug",
    field: { type: "input", label: "Slug" },
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Deleted",
    id: "isDeleted",
    type: "checkbox",
  },
  {
    title: "Supplier",
    id: "externalFields.supplier.name",
    size: "large",
  },
  {
    title: "Group",
    id: "externalFields.group.name",
    size: "large",
  },
  {
    title: "Grams",
    id: "cannabisGramsUnit",
  },
  {
    title: "Product Name",
    id: "name",
    required: true,
    size: "large",
  },
  {
    title: "Recreational Price",
    id: "recreationalPrice",
    prefix: "$",
  },
  {
    title: "Medical Price",
    id: "medicalPrice",
    prefix: "$",
  },
  {
    title: "Brand Name",
    id: "brand.name",
    size: "medium",
  },
  {
    title: "Online Store Name",
    id: "onlineStoreName",
    size: "large",
  },
  {
    title: "Product Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Supplier",
    id: "supplier.name",
  },
  {
    title: "Group",
    id: "group.name",
  },
  {
    title: "Cannabis Gram/Unit",
    id: "cannabisGramsUnit",
  },
  {
    title: "Product Name",
    id: "name",
    required: true,
  },
  {
    title: "Brand Name",
    id: "brandName",
  },
  {
    title: "Online Store Name",
    id: "onlineStoreName",
  },
  {
    title: "Recreational Price",
    id: "recreationalPrice",
    prefix: "$",
  },
  {
    title: "Medical Price",
    id: "medicalPrice",
    prefix: "$",
  },
  {
    title: "Inventory Type",
    id: "externalFields.group.inventoryType",
  },
  {
    title: "Id",
    id: "_id",
  },
];

// Inventory Inputs - Main Section
export const productInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "msoProduct",
        placeholder: "MSO Product",
        type: "search",
        title: "MSO Product",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.MSOPRODUCT,
        columnHeaders: msoProductColumnHeaders,
        filterCriteria: msoProductFilterCriteria,
        filter: {
          isActive: true,
          $and: [{ isArchived: false }, { isDeleted: false }],
        },
      },
      {
        id: "group",
        placeholder: "Group",
        type: "search",
        title: "Group",
        label: "name",
        entity: Entities.GROUP,
        columnHeaders: groupColumnHeaders,
        filterCriteria: groupFilterCriteria,
        parentEntity: "product",
        filter: {
          isActive: true,
          isDeleted: false,
        },
      },
      {
        id: "supplier",
        placeholder: "Supplier",
        type: "search",
        title: "Supplier",
        label: "name",
        entity: Entities.SUPPLIER,
        columnHeaders: supplierColumnHeaders,
        filterCriteria: supplierFilterCriteria,
        parentEntity: "product",
        filter: {
          isActive: true,
          isDeleted: false,
        },
      },
      {
        id: "brand",
        placeholder: "Brand",
        type: "search",
        title: "Brand",
        label: "name",
        entity: Entities.BRAND,
        columnHeaders: brandColumnHeaders,
        filterCriteria: brandFilterCriteria,
        parentEntity: "product",
        isClearable: true,
        filter: {
          isArchived: false,
        },
      },
      {
        id: "name",
        placeholder: "Product Name",
        type: "input",
        title: "Product Name",
      },
      {
        id: "variety",
        placeholder: "Variety",
        type: "select",
        title: "Variety",
        options: [
          { label: "CBD", code: "CBD" },
          { label: "CBN", code: "CBN" },
          { label: "High CBD", code: "High CBD" },
          { label: "1:1", code: "1:1" },
          { label: "Hybrid", code: "Hybrid" },
          { label: "Hybrid - I/S", code: "Hybrid - I/S" },
          { label: "Hybrid - S/I", code: "Hybrid - S/I" },
          { label: "Indica", code: "Indica" },
          { label: "Ruderalis", code: "Ruderalis" },
          { label: "Sativa", code: "Sativa" },
          { label: "THCv", code: "THCv" },
      ],
          //Spark'd and D33 asked to remove the above - check if anyone is using
          //CBD
          //Hybrid I/S
          //Hybrid S/I
          //Rudralis
      },
      {
        id: "cannabisGramsUnit",
        type: "number",
        title: "Cannabis Gram/Unit",
        precision: 3,
        placeholder: "Cannabis Gram/Unit",
      },
      {
        id: "unitOfMeasure",
        placeholder: "Unit of Measure",
        type: "select",
        title: "Unit of Measure",
        options: [
          { label: "Unit", code: "unit" },
          { label: "Grams", code: "g" },
        ],
      },

      {
        id: "medicalPrice",
        type: "money",
        title: "Medical Price",
        prefix: "$",
        precision: Constants.numericInput.moneyPrecision,
      },
      // {
      //   id: "medicalPricePreTax",
      //   type: "money",
      //   title: "Medical Price Pre Tax",
      //   prefix: "$",
      //   precision: Constants.numericInput.moneyPrecision,
      // },
      {
        id: "recreationalPrice",
        type: "money",
        title: "Recreational Price",
        placeholder: "Recreational Price",
        precision: Constants.numericInput.moneyPrecision,
        prefix: "$",
      },
      // {
      //   id: "recreationalPricePreTax",
      //   type: "money",
      //   title: "Recreational Price pre Tax",
      //   placeholder: "Recreational Price",
      //   precision: Constants.numericInput.moneyPrecision,
      //   prefix: "$",
      // },
      {
        id: "quantityOnHand",
        type: "text",
        title: "Quantity on Hand",
        placeholder: "Quantity on Hand",
      },
      {
        id: "quantityWorkingInventory",
        type: "text",
        title: "Quantity in Working Inventory",
        placeholder: "Quantity in Working Inventory",
      },
      {
        id: "packagedWeight",
        type: "input",
        title: "Packaged Weight",
        placeholder: "Packaged Weight",
      },
      {
        id: "isActive",
        type: "checkBox",
        title: "Active",
        placeholder: "Active",
      },
      {
        id: "externalFields.group.isCannabis",
        type: "checkBox",
        title: "Cannabis",
        placeholder: "Cannabis",
        disabled: true,
      },
      {
        id: "externalFields.group.isInventoried",
        type: "checkBox",
        title: "Inventoried",
        placeholder: "Inventoried",
        disabled: true,
      },
      {
        id: "externalFields.group.isTaxable",
        type: "checkBox",
        title: "Taxable",
        placeholder: "Taxable",
        disabled: true,
      },
      {
        id: "externalFields.group.isService",
        type: "checkBox",
        title: "Service",
        placeholder: "Item is Service",
        disabled: true,
      },
      {
        id: "externalFields.group.isGiftCard",
        type: "checkBox",
        title: "Gift Card",
        placeholder: "Gift Card",
        disabled: true,
      },
      {
        id: "isPublishedToOnlineStore",
        type: "checkBox",
        title: "Online Store",
        placeholder: "Public to Online Store",
      },
      {
        id: "isMenuPrint",
        type: "checkBox",
        title: "Is Menu Print",
        placeholder: "Is Menu Print",
      },
      {
        id: "isFree",
        type: "checkBox",
        title: "Is Free",
        placeholder: "Is Free",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isForResale",
        type: "checkBox",
        title: "For Sale",
        placeholder: "For Sale",
        disabled: true,
      },
      {
        id: "stockMinimum",
        type: "input",
        title: "Stock Minimum",
        placeholder: "Stock Minimum",
      },
      {
        id: "storeDefaultQuantity",
        type: "input",
        title: "Store Default Quantity",
        placeholder: "Store Default Quantity",
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

// Bulk Prices Section
export const bulkPricesInputs = [
  {
    id: "isActive",
    type: "checkBox",
    title: "Active",
    placeholder: "Active",
  },
  {
    id: "name",
    type: "input",
    title: "Name",
    placeholder: "Name",
  },
  {
    id: "weight",
    type: "number",
    title: "Grams",
    placeholder: "Grams",
  },
  {
    id: "price",
    type: "money",
    title: "Price",
    placeholder: "0",
  },
];

// Sale Pricing Section
export const salePricingInputs = [
  {
    id: "isActive",
    type: "checkBox",
    title: "Active",
    placeholder: "Active",
  },
  {
    id: "saleType",
    type: "select",
    title: "Sale Type",
    placeholder: "Sale Type",
    options: ["medical", "recreational"],
    label: "name",
  },
  {
    id: "quantity",
    type: "number",
    precision: 0,
    title: "Quantity",
    placeholder: "Quantity",
  },
  {
    id: "price",
    type: "money",
    prefix: "$",
    title: "Price",
    placeholder: "Price",
  },
  {
    id: "quantityLimit",
    type: "number",
    precision: 0,
    title: "Quantity Limit",
    placeholder: "Quantity Limit",
  },
  {
    id: "applyDateRange",
    type: "checkBox",
    title: "Apply Date Range",
    placeholder: "Apply Date Range",
  },
  {
    id: "startDate",
    type: "calendar",
    title: "Start Date",
    placeholder: "Start Date",
    class: "salePricing__calendar",
    showTime: true,
  },
  {
    id: "endDate",
    type: "calendar",
    title: "End Date",
    placeholder: "End Date",
    class: "salePricing__calendar",
    showTime: true,
  },
];

// Online Store Section
export const onlineStoreInputs = [
  {
    id: "onlineStoreName",
    type: "input",
    title: "Online Store Name",
    placeholder: "Online Store Name",
  },
  {
    title: "Slug (online store url)",
    id: "slug",
    type: "input",
    placeholder: "Slug (online store url)",
  },
  {
    id: "onlineStoreDescription",
    placeholder: "Product description for the Online store",
    type: "textarea",
    title: "Online Store Description",
    class: "grid-row-two-column",
  },
  {
    id: "imageNormal.secure_url",
    placeholder: "Image URL",
    type: "input",
    title: "Image URL",
  },
  {
    id: "flavors",
    placeholder: "flavors",
    type: "input",
    title: "Flavors",
  },
  {
    id: "effects",
    placeholder: "Effects",
    type: "input",
    title: "Effects",
  },
  {
    id: "terpenes",
    placeholder: "Terpenes",
    type: "input",
    title: "Terpenes",
  },
  {
    id: "tags",
    placeholder: "Tags",
    type: "input",
    title: "Tags",
  },
];

export const extraInfoInputs = [
  {
    id: "name",
    placeholder: "Name",
    type: "select",
    title: "Name",
    options: productExtraInfo,
  },
  {
    id: "value",
    type: "input",
    title: "Value",
    placeholder: "Value",
  },
];

export const defaultSelectedProduct = {
  _id: "NEW",
  name: "",
  group: undefined,
  supplier: undefined,
  variety: "",
  description: "",
  notes: "",
  medicalPrice: 0,
  recreationalPrice: 0,
  medicalPricePreTax: 0,
  recreationalPricePreTax: 0,
  priceByWeight: [],
  unitOfMeasure: "unit",
  cannabisGramsUnit: 0,
  packagedWeight: 0,
  stockMinimum: 0,
  storeDefaultQuantity: 0,
  isActive: true,
  isMedicalOnly: false,
  isPublishedToOnlineStore: true,
  brand: '',
  brandName: '',
  onlineStoreName: "",
  onlineStoreDescription: "",
  isCannabis: true,
  isTaxable: true,
  isInventoried: true,
  isForResale: true,
  imageThumbnail: undefined,
  imageNormal: undefined,
  createdBy: {},
  updatedBy: {},
  msoProduct: undefined,
  salePrices: [],
  flavors: [],
  effects: [],
  terpenes: [],
  tags: [],
  extraInfo: [],
};

export const defaultSalePrice = {
  isActive: false,
  salePrice: "",
  salePricePreTax: "",
  quantity: 1,
  price: 0,
  quantityLimit: 0,
  startDate: "",
  endDate: "",
  applyDateRange: false,
  saleType: "recreational",
};
