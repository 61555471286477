<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Sales Summary Breakdown" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesSummaryBreakdown',
          label: 'Sales Summary Breakdown',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th align="left">Day</th>
                <th align="right">Total Sales</th>
                <th align="right">Discount</th>
                <th align="right">Tax</th>
                <th align="right">Delivery</th>
                <th align="right"># of Discounts</th>
                <th align="right"># of Orders</th>
                <th align="right"># of Items</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[day, value] in Object.entries(reportData.list)" :key="day">
                <td>{{ value.dateString }}</td>
                <td align="right">{{ Utilities.formatPrice(value.total) }}</td>
                <td align="right">
                  {{ Utilities.formatPrice(value.discount) }}
                </td>
                <td align="right">{{ Utilities.formatPrice(value.tax) }}</td>
                <td align="right">
                  {{ Utilities.formatPrice(value.freight) }}
                </td>
                <td align="right">{{ value.discountsCount }}</td>
                <td align="right">{{ value.ordersCount }}</td>
                <td align="right">{{ value.itemsCount }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="1">Totals</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.total) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.discount) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.tax) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.freight) }}
              </td>
              <td align="right">
                {{ reportData.total.discountsCount }}
              </td>
              <td align="right">
                {{ reportData.total.ordersCount }}
              </td>
              <td align="right">
                {{ reportData.total.itemsCount }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";
import moment from 'moment'

export default {
  data() {
    return {
      Utilities,
      selectedPeriod: null,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Sales Summary Breakdown");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generate: "reports/generate",
      clearData: "reports/clearData",
    }),
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "SalesSummaryBreakdown");
    },
    performGetReport(filter) {
      let reportType = "salesSummaryBreakdown";

      console.log(filter)
      this.generate({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesSummaryBreakdown.scss";
</style>
