<template>
  <div class="dashboard">
    <PageHeader title="Sales Overview" :links="[
      { name: 'home', label: 'Home' },
      { name: 'dashboards', label: 'Dashboards' },
      { name: 'salesReport', label: 'Sales' },
      {
        name: 'salesOverview',
        label: 'Sales Overview',
      },
    ]" />
    <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
      :percentage="percentageCompleted" :initialSelectedPeriod="initialSelectedPeriod" :triggerFilterOnLoad="true" />
    <div class="graph-container">
      <div class="graph-container__row">
        <Graph :loadingStatus="loadingStatus.numberOfOrders" title="Number of Orders">
          <ValueGraph :chartData="numberOfOrdersChartData" />
        </Graph>
        <Graph :loadingStatus="loadingStatus.salesSummary" title="Sales Total">
          <ValueGraph dataType="money" :chartData="salesTotalChartData" />
        </Graph>
        <Graph :loadingStatus="loadingStatus.salesBySource" ref="salesBySource" :chartData="salesBySourceChartData"
          title="Orders by Source" />
      </div>
      <Graph :loadingStatus="loadingStatus.salesSummary" ref="salesByDay" :chartData="salesChartData"
        title="Sales by day" />
      <div class="graph-container__row">

        <Graph v-if="saleType == 'recreational' || saleType == 'medical and recreational'" :centerSlot="false" size="lg"
          :loadingStatus="loadingStatus.topProducts" :title="getTopRecreationalProductsTitle">
          <TopList :chartData="topProductsChartData"
            :filter="(row) => row.entry.type == 'recreational' && row.entry.isCannabis" />
        </Graph>
        <Graph v-if="saleType == 'medical' || saleType == 'medical and recreational'" :centerSlot="false" size="lg"
          :loadingStatus="loadingStatus.topProducts" :title="getTopMedicalProductsTitle">
          <TopList :chartData="topProductsChartData"
            :filter="(row) => row.entry.type == 'medical' && row.entry.isCannabis" />
        </Graph>
        <Graph :centerSlot="false" size="lg" :loadingStatus="loadingStatus.topProducts" title="Top Non Cannabis Products">
          <TopList :chartData="topProductsChartData" :filter="(row) => !row.entry.isCannabis" />
        </Graph>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import Graph from "@/components/Graph/Graph";
import ValueGraph from "@/components/ValueGraph/ValueGraph";
import TopList from "@/components/TopList/TopList";
import { getDefaultSalesChartData, populateSalesChartData } from "@/views/dashboards/SalesOverview/helpers/sales.helper"
import { populateSalesTotalChartData } from "@/views/dashboards/SalesOverview/helpers/salesTotal.helper"
import { populateNumberOfOrdersChartData } from "@/views/dashboards/SalesOverview/helpers/numberOfOrders.helper"
import { getDefaultSalesBySourceChartData, populateSalesBySourceChartData } from "@/views/dashboards/SalesOverview/helpers/salesBySource.helper"
import { getDefaultTopProductsChartData, populateTopProductsChartData } from "@/views/dashboards/SalesOverview/helpers/topProducts.helper"

export default {
  data() {
    return {
      Utilities,
      salesChartData: undefined,
      salesTotalChartData: { value: 0 },
      numberOfOrdersChartData: { value: 0 },
      salesBySourceChartData: undefined,
      topProductsChartData: undefined
    };
  },
  components: {
    PageHeader,
    ReportFilter,
    Graph, ValueGraph, TopList
  },
  created: function () {
    this.setPageTitle("Sales Overview");

    // Default sales chart data
    this.salesChartData = getDefaultSalesChartData(this.theme)

    // Default orders by type
    this.salesBySourceChartData = getDefaultSalesBySourceChartData(this.theme)

    // Default top products data
    this.topProductsChartData = getDefaultTopProductsChartData(this.theme)
  },
  computed: {
    ...mapGetters({
      isLoading: "dashboardModule/isLoading",
      getData: "dashboardModule/getData",
      percentageCompleted: 'dashboardModule/percentageCompleted',
      loadingStatus: 'dashboardModule/loadingStatus',
      theme: "theme",
      saleType: "reports/saleType",
    }),
    reportData() {
      return this.getData;
    },
    initialSelectedPeriod() {
      return undefined
      // return {
      //   //from: new Date(new Date().setDate(new Date().getDate() - 1)),
      //   from: new Date('04/01/2023'),
      //   to: new Date(),
      // }
    },
    getTopRecreationalProductsTitle() {
      return this.saleType == 'medical and recreational' ? 'Top Recreational Products' : 'Top Cannabis Products'
    },
    getTopMedicalProductsTitle() {
      return this.saleType == 'medical and recreational' ? 'Top Medical Products' : 'Top Cannabis Products'
    }
  },
  methods: {
    ...mapActions({
      batchGenerate: "dashboardModule/batchGenerate",
      clearData: "dashboardModule/clearData",
    }),
    performGetReport(filter) {
      this.clearGraphsData()
      this.batchGenerate({
        requests: [{
          reportType: "salesSummary",
          ...filter,
        }, {
          reportType: "salesBySource",
          ...filter,
        },
        {
          reportType: "topProducts",
          ...filter,
        },
        {
          reportType: "numberOfOrders",
          ...filter,
        }]
      });
    },
    clearGraphsData() {
      this.salesTotalChartData = { value: 0 }
      this.numberOfOrdersChartData = { value: 0 }
      if (this.$refs.salesByDay) {
        this.$refs.salesByDay.clear()
      }
      if (this.$refs.ordersByType) {
        this.$refs.ordersByType.clear()
      }
      if (this.$refs.salesBySource) {
        this.$refs.salesBySource.clear()
      }
    }
  },
  watch: {
    "getData.salesSummary.list"(newData) {
      populateSalesChartData(this.salesChartData, newData, this.theme)
      populateSalesTotalChartData(this.salesTotalChartData, this.getData.salesSummary)
    },
    "getData.salesBySource.list"(newData) {
      populateSalesBySourceChartData(this.salesBySourceChartData, this.getData.salesBySource)
    },
    "getData.topProducts.list"(newData) {
      populateTopProductsChartData(this.topProductsChartData, newData)
    },
    "getData.numberOfOrders.list"(newData) {
      populateNumberOfOrdersChartData(this.numberOfOrdersChartData, this.getData.numberOfOrders.total)
    }
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesOverview.scss";
</style>
