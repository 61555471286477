<template>
  <div class="group-editor responsive-menu">
    <Ordering
      v-if="groupOrderingPopUp.isOpen"
      title="Inventory Group Receipt Order"
      :items="groups"
      propertyToSortBy="receiptDisplayOrder"
      @close="closeHandler"
      @save="saveHandler"
    />
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'groups', label: 'Groups' },
          {
            name: 'groupEditor',
            label: selectedGroup.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedGroup"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
        @menu-option-click="menuOptionClick"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="inputs in groupInputs" :key="inputs.id">
              <div
                class="
                  grid-two-column
                  two-columns-layout--responsive-horizontal-space-10
                "
              >
                <div
                  v-for="input in inputs.inputs"
                  :key="input.id"
                  :class="'input-text' + ' ' + input.class"
                >
                  <Input
                    :model="selectedGroup[input.id]"
                    @updateModel="selectedGroup[input.id] = $event"
                    :inputData="input"
                    :isEdit="isEdit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditorSection
        :title="'Integrations'"
        :bus="bus"
      >
        <div class="padded elevated-0 bordered">
          <div v-for="input in groupIntegrationInputs" :key="input.id" class="input-text">
            <Input :model="selectedGroup[input.id]"
              @updateModel="selectedGroup[input.id] = $event" :inputData="input" :isEdit="isEdit" />
          </div>
        </div>
      </EditorSection>
      <EditorSection
        :title="`About ${selectedGroup.name || ''}`"
        sectionType="about"
        v-if="selectedGroup._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedGroup" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="toggleSearchModal"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import { mapActions, mapGetters } from "vuex";
import Input from "@/components/Input/Input.vue";
import moment from "moment";
import _ from "lodash";
import Constants from "@/resources/Constants";
import EditorSection from "@/components/EditorSection";
import Entities from "@/resources/Entities";
import Ordering from "@/components/Modals/Ordering/Ordering.vue";

import {
  groupInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
  defaultSelectedGroup,
  groupIntegrationInputs
} from "./inputs";

export default {
  name: "groupEditor",
  data() {
    return {
      Constants: Constants,
      moment: moment,
      groupInputs,
      inventoryTypes: [],
      unitOfMeasures: ["unit", "g"],
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.GROUP,
      pageHeader,
      groups: [],
      groupIntegrationInputs
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
    Ordering,
  },

  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getGroupById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "groupModule/loadingStatus",
      group: "groupModule/group",
      groupOrderingPopUp: "groupModule/groupOrderingPopUp",
      getGroups: "groupModule/groups",
    }),
    selectedGroup: {
      get() {
        this.setPageTitle("Group " + this.group.name);
        return this.prefillItemBeforeRendering(this.group);
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getGroupById: "groupModule/getGroupById",
      saveGroup: "groupModule/saveGroup",
      deleteGroup: "groupModule/deleteGroup",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "groupModule/getAdjacent",
      setGroupOrderingPopUpOpen: "groupModule/setGroupOrderingPopUpOpen",
      retrieveGroups: "groupModule/getGroups",
      updateGroupOrdering: "groupModule/updateGroupOrdering",
    }),
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedGroup.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedGroup = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    prefillItem: function (item) {
      const itemSubsetToCopy = _.pick(item, ["name"]);
      function mergeEmptyItems(objValue, srcValue) {
        if (objValue) return objValue;
        return srcValue;
      }
      _.mergeWith(this.selectedGroup, itemSubsetToCopy, mergeEmptyItems);
    },
    clearItemFromSearch: function (entity) {
      this.selectedGroup[entity] = null;
    },
    selectItemFromSearch: function (entity, item) {
      if (entity === Entities.MSOGROUP && this.selectedGroup._id === "NEW")
        this.prefillItem(item);

      this.$set(this.selectedGroup, entity, item);

      // this.selectedGroup[entity] = item;
    },
    toggleEdit() {
      if (!(this.selectedGroup._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedGroup._id === "NEW") {
        this.isEdit = true;
      }
    },
    menuOptionClick(entity, key) {
      if (key === "group-order-receipt") {
        this.setGroupOrderingPopUpOpen(true);
      }
      if (key === "group-listing") {
        window.open(`/reports/inventory/inventoryListGroup`);
      }
      if (key === "group-listing-online") {
        window.open(`/reports/inventory/inventoryListGroupOnline`);
      }
    },
    prefillItemBeforeRendering(entity) {
      entity.inventoryAccountCode = entity.inventoryAccountCode
        ? entity.inventoryAccountCode
        : {};
      entity.salesAccountCode = entity.salesAccountCode
        ? entity.salesAccountCode
        : {};
      entity.expenseAccountCode = entity.expenseAccountCode
        ? entity.expenseAccountCode
        : {};
      return entity;
    },
    closeHandler() {
      this.setGroupOrderingPopUpOpen(false);
    },
    saveHandler(updatedGroups) {
      this.updateGroupOrdering(updatedGroups).then(() => {
        this.closeHandler();
      });
    },
  },
  async mounted() {
    this.setPageTitle("Group");
    if (this.$route.query.id) {
      this.getGroupById({
        _id: this.$route.query.id,
        positiveCallback: (group) => {
          this.setPageTitle(`Group ${group.name}`);
        },
      });
    } else {
      this.isEdit = true;
      this.$store.commit("groupModule/setGroup", { _id: "NEW" });
    }
    let groupsInStore = this.getGroups;
    if (!groupsInStore || groupsInStore.length == 0) {
      await this.retrieveGroups().then((data) => {
        groupsInStore = data;
      });
    }

    groupsInStore = groupsInStore.sort(
      (a, b) => a.receiptDisplayOrder - b.receiptDisplayOrder
    );
    this.groups = JSON.parse(JSON.stringify(groupsInStore));
  },
};
</script>
