<template>
  <div class="onlineStoreGroup-base">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "onlineStoreGroupBase",
};
</script>
