<template>
  <div class="returnItemSummary__body">
    <div class="returnItemSummary__detail">
      <div>Available Quantity</div>
      <div>{{ remainingQuantity }}</div>
    </div>
    <div class="returnItemSummary__detail">
      <div>Return/Refund Quantity</div>
      <BaseInput
        label=""
        placeholder="0"
        controlType="number"
        v-model="returnItem.quantity"
        :disabled="!isEdit || !canEdit"
        :isSmall="true"
      />
    </div>
    <div class="returnItemSummary__detail">
      <div>Unit Cost</div>
      <div>{{ Utilities.formatPrice(unitCost) }}</div>
    </div>
    <div class="returnItemSummary__detail returnItemSummary__detail--highlight">
      <div>SubTotal</div>
      <div>{{ Utilities.formatPrice(subTotal) }}</div>
    </div>
    <div class="returnItemSummary__detail">
      <div>Discount</div>
      <div>{{ Utilities.formatPrice(discount) }}</div>
    </div>
    <div class="returnItemSummary__detail returnItemSummary__detail--highlight">
      <div>Line Total</div>
      <div>{{ Utilities.formatPrice(lineTotal) }}</div>
    </div>
    <div class="returnItemSummary__detail">
      <div>Tax</div>
      <div>{{ Utilities.formatPrice(tax) }}</div>
    </div>
    <div class="returnItemSummary__detail">
      <div>Delivery Charge</div>
      <div>{{ Utilities.formatPrice(delivery) }}</div>
    </div>
    <div
      class="
        returnItemSummary__detail
        returnItem__total
        returnItemSummary__detail--highlight
      "
    >
      <div>Return Total</div>
      <div>{{ Utilities.formatPrice(returnTotal) }}</div>
    </div>
  </div>
</template>

<script>
import OrderHelper from "@/lib/OrderHelper";
import BaseInput from "@/components/Base/Input/Base.vue";
import Utilities from "@/lib/Utilities";

export default {
  props: {
    returnItem: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Utilities,
    };
  },
  components: {
    BaseInput,
  },
  watch: {
    subTotal(val) {
      this.returnItem.subTotal = val;
    },
  },
  computed: {
    remainingQuantity() {
      const value =
        this.returnItem.availableQuantity - this.returnRefundQuantity;
      if (value >= 0) return value;
      return 0;
    },
    returnRefundQuantity() {
      return this.returnItem.quantity;
    },
    itemInOrder() {
      return (
        OrderHelper.findItemInOrder(
          this.returnItem.item,
          this.returnItem.order
        ) || {}
      );
    },
    unitCost() {
      let unitCost = this.itemInOrder.price;

      const unitOfMeasure =
        this.itemInOrder?.externalFields?.group?.unitOfMeasure;
      if (unitOfMeasure == "g") {
        unitCost = unitCost / this.returnItem.originalQuantityOrdered;
      }

      return unitCost;
    },
    actualUnitCost() {
      return this.itemInOrder.actualUnitCost;
    },
    subTotal() {
      const subTotal = this.unitCost * this.returnRefundQuantity;
      return subTotal;
    },
    discount() {
      const discounts = this.itemInOrder.discountsApplied || [];
      let tot = 0;
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        tot +=
          (this.returnRefundQuantity * discount.amountDiscountedInDollars) /
          this.returnItem.originalQuantityOrdered;
      }
      const discount = +tot.toFixed(2);
      return discount;
    },
    lineTotal() {
      return +(this.subTotal - this.discount);
    },
    tax() {
      let taxes = this.itemInOrder.taxesApplied || [];
      taxes = taxes.filter((t) => t.allIn == false);
      let tot = 0;
      for (let i = 0; i < taxes.length; i++) {
        const tax = taxes[i];
        tot +=
          (this.returnRefundQuantity * tax.taxAmount) /
          this.returnItem.originalQuantityOrdered;
      }

      const value = +tot.toFixed(2);
      this.$emit("update-tax", value);

      return value;
    },
    delivery() {
      const value = this.returnRefundQuantity * this.itemInOrder.delivery || 0;
      this.$emit("update-delivery", value);
      return value;
    },
    returnTotal() {
      const value = (this.lineTotal + this.tax + this.delivery).toFixed(2);
      this.$emit("update-returnTotal", value);
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_returnItemSummary.scss";
</style>
