<template>
  <div class="instant">
    <div class="instant__body">
      <div>
        <BaseInput
          class="instant__body__input"
          label="Employee PIN"
          placeholder="Employee PIN"
          v-model="pin"
          :isPassword="true"
          :applyFocus="true"
          @focus="setFocus"
        />
        <BaseInput
          class="instant__body__input"
          label="Discount"
          controlType="number"
          placeholder="Discount"
          v-model="value"
          @focus="setFocus"
        />
        <div class="instant__options">
          <CheckBox
            sideLabel="%"
            :value="type == '%'"
            @click="() => (type = '%')"
          />
          <CheckBox
            sideLabel="$"
            :value="type == '$'"
            @click="() => (type = '$')"
          />
        </div>
        <BaseBtn
          class="instant__action"
          label="Apply Discount"
          @click="apply"
          :disabled="!canApply"
        />
      </div>
      <div class="instant__reason">
        <textarea
          class="flat-textarea"
          rows="5"
          placeholder="Reason..."
          v-model="reason"
          @focus="setFocus"
        ></textarea>
      </div>
      <BaseBtn
        class="instant__action--mobile"
        label="Apply Discount"
        @click="apply"
        :disabled="!canApply"
      />
    </div>
    <Keypad class="instant__keypad" :input="input" />
  </div>
</template>

<script>
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";
import Keypad from "@/components/Keypad";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import { mapActions, mapGetters } from "vuex";
import { isManager } from '@/lib/rolesHelper'
export default {
  data() {
    return {
      employee: null,
      pin: "",
      value: "", // null
      reason: "",
      type: "%",
      input: {},
    };
  },
  components: {
    CheckBox,
    Keypad,
    BaseBtn,
    BaseInput,
  },
  watch: {
    pin(val) {
      this.getEmployeeByPin({
        pin: val,
        showMessage: false,
        workstation: this.workstation,
        positiveCallback: (emp) => {
          this.employee = emp;
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      workstation: "posModule/selectedWorkstation",
      getCurrentStore: "storeModule/currentStore",
    }),
    canApply() {
      return (
        this.employee &&
        this.value &&
        this.value.trim().length > 0 &&
        this.reason.trim().length > 0
      );
    },
  },
  methods: {
    ...mapActions({
      getEmployeeByPin: "employeeModule/getEmployeeByPin",
      setMessage: "setMessage",
    }),
    setFocus(e) {
      this.input = e.target;
    },
    areValidInputs() {
      if (!this.value) {
        this.setMessage({
          text: "Value is not correct",
          type: "error",
        });
        return false;
      }
      return true;
    },
    isEmployeeValid() {
      if (
        !this.employee ||
        this.employee.isDeleted ||
        !this.employee.isEmployeed
      ) {
        this.setMessage({
          text: "Employee is not authorized",
          type: "error",
        });
        return false;
      }

      const managersOnly = this.getCurrentStore.allowInstantDiscountsToManagersOnly;

      if(managersOnly) {
        const isManagerRole = isManager(this.employee, this.getCurrentStore._id)
        if (!isManagerRole) {
          this.setMessage({
            text: "This employee is not a manager. Please ask a manager to apply this discount",
            type: "error",
          });
          return false;
        }
      }

      return true;
    },
    isValid() {
      return this.isEmployeeValid();
    },
    apply() {
      if (!this.isValid()) return;
      

      const discount = {
        type: "instant",
        instantDiscountAmount: this.value,
        instantDiscountType: this.type,
        instantDiscountReason: this.reason,
        instantDiscountEmployee: this.employee._id,
      };
      this.$emit("applyDiscount", discount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_instant.scss";
</style>
