/*
  Function: prefillCustomerFieldsFromParam
  Populates 'customer' fields with corresponding 'params' values.
  Parameters: customer - object to update, params.
  Note: Modifies 'customer' directly.
*/
export const prefillCustomerFieldsFromParams = (customer, params) => {
    if (params.name) customer.name = params.name
    if (params.lastName) customer.lastName = params.lastName
    if (params.licenseNumber) customer.licenseNumber = params.licenseNumber
    if (params.state) customer.state = params.state
}