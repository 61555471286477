<template>
  <div class="cash-reconciliation-overview">
    <DataColumn :items="reportInfo" />
    <DataColumn :items="cashInfo" />
    <DataColumn :items="saleInfo" />
    <DataColumn :items="paymentInfo" />
  </div>
</template>

<script>
import DataColumn from "./DataColumn/index.vue";
import { DEFAULT_SCHEMA_CASH_RECONCILIATION } from "@/store/cashReconciliationModule/cashReconciliationModule.js";
import moment from "moment";
import Constants from "@/resources/Constants";

export default {
  props: {
    cashReconciliation: {
      type: Object,
      default: () => {
        return { ...DEFAULT_SCHEMA_CASH_RECONCILIATION };
      },
    },
  },
  data() {
    return {};
  },
  components: {
    DataColumn,
  },
  computed: {
    reportInfo() {
      return [
        {
          name: "ID #",
          value: this.cashReconciliation._id,
        },
        {
          name: "Station",
          value: this.cashReconciliation.workstation,
        },
        {
          name: "Employee",
          value: this.cashReconciliation.employee,
        },
        {
          name: "Start Date",
          value: this.cashReconciliation.startDate
            ? moment(this.cashReconciliation.startDate).format(
                Constants.dates.extendedDateFormat
              )
            : "none",
        },
        {
          name: "End Date",
          value: this.cashReconciliation.endDate
            ? moment(this.cashReconciliation.endDate).format(
                Constants.dates.extendedDateFormat
              )
            : "none",
        },
      ];
    },
    cashInfo() {
      return [
        {
          name: "Opening",
          value: this.cashReconciliation.cashAtOpening,
          type: "money",
        },
        {
          name: "Cash",
          value: this.cashReconciliation.cashCollected,
          type: "money",
        },
        {
          name: "Payout",
          value: this.cashReconciliation.payoutTotal,
          type: "money",
        },
        {
          name: "Counted",
          value: this.cashReconciliation.userCashCountTotal,
          type: "money",
        },
        {
          name: "Expected",
          value: this.cashReconciliation.cashTotalExpected,
          type: "money",
        },
        {
          name: "Difference",
          value: this.cashReconciliation.cashTotalDifference,
          type: "money",
        },
      ];
    },
    saleInfo() {
      return [
        {
          name: "Sales",
          value: this.cashReconciliation.saleTotal,
          type: "money",
        },
        {
          name: "Refunds",
          value: this.cashReconciliation.refundTotal,
          type: "money",
        },
        {
          name: "Total Sales",
          value: this.cashReconciliation.totalSales,
          type: "money",
          isHighlight: true,
        },
      ];
    },
    paymentInfo() {
      return [
        {
          name: "Cash",
          value: this.cashReconciliation.cashCollected,
          type: "money",
        },
        {
          name: "Credit Memo",
          value: this.cashReconciliation.creditmemoCollected,
          type: "money",
        },
        {
          name: "Debit/Credit",
          value: this.cashReconciliation.debitcreditCardCollected,
          type: "money",
        },
        {
          name: "Gift Card",
          value: this.cashReconciliation.giftcardCollected,
          type: "money",
        },
        {
          name: "Total Payments",
          value: this.cashReconciliation.totalCollected,
          type: "money",
          isHighlight: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
