<template>
  <div id="CustomerVerificationPopUp">
    <div
      v-on:click.stop="() => setCustomerVerificationPopUp({ isOpen: false })"
      class="overlay-div"
    ></div>
    <div class="default-pop-up-center-div bordered center">
      <span class="h1">Patient Verification</span>
      <br />
      <br />
      <div class="padded">
        <input
          type="text"
          class="flat-input margin-right"
          v-model="customerSRN"
          placeholder="Customer SRN #"
          disabled
        />
        <br />
        <br />
        <!-- <input type="text" class="flat-input" v-model="caregiverSRN" placeholder="Caregiver SRN #" />
        <br />
        <br />-->

        <div
          class="interspace button-secondary"
          @click="() => performVerifyCustomer()"
        >
          <span v-show="!loadingStatus.isVerifyCustomerLoading">Verify</span>
          <span v-show="loadingStatus.isVerifyCustomerLoading">
            <i class="fas fa-spin fa-circle-notch"></i>
          </span>
        </div>
        <div class="interspace button-info">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerVerificationPopUp",
  data() {
    return {
      customerSRN: "",
      caregiverSRN: "",
    };
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.setCustomerVerificationPopUp({ isOpen: false });
        }
      }.bind(this)
    );
  },
  mounted: function () {
    if (this.selectedCustomer.id_No) {
      this.customerSRN = this.selectedCustomer.srN_No;
      this.caregiverSRN = this.selectedCustomer.care_Id_No;
    }
  },
  computed: {
    ...mapGetters([
      "customerVerificationPopUp",
      "selectedCustomer",
      "loadingStatus",
    ]),
  },
  methods: {
    ...mapActions(["setCustomerVerificationPopUp", "verifyCustomer"]),
    performVerifyCustomer: function () {
      this.verifyCustomer({
        customerSRN: this.customerSRN,
        positiveCallback: () =>
          this.setCustomerVerificationPopUp({ isOpen: false }),
        negativeCallback: () =>
          this.setCustomerVerificationPopUp({ isOpen: false }),
      });
    },
  },
};
</script>

<style lang="scss">
// .customer-Verification-center-div {
//   position: fixed;
//   top: 50%;
//   right: 50%;
//   transform: translate(50%, -50%);
//   min-width: 80%;
//   background-color: $dark-background-color;
//   padding: 20px;
//   min-height: 200px;
//   overflow-y: auto;
// }

// .light .customer-Verification-center-div {
//   background-color: $light-background-color;
// }
</style>
