import {
  filterCriteria as couponFilterCriteria,
  searchColumnHeaders as couponColumnHeaders,
} from "@/views/Coupon/inputs";

import * as Entities from "@/resources/Entities";

export const inputs = [
  {
    id: "coupon",
    placeholder: "Select Coupon",
    type: "search",
    title: "Coupon",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.COUPON,
    columnHeaders: couponColumnHeaders,
    filterCriteria: couponFilterCriteria,
    isClearable: true,
    fieldsToDisplay: [
      { label: "Name", id: "name" },
      { label: "ID", id: "_id" },
    ],
    filter: {
      isDeleted: false,
      isArchived: false,
      isActive: true,
    },
  },
];
