<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Inventory Count Differences"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'inventoryReport', label: 'Inventory' },
            {
              name: 'inventoryCountFeatureOff',
              label: 'Inventory Count Differences',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
          @printAll="isPrintAllModalOpen = true"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          @submit-filter="performGetReport"
          :isLoading="inventoryTransactions"
        />
        <BaseModal
          v-if="isPrintAllModalOpen"
          @toggle-modal="togglePrintAllModal"
          title=""
          :autoWidth="true"
        >
          <PrintAll
            :items="reportData.records"
            @closePrintModal="togglePrintAllModal"
            />
      </BaseModal>
      <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr>
                  <th align="left">Employee</th>
                  <th align="left">Item Id</th>
                  <th align="left">Batch #</th>
                  <th align="left">Group</th>
                  <th align="left">Supplier</th>
                  <th align="left">Grams</th>
                  <th align="left">Product</th>
                  <th align="right">Hand Count</th>
                  <th align="right">Expected</th>
                  <th align="right">Difference</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                  <td>{{ record.employee }}</td>
                  <td>{{ record.item }}</td>
                  <td>{{ record.batchId }}</td>
                  <td>{{ record.group }}</td>
                  <td>{{ record.supplier }}</td>
                  <td>{{ record.grams }}</td>
                  <td>{{ record.product }}</td>
                  <td align="right">{{ record.handCount }}</td>
                  <td align="right">{{ record.currentValue }}</td>
                  <td align="right">{{ record.countDifference }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";
  import ReportHelper from "@/lib/report/index.js";
  import PrintAll from '@/components/PrintAll/PrintAll.vue'
  import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
        isPrintAllModalOpen: false
      };
    },
    components: {
      PageHeader,
      ReportFilter,
      BaseModal,
      PrintAll
    },
    created: function () {
      this.setPageTitle("Inventory Count Differences");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        inventoryTransactions: "reports/inventoryTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getSalesTransactions: "reports/getInventoryTransactions",
      }),
      performGetReport(filter) {
        let reportType = "inventoryCountFeatureOff";
        this.getSalesTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
          },
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "inventoryCountFeatureOff");
      },
      togglePrintAllModal() {
        this.isPrintAllModalOpen = false;
      }
    },
  };
  </script>

  <style lang="scss">
  @import "./_inventoryCountFeatureOff.scss";
