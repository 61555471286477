import HttpService from "@/services/HttpService";
import LocalStorageManager from "@/lib/LocalStorageManager";
import router from "@/router";
import Apis from "@/services/Apis";

const state = {
  manifest: undefined,
  stateInventory: [],
  loadingStatus: {
    getManifestById: false,
    getStateInventory: false,
    checkCannabisLimit: false,
    getChemicalComposition: false,
    checkCredentials: false
  },
  refetchTableData: false,
  stateInventoryPopUp: {
    isOpen: false,
  },
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setManifest: (state, payload) => {
    state.manifest = payload;
  },
  setStateInventory: (state, payload) => {
    state.stateInventory = payload;
  },
  setStateInventoryPopUp: (state, payload) => {
    state.stateInventoryPopUp = payload;
  },
  setRefetchTableData: (state, payload) => {
    state.refetchTableData = payload;
  },
};

const getters = {
  manifest: (state) => state.manifest,
  loadingStatus: (state) => state.loadingStatus,
  stateInventory: (state) => state.stateInventory,
  stateInventoryPopUp: (state) => state.stateInventoryPopUp,
  refetchTableData: (state) => state.refetchTableData,
};

const actions = {
  getManifestById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getManifestById", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.getManifestById,
      {
        manifestId: payload.manifestId,
        supplier: payload.supplier,
        manifestDate: payload.manifestDate,
        purchaseOrderId: payload.purchaseOrderId,
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "getManifestById", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
          commit("setManifest", response.data.body);
          let message = "Manifest successfully retrieved"
          if (response.data.body?.length == 0) {
            message = "Manifest not found"
          }
          commit(
            "setMessage",
            { text: message, type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: error.errorCode == 99 ? "state-error" : "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getChemicalComposition: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getChemicalComposition", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.getChemicalComposition,
      { purchaseOrder: payload.purchaseOrder },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getChemicalComposition",
          status: false,
        });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
          commit(
            "setMessage",
            {
              text: "Chemical composition successfully retrieved",
              type: "success",
            },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getStateInventory: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getStateInventory", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.getStateInventory}`;
    if (payload && payload.transactionStart) {
      url += `?transactionStart=${payload.transactionStart}`;
    }
    if (payload && payload.transactionEnd) {
      url += `&transactionEnd=${payload.transactionEnd}`;
    }

    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getStateInventory", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
        commit("setStateInventory", response.data.body);
      } else {
        commit(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
        console.log(error);
      }
    });
  },
  checkCannabisLimit: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "checkCannabisLimit", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.checkCannabisLimit}`;
    if (payload && payload.customerId) {
      url += `?customerId=${payload.customerId}`;
    }

    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "checkCannabisLimit", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        commit(
          "setMessage",
          { text: error.message, type: error.errorCode == 901 ? "state-error" : "error" },
          { root: true }
        );
        console.log(error);
      }
    });
  },
  setStateInventoryPopUp: ({ commit, state }, payload) => {
    commit("setStateInventoryPopUp", payload);
  },
  setRefetchTableData: ({ commit, state }, payload) => {
    commit("setRefetchTableData", payload);
  },
  checkCredentials: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "checkCredentials", status: true });
    HttpService.post(`${Apis.backendBaseUrl}${Apis.checkCredentials}`, { credentials: payload.credentials }, function (response, error) {
      commit("setLoadingStatus", { api: "checkCredentials", status: false });
      if (response) {
        commit(
          "setMessage",
          { text: "Credentials correct!", type: "success" },
          { root: true }
        );
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        commit(
          "setMessage",
          { text: error.message, type: error.errorCode == 901 ? "state-error" : "error" },
          { root: true }
        );
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
