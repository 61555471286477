<template>
  <div>
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <state-inventory-list
      :showOptions="true"
      @negative-answer="handleNegativeAnswer"
      @positive-answer="handlePositiveAnswer"
      class="state-inventory-pop-up background-color"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StateInventoryList from "@/views/reports/StateInventory/components/StateInventoryList.vue";

export default {
  name: "StateInventoryPopUp",
  data() {
    return {};
  },
  components: {
    StateInventoryList,
  },
  computed: {
    ...mapGetters({
      stateInventoryPopUp: "stateModule/stateInventoryPopUp",
    }),
  },
  methods: {
    ...mapActions({
      setStateInventoryPopUp: "stateModule/setStateInventoryPopUp",
    }),
    handleNegativeAnswer: function () {
      if (this.stateInventoryPopUp.negativeCallback) {
        this.stateInventoryPopUp.negativeCallback();
      }
      this.setStateInventoryPopUp({ isOpen: false });
    },
    handlePositiveAnswer: function () {
      if (this.stateInventoryPopUp.positiveCallback) {
        this.stateInventoryPopUp.positiveCallback();
      }
      this.setStateInventoryPopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss">
.state-inventory-pop-up {
  border-radius: $border-radius-0;
  position: fixed;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: $dark-background-color;
  overflow-y: auto;
}
</style>
