// Purchase Order Editor
import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as customerColumnHeaders,
  filterCriteria as customerFilterCriteria,
} from "../Customer/inputs";

// Purchase Order Information - Main Section
export const PosMainInput = [
  {
    id: "customerId",
    type: "input",
    title: "Customer #",
    placeholder: "Customer #",
    focus: true,
  },
  {
    id: "productBarcode",
    type: "input",
    title: "Barcode #",
    placeholder: "Barcode #",
  },
];
