<template>
  <div class="queue-list">
    <EntityFilter
      :selectedFilter="selectedFilter"
      :filterCriteria="filterCriteria"
      :preFilters="selectedPreFilters"
      @criteriaChange="performSetSelectedFilter"
      @valueChange="loadData"
    />
    <div class="table-container margined bordered">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Email</th>
            <th class="cell">Name</th>
            <th class="cell">Last name</th>
            <th class="cell">Created by</th>
            <th class="cell">Updated by</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loadingStatus.getQueue && queue.length === 0" class="row">
            <td colspan="100" class="center padded">
              <i class="fas fa-spin fa-circle-notch"></i> Loading...
            </td>
          </tr>
          <tr class="row" v-for="g in queue" :key="g._id">
            <td class="cell">
              <inline-button
                v-if="!allowSelection"
                label="to do open customer file"
                type="plain-primary responsive-column form-height wide"
              />
              <inline-button
                v-if="allowSelection"
                label="Select"
                type="plain-primary responsive-column form-height wide"
                @click="() => emitSelectedElement(g)"
              />
            </td>
            <td class="cell">
              {{ g.customer ? g.customer.email : g.recreationalCustomer.email }}
            </td>
            <td class="cell">
              {{ g.customer ? g.customer.name : g.recreationalCustomer.name }}
            </td>

            <td class="cell">
              {{
                g.customer
                  ? g.customer.lastName
                  : g.recreationalCustomer.lastName
              }}
            </td>
            <td class="cell">
              {{
                g.createdBy
                  ? g.createdBy.name +
                    " " +
                    (g.createdBy.lastName ? g.createdBy.lastName : "")
                  : "-"
              }}
            </td>
            <td class="cell">
              {{
                g.updatedBy
                  ? g.updatedBy.name +
                    " " +
                    (g.updatedBy.lastName ? g.updatedBy.lastName : "")
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EntityFilter from "@/components/EntityFilter/EntityFilter";

export default {
  name: "QueueList",
  data() {
    return {};
  },
  props: {
    allowSelection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EntityFilter,
  },
  mounted: function () {
    this.getQueue();
  },
  computed: {
    ...mapGetters({
      loadingStatus: "queueModule/loadingStatus",
      queue: "queueModule/queue",
      filterCriteria: "queueModule/filterCriteria",
      selectedFilter: "queueModule/selectedFilter",
      selectedPreFilters: "queueModule/selectedPreFilters",
    }),
  },
  methods: {
    ...mapActions({
      getQueue: "queueModule/getQueue",
      setSelectedFilter: "queueModule/setSelectedFilter",
    }),
    loadData: function (filter) {
      this.setSelectedFilter(filter);
      this.getCustomer();
    },
    performSetSelectedFilter({ filter, filter2 }) {
      this.setSelectedFilter(filter);
      this.getCustomer();
    },
    emitSelectedElement(selectedElement) {
      this.$emit("elementSelected", selectedElement);
    },
  },
};
</script>

<style lang="scss"></style>
