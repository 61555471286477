<template>
  <div class="groups responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'groups', label: 'Groups' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity"  :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import moment from "moment";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";

import { listPageHeader } from "./inputs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "groups",
  data() {
    return {
      moment: moment,
      Entities,
      entity: Entities.GROUP,
      listPageHeader,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("Groups");
  },
  computed: {
    ...mapGetters({
      sort: "groupModule/sort",
      pagination: "groupModule/pagination",
      initialFilter: "groupModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "groupModule/updateSort",
      updatePagination: "groupModule/updatePagination",
      updateInitialFilter: "groupModule/updateInitialFilter"
    }),
  }
};
</script>
