<template>
  <div class="item-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :canEdit="selectedItem.isReceived"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'items', label: 'Items' },
          {
            name: 'itemEditor',
            label: `${selectedItem.batchId} - ${
              selectedItem.product ? selectedItem.product.name : ''
            }`,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        :hideAddButton="true"
        :selectedEntity="selectedItem"
        @updateIsEdit="isEdit = $event"
        @toggle-sections="forceToggle"
        @toggle-edit="
          () => {
            if (!(selectedItem._id === 'NEW')) isEdit = !isEdit;
          }
        "
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick"
      >
        <BaseBtn
          iconGroup="fas"
          iconName="people-carry"
          label="Move"
          @click="performItemMovement"
        />
      </PageHeader>
      <div class="section-base">
        <div class="padded elevated-0 bordered item-editor__main-section">
          <div
            class="grid-two-column two-columns-layout--responsive-horizontal-space-10"
          >
            <div class="item-editor__barcode center">
              <span class="input-text__label">Batch ID</span>
              {{ selectedItem.batchId || "-" }}
            </div>
            <div class="item-editor__barcode center">
              <span class="input-text__label">Barcode</span>
              {{ selectedItem._id }}
            </div>
          </div>
          <div class="padded elevated-0 bordered item-editor__quantities">
            <div>
              <span class="input-text__label">WI</span>
              <div class="center">
                {{ selectedItem.quantityWorkingInventory }}
              </div>
            </div>
            <div>
              <span class="input-text__label">Preordered</span>
              <div class="center">
                {{ selectedItem.quantityPreorder }}
              </div>
            </div>
            <div>
              <span class="input-text__label">On Hand</span>
              <div class="center">
                {{ selectedItem.quantityOnHand }}
              </div>
            </div>
            <div>
              <span class="input-text__label">Available</span>
              <div class="center">
                {{ selectedItem.quantityAvailable }}
              </div>
            </div>
            <div>
              <span class="input-text__label">Purchased</span>
              <div class="center">
                {{ selectedItem.quantityPurchased }}
              </div>
            </div>
            <div>
              <span class="input-text__label">Sold</span>
              <div class="center">
                {{ selectedItem.quantitySold }}
              </div>
            </div>
            <div>
              <span class="input-text__label">Scheduled for Disposal</span>
              <div class="center">
                {{ selectedItem.quantityScheduledForDisposal }}
              </div>
            </div>
          </div>
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="inputs in itemInputsComputed" :key="inputs.id">
              <div
                class="grid-two-column two-columns-layout--responsive-horizontal-space-10"
              >
                <div
                  v-for="input in inputs.inputs"
                  :key="input.id"
                  :class="'input-text' + ' ' + input.class"
                >
                  <Input
                    :model="lodash.get(selectedItem, input.id)"
                    @updateModel="lodash.set(selectedItem, input.id, $event)"
                    :inputData="input"
                    :isEdit="isEdit"
                    :selectedItem="selectedItem"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditorSection title="Orders" :bus="bus" :defaultIsOpen="true">
        <div class="orders">
          <div class="orders__selector">
            <inline-button
              :type="ordersMode == 'open' ? 'primary' : 'plain-primary'"
              @click="() => filterTableOrders('open')"
              >Open Orders</inline-button
            >
            <inline-button
              :type="ordersMode == 'all' ? 'primary' : 'plain-primary'"
              @click="() => filterTableOrders('all')"
              >All</inline-button
            >
          </div>
          <table
            v-if="filteredOrders.length > 0"
            cellspacing="0"
            class="table-element"
          >
            <thead>
              <tr class="header">
                <th class="cell"></th>
                <th class="cell">Posted</th>
                <th class="cell">Preorder</th>
                <th class="cell">Archived</th>
                <th class="cell">Reversed</th>
                <th class="cell">Source</th>
                <th class="cell">Code</th>
                <th class="cell">Created</th>
                <th class="cell">Posted On</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in filteredOrders" :key="order._id" class="row">
                <td class="cell">
                  <inline-button
                    label="Open"
                    type="plain-primary responsive-column form-height wide"
                    @click="() => openOrder(order)"
                  />
                </td>

                <td class="cell">
                  <check-mark :toggleVariable="order.isPaid" />
                </td>
                <td class="cell">
                  <check-mark :toggleVariable="order.isPreorder" />
                </td>
                <td class="cell">
                  <check-mark :toggleVariable="order.isArchived" />
                </td>
                <td class="cell">
                  <check-mark :toggleVariable="order.isVoided" />
                </td>

                <td class="cell">
                  <span>{{ order.source }}</span>
                </td>
                <td class="cell">
                  <span>{{ order.code }}</span>
                </td>
                <td class="cell">
                  <span>{{
                    moment(order.createdAt).format(
                      Constants.dates.defaultDateFormat
                    )
                  }}</span>
                </td>
                <td v-if="order.isPostedToJournal" class="cell">
                  <span>{{
                    moment(order.postedToJournalAt).format(
                      Constants.dates.defaultDateFormat
                    )
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="orders__empty-state" v-else>No Orders</div>
        </div>
      </EditorSection>

      <!-- Rooms Begin  -->
      <div id="rooms"></div>
      <EditorSection title="Rooms" sectionType="rooms" :bus="bus">
        <div v-for="room in getItemRoomsWithWI" :key="room._id" class="rooms">
          <div>{{ room.label }}</div>
          <div>{{ room.quantity }}</div>
        </div>
      </EditorSection>
      <!-- Rooms End  -->

      <EditorSection
        title="Chemical Composition"
        sectionType="chemicalComposition"
        :bus="bus"
        v-if="isCannabis"
      >
        <ChemicalCompositionForm
          :disabled="!isEdit"
          :chemicalComposition="selectedItem.chemicalComposition"
        />
      </EditorSection>

      <!-- Adjustments Begin  -->
      <div id="adjustment"></div>
      <EditorSection title="Adjustments" sectionType="adjustments" :bus="bus">
        <Adjustments
          :isEdit="isEdit"
          @toggle-edit="() => (isEdit = !isEdit)"
          :rooms="getItemRooms"
        />
      </EditorSection>
      <!-- Adjustments End  -->

      <!-- Disposal Begin  -->
      <div id="disposal"></div>
      <EditorSection
        title="Disposal"
        sectionType="disposal"
        :bus="bus"
        v-if="isCannabis"
      >
        <Disposals
          :isEdit="isEdit"
          @toggle-edit="() => (isEdit = !isEdit)"
          :rooms="getItemRooms"
        />
      </EditorSection>
      <!-- Disposal End  -->

      <!-- Return Begin  -->
      <div id="return"></div>
      <EditorSection title="Return" sectionType="return" :bus="bus">
        <Returns
          :isEdit="isEdit"
          @toggle-edit="() => (isEdit = !isEdit)"
          :rooms="getItemRooms"
        />
      </EditorSection>

      <div id="batch-management"></div>
      <EditorSection
        v-if="isMetrc"
        title="Batch Management"
        sectionType="batch-management"
        :bus="bus"
      >
        <div class="section-base">
          <div class="padded elevated-0 bordered">
            <div class="split-info-container" v-if="canMergeToOriginal">
              <BaseBtn
                @click="performMergeToOriginal()"
                label="Merge to Original"
                size="sm"
              />
            </div>
            <div class="split-info-container" v-if="canSplit">
              <BaseInput
                label="Quantity"
                placeholder="Quantity"
                v-model="splitInformation.quantity"
                controlType="number"
              />

              <Input
                :inputData="getInput('product')"
                :isEdit="true"
                :model="splitInformation.product"
              />

              <BaseBtn @click="performSplit()" label="Split Batch" size="sm" />
            </div>
            <table v-if="canSplit" cellspacing="0" class="table-element">
              <thead>
                <tr class="header">
                  <th class="cell">Item</th>
                  <th class="cell">Product</th>
                  <th class="cell">Quantity Available</th>
                  <th class="cell">Unit Cost</th>
                  <th class="cell">Batch ID</th>
                  <th class="cell">Barcode</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getSplitItems" :key="item._id" class="row">
                  <td class="cell">
                    <router-link
                      class="internal-link"
                      target="_blank"
                      :to="{
                        name: 'itemEditor',
                        query: { id: item._id },
                      }"
                    >
                      {{ item._id }}
                    </router-link>
                  </td>
                  <td class="cell">
                    <span>{{ item.externalFields.product.name }}</span>
                  </td>
                  <td class="cell">
                    <span>{{ item.quantityAvailable }}</span>
                  </td>

                  <td class="cell">
                    {{ item ? Utilities.formatPrice(item.unitCost) : "-" }}
                  </td>
                  <td class="cell">{{ item.batchId }}</td>
                  <td class="cell">{{ item.barcode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </EditorSection>

      <EditorSection
        :title="`About ${selectedItem.name || ''}`"
        sectionType="about"
        v-if="selectedItem._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedItem" />
      </EditorSection>
    </div>
    <Maintenance
      v-if="showMaintenance"
      @execute="executeMaintenance"
      @close="closeMaintenance"
      maintenanceType="changeProductCode"
    />
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";
import router from "@/router";

import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import { entityModule, setEntity } from "@/utils/entity-mappers";
import { prefillEntityBeforeRendering } from "@/utils/prefill-entity-before-rendering/item";
import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/item";

import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import Input from "@/components/Input/Input.vue";
import Entities from "@/resources/Entities";
import ChemicalCompositionForm from "@/components/ChemicalCompositionForm/ChemicalCompositionForm.vue";
import Disposals from "./components/Disposals/Disposals.vue";
import Adjustments from "./components/Adjustments/Adjustments.vue";
import Returns from "./components/Returns/Returns.vue";
import Maintenance from "@/components/Maintenance/Maintenance.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";

import {
  itemInputs,
  adjustmentsInputs,
  returnsInputs,
  editorModalColumnHeaders as columnHeaders,
  filterCriteria,
  pageHeader,
  splitInputs,
} from "./inputs";
export default {
  name: "itemEditor",
  data() {
    return {
      Constants: Constants,
      Utilities: Utilities,
      moment: moment,
      itemInputs,
      adjustmentsInputs,
      returnsInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.ITEM,
      Entities,
      pageHeader,
      lodash: _,
      showMaintenance: false,
      splitInformation: {
        quantity: 0,
        product: {},
      },
      rooms: [],
      orders: [],
      ordersMode: "open",
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    Input,
    ChemicalCompositionForm,
    Disposals,
    Returns,
    Adjustments,
    Maintenance,
    BaseInput,
    BaseBtn,
  },
  mounted: function () {
    this.setPageTitle("Items");
    if (this.$route.query.id) {
      this.getItemById({
        _id: this.$route.query.id,
        positiveCallback: (item) => {
          this.setPageTitle(`Item ${item.batchId} - ${item.product?.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
    this.getSuppliers();
    this.getRooms({
      filter: {},
      sort: { name: "asc" },
      positiveCallback: (rooms) => {
        this.rooms = rooms;
      },
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getItemById({
          _id: this.id,
          positiveCallback: (item) => {
            console.log(item);
          },
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
    selectedItem(item) {
      if (item.barcode) {
        this.filterOrders({
          filter: { "products.items.barcode": item.barcode },
          positiveCallback: (orders) => {
            this.orders = orders;
            console.log(this.orders);
          },
        });
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "itemModule/loadingStatus",
      item: "itemModule/item",
      suppliers: "supplierModule/suppliers",
      theme: "theme",
      currentStore: "storeModule/currentStore",
    }),
    isCannabis() {
      return (
        this.selectedItem.externalFields &&
        this.selectedItem.externalFields.group &&
        this.selectedItem.externalFields.group.isCannabis
      );
    },
    isMetrc() {
      try {
        return this.currentStore.traceabilitySystem == "metrc";
      } catch (error) {
        return false;
      }
    },
    selectedItem: {
      get() {
        return prefillEntityBeforeRendering(this.item);
      },
      set(entity) {
        return entity;
      },
    },
    alternateModels() {
      return {
        room: this.selectedItem.room?.name,
        name: this.selectedItem.product?.name,
        description: this.selectedItem.product?.description,
        manifestId: this.selectedItem.purchaseOrder?.manifestId,
      };
    },
    itemInputsComputed() {
      return this.itemInputs.map((item) => {
        return {
          ...item,
          inputs: item.inputs.map((input) => {
            var newInput = input;
            if (input.type === "link") {
              newInput = {
                ...input,
                to: {
                  ...input.to,
                  queryItem: this.getQuery(input),
                },
              };
            }
            return newInput;
          }),
        };
      });
    },
    canSplit() {
      try {
        return (
          this.selectedItem.externalFields.group.unitOfMeasure == "g" &&
          !this.selectedItem.isArchived
        );
      } catch (error) {
        return false;
      }
    },
    canMergeToOriginal() {
      try {
        return (
          this.selectedItem.originalItem != undefined &&
          !this.selectedItem.isArchived
        );
      } catch (error) {
        return false;
      }
    },
    getSplitItems() {
      return this.selectedItem.splitItems.filter((si) => !si.isArchived);
    },
    getItemRoomsWithWI() {
      const rooms = this.matchRooms();
      rooms.unshift({
        label: "Working Inventory",
        code: "working-inventory",
        quantity: this.selectedItem.quantityWorkingInventory,
      });
      return rooms;
    },
    getItemRooms() {
      const itemRooms = this.matchRooms({ showQuantity: true });
      const defaultRoom = this.rooms.find((r) => r.isDefault);
      if (defaultRoom) {
        // Add it only if there is no default room in item
        const defaultInItemRooms = itemRooms.find(
          (r) => r.code == defaultRoom._id
        );
        if (!defaultInItemRooms) {
          itemRooms.unshift({
            label: `${defaultRoom.name} (0)`,
            code: defaultRoom._id,
          });
        }
      }
      return itemRooms;
    },
    filteredOrders() {
      if (this.ordersMode == "open") {
        return this.orders.filter(
          (o) =>
            !o.isArchived &&
            !o.isPaid &&
            !o.isPostedToJournal &&
            !o.isVoided &&
            o.isPreorder
        );
      } else {
        return this.orders.filter((o) => {
          if ((o.source == "online-store" || o.source == "kiosk") && !o.isPreorder) return false;
          return true;
        });
      }
    },
  },
  methods: {
    ...mapActions({
      getItemById: "itemModule/getItemById",
      saveItem: "itemModule/saveItem",
      deleteItem: "itemModule/deleteItem",
      setMessagePopUp: "setMessagePopUp",
      getSuppliers: "supplierModule/getSuppliers",
      getAdjacent: "itemModule/getAdjacent",
      performMaintenance: "productModule/performMaintenance",
      split: "itemModule/split",
      mergeToOriginal: "itemModule/mergeToOriginal",
      setItemMovementPopUp: "itemModule/setItemMovementPopUp",
      getRooms: "productModule/getRooms",
      filterOrders: "orderModule/filter",
    }),
    matchRooms(options) {
      const rooms = [];
      try {
        for (let itemRoom of this.selectedItem.rooms) {
          const potentialRoomMatch = this.rooms.find(
            (r) => r._id == itemRoom.room
          );
          const room = { label: "-", code: "", quantity: itemRoom.quantity };
          if (potentialRoomMatch) {
            if (options?.showQuantity) {
              room.label = `${potentialRoomMatch.name} (${itemRoom.quantity})`;
            } else {
              room.label = potentialRoomMatch.name;
            }
            room.code = potentialRoomMatch._id;
          }
          rooms.push(room);
        }
      } catch (error) {}
      return rooms;
    },
    performItemMovement() {
      this.setItemMovementPopUp({
        isOpen: true,
        item: this.selectedItem,
        positiveCallback: () => {
          this.getItemById({
            _id: this.selectedItem.id,
          });
        },
      });
    },
    getInput(field) {
      const input = splitInputs.find((i) => i.id == field);
      return input;
    },
    isNotEmpty(obj) {
      return obj && JSON.stringify(obj) != JSON.stringify({});
    },
    performSave() {
      if (this.loadingStatus.saveItem) {
        return;
      }

      const itemToSave = normalizeEntityBeforeSaving(this.selectedItem);

      this.saveItem({
        item: itemToSave,
        positiveCallback: (item) => {
          const id = item.id ? item.id : item._id;
          this.$store.commit(
            `${entityModule[this.entity]}/${setEntity[this.entity]}`,
            item
          );
          const module =
            this.entity !== Entities.PRODUCT
              ? `${this.entity}s`
              : `${this.entity}`;
          if (this.$route.query.id !== id) {
            this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
          }
          this.$store.dispatch(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          this.isEdit = false;
        },
        negativeCallback: () => {
          this.selectedItem.postAdjustment = false;
        },
      });
    },
    performDelete() {
      if (this.loadingStatus.deleteItem) {
        return;
      }

      const itemToDelete = normalizeEntityBeforeSaving(this.selectedItem);

      this.deleteItem({
        item: itemToDelete,
        positiveCallback: () => {
          router.push({
            name: "items",
          });
        },
      });
    },
    setSelectedReturn(returnItem) {
      this.$set(this.selectedItem, "selectedAdjustment", {});
      this.$set(this.selectedItem, "selectedDisposal", {});
      if (!returnItem) {
        returnItem = {};
        this.$set(returnItem, "_id", "NEW");
      } else {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#return").offset().top,
          },
          300
        );
      }
      this.$set(this.selectedItem, "selectedReturn", returnItem);
    },
    deleteReturn() {
      if (this.selectedItem.selectedReturn._id === "NEW") {
        this.removeSelectedReturn();
      } else {
        this.setMessagePopUp({
          isOpen: true,
          title: Constants.strings.warningMessageTitle,
          message: this.selectedItem.selectedReturn.isArchived
            ? Constants.strings.unArchiveConfirmation
            : Constants.strings.archiveConfirmation,
          positiveCallback: () => {
            if (this.selectedItem.selectedReturn.isArchived) {
              this.selectedItem.unarchiveReturn = true;
              this.selectedItem.archiveReturn = false;
            } else {
              this.selectedItem.archiveReturn = true;
              this.selectedItem.unarchiveReturn = false;
            }
            this.performSave();
          },
        });
      }
    },
    removeSelectedReturn() {
      this.selectedItem.selectedReturn = {};
    },
    performPostReturn() {
      this.selectedItem.postReturn = true;
      this.performSave();
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    getQuery(input) {
      return input.id === "name"
        ? this.selectedItem.product?._id
        : this.selectedItem.purchaseOrder?._id;
    },
    //clearSerach(entity) {
    clearSearch(entity) {
      this.selectedItem[entity] = null;
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (entity == "product") {
        this.splitInformation.product = item;
        return;
      }
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedItem[entity] = item;
    },
    executeMaintenance(type, payload) {
      this.performMaintenance({
        type,
        payload,
        positiveCallback: () => {
          this.showMaintenance = false;
        },
      });
    },
    closeMaintenance() {
      this.showMaintenance = false;
    },
    menuOptionClick(entity, key) {
      if (key === "changeProductCode") {
        this.showMaintenance = true;
      }
      if (key === "reports-inventory-system-check-item") {
        window.open(
          `/reports/inventory/inventorySystemCheckItem?itemId=${this.item._id}`
        );
      }
      if (key === "reports-inventory-working-count") {
        window.open("/reports/inventory/inventoryWorkingCount");
      }
    },
    performSplit() {
      this.split({
        item: this.selectedItem,
        ...this.splitInformation,
        positiveCallback: () => {
          this.splitInformation = { quntity: 0, product: {} };
        },
      });
    },
    performMergeToOriginal() {
      this.mergeToOriginal({
        item: this.selectedItem,
      });
    },
    openOrder(order) {
      window.open(`/orders/orderEditor?id=${order._id}`, "_blank").focus();
    },
    filterTableOrders(mode) {
      this.ordersMode = mode;
    },
  },
};
</script>
<style lang="scss" scoped>
.item-editor {
  &__barcode {
    margin: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__barcode-img {
    max-width: 100%;
  }

  &__main-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__quantities {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.item-section-button-wrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.split-info-container {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.rooms {
  display: flex;
  gap: 1rem;
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__selector {
    display: flex;
    gap: 0.5rem;
  }

  &__empty-state {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}
</style>
