<template>
  <div class="inventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'products', label: 'Products' },
      ]" :entity="entity" />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort" :pagination="pagination"
        :filterButtons="filterButtons" @filterButtonsChange="updateFilterButtons" :enableAdditionalFilter="true"
        @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter" :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";

import { listPageHeader } from "./inputs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "products",
  data() {
    return {
      listPageHeader,
      entity: Entities.PRODUCT,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  created: function () {
    this.setPageTitle("Products");
  },
  computed: {
    ...mapGetters({
      sort: "productModule/sort",
      pagination: "productModule/pagination",
      initialFilter: "productModule/initialFilter",
      filterButtons: "productModule/filterButtons",
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "productModule/updateSort",
      updatePagination: "productModule/updatePagination",
      updateInitialFilter: "productModule/updateInitialFilter",
      updateFilterButtons: "productModule/updateFilterButtons",
    }),
  }
};
</script>
