<template>
  <div class="playGround">
    <br />
    <Slider v-model="sliderValue" :min="1" :max="300" :range="true" />
    <br />
    <InputMask mask="99-999999" />

    <InputText />

    <Calendar v-model="calendarValue" :showIcon="true" /> <br />

    <ColorPicker v-model="color" />
    <InputNumber v-model="numericValue" />

    <Dropdown
      v-model="selectedCity"
      :options="cities"
      optionLabel="name"
      placeholder="Select a City"
    />
    <InputNumber
      v-model="numericValue"
      mode="currency"
      currency="USD"
      locale="en-US"
    />
    <MultiSelect
      v-model="selectedCars2"
      :options="cars2"
      optionLabel="brand"
      placeholder="Select Brands"
    />
    <br />
    <Chips v-model="chipsValue" separator="," />
    <br />
    <Textarea v-model="textValue" rows="5" cols="30" />
    <br />

    <DataTable :value="cars" :filters="filters" :paginator="true" :rows="10">
      <template #header>
        <div style="text-align: right">
          <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
          <InputText
            v-model="filters['global']"
            placeholder="Global Search"
            size="50"
          />
        </div>
      </template>
      <Column
        field="vin"
        header="Vin"
        filterMatchMode="startsWith"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="text"
            v-model="filters['vin']"
            class="p-column-filter"
            placeholder="Starts with"
          />
        </template>
      </Column>
      <Column field="year" header="Year" filterMatchMode="contains">
        <template #filter>
          <InputText
            type="text"
            v-model="filters['year']"
            class="p-column-filter"
            placeholder="Contains"
          />
        </template>
      </Column>
      <Column field="brand" header="Brand" filterMatchMode="equals">
        <template #filter>
          <Dropdown
            v-model="filters['brand']"
            :options="brands"
            optionLabel="brand"
            optionValue="value"
            placeholder="Select a Brand"
            class="p-column-filter"
            :showClear="true"
          >
            <template #option="slotProps">
              <div class="p-clearfix p-dropdown-car-option">
                <img
                  :alt="slotProps.option.brand"
                  :src="'demo/images/car/' + slotProps.option.brand + '.png'"
                />
                <span>{{ slotProps.option.brand }}</span>
              </div>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="color" header="Color" filterMatchMode="in">
        <template #filter>
          <MultiSelect
            v-model="filters['color']"
            :options="colors"
            optionLabel="name"
            optionValue="value"
            placeholder="Select a Color"
          />
        </template>
      </Column>
      <template #empty>No records found.</template>
    </DataTable>

    <FileUpload name="demo[]" url="./upload">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>
</template>

<script>
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Slider from "primevue/slider";
import Calendar from "primevue/calendar";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import FileUpload from "primevue/fileupload";

export default {
  name: "PlayGround",
  data() {
    return {
      textValue: "",
      sliderValue: [20, 80],
      calendarValue: "",
      chipsValue: undefined,
      color: undefined,
      selectedCity: null,
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      numericValue: 0,
      selectedCars2: null,
      cars2: [
        { brand: "Audi", value: "Audi" },
        { brand: "BMW", value: "BMW" },
        { brand: "Fiat", value: "Fiat" },
        { brand: "Honda", value: "Honda" },
        { brand: "Jaguar", value: "Jaguar" },
        { brand: "Mercedes", value: "Mercedes" },
        { brand: "Renault", value: "Renault" },
        { brand: "Volkswagen", value: "Volkswagen" },
        { brand: "Volvo", value: "Volvo" },
      ],
      filters: {},
      brands: [
        { brand: "Audi", value: "Audi" },
        { brand: "BMW", value: "BMW" },
        { brand: "Fiat", value: "Fiat" },
        { brand: "Honda", value: "Honda" },
        { brand: "Jaguar", value: "Jaguar" },
        { brand: "Mercedes", value: "Mercedes" },
        { brand: "Renault", value: "Renault" },
        { brand: "Volkswagen", value: "Volkswagen" },
        { brand: "Volvo", value: "Volvo" },
      ],
      colors: [
        { name: "White", value: "White" },
        { name: "Green", value: "Green" },
        { name: "Silver", value: "Silver" },
        { name: "Black", value: "Black" },
        { name: "Red", value: "Red" },
        { name: "Maroon", value: "Maroon" },
        { name: "Brown", value: "Brown" },
        { name: "Orange", value: "Orange" },
        { name: "Blue", value: "Blue" },
      ],
      cars: [
        { brand: "Volkswagen", year: 2012, color: "Orange", vin: "dsad231ff" },
        { brand: "Audi", year: 2011, color: "Black", vin: "gwregre345" },
        { brand: "Renault", year: 2005, color: "Gray", vin: "h354htr" },
        { brand: "BMW", year: 2003, color: "Blue", vin: "j6w54qgh" },
        { brand: "Mercedes", year: 1995, color: "Orange", vin: "hrtwy34" },
        { brand: "Volvo", year: 2005, color: "Black", vin: "jejtyj" },
        { brand: "Honda", year: 2012, color: "Yellow", vin: "g43gr" },
        { brand: "Jaguar", year: 2013, color: "Orange", vin: "greg34" },
        { brand: "Ford", year: 2000, color: "Black", vin: "h54hw5" },
        { brand: "Fiat", year: 2013, color: "Red", vin: "245t2s" },
      ],
    };
  },
  components: {
    InputMask,
    InputText,
    Slider,
    Calendar,
    Chips,
    ColorPicker,
    Dropdown,
    InputNumber,
    MultiSelect,
    Textarea,
    DataTable,
    Column,
    FileUpload,
  },
  carService: null,
  created() {
    // this.carService = new CarService();
  },
  mounted() {
    // this.carService.getCarsLarge().then(data => (this.cars = data));
  },
  methods: {
    onUpload() {},
  },
};
</script>
