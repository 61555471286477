<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="Non Cannabis Inventory"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'inventoryReport', label: 'Inventory' },
          {
            name: 'inventoryCountNc',
            label: 'Non Cannabis Inventory',
          },
        ]"
        @export="exportToCsv"
        :disableExport="!reportData"
      />
      <br class="no-print" />
      <ReportFilter
        class="no-print"
        @submit-filter="performGetReport"
        :isLoading="inventoryTransactions"
      />
    <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th align="left">Group</th>
                <th align="left">Supplier</th>
                <th align="left">Product</th>
                <th align="left">Item Id</th>
                <th align="right">WI Qty</th>
                <th align="right">PreOrder Qty</th>
                <th align="right">Vault Qty</th>
                <th align="right">DF Qty</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.records" :key="index">
                <td>{{ record.group }}</td>
                <td>{{ record.supplier }}</td>
                <td>{{ record.name }}</td>
                <td>{{ record.itemId }}</td>
                <td align="right">{{ record.quantityWorkingInventory }}</td>
                <td align="right">{{ record.quantityPreorder }}</td>
                <td align="right">{{ record.quantityVault }}</td>
                <td align="right">{{ record.quantityOnHand }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="7">Total</td>
              <td align="right">
                {{ reportData.total.quantityOnHand }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%"><hr class="line1" /></td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Non Cannabis Inventory");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      inventoryTransactions: "reports/inventoryTransactions",
    }),
  },
  methods: {
    ...mapActions({
      getSalesTransactions: "reports/getInventoryTransactions",
    }),
    performGetReport(filter) {
      let reportType = "inventoryCountNc";
      this.getSalesTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "inventoryCountNc");
    },
  },
};
</script>

<style lang="scss">
@import "./_inventoryCountNc.scss";