<template>
    <div class="report-base">
      <router-view></router-view>
    </div>
  </template>

  <script>
  export default {
    name: "reportBase",
  };
  </script>