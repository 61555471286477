import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";

import {
  filterCriteria as itemFilterCriteria,
  searchColumnHeaders as itemColumnHeaders,
} from "../Item/inputs";

// Page Header
export const pageHeader = {
  title: "Flight",
  isEditorView: true,
  cancelPath: "flights",
  addPath: "flightEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Flights",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "flightEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    numCharsToSearch: 1,
    field: { type: "input", label: "name" },
    isPartial: true,
  },
  {
    label: "Sale Type",
    code: "saleType",
    field: { type: "input", label: "saleType" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Sale Type",
    id: "saleType",
    size: "medium",
  },
  {
    title: "Sale Price",
    id: "salePrice",
    size: "medium",
  },
  {
    title: "Is Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Publish to Online Store",
    id: "isPublishedToOnlineStore",
    type: "checkbox",
  },
  {
    title: "Apply Date Range",
    id: "applyDateRange",
    type: "checkbox",
  },
  {
    title: "Start Date",
    id: "startDate",
    type: "date",
  },
  {
    title: "End Date",
    id: "endDate",
    type: "date",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Sale Type",
    id: "saleType",
  },
  {
    title: "Sale Price",
    id: "salePrice",
  },
  {
    title: "Is Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Menu Upload",
    id: "menuUpload",
    type: "checkbox",
  },
  {
    title: "Apply Date Range",
    id: "applyDateRange",
    type: "checkbox",
  },
  {
    title: "Start Date",
    id: "startDate",
  },
  {
    title: "End Date",
    id: "endDate",
  },
];

export const flightInfoInputs = [
  {
    id: "name",
    placeholder: "Flight name",
    type: "input",
    title: "Flight Name",
    focus: true,
  },
  {
    id: "salePrice",
    type: "money",
    title: "Sale Price",
  },
  {
    id: "grams",
    type: "text",
    title: "Grams",
  },
  {
    id: "applyDateRange",
    type: "checkbox",
    title: "Apply Date Range",
  },
  {
    id: "startDate",
    placeholder: "Start Date",
    type: "calendar",
    title: "Start Date",
    showTime: true,
  },
  {
    id: "endDate",
    placeholder: "End Date",
    type: "calendar",
    title: "End Date",
    showTime: true,
  },
  {
    id: "isActive",
    type: "checkbox",
    title: "Is Active",
  },
  {
    id: "menuUpload",
    type: "checkbox",
    title: "Menu Upload",
  },
  {
    id: "imageNormal",
    placeholder: "Upload Image",
    type: "fileUploader",
    title: "Image File",
  },
  {
    id: "saleType",
    type: "select",
    title: "Sale Type",
    options: Constants.listOfSaleTypes,
    label: "label",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
  {
    id: "selectedItem",
    type: "search",
    title: "Item",
    iconName: "plus",
    buttonOnly: true,
    entity: Entities.ITEM,
    columnHeaders: itemColumnHeaders,
    filterCriteria: itemFilterCriteria,
  },
];

export const defaultSelectedFlight = {
  _id: "NEW",
  name: "",
  salePrice: 0.0,
  grams: 0,
  applyDateRange: false,
  startDate: "",
  endDate: "",
  isActive: false,
  isPublishedToOnlineStore: true,
  saleType: "recreational",
  notes: "",
  imageThumbnail: {},
  imageNormal: {},
  items: [],
  menuUpload: false,
};
