<template>
  <div class="loyalty-summary">
    <div v-if="loyaltyInfo.cardNumber && showCardNumber">
      <div class="input-text__label">Member</div>
      {{ loyaltyInfo.cardNumber.substr(loyaltyInfo.cardNumber.length - 4) }}
    </div>

    <div>
      <div class="input-text__label">Total Points</div>
      {{ getPointTotal }}
    </div>

    <div v-if="getPointEarned">
      <div class="input-text__label">Points Earned</div>
      {{ getPointEarned }}
    </div>

    <div v-if="getCashbackTotal">
      <div class="input-text__label">Cashback</div>
      ${{ getCashbackTotal }}
    </div>

    <div v-if="getIssuedCoupons">
      <div class="input-text__label">Coupon Issued</div>
      {{ getIssuedCoupons }}
    </div>

    <div v-if="showRedeemRewards">
      <BaseBtn @click="redeemRewardsClicked" :label="'Redeem Rewards!'" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "LoyaltySummary",
  data() {
    return {
      loyaltyCustomer: {},
    };
  },
  props: {
    loyaltyTransaction: {
      type: Object,
      default: () => ({}),
    },
    loyaltyInfo: {
      type: Object,
      default: () => ({}),
    },
    showCardNumber: {
      type: Boolean,
      default: true,
    },
    refreshLoyalty: {
      type: Number,
      default: undefined,
    },
    phone: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    showRedeemRewards: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseBtn,
  },
  watch: {
    loyaltyTransaction() {
      this.fetchLoyaltyCustomer();
    },
    "loyaltyInfo.cardNumber"() {
      this.fetchLoyaltyCustomer();
    },
    refreshLoyalty(newVal) {
      if (newVal > 1) {
        this.fetchLoyaltyCustomer();
      }
    },
  },
  created() {
    this.fetchLoyaltyCustomer();
  },
  computed: {
    getPointEarned() {
      return this.loyaltyTransaction?.pointsEarned;
    },
    getPointTotal() {
      return this.loyaltyCustomer?.points;
    },
    getCashbackTotal() {
      return this.loyaltyCustomer?.cashback;
    },
    getIssuedCoupons() {
      return this.loyaltyTransaction?.issuedCoupons;
    },
  },
  methods: {
    ...mapActions({
      lookup: "loyaltyModule/lookup",
    }),
    fetchLoyaltyCustomer() {
      this.lookup({
        searchFields: { cardNumber: this.loyaltyInfo.cardNumber, phone: this.phone, email: this.email },
        positiveCallback: (result) => {
          this.loyaltyCustomer = result[0];
        },
      });
    },
    redeemRewardsClicked() {
      this.$emit("redeem-rewards");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_loyaltySummary.scss";
</style>
