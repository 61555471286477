<template>
  <div class="receipt">
    <div class="header">
      <div class="header__store-name">{{ receipt.storeName }}</div>
      <div class="header__info">
        {{ receipt.storeAddress.line1 }}<br />{{ receipt.storeAddress.line2
        }}<br />{{ receipt.storePhone }}
      </div>
    </div>
    <div>
      <table width="100%">
        <tr>
          <td id="employee">
            Employee
            {{ receipt.employeeId ? receipt.employeeId.slice(-6) : "-" }}
          </td>
          <td class="right" id="date">{{ getDate }}</td>
        </tr>
        <tr>
          <td id="full-order-code">Code {{ receipt.orderCode }}</td>
          <td class="right" id="workstation">{{ receipt.workstationName }}</td>
        </tr>
      </table>
    </div>
    <div class="pick-up-code">
      <div class="pick-up-code__header">Pick Up Code</div>
      <div class="pick-up-code__body">
        {{ getPickUpCode(receipt.orderCode) }}
      </div>
    </div>
    <div class="products">
      <div v-for="group of receipt.productGroups" :key="group._id">
        <div class="bold">
          {{ group.name }}
        </div>
        <div v-for="product of group.products" :key="product._id">
          <table width="100%">
            <tr>
              <td style="width: 5%">
                {{ product.quantity }}
              </td>
              <td style="width: 45%">
                {{ product.name }}
              </td>
              <td style="width: 25%">
                {{ product.salePrice.toFixed(2) }}
              </td>
              <td style="width: 25%" class="right">
                {{ (product.salePrice * product.quantity).toFixed(2) }}
                {{ product.cannabisMark }}
              </td>
            </tr>
          </table>
          <div class="supplier-name">
            {{
              product.externalFields.supplier.shortName ||
              product.externalFields.supplier.name
            }}
          </div>
          <div class="batch" v-for="item of product.items" :key="item._id">
            {{ item.quantity }} X {{ item.batchId }}
          </div>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
    <div class="price-breakdown">
      <table width="100%">
        <tr v-if= "receipt.discount <= 0">
          <td width="75%" class="bold-right">Order Total:</td>
          <td id="discount" width="25%" class="bold-right">
            {{ receipt.subTotal.toFixed(2) }}
          </td>
        </tr>
        <tr v-if= "receipt.discount > 0">
          <td width="75%" class="right">Sub Total:</td>
          <td id="discount" width="25%" class="right">
            {{ receipt.subTotal.toFixed(2) }}
          </td>
        </tr>
        <tr v-if= "receipt.discount > 0">
          <td width="75%" class="right">Discount:</td>
          <td id="discount" width="25%" class="right">
            {{ receipt.discount.toFixed(2) }}
          </td>
        </tr>
        <tr v-if="receipt.tax" id="tax-tr">
          <td width="75%" class="right">Tax:</td>
          <td id="tax" width="25%" class="right">
            {{ receipt.tax.toFixed(2) }}
          </td>
        </tr>
        <tr v-if= "receipt.discount > 0 || receipt.tax > 0">
          <td width="75%" class="bold-right">Order Total:</td>
          <td id="total" width="25%" class="bold-right">
            {{ ((receipt.subTotal - receipt.discount) + receipt.tax).toFixed(2) }}
          </td>
        </tr>
        <tr>
          <td width="75%" class="right">Amount Paid:</td>
          <td id="amountPaid" width="25%" class="right">
            {{ receipt.amountPaid.toFixed(2) }}
          </td>
        </tr>
        <tr>
          <td width="75%" class="right">Amount Due:</td>
          <td id="amountDue" width="25%" class="right">
            {{ receipt.amountDue.toFixed(2) }}
          </td>
        </tr>
      </table>
    </div>
    <div>
      <tr>
        <td id="messageString">
          {{ receipt.messageString }}
        </td>
      </tr>
    </div>
    <div class="taxes-breakdown">
      <tr v-if= "receipt.discount > 0 && receipt.storeAllInPrice">
          <td width="75%" class="left">Pre-Tax Sub Total:</td>
          <td id="discount" width="25%" class="right">
            {{ ((receipt.subTotal - receipt.discount - receipt.orderTaxes.total) + receipt.discount).toFixed(2) }}
          </td>
        </tr>
        <tr v-if= "receipt.discount > 0 && receipt.storeAllInPrice">
          <td width="75%" class="left">Discount:</td>
          <td id="discount" width="25%" class="right">
            {{ receipt.discount.toFixed(2) }}
          </td>
        </tr>
        <tr v-if= "receipt.storeAllInPrice">
          <td width="75%" class="left">Pre-Tax Total:</td>
          <td id="total" width="25%" class="right">
            {{ (receipt.subTotal - receipt.discount - receipt.orderTaxes.total).toFixed(2) }}
          </td>
        </tr>
        <tr v-for="tax of receipt.orderTaxes.cannabisItemized" :key="tax._id">
          <td width="65%">{{ tax.taxName }} ({{ tax.taxRate }}%)</td>
            <td id="tax-c-total" width="35%" class="right">
              {{ tax.taxAmount }}
          </td>
        </tr>
        <tr v-if= "receipt.storeAllInPrice">
          <td width="75%" class="bold-left">Order Total:</td>
          <td id="total" width="25%" class="bold-right">
            {{ (receipt.subTotal - receipt.discount).toFixed(2) }}
          </td>
        </tr>
      </div>
    <div class="points">
      <div v-if="receipt.isClutchEnabled">
        <div v-if="receipt.loyaltyInfo && receipt.loyaltyInfo.isClutchOrder">
          <div>Points Earned: {{ receipt.loyaltyInfo.pointsEarned || 0 }}</div>
          <div>Points Total: {{ receipt.loyaltyInfo.pointTotal || 0 }}</div>
          <div>
            Cashback Total: ${{ receipt.loyaltyInfo.cashbackTotal || 0 }}
          </div>

          <div class="coupon" v-if="receipt.loyaltyInfo.issuedCoupons">
            <div>You earned a coupon!</div>
            <div>
              {{ receipt.loyaltyInfo.issuedCoupons }}
            </div>
          </div>
        </div>
        <div v-else>Create an account to start earning points and rewards!</div>
      </div>
      <div v-else>
        <div>
          Level 1 Points:
          {{ receipt.points.pointsLevel1 ? receipt.points.pointsLevel1 : 0 }}
        </div>
        <div v-if= "printLevel2PointsOnReceipt">
          Level 2 Points:
          {{ receipt.points.pointsLevel2 ? receipt.points.pointsLevel2 : 0 }}
        </div>
        <div v-if= "printLifetimePointsOnReceipt">
          Lifetime Points:
          {{ receipt.points.pointsLevel3 ? receipt.points.pointsLevel3 : 0 }}
        </div>
      </div>
    </div>
    <center>
      <div class="footer">{{ receipt.receiptFooter }}</div>
    </center>
    <div><br /></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function getFormattedDate(d) {
  return (
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + d.getDate()).slice(-2) +
    "/" +
    d.getFullYear().toString().substring(2) +
    " " +
    formatAMPM(d)
  );
}

export default {
  name: "receipt",
  data() {
    return {
      receipt: {},
    };
  },
  created: function () {
    this.getReceipt({
      _id: this.$route.query.orderId,
      positiveCallback: (receipt) => {
        this.receipt = receipt;
        setTimeout(window.print, 500);
      },
    });
  },
  components: {},
  computed: {
    getDate() {
      if (this.receipt.orderPostedToJournalAt) {
        return getFormattedDate(new Date(this.receipt.orderPostedToJournalAt));
      } else {
        return getFormattedDate(new Date(this.receipt.orderCreatedAt));
      }
    },
  },
  methods: {
    ...mapActions({ getReceipt: "orderModule/getReceipt" }),
    getPickUpCode(orderCode) {
      try {
        const l = orderCode.length;
        return l > 4 ? orderCode.substring(l - 4, l) : orderCode;
      } catch (error) {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  max-width: 400px;
  margin: auto;
}

@media print {
  @page {
    margin: 0;
  }

  .receipt {
    max-width: none;
    margin: auto;
  }
}

.receipt > div {
  margin-bottom: 10px;
  padding: 10px 0;
}

.header {
  text-align: center;
}

.header__store-name {
  font-size: 10pt;
}

.header__info {
  font-size: 8pt;
}

.pick-up-code {
  border-bottom: 1px dashed black;
  border-top: 1px dashed black;
  text-align: center;
}

.pick-up-code__body {
  font-size: 14pt;
  font-weight: 900;
}

td {
  font-size: 8pt;
}

.bold {
  font-weight: 900;
}

.right {
  text-align: right;
}

.bold-right {
  text-align: right;
  font-weight: 600;
}

.bold-left {
  text-align: left;
  font-weight: 600;
}

.batch {
  margin-left: 20px;
  font-size: 8pt;
}

.supplier-name {
  margin-left: 20px;
  font-size: 8pt;
}

.taxes-breakdown {
  border-bottom: 1px dotted black;
  border-top: 1px dotted black;
}

.spacer {
  margin-bottom: 10px;
}

.hidden {
  display: none;
}

.points {
  font-size: 8pt;
}

.footer {
  font-size: 8pt;
}

.coupon {
  padding: 20px;
  margin: 20px;
  border: 1px dotted black;
  border-radius: 5px;
  font-size: 14pt;
  text-align: center;
}
</style>
