export const normalizeEntityBeforeSaving = (supplier) => {
  const entity = JSON.parse(JSON.stringify(supplier));
  if (entity.expirationDate === "") {
    delete entity.expirationDate;
  }
  if (entity.verificationDate === "") {
    delete entity.verificationDate;
  }
  delete entity.verifiedBy;
  return entity;
};
