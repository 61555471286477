import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import {
  defaultSelectedProduct,
  defaultSalePrice,
} from "@/views/Product/inputs";
import { defaultSelectedRoom } from "@/views/Room/inputs";

const defaultSelectedSpecialPricing = {
  _id: "NEW",
  name: "",
  description: "",
  brandName: "",
  onlineStoreName: "",
  onlineStoreDescription: "",
  startDate: "",
  endDate: "",
  saleType: "recreational",
  maximumPrice: 0,
  minimumPrice: 0,
  isLimitedTime: false,
  isPublishedToOnlineStore: false,
  isActive: false,
  quantity: 1,
  maximumQuantity: 0,
  quantityLimit: 0,
  price: 0,
  pricePreTax: 0,
  group: undefined,
  supplier: undefined,
  isGiftCard: false,
  isMenuPrint: false,
  isUnitPriced: false,
  isFree: false,
  type: "DOLLAR",
  percentageAmount: 0,
};

const state = {
  products: [],
  product: { ...defaultSelectedProduct },
  rooms: [],
  room: { ...defaultSelectedRoom },
  specialPricings: [],
  specialPricing: { ...defaultSelectedSpecialPricing },
  audits: [],
  loadingStatus: {
    saveProduct: false,
    getProductById: false,
    getProducts: false,
    deleteProduct: false,
    saveRoom: false,
    getRoomById: false,
    getRooms: false,
    deleteRoom: false,
    saveSpecialPricing: false,
    getSpecialPricingById: false,
    getSpecialPricings: false,
    deleteSpecialPricing: false,
  },
  productPopUp: {
    isOpen: false,
  },
  filterButtons:[
    {
      isSelected: false,
      _id: 10,
      label: "All",
      name: "all",
      showAll: false,
      filter: {
      },
    },{
      isSelected: true,
      _id: 11,
      label: "Active",
      name: "active",
      showAll: false,
      filter: {
        isActive: true,
        isDeleted: false,
      },
    },
    {
      isSelected: false,
      _id: 12,
      label: "In Active",
      name: "in-active",
      showAll: false,
      filter: {
        isDeleted: false,
        isActive: false
      },
    },
    {
      isSelected: false,
      _id: 13,
      label: "Archived",
      name: "archived",
      showAll: false,
      filter: {
        isArchived: true,
        isDeleted: false,
      },
    }
  ],
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: {
    'name': 1,
    'externalFields.group.name': 1,
    'externalFields.supplier.name': 1,
    'cannabisGramsUnit': 1,
  },
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setProducts: (state, payload) => {
    state.products = payload;
  },
  setProduct: (state, payload) => {
    if (payload._id === "NEW") state.product = { ...defaultSelectedProduct };
    else {
      payload.salePrices = payload.salePrices.map((s) => {
        return {
          ...defaultSalePrice,
          ...s,
        };
      });
      state.product = { ...defaultSelectedProduct, ...payload };
    }
  },
  setRooms: (state, payload) => {
    state.rooms = payload;
  },
  setRoom: (state, payload) => {
    if (payload._id === "NEW") state.room = { ...defaultSelectedRoom };
    else state.room = payload;
  },
  setSpecialPricings: (state, payload) => {
    state.specialPricings = payload;
  },
  setSpecialPricing: (state, payload) => {
    if (payload._id === "NEW")
      state.specialPricing = { ...defaultSelectedSpecialPricing };
    if (!state.specialPricing.group) {
      state.specialPricing = {
        ...defaultSelectedSpecialPricing,
        ...payload,
      };
    } else state.specialPricing = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setProductPopUp: (state, payload) => {
    state.productPopUp = payload;
  },
  setAudits: (state, payload) => {
    state.audits = payload || [];
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
  updateFilterButtons: (state, payload) => {
    state.filterButtons = payload;
  },
};

const getters = {
  products: (state) => state.products,
  product: (state) => state.product,
  rooms: (state) => state.rooms,
  room: (state) => state.room,
  specialPricings: (state) => state.specialPricings,
  specialPricing: (state) => state.specialPricing,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  productPopUp: (state) => state.productPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  getAudits: (state) => state.audits,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
  filterButtons: (state) => state.filterButtons,
};

const actions = {
  saveProduct: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveProduct", status: true });

    HttpService.post(
      Apis.backendBaseUrl + Apis.saveProduct,
      payload.product,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveProduct", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteProduct: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteProduct", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteProduct,
      payload.product,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteProduct",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getProductById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getProductById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getProductById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getProductById",
          status: false,
        });
        if (response) {
          commit("setProduct", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  saveRoom: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveRoom", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveRoom,
      payload.room,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveRoom", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteRoom: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteRoom", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteRoom,
      payload.room,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteRoom", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getRoomById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getRoomById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getRoomById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getRoomById", status: false });
        if (response) {
          commit("setRoom", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getRooms: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getRooms", status: true });
    let url = Apis.backendBaseUrl + Apis.filterRooms;
    HttpService.post(url, { filter: payload.filter, sort: payload.sort }, function (response, error) {
      commit("setLoadingStatus", { api: "getRooms", status: false });
      var room = [];

      if (response) {
        room = response.data.body;

        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }

        commit("setRooms", room);
      }
    });
  },
  saveSpecialPricing: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveSpecialPricing", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveSpecialPricing,
      payload.specialPricing,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveSpecialPricing",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteSpecialPricing: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteSpecialPricing", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteSpecialPricing,
      payload.specialPricing,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteSpecialPricing",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getSpecialPricingById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getSpecialPricingById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getSpecialPricingById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getSpecialPricingById",
          status: false,
        });
        if (response) {
          commit("setSpecialPricing", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getSpecialPricings: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getSpecialPricings", status: true });
    let url = Apis.backendBaseUrl + Apis.getSpecialPricings + "?";
    if (payload?.filterCriteria && payload?.filterValue) {
      url +=
        `&filterCriteria=${payload.filterCriteria}` +
        `&filterValue=${payload.filterValue}`;
    }
    return new Promise((resolve, reject) => {
      HttpService.get(url, function (response, error) {
        if (error) {
          reject(error);
        }
        commit("setLoadingStatus", {
          api: "getSpecialPricings",
          status: false,
        });
        var specialPricing = {};

        if (response) {
          specialPricing = response.data.body;

          commit("setSpecialPricings", specialPricing);
          resolve(specialPricing);
        }
      });
    });
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setProductPopUp: ({ commit, state }, payload) => {
    commit("setProductPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  getAdjacentProduct: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentProduct", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "product/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentProduct",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getAdjacentRoom: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentRoom", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "room/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getAdjacentRoom", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getAdjacentSpecialPricing: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getAdjacentSpecialPricing",
      status: true,
    });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl +
      "specialPricing/" +
      Apis.getAdjacent +
      "?mode=" +
      mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentSpecialPricing",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getAuditsByProductId({ commit, state }, productId) {
    let url = Apis.backendBaseUrl + Apis.getAuditsByProductId + `?productId=${productId}`;
    HttpService.get(url, function (response, error) {
      var audit = [];

      if (response) {
        audit = response.data.body;
      }
      commit("setAudits", audit);
    });
  },
  performMaintenance: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.product.maintenance[payload.type],
      payload.payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Success", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  },
  updateFilterButtons: ({ commit, state }, payload) => {
    commit("updateFilterButtons", payload)
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
