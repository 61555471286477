import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedOnlineStoreGroup } from "@/views/OnlineStoreGroup/inputs";

const state = {
  onlineStoreGroups: [],
  onlineStoreGroup: { ...defaultSelectedOnlineStoreGroup },
  loadingStatus: {
    saveOnlineStoreGroup: false,
    getOnlineStoreGroups: false,
    getOnlineStoreGroupById: false,
    deleteOnlineStoreGroup: false,
  },
  onlineStoreGroupPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  orderingPopUp: {
    isOpen: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setOnlineStoreGroups: (state, payload) => {
    state.onlineStoreGroups = payload;
  },
  setOnlineStoreGroup: (state, payload) => {
    if (payload._id === "NEW")
      state.onlineStoreGroup = { ...defaultSelectedOnlineStoreGroup };
    else state.onlineStoreGroup = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setOnlineStoreGroupPopUp: (state, payload) => {
    state.onlineStoreGroupPopUp = payload;
  },
  setOrderingPopUp: (state, payload) => {
    state.orderingPopUp.isOpen = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  onlineStoreGroups: (state) => state.onlineStoreGroups,
  onlineStoreGroup: (state) => state.onlineStoreGroup,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  onlineStoreGroupPopUp: (state) => state.onlineStoreGroupPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  getOrderingPopUp: (state) => state.orderingPopUp,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveOnlineStoreGroup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveOnlineStoreGroup", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveOnlineStoreGroup,
      payload.onlineStoreGroup,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveOnlineStoreGroup",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteOnlineStoreGroup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteOnlineStoreGroup", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteOnlineStoreGroup,
      payload.onlineStoreGroup,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteOnlineStoreGroup",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getOnlineStoreGroupById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getOnlineStoreGroupById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getOnlineStoreGroupById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getOnlineStoreGroupById",
          status: false,
        });
        if (response) {
          commit("setOnlineStoreGroup", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setOnlineStoreGroupPopUp: ({ commit, state }, payload) => {
    commit("setOnlineStoreGroupPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  getAdjacent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getAdjacentOnlineStoreGroup",
      status: true,
    });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "group/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentOnlineStoreGroup",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  setOrderingPopUp: ({ commit }, payload) => {
    commit("setOrderingPopUp", payload);
  },
  updateOnlineStoreGroupOrdering: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      const url = Apis.backendBaseUrl + Apis.onlineStoreGroup.updateOrdering;

      HttpService.post(url, payload, (response, error) => {
        if (response) {
          const onlineStoreGroups = response.data.body;
          const id = state.onlineStoreGroup?._id || null;
          if (id) {
            const index = onlineStoreGroups
              .map(function (x) {
                return x._id;
              })
              .indexOf(id);
            const newNameOrder = onlineStoreGroups[index].nameOrder;
            const updatedGroup = {
              ...state.onlineStoreGroup,
              nameOrder: newNameOrder,
            };
            commit("setOnlineStoreGroup", updatedGroup);
          }
          commit("setOnlineStoreGroups", onlineStoreGroups);

          commit(
            "setMessage",
            { text: "Updated", type: "success" },
            { root: true }
          );

          resolve(response);
        } else {
          reject(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      });
    });
  },
  getOnlineStoreGroups: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingStatus", { api: "getOnlineStoreGroups", status: true });
      let url = Apis.backendBaseUrl + Apis.getOnlineStoreGroups

      HttpService.get(url, function (response, error) {
        commit("setLoadingStatus", {
          api: "getOnlineStoreGroups",
          status: false,
        });
        var onlineStoreGroups = {};

        if (response) {
          onlineStoreGroups = response.data.body;
          commit("setOnlineStoreGroups", onlineStoreGroups);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
          resolve(onlineStoreGroups);
        } else {
          reject(error);
        }
      });
    });
  },

  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
