<template>
  <div id="item-movement-pop-up" class="item-movement-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered item-movement-pop-up" :class="{ blur: isBlurred }">
      <span class="h1 padded">Item Movement</span>
      <div class="padded item-movement-pop-up__body">
        <div class="rounded elevated-0 padded">
          <div class="item-movement-pop-up__product">
            {{ itemMovementPopUp.item.product.name }}
          </div>
          <div v-if="itemMovementPopUp.item.batchId" class="item-movement-pop-up__batch">
            {{ itemMovementPopUp.item.batchId }}
          </div>
        </div>

        <div class="item-movement-pop-up__dropdowns">
          <div class="item-movement-pop-up__dropdown">
            <div class="input-text__label">Source</div>
            <v-select v-model="source" placeholder="Select Source" :label="'label'" :options="generateRooms" />
          </div>
          <div>
            <div class="button-info" @click="swapRooms"><font-awesome-icon :icon="['fas', 'exchange-alt']" /></div>
          </div>
          <div class="item-movement-pop-up__dropdown">
            <div class="input-text__label">Destination</div>
            <v-select v-model="destination" placeholder="Select Destination" :label="'label'" :options="generateRooms" />
          </div>
        </div>

        <div>
          <div class="input-text">
            <div class="reversed-flex-column">
              <vue-numeric ref="quantityInput" :precision="Constants.numericInput.defaultPrecision"
                class="input-text__field" v-model="quantityToMove"></vue-numeric>
              <div class="input-text__label">Quantity to Move</div>
            </div>
          </div>
        </div>
      </div>

      <div class="control-buttons padded">
        <div class="button-info" @click="handleNegativeAnswer">Cancel</div>
        <div class="button-secondary" @click="performMove" v-shortkey="['enter']" @shortkey="performMove">
          <span v-show="loadingStatus.move">
            <i class="fas fa-spin fa-circle-notch"></i>
          </span>
          <span v-show="!loadingStatus.move">Move</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import VueNumeric from "vue-numeric";

const generateWIRoom = (quantityWorkingInventory) => ({ label: `Working Inventory (${quantityWorkingInventory})`, code: 'working-inventory' })
const sortRooms = (rooms, itemRooms) => {
  return rooms.map(r => {
    let quantity = 0
    const roomInItem = itemRooms.find(ir => ir.room == r._id)
    if (roomInItem) {
      quantity = roomInItem.quantity
    }
    return { label: `${r.name} (${quantity})`, code: r._id }
  })
}
export default {
  name: "ItemMovementPopUp",
  data() {
    return {
      Constants: Constants,
      isBlurred: false,
      quantityToMove: '',
      rooms: [],
      source: undefined,
      destination: undefined
    };
  },
  components: { VueNumeric },
  created: function () {
    window.addEventListener(
      "keyup",
      this.keyDownHandler
    );
    this.getRooms({
      filter: {}, sort: { isDefault: 'desc', name: 'asc' },
      positiveCallback: (rooms) => {
        this.rooms = rooms;
        const sortedRooms = sortRooms(this.rooms, this.itemMovementPopUp.item.rooms)
        this.source = sortedRooms[0]
      }
    })
    this.destination = generateWIRoom(this.itemMovementPopUp.item.quantityWorkingInventory)
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  computed: {
    ...mapGetters({
      itemMovementPopUp: "itemModule/itemMovementPopUp",
      loadingStatus: "itemModule/loadingStatus",
      refetchTableData: "stateModule/refetchTableData",
    }),
    generateRooms() {
      const rooms = sortRooms(this.rooms, this.itemMovementPopUp.item.rooms)
      rooms.unshift(generateWIRoom(this.itemMovementPopUp.item.quantityWorkingInventory))
      return rooms
    }
  },
  mounted() {
    this.$nextTick(function () {
      try {
        document.querySelectorAll("input[type=tel]")[0].focus();
      } catch (error) { }
    });
  },
  methods: {
    ...mapActions({
      setItemMovementPopUp: "itemModule/setItemMovementPopUp",
      move: "itemModule/move",
      getItems: "itemModule/getItems",
      setRefetchTableData: "stateModule/setRefetchTableData",
      getRooms: "productModule/getRooms"
    }),
    keyDownHandler(e) {
      if (e.keyCode === 27) {
        this.handleNegativeAnswer();
      }
    },
    handleNegativeAnswer: function () {
      this.setItemMovementPopUp({ isOpen: false });
    },
    swapRooms() {
      const tmp = this.source
      this.source = this.destination
      this.destination = tmp
    },
    performMove() {
      if (this.loadingStatus.move) {
        return;
      }
      const body = {
        _entity: 'item',
        _id: this.itemMovementPopUp.item._id,
        updatedAt: this.itemMovementPopUp.item.updatedAt,
        quantityToMove: this.quantityToMove,
        source: this.source,
        destination: this.destination,
      }
      this.move({
        body,
        positiveCallback: (result) => {
          this.setRefetchTableData(result);
          if (this.itemMovementPopUp.positiveCallback) {
            this.itemMovementPopUp.positiveCallback(result)
          }
          this.setItemMovementPopUp({ isOpen: false });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-movement-pop-up {
  min-width: 80%;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem
  }

  &__product {
    font-weight: bold;
    font-size: $text-size-5;
  }

  &__batch {
    font-size: $text-size-3;
  }

  &__dropdowns {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }

  &__dropdown {
    width: 100%;
  }
}


.mode-selector {
  display: flex;
  margin-bottom: 1rem;
  gap: 0.5rem;

  &>div {
    width: 100%;
  }
}

.control-buttons {
  display: flex;
  margin-bottom: 1rem;
  gap: 0.5rem;

  &>div {
    width: 100%;
  }
}
</style>
