<template>
  <div>
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered discounts">
      <div class="discounts__header">
        <div class="discounts__header__action" @click="resetSelection">
          <font-awesome-icon
            class="icon discounts-back-button"
            :class="{
              'discounts-back-button--hide': discountModeSelected == -1,
            }"
            :icon="['fas', 'arrow-left']"
          />
        </div>
        <div class="discounts__header__title">
          <span class="h1">Discounts</span>
        </div>
      </div>
      <div class="discounts__body">
        <div class="options-grid">
          <BaseBtn
            v-for="button in getDiscountModeList"
            :key="button._id"
            @click="() => selectDiscountMode(button)"
            :label="button.label"
            :colorType="button._id == discountModeSelected ? 5 : undefined"
            :disabled="
              ((button._id == 2 || button._id == 3) && isRetailCusotmer) ||
              (button._id == 4 && !posOrder.loyaltyInfo)
            "
          />
        </div>
        <div class="discounts__types" v-if="discountModeSelected >= 0">
          <Instant
            v-if="discountModeSelected == 0"
            @applyDiscount="addDiscount"
          />
          <Coupon
            v-if="discountModeSelected == 1"
            @applyDiscount="addDiscount"
            :order="posOrder"
          />
          <Points
            v-if="discountModeSelected == 2"
            @applyDiscount="addDiscount"
            :order="posOrder"
            :lineTotal="lineTotal"
          />
          <CCP
            v-if="discountModeSelected == 3"
            :order="posOrder"
            :lineTotal="lineTotal"
            @applyDiscount="addDiscount"
          />
          <ClutchRewardsRedeem
            v-if="discountModeSelected == 4"
            :loyaltyInfo="posOrder.loyaltyInfo"
            :lineTotal="lineTotal"
            @applyDiscount="addDiscount"
          />
          <SpringbigRewardsRedeem
            v-if="discountModeSelected == 6"
            :loyaltyInfo="posOrder.loyaltyInfo"
            :lineTotal="lineTotal"
            @applyDiscount="addDiscount"
          />
          <LoyaltyDiscounts
            v-if="discountModeSelected == 7"
            :customer="posOrder.customer"
          />
          <ClutchCoupon
            v-if="discountModeSelected == 5"
            @applyDiscount="addDiscount"
            :order="posOrder"
            @cancelClutchCoupon="cancelClutchCoupon"
          />
        </div>
        <Summary
          v-if="discountModeSelected == -1"
          :subTotal="posOrder.subTotal"
          :discount="posOrder.discount"
          :discounts="discounts"
          :orderTotal="posOrder.lineTotal"
        />
        <List
          v-if="discountModeSelected == -1"
          :discounts="discounts"
          @removeDiscount="removeDiscount"
        />
      </div>
      <div class="center discounts__actions" v-if="discountModeSelected == -1">
        <!-- <BaseBtn
          @click="cancellAllDiscounts"
          :label="'Cancell All'"
          :colorType="1"
          :disabled="discounts.length == 0"
        /> -->
        <BaseBtn
          class="discounts__actions__right"
          @click="handleNegativeAnswer"
          :label="'Close'"
          :colorType="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Instant from "./Instant/Instant.vue";
import Points from "./Points/Points.vue";
import CCP from "./CCP/Ccp.vue";
import Coupon from "./Coupon/Coupon.vue";
import ClutchCoupon from "./ClutchCoupon/ClutchCoupon.vue";
import List from "./List/List.vue";
import Summary from "./Summary/Summary.vue";

import { mapActions, mapGetters } from "vuex";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";
import ClutchRewardsRedeem from "@/components/ClutchRewardsRedeem/ClutchRewardsRedeem.vue";
import SpringbigRewardsRedeem from "@/components/SpringbigRewardsRedeem/SpringbigRewardsRedeem.vue";
import LoyaltyDiscounts from "@/components/LoyaltyDiscounts/LoyaltyDiscounts.vue";

export default {
  name: "DiscountPopUp",
  data() {
    return {
      isBlurred: false,
      Constants: Constants,
      discountModeSelected: -1,
      discountModeList: [
        {
          _id: 0,
          mode: "instant",
          label: "Instant",
        },
        {
          _id: 1,
          mode: "coupon",
          label: "Coupon",
        },
      ],
    };
  },
  components: {
    Instant,
    Points,
    CCP,
    Coupon,
    List,
    BaseBtn,
    Summary,
    ClutchRewardsRedeem,
    SpringbigRewardsRedeem,
    ClutchCoupon,
    LoyaltyDiscounts
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (this.isBlurred) {
          return;
        }
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
    this.getStoreCurrentStore();
    this.getDiscountPopupTabSelected()
  },
  computed: {
    ...mapGetters({
      getPosOrder: "orderModule/posOrder",
      currentStore: "storeModule/currentStore",
      discountsPopUp: "posModule/discountsPopUp",

    }),
    posOrder() {
      return this.getPosOrder;
    },
    isRetailCusotmer() {
      return this.posOrder.customer == null;
    },
    discounts() {
      return this.posOrder.discountsApplied;
    },
    lineTotal() {
      const discount = this.posOrder.discount || 0;
      const subTotal = this.posOrder.subTotal || 0;
      return subTotal - discount;
    },
    getDiscountModeList() {
      if (this.currentStore.loyaltySystem === "clutch") {
        return this.discountModeList.concat([
          {
            _id: 4,
            mode: "clutch-rewards",
            label: "Redeem Rewards",
          },
          {
            _id: 5,
            mode: "clutch-coupon",
            label: "Loyalty Coupon",
          },
        ]);
      }

      if (this.currentStore.loyaltySystem === "springbig") {
        return this.discountModeList.concat([
          {
            _id: 6,
            mode: "springbig-rewards",
            label: "Redeem Rewards",
          },
        ]);
      }

      if (this.currentStore.loyaltySystem === "alpine") {
        return this.discountModeList.concat([
          {
            _id: 7,
            mode: "alpine-rewards",
            label: "Redeem Rewards",
          },
        ]);
      }

      return this.discountModeList.concat([
        {
          _id: 2,
          mode: "points",
          label: "Points",
        },
        {
          _id: 3,
          mode: "ccp",
          label: "CCP",
        },
      ]);
    },
  },
  methods: {
    ...mapActions({
      setDiscountsPopUp: "posModule/setDiscountsPopUp",
      applyDiscountToOrder: "orderModule/applyDiscountToOrder",
      removeDiscountFromOrder: "orderModule/removeDiscountFromOrder",
      getStoreCurrentStore: "storeModule/getCurrentStore",
      removeClutchDiscountFromOrder:
        "orderModule/removeClutchDiscountFromOrder",
    }),
    getDiscountPopupTabSelected(){
      const tabSelected = this.discountsPopUp.tab
      if(tabSelected == 'loyalty'){
        this.discountModeSelected = 7
      }
    },
    cancellAllDiscounts() {
      // TODO
      // this.posOrder.discounts = [];
    },
    selectDiscountMode(mode) {
      if (mode._id == this.discountModeSelected) {
        this.discountModeSelected = -1;
      } else {
        this.discountModeSelected = mode._id;
      }
    },
    resetSelection() {
      this.discountModeSelected = -1;
    },
    async addDiscount(discount) {
      await this.applyDiscountToOrder({
        discount,
        orderId: this.posOrder._id,
        order: this.posOrder,
      }).then(() => {
        this.discountModeSelected = -1;
      });
    },
    handleNegativeAnswer() {
      this.setDiscountsPopUp({ isOpen: false });
    },
    removeDiscount(discountId) {
      this.removeDiscountFromOrder({
        discountId,
        orderId: this.posOrder._id,
        order: this.posOrder,
      });
    },
    cancelClutchCoupon(coupon) {
      this.removeClutchDiscountFromOrder({ coupon });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_discounts.scss";
</style>
