export const normalizeEntityBeforeSaving = (supplierAgent) => {
  const entity = JSON.parse(JSON.stringify(supplierAgent));
  if (entity.verificationExpirationDate === "") {
    delete entity.verificationExpirationDate;
  }
  if (entity.verificationDate === "") {
    delete entity.verificationDate;
  }
  delete entity.verifiedBy;
  return entity;
};
