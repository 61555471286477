<template>
  <div id="ordering">
    <div v-on:click.stop="closeHandler" class="overlay-div overlay"></div>
    <div class="default-pop-up-center-div center content">
      <h1 class="ordering__title">
        {{ title }}
      </h1>
      <div class="ordering__body__title">
        <div class="ordering__body__title__left">Name</div>
        <div># Order</div>
      </div>
      <div class="ordering__body">
        <div v-if="isEmpty">No Items available</div>
        <draggable
          v-else
          class="body__draggable"
          v-model="mutableItems"
          @start="drag = true"
          @end="drag = false"
          @change="updateOrdering"
        >
          <div class="row" v-for="item of mutableItems" :key="item._id">
            <div class="row__drag">
              <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
            </div>
            <div class="row__name">
              {{ item.name }}
            </div>
            <div>
              {{ item[propertyToSortBy] }}
            </div>
          </div>
        </draggable>
      </div>
      <div class="footer">
        <BaseBtn
          @click="closeHandler"
          iconGroup="fas"
          iconName="times"
          :colorType="3"
          label="Cancel"
        />
        <BaseBtn
          :disabled="isEmpty"
          @click="saveHandler"
          iconGroup="fas"
          iconName="save"
          label="Save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "GroupOrderingPopUp",
  data() {
    return {
      mutableItems: JSON.parse(JSON.stringify(this.items)),
    };
  },
  components: {
    FontAwesomeIcon,
    draggable,
    BaseBtn,
  },
  props: {
    title: {
      type: String,
      default: "Sort values",
    },
    items: {
      type: Array,
      required: true,
    },
    propertyToSortBy: {
      type: String,
      required: true,
    },
  },
  computed: {
    isEmpty() {
      return !this.items || this.items.length == 0;
    },
  },
  methods: {
    closeHandler: function () {
      this.$emit("close");
    },
    saveHandler() {
      this.$emit("save", this.mutableItems);
    },
    updateOrdering() {
      this.mutableItems = this.mutableItems.map((i, index) => {
        return {
          ...i,
          [this.propertyToSortBy]: index + 1,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_ordering.scss";
</style>
