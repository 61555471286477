import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  returns: [],
  loadingStatus: {
    saveReturn: false,
    getReturns: false,
    getReturnById: false,
    deleteReturn: false,
  },
  returnPopUp: {
    isOpen: false,
  },
  filterCriteria: [
    { label: "ID", code: "_id", field: { type: "input", label: "ID" } },
    { label: "Email", code: "email", field: { type: "input", label: "Email" } },
    { label: "Name", code: "name", field: { type: "input", label: "Name" } },
    {
      label: "Last name",
      code: "lastName",
      field: { type: "input", label: "Last name" },
    },
  ],
  selectedFilter: undefined,
  selectedPreFilters: undefined,
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setReturns: (state, payload) => {
    state.returns = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setReturnPopUp: (state, payload) => {
    state.returnPopUp = payload;
  },
};

const getters = {
  returns: (state) => state.returns,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  returnPopUp: (state) => state.returnPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
};

const actions = {
  saveReturn: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveReturn", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveReturn,
      payload.return,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveReturn", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteReturn: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteReturn", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteReturn,
      payload.return,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteReturn", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getReturns: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getReturns", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.getReturns}?`;
    if (state.selectedFilter) {
      if (state.selectedFilter.limit) {
        url += `&limit=${state.selectedFilter.limit}`;
      }
      if (state.selectedFilter.skip) {
        url += `&skip=${state.selectedFilter.skip}`;
      }
      if (state.selectedFilter.criteria) {
        url += `&filterCriteria=${state.selectedFilter.criteria.code}`;
      }
      if (state.selectedFilter.value) {
        url += `&filterValue=${state.selectedFilter.value}`;
      }
    }
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getReturns", status: false });
      var returns = {};

      if (response) {
        returns = response.data.body;
        commit("setReturns", returns);
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getReturnById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getReturnById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getReturnById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getReturnById", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setReturnPopUp: ({ commit, state }, payload) => {
    commit("setReturnPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
