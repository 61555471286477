// msoChartOfAccount Editor Inputs

import Constants from "@/resources/Constants";

// Page Header
export const pageHeader = {
  title: "MSO Chart Of Account",
  isEditorView: true,
  cancelPath: "msoChartOfAccounts",
  addPath: "msoChartOfAccountEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "MSO Chart Of Account",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "msoChartOfAccountEditor",
};

// msoChartOfAccount Information - Main Section
export const msoChartOfAccountsInfoInputs = [
  {
    id: "name",
    placeholder: "Account Name",
    type: "input",
    title: "MSO Chart of Account Name",
    focus: true,
  },
  {
    id: "accountNo",
    placeholder: "Account #",
    type: "mask",
    title: "MSO Chart of Account #",
    mask: Constants.inputMasks.defaultAccountNo,
  },
  {
    id: "category",
    placeholder: "Category",
    type: "select",
    title: "Category",
    options: [
      "Asset",
      "Liability",
      "Stockholders Equity",
      "Income",
      "Cost Of Goods Sold",
      "Expense",
    ],
    class: "grid-row-two-column",
  },
  {
    id: "nbal",
    placeholder: "Account Type",
    type: "select",
    title: "Select Account Type",
    options: ["debit", "credit"],
    class: "grid-row-two-column",
  },
  {
    id: "isActive",
    placeholder: "Active",
    type: "checkBox",
    title: "Active",
  },
  {
    id: "isTaxDeductible",
    placeholder: "Tax Deductible",
    type: "checkBox",
    title: "Tax Deductible",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const defaultSelectedMsoChartOfAccounts = {
  _id: "NEW",
  name: "",
  accountNo: "",
  category: "",
  isActive: true,
  isDeleted: false,
  isArchived: false,
  isTaxDeductible: false,
  notes: "",
  nbal: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  {
    label: "Account #",
    code: "accountNo",
    field: { type: "input", label: "Account #" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Account Name",
    id: "name",
    size: "large",
  },
  {
    title: "Account #",
    id: "accountNo",
    size: "medium",
  },
  {
    title: "Category",
    id: "category",
    size: "medium",
  },
  {
    title: "Account Type",
    id: "nbal",
    size: "medium",
  },
  {
    title: "Is Tax Deductible",
    id: "isTaxDeductible",
    type: "checkbox",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Account Name",
    id: "name",
  },
  {
    title: "Account #",
    id: "accountNo",
  },
  {
    title: "Category",
    id: "category",
  },
  {
    title: "Account Type",
    id: "nbal",
  },
  {
    title: "Is Tax Deductible",
    id: "isTaxDeductible",
    type: "checkbox",
  },
];
