<template>
  <div class="purchaseOrder-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :selectedEntity="selectedPurchaseOrder"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'purchaseOrders', label: 'Purchase Orders' },
          {
            name: 'purchaseOrderEditor',
            label: selectedPurchaseOrder._id,
            query: $route.query,
          },
        ]"
        :isLoading="isLoading"
        :disable="isLoading"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @updateIsEdit="isEdit = $event"
        @menu-option-click="
          (entity, action) => handleMenuOptionClick(entity, action)
        "
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
      />
      <div class="section-base section-base--spaced">
        <PurchaseOrderHeaderForm
          :suppliers="suppliers"
          :supplierAgents="supplierAgents"
          :currentStore="currentStore"
          :disabled="
            !isEdit ||
            selectedPurchaseOrder.isArchived ||
            selectedPurchaseOrder.isPostedToJournal
          "
          :selectedPurchaseOrder="selectedPurchaseOrder"
          :totals="totals"
          @performGetManifestById="performGetManifestById"
          @batch-type-changed="propagateBatchIdChangeConfirmation"
          @total-modifier-toggled="showTotalModifier = !showTotalModifier"
          :stateLoadingStatus="stateLoadingStatus"
        />
        <PurchaseOrderTotalModifiersForm
          v-if="showTotalModifier"
          :disabled="
            !isEdit ||
            selectedPurchaseOrder.isArchived ||
            selectedPurchaseOrder.isPostedToJournal
          "
          :totalModifiers="defaultMainSectionValues"
          @apply-total-modifiers="applyTotalModifiers"
          :purchaseOrder="selectedPurchaseOrder"
        />
        <PurchaseOrderMainButtons
          :disabled="!isEdit || selectedPurchaseOrder.isArchived || isLoading"
          @mark-all-unreceived="() => toggleAllUnreceived(false)"
          @mark-all-received="() => toggleAllReceived(true)"
          @post-to-journal="performPostToJournal"
          @reverse-posting="performReversePurchaseOrder"
          @move-all-to-wi="moveAllToWI"
          :selectedPurchaseOrder="selectedPurchaseOrder"
        />
      </div>
      <!-- <EditorSection id="items" :bus="bus" title="PO Items" isTable> -->
      <PurchaseOrderItems
        :disabled="!isEdit || selectedPurchaseOrder.isArchived"
        :items="selectedPurchaseOrder.items"
        @addItem="addItemToPurchaseOrder"
        @selectItem="selectItem"
        @archiveItem="peformArchiveItem"
        :showChemicalCompositionButton="showUpdateChemicalCompositionButton"
        :isUpdateChemicalCompositionLoading="
          stateLoadingStatus.getChemicalComposition
        "
        @removeItem="peformRemoveItem"
        :selectedFilter="itemsSelectedFilter"
        :filterCriteria="itemsFilterCriteria"
        @update-checmical-composition="updateChemicalComposition"
        @items-filter-value-change="filterItems"
        @items-filter-criteria-change="clearItemFilter"
      />
      <!-- </EditorSection> -->

      <div id="item"></div>
      <div v-if="selectedItem" class="section-base">
        <PurchaseOrderItemDetail
          :disabled="
            !isEdit ||
            selectedPurchaseOrder.isArchived ||
            selectedItem.isArchived ||
            selectedPurchaseOrder.isPostedToJournal
          "
          @set-selected-item-taxes="setSelectedItemTax"
          :item="selectedItem"
          :selectedItemSequentialNumber="selectedItemSequentialNumber"
          :numberOfItems="selectedPurchaseOrder.items.length"
          @select-item-by-sequential-number="selectItemBySequentialNumber"
          @assign-product="assignProduct"
          @unit-cost-change="setSelectedItemTax"
          @quantity-purchased-change="setSelectedItemTax"
          :taxRate="getTaxRate(selectedPurchaseOrder, selectedItem)"
          @receive="performReceive"
          @unreceive="performUnreceive"
          @close="unselectItem"
          :purchaseOrder="selectedPurchaseOrder"
        />
      </div>

      <div
        v-if="
          lodash.get(selectedItem, 'product.externalFields.group.isCannabis')
        "
        class="section-base"
      >
        <ChemicalCompositionForm
          :disabled="
            !isEdit ||
            selectedPurchaseOrder.isArchived ||
            selectedItem.isReceived
          "
          title="Item - Chemical Composition"
          :chemicalComposition="selectedItem.chemicalComposition"
        />
      </div>
      <EditorSection
        :title="`About`"
        sectionType="about"
        v-if="selectedPurchaseOrder._id !== 'NEW'"
        :bus="bus"
      >
        <About
          v-if="selectedPurchaseOrder._id !== 'NEW'"
          :entity="selectedPurchaseOrder"
        />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
    <JournalEntries
      v-if="showJournalEntries"
      :showSearchModal="showJournalEntries"
      @toggle-modal="() => (showJournalEntries = !showJournalEntries)"
      type="purchaseOrder"
      :filter="filterJournalEntries"
      :hideFilter="true"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import { cloneDeep } from "lodash";

import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import JournalEntries from "@/components/JournalEntries/JournalEntries.vue";
import EditorSection from "@/components/EditorSection";
import PurchaseOrderItems from "@/views/PurchaseOrder/components/PurchaseOrderItems/PurchaseOrderItems.vue";
import PurchaseOrderHeaderForm from "@/views/PurchaseOrder/components/PurchaseOrderHeaderForm/PurchaseOrderHeaderForm.vue";
import PurchaseOrderTotalModifiersForm from "@/views/PurchaseOrder/components/PurchaseOrderTotalModifiersForm/PurchaseOrderTotalModifiersForm.vue";
import PurchaseOrderMainButtons from "@/views/PurchaseOrder/components/PurchaseOrderMainButtons/PurchaseOrderMainButtons.vue";
import ChemicalCompositionForm from "@/components/ChemicalCompositionForm/ChemicalCompositionForm.vue";
import PurchaseOrderItemDetail from "@/views/PurchaseOrder/components/PurchaseOrderItemDetail/PurchaseOrderItemDetail.vue";
import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";
import { entityModule, setEntity } from "@/utils/entity-mappers";
import {
  prefillPurchaseOrderBeforeRendering,
  prefillManifestBeforeRendering,
} from "@/utils/prefill-entity-before-rendering/purchase-order";
import {
  validateReceive,
  validateUnreceive,
  validateEntityBeforeSaving,
  isEditallowed,
  validateItemArchiving,
  validateEntityBeforePostingToJournal,
} from "@/utils/validation/purchase-order";
import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/purchase-order";
import {
  columnHeaders,
  filterCriteria,
  itemsFilterCriteria,
  pageHeader,
  defaultPurchaseOrderItem,
} from "./inputs";
import { getDefaultExpirationDate } from "@/views/PurchaseOrder/get-default-expiration-date";

export default {
  name: "purchaseOrderEditor",
  data() {
    return {
      lodash: _,
      Utilities: Utilities,
      Constants: Constants,
      moment: moment,
      selectedItem: undefined,
      selectedItemSequentialNumber: 0,
      defaultMainSectionValues: {
        delivery: 0,
        discount: 0,
        forcedTaxRate: 0,
      },
      isEdit: false,
      isCollapsed: false,
      bus: new Vue(),
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.PURCHASEORDER,
      pageHeader,
      showTotalModifier: false,
      itemsSelectedFilter: {},
      itemsFilterCriteria: [],
      showJournalEntries: false,
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    PurchaseOrderItems,
    PurchaseOrderHeaderForm,
    PurchaseOrderTotalModifiersForm,
    PurchaseOrderMainButtons,
    ChemicalCompositionForm,
    PurchaseOrderItemDetail,
    JournalEntries,
  },
  mounted: function () {
    this.forceToggle();
    this.setPageTitle("Purchase Order");
    if (this.$route.query.id) {
      this.getPurchaseOrderById({ _id: this.$route.query.id });
    } else {
      // new element
      this.isEdit = true;
    }
    this.getSuppliers();
    this.getSupplierAgents();
    this.getCurrentStore({
      positiveCallback: function (currentStore) {
        let batchTypeIndex = 1;
        if (currentStore.isMedical) {
          batchTypeIndex = 0;
        }
        if (currentStore.isRecreational) {
          batchTypeIndex = 1;
        }
        if (currentStore.isMedical && currentStore.isRecreational) {
          batchTypeIndex = 2;
        }

        if (!this.selectedPurchaseOrder.batchType) {
          this.setBatchType(Constants.listOfSaleType[batchTypeIndex]);
        }
      }.bind(this),
    });
    this.itemsFilterCriteria = itemsFilterCriteria;
    this.itemsSelectedFilter = {
      criteria: itemsFilterCriteria[0],
      value: undefined,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      this.selectedItem = undefined;
      if (this.id && this.id !== "NEW") {
        this.getPurchaseOrderById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
    purchaseOrder: function (newPurchaseOrder) {
      this.updateSelectedItem(newPurchaseOrder.items);
    },
  },
  computed: {
    showUpdateChemicalCompositionButton() {
      return (
        this.selectedPurchaseOrder?.manifestId?.length > 0 &&
        this.selectedPurchaseOrder.items?.length > 0
      );
    },
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    filterJournalEntries() {
      return {
        purchaseOrder: this.id,
      };
    },
    ...mapGetters({
      loadingStatus: "purchaseOrderModule/loadingStatus",
      purchaseOrder: "purchaseOrderModule/purchaseOrder",
      stateLoadingStatus: "stateModule/loadingStatus",
      suppliers: "supplierModule/suppliers",
      supplierAgents: "supplierModule/supplierAgents",
      rooms: "productModule/rooms",
      currentStore: "storeModule/currentStore",
      user: "loginModule/user",
    }),
    isLoading() {
      return (
        this.loadingStatus.savePurchaseOrder ||
        this.loadingStatus.reversePurchaseOrder
      );
    },
    selectedPurchaseOrder: {
      get() {
        this.setPageTitle(`Purchase Order ${this.purchaseOrder._id}`);
        return this.prefillPurchaseOrderBeforeRendering(this.purchaseOrder);
      },
      set(entity) {
        return entity;
      },
    },
    calculateSubTotal() {
      return (
        this.calculateCumulativeValue(
          this.selectedPurchaseOrder.items,
          "unitCost"
        ) - this.selectedPurchaseOrder.discount
      );
    },
    calculateTotal() {
      return (
        this.calculateSubTotal +
        this.selectedPurchaseOrder.delivery +
        this.calculateCumulativeValue(
          this.selectedPurchaseOrder.items,
          "tax",
          false
        )
      );
    },
    totals: function () {
      return {
        unitCost: this.calculateCumulativeValue(
          this.selectedPurchaseOrder.items,
          "unitCost"
        ),
        discount: this.selectedPurchaseOrder.discount,
        tax: this.calculateCumulativeValue(
          this.selectedPurchaseOrder.items,
          "tax",
          false
        ),
        subTotal: this.calculateSubTotal,
        delivery: this.selectedPurchaseOrder.delivery,
        total: this.calculateTotal,
        forcedTaxRate: this.calculateTotal,
      };
    },
  },
  methods: {
    ...mapActions({
      getCurrentStore: "storeModule/getCurrentStore",
      getProductById: "productModule/getProductById",
      getPurchaseOrderById: "purchaseOrderModule/getPurchaseOrderById",
      savePurchaseOrder: "purchaseOrderModule/savePurchaseOrder",
      reversePurchaseOrder: "purchaseOrderModule/reversePurchaseOrder",
      deletePurchaseOrder: "purchaseOrderModule/deletePurchaseOrder",
      undeletePurchaseOrder: "purchaseOrderModule/undeletePurchaseOrder",
      setMessage: "setMessage",
      setMessagePopUp: "setMessagePopUp",
      setStateInventoryPopUp: "stateModule/setStateInventoryPopUp",
      getSuppliers: "supplierModule/getSuppliers",
      getManifestById: "stateModule/getManifestById",
      getChemicalComposition: "stateModule/getChemicalComposition",
      getSupplierAgents: "supplierModule/getSupplierAgents",
      getRooms: "productModule/getRooms",
      getAdjacent: "purchaseOrderModule/getAdjacent",
      setBatchType: "purchaseOrderModule/setBatchType",
      logEvent: "logEvent",
    }),
    handleMenuOptionClick(entity, action) {
      switch (action) {
        case "journal-entries-all":
          this.journalEntryType = "purchaseOrder";
          this.showJournalEntries = !this.showJournalEntries;
          break;
        default:
          break;
      }
    },
    updateChemicalComposition() {
      this.getChemicalComposition({
        purchaseOrder: this.selectedPurchaseOrder,
        positiveCallback: (items) => {
          for (let item of items) {
            const itemToUpdate = this.selectedPurchaseOrder.items.find(
              (i) => i.batchId === item.batchId
            );
            if (itemToUpdate) {
              itemToUpdate.chemicalComposition = {
                ...itemToUpdate.chemicalComposition,
                ...item.chemicalComposition,
              };
            }
          }
        },
      });
    },
    applyTotalModifiers() {
      this.setDelivery();
      this.setDiscount();
      this.calculateItemTax();
    },
    calculateItemTax() {
      this.selectedPurchaseOrder?.items?.forEach((item) => {
        this.setSelectedItemTax({ item });
      });
    },
    getTaxRate(purchaseOrder, item) {
      if (!item.product) {
        return 0;
      }
      if (_.get(item, "product.externalFields.group.isCannabis")) {
        return purchaseOrder.supplier.cannabisTaxRate;
      } else {
        return purchaseOrder.supplier.taxRate;
      }
    },
    updateSelectedItem(items) {
      if (!items || items.length == 0) {
        return;
      }
      if (!this.selectedItem) {
        return;
      }
      if (!this.selectedItem._id) {
        this.selectedItem = items[0];
      }
      const item = items.find((i) => i._id == this.selectedItem._id);
      if (item) {
        this.selectedItem = item;
      }
    },
    unselectItem() {
      this.selectedItem = undefined;
    },
    moveAllToWI() {
      this.logEvent({
        logString: `action: move-all-to-wi user: ${this.user?.email}`,
      });
      var receivedFound = false;

      const isWi = this.currentStore.isWorkingInventoryOnlyEnabled;
      const isWiForNonCannabis =
        this.currentStore.isWorkingInventoryOnlyEnabledForNonCannabis;

      var message = {
        text: "Items moved to WI",
        type: "success",
      };

      this.selectedPurchaseOrder.items.forEach((i) => {
        const isCannabis = i.product.externalFields.group.isCannabis;
        if (i.isReceived) {
          if ((isCannabis && isWi) || (!isCannabis && isWiForNonCannabis)) {
            if (!i._id || i.isNew) {
              this.$set(i, "quantityWorkingInventory", i.quantityPurchased);
            } else {
              this.$set(i, "quantityWorkingInventory", i.quantityOnHand);
            }
          }
          receivedFound = true;
        }
      });
      if (!receivedFound) {
        message = {
          text: "No received items found.<br><br>Please receive some items before trying this action.",
          type: "error",
        };
      }
      this.$store.dispatch("setMessage", message, { root: true });
    },
    calculateCumulativeValue(items, field, itemized = true) {
      if (!items || items.length === 0) {
        return 0;
      }
      let cumulator = 0;
      items.forEach((i) => {
        if (i.isArchived) {
          return cumulator;
        }
        if (itemized) {
          return (cumulator += i[field] * i.quantityPurchased);
        } else {
          return (cumulator += i[field]);
        }
      });
      return cumulator;
    },
    getNestedValue(item, id) {
      if (id.includes(".")) {
        const keys = id.split(".");
        let result = item;
        keys.forEach((key) => {
          if (result) result = result[key];
        });
        return result;
      } else return item[id];
    },
    clearItemFilter() {
      this.selectedPurchaseOrder.items.forEach((i) => {
        return this.$set(i, "hidden", false);
      });
    },
    filterItems(event) {
      this.selectedPurchaseOrder.items.forEach((i) => {
        let itemValue = this.getNestedValue(i, event.filter.criteria.code);
        const valueToSearchFor = event.filter.value.toLowerCase();
        if (valueToSearchFor == "") {
          return this.$set(i, "hidden", false);
        }

        if (!itemValue) {
          return this.$set(i, "hidden", true);
        }

        itemValue = itemValue.toLowerCase();

        if (!itemValue.includes(valueToSearchFor)) {
          return this.$set(i, "hidden", true);
        }

        return this.$set(i, "hidden", false);
      });
    },
    performSave(options = {}) {
      if (
        !this.validateEntityBeforeSaving(this.selectedPurchaseOrder, options)
      ) {
        return;
      }

      if (!this.isEditallowed()) {
        return;
      }

      const purchaseOrderToSave = this.normalizeEntityBeforeSaving(
        this.selectedPurchaseOrder
      );

      purchaseOrderToSave._entity = "purchaseOrder";

      this.savePurchaseOrder({
        purchaseOrder: purchaseOrderToSave,
        options: options,
        positiveCallback: function (purchaseOrder) {
          const id = purchaseOrder.id ? purchaseOrder.id : purchaseOrder._id;
          this.$store.commit(
            `${entityModule[this.entity]}/${setEntity[this.entity]}`,
            purchaseOrder
          );
          const module =
            this.entity !== Entities.PRODUCT
              ? `${this.entity}s`
              : `${this.entity}`;
          if (this.$route.query.id !== id) {
            this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
          }
          this.$store.dispatch(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (options.positiveCallback) {
            options.positiveCallback();
          } else {
            this.toggleEdit(true);
          }
        }.bind(this),
      });
    },
    performPostToJournal() {
      let validationResult = validateEntityBeforePostingToJournal(
        this.selectedPurchaseOrder
      );
      if (!validationResult.succeeded) {
        return this.setMessage({
          text: validationResult.message,
          type: "error",
        });
      }
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message:
          "Are you sure that you want to post these items to the journal?",
        positiveCallback: () => {
          if (this.isCannabisProductPresent()) {
            this.performSave({
              positiveCallback: () => {
                this.setStateInventoryPopUp({
                  isOpen: true,
                  title: Constants.strings.warningMessageTitle,
                  message:
                    "Are you sure that you want to post these items to the journal?",
                  positiveCallback: () =>
                    this.performSave({ postToJournal: true }),
                });
              },
            });
          } else {
            this.performSave({ postToJournal: true });
          }
        },
      });
    },
    isCannabisProductPresent() {
      var isCannabisProductPresent = false;
      try {
        this.selectedPurchaseOrder.items.forEach((i) => {
          if (i.product && i.product.externalFields.group.isCannabis) {
            isCannabisProductPresent = true;
          }
        });
      } catch (error) {}
      return isCannabisProductPresent;
    },
    performReversePurchaseOrder() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: "Are you sure that you want to reverse this Purchase Order?",
        positiveCallback: () => {
          this.reversePurchaseOrder({
            purchaseOrder: this.selectedPurchaseOrder,
            positiveCallback: function (purchaseOrder) {
              this.$store.commit(
                `${entityModule[this.entity]}/${setEntity[this.entity]}`,
                purchaseOrder
              );

              this.$store.dispatch(
                "setMessage",
                { text: "Saved", type: "success" },
                { root: true }
              );
              this.toggleEdit(true);
            }.bind(this),
          });
        },
      });
    },
    normalizeEntityBeforeSaving(purchaseOrder) {
      return normalizeEntityBeforeSaving(purchaseOrder);
    },
    validateGetManifestById(purchaseOrder) {
      if (
        !purchaseOrder ||
        !purchaseOrder.manifestId ||
        purchaseOrder.manifestId.length === 0
      ) {
        this.setMessage({
          text: "Please enter a Manifest #.",
          type: "error",
        });
        return false;
      }
      if (
        this.selectedPurchaseOrder.items &&
        this.selectedPurchaseOrder.items.length > 0
      ) {
        this.setMessage({
          text: "This PO has items, manifest cannot be imported.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    validateEntityBeforeSaving(purchaseOrder, options) {
      let validationResult = validateEntityBeforeSaving(purchaseOrder, options);
      if (!validationResult.succeeded) {
        this.setMessage({
          text: validationResult.message,
          type: "error",
        });
      }
      return validationResult.succeeded;
    },
    isEditallowed() {
      let validationResult = isEditallowed(this.selectedPurchaseOrder);
      if (!validationResult.succeeded) {
        this.setMessage({
          text: validationResult.message,
          type: "error",
        });
      }
      return validationResult.succeeded;
    },
    performGetManifestById() {
      if (
        this.validateGetManifestById(this.selectedPurchaseOrder) &&
        this.validateEntityBeforeSaving(this.selectedPurchaseOrder)
      ) {
        this.getManifestById({
          manifestId: this.selectedPurchaseOrder.manifestId,
          purchaseOrderId: this.selectedPurchaseOrder._id,
          supplier: this.selectedPurchaseOrder.supplier,
          manifestDate: this.selectedPurchaseOrder.manifestDate,
          positiveCallback: (manifest) => {
            this.selectedPurchaseOrder.isManual = false;
            this.selectedPurchaseOrder.items =
              this.prefillManifestBeforeRendering(manifest);
          },
        });
      }
    },
    peformArchiveItem({ item, itemPosition }) {
      let message = "Are you sure that you want to archive this item?";
      if (item.isArchived) {
        message = "Are you sure that you want unarchive this item?";
      }
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: message,
        positiveCallback: () => this.archiveItem(itemPosition),
      });
    },
    peformRemoveItem({ item, itemPosition }) {
      let message = "Are you sure that you want to remove this item?";
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: message,
        positiveCallback: () => this.removeItem(itemPosition),
      });
    },
    archiveItem(itemPosition) {
      let itemToArchive = this.selectedPurchaseOrder.items[itemPosition];
      let validationResult = validateItemArchiving(itemToArchive);
      if (!validationResult.succeeded) {
        this.setMessage({
          text: validationResult.message,
          type: "error",
        });
        return;
      }
      itemToArchive.isArchived = !itemToArchive.isArchived;
      this.setFreightForItems();
      this.setDiscountForItems();
      this.unselectItem();
    },
    removeItem(itemPosition) {
      this.selectedPurchaseOrder.items.splice(itemPosition, 1);
      this.setFreightForItems();
      this.setDiscountForItems();
      this.unselectItem();
    },
    addItemToPurchaseOrder() {
      if (!this.validateEntityBeforeSaving(this.selectedPurchaseOrder)) {
        return false;
      }
      if (this.selectedPurchaseOrder.items.length == 0) {
        this.selectedPurchaseOrder.manifestId = "";
      }
      const newItem = JSON.parse(JSON.stringify(defaultPurchaseOrderItem));

      // Add one year to the current date
      newItem.expirationDate = getDefaultExpirationDate();

      newItem.batchType = this.selectedPurchaseOrder.batchType;

      this.selectedPurchaseOrder.items.unshift(newItem);
      this.setFreightForItems();
      this.setDiscountForItems();
    },
    selectItemBySequentialNumber(mode) {
      let nextSelectedItemSequentialNumber = 0;
      if (mode === "next") {
        nextSelectedItemSequentialNumber =
          this.selectedItemSequentialNumber + 1;
      }
      if (mode === "previous") {
        nextSelectedItemSequentialNumber =
          this.selectedItemSequentialNumber - 1;
      }

      const nextItem =
        this.selectedPurchaseOrder.items[nextSelectedItemSequentialNumber];
      const payload = {
        item: nextItem,
        sequentialNumber: nextSelectedItemSequentialNumber,
      };
      this.selectItem(payload, false);
    },
    selectItem({ item, sequentialNumber }, scroll = true) {
      if (!item.chemicalComposition) {
        const cp = cloneDeep(defaultPurchaseOrderItem.chemicalComposition);
        this.$set(item, "chemicalComposition", cp);
      }

      this.selectedItem = item;
      if (!this.selectedItem.product || !this.selectedItem.product._id) {
        this.$set(this.selectedItem, "product", {});
      }
      this.selectedItemSequentialNumber = sequentialNumber;

      if (scroll) {
        this.scrollTop("#item", 200);
      }
    },
    scrollTop(selector, offset) {
      try {
        const elementToScroll = "#app-body";

        $(elementToScroll).animate(
          {
            scrollTop:
              $(elementToScroll).scrollTop() +
              $(selector).offset().top -
              offset,
          },
          300
        );
      } catch (error) {
        console.log("cannot scroll to " + selector);
      }
    },
    prefillPurchaseOrderBeforeRendering(purchaseOrder) {
      purchaseOrder = prefillPurchaseOrderBeforeRendering(purchaseOrder);
      this.defaultMainSectionValues.delivery = purchaseOrder.delivery;
      this.defaultMainSectionValues.forcedTaxRate = purchaseOrder.forcedTaxRate;
      this.defaultMainSectionValues.discount = purchaseOrder.discount;
      return purchaseOrder;
    },
    prefillManifestBeforeRendering(items) {
      items = prefillManifestBeforeRendering(
        items,
        this.selectedPurchaseOrder.batchType
      );
      this.scrollTop("#items", 200);
      this.items = items;
      return this.items;
    },
    assignProduct(response) {
      this.selectedItem.product = response;
      this.selectedItem.room = this.selectedItem.product.group
        ? this.selectedItem.product.group.room
        : undefined;
      this.$set(this.selectedItem, "product", response);
      this.setSelectedItemTax();
    },
    setSelectedItemTax(options) {
      let item = this.selectedItem;
      if (options?.item) {
        item = options.item;
      }
      if (options?.removeTaxes) {
        item.tax = 0;
        return;
      }
      let taxRate = this.selectedPurchaseOrder?.supplier?.taxRate;
      if (_.get(item, "product.externalFields.group.isCannabis")) {
        taxRate = this.selectedPurchaseOrder?.supplier?.cannabisTaxRate;
      }
      if (this.selectedPurchaseOrder.applyTaxBeforeDiscount) {
        item.tax =
          ((item.quantityPurchased * item.unitCost - item.discount) * taxRate) /
          100;
      } else {
        item.tax = (item.quantityPurchased * item.unitCost * taxRate) / 100;
      }
      if (taxRate > 0) {
        item.isTaxed = true;
      }
    },
    setDelivery() {
      this.selectedPurchaseOrder.delivery =
        this.defaultMainSectionValues.delivery;
      this.setFreightForItems();
    },
    setFreightForItems() {
      if (!this.selectedPurchaseOrder.items) {
        return;
      }
      var x =
        this.selectedPurchaseOrder.delivery /
        this.selectedPurchaseOrder.items.length;
      this.selectedPurchaseOrder.items.forEach((i) => {
        i.freight =
          this.selectedPurchaseOrder.delivery /
          this.selectedPurchaseOrder.items.length;
      });
    },
    setDiscount() {
      this.selectedPurchaseOrder.discount =
        this.defaultMainSectionValues.discount;
      this.setDiscountForItems();
    },
    setDiscountForItems() {
      if (!this.selectedPurchaseOrder.items) {
        return;
      }

      let discountPercent = this.selectedPurchaseOrder.discount/(this.calculateSubTotal + this.selectedPurchaseOrder.discount)

      this.selectedPurchaseOrder.items.forEach(
        (i) =>
          (i.discount =
            (i.unitCost*i.quantityPurchased) * discountPercent)
      );
    },
    showDoneMessage() {
      this.setMessage({
        text: "Done",
        type: "success",
      });
    },
    validateReceive(item, options) {
      let validationResult = validateReceive(
        item,
        this.selectedPurchaseOrder,
        options
      );
      if (!validationResult.succeeded) {
        var errorMessage = `You may not mark items received for item with barcode ${
          item._id || item.batchId
        }. ${validationResult.message}`;
        if (!options.counter) {
          errorMessage = `Unable to receive this item.<br>${validationResult.message}`;
        }
        this.setMessage({
          text: errorMessage,
          type: "error",
        });
      }
      return validationResult.succeeded;
    },
    toggleAllReceived(receivedStatus) {
      let counter = 0;
      for (let i of this.selectedPurchaseOrder.items) {
        counter += 1;

        if (i.isArchived) {
          continue;
        }

        if (receivedStatus) {
          const validationResult = this.validateReceive(i, {
            showError: true,
            counter,
          });
          if (!validationResult) {
            return;
          }

          if (i.product && i.quantityPurchased > 0) {
            i.isReceived = receivedStatus;
          }
        } else {
          i.isReceived = receivedStatus;
        }
      }

      this.showDoneMessage();
    },
    performReceive() {
      if (
        !this.selectedItem.isReceived &&
        this.validateReceive(this.selectedItem, { showError: true })
      ) {
        this.selectedItem.isReceived = true;
      } else {
        this.selectedItem.isReceived = false;
      }
    },
    validateUnreceive(item, options) {
      let validationResult = validateUnreceive(
        item,
        this.selectedPurchaseOrder,
        options
      );
      if (!validationResult.succeeded) {
        //var errorMessage = `Unable to receive item #${options.counter} in PO:<br>${validationResult.message}`;
        var errorMessage = `You may not mark all items unreceived because barcode item ${item._id} has been sold. Please unmark only the items that you need to correct. ${validationResult.message}`;
        if (!options.counter) {
          errorMessage = `Unable to receive this item.<br>${validationResult.message}`;
        }
        this.setMessage({
          text: errorMessage,
          type: "error",
        });
      }
      return validationResult.succeeded;
    },
    toggleAllUnreceived(receivedStatus) {
      let counter = 1;

      // for (let i of this.selectedPurchaseOrder.items) {
      //   if (receivedStatus == false) {
      //     const validationResult = this.validateUnreceive(i, {
      //       showError: true,
      //       counter,
      //     });
      //     if (!validationResult) {
      //       return;
      //     }

      //     if (i.product && i.quantityPurchased > 0) {
      //       i.isReceived = receivedStatus;
      //     }
      //   } else {
      //     i.isReceived = receivedStatus;
      //   }
      //   counter += 1;
      // }

      let updateUnreceive = true;

      for (let i of this.selectedPurchaseOrder.items) {
        if (receivedStatus == false) {
          const validationResult = this.validateUnreceive(i, {
            showError: true,
            counter,
          });
          if (!validationResult) {
            updateUnreceive = false;
            return;
          }
        }
        counter += 1;
      }

      if (updateUnreceive == true) {
        for (let i of this.selectedPurchaseOrder.items) {
          i.isReceived = receivedStatus;
        }
      }
      this.showDoneMessage();
    },

    performUnreceive() {
      // todo unrecieve validation
      this.selectedItem.isReceived = false;
    },
    propagateBatchIdChangeConfirmation() {
      if (
        this.selectedPurchaseOrder.items &&
        this.selectedPurchaseOrder.items.length > 0
      ) {
        this.setMessagePopUp({
          isOpen: true,
          title: Constants.strings.actionPropagation,
          message: Constants.strings.propagateBatchTypeChange,
          positiveCallback: () => {
            this.selectedPurchaseOrder.items.forEach((i) => {
              if (!i.isArchived) {
                i.batchType = this.selectedPurchaseOrder.batchType;
              }
            });
          },
        });
      }
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedPurchaseOrder.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedPurchaseOrder = adjacent;
        },
      });
    },
    toggleEdit() {
      if (!(this.selectedPurchaseOrder._id === "NEW")) {
        this.isEdit = !this.isEdit;
      }
      if (this.selectedPurchaseOrder._id === "NEW") {
        this.isEdit = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_purchaseOrderEditor.scss";
</style>
