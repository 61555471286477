import { OPTIONS, TIMESERIES_OPTIONS, DEFAULT_PALETTE, DEFAULT_SCALE } from "@/views/dashboards/chart.constant.js"
import { cloneDeep } from "lodash"

export const getDefaultChartData = (type, theme) => {
    switch (type) {
        case 'bar':
            return cloneDeep(getDefaultTimeseriesChartData(type, theme))
        case 'line':
            return cloneDeep(getDefaultTimeseriesChartData(type, theme))
        case 'pie':
            return cloneDeep(getDefaultPieChartData(type, theme))
        case 'topList':
            return cloneDeep(getDefaultTopListChartData(type, theme))
        case 'calendar-heatmap':
            return cloneDeep(getDefaultCalendarHeatmapChartData(type, theme))
        default:
            break;
    }
}

const getDefaultTimeseriesChartData = (type, theme) => {
    const chartData = {
        type: type,
        data: {
            labels: [],
            datasets: []
        },
        options: {
            ...getOptions(theme), ...getTimeseriesOptions(theme)
        }
    }
    return chartData
}

const getDefaultTopListChartData = (type, theme) => {
    const chartData = {
        type: type,
        data: [],
        palette: getPalette(theme)
    }
    return chartData
}

const getDefaultCalendarHeatmapChartData = (type, theme) => {
    const chartData = {
        type: type,
        data: [],
        palette: getScale(theme)
    }
    return chartData
}

const getDefaultPieChartData = (type, theme) => {
    const chartData = {
        type: type,
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Dataset 1',
                    data: [],
                    backgroundColor: getPalette(theme),
                    hoverBackgroundColor: getPalette(theme),
                    borderWidth: 0
                }
            ]
        },
        options: getOptions(theme)
    }
    return chartData
}

const getPalette = (theme) => {
    return DEFAULT_PALETTE[theme]
}

const getScale = (theme) => {
    return DEFAULT_SCALE[theme]
}

const getFontColor = (theme) => theme == 'dark' ? `white` : `#2c3e50`
const getGrayColor = (theme) => theme == 'dark' ? `#3c3c3e` : `#d1d1d1`

const getTimeseriesOptions = (theme) => {
    const timeseriesOptions = cloneDeep(TIMESERIES_OPTIONS)
    const gray = getGrayColor(theme)
    const fontColor = getFontColor(theme)

    timeseriesOptions.scales.yAxes.forEach(a => {
        a.gridLines.color = gray
        a.ticks.fontColor = fontColor
    })
    timeseriesOptions.scales.xAxes.forEach(a => {
        a.gridLines.color = gray
        a.ticks.fontColor = fontColor
    })

    return timeseriesOptions
}

const getOptions = (theme) => {
    const options = cloneDeep(OPTIONS)
    const fontColor = getFontColor(theme)
    options.legend.labels.fontColor = fontColor
    return options
}

export const assignPaletteToDataset = (datasets, theme) => {
    const defaultPalette = getPalette(theme)

    datasets.forEach((dataset, i) => {
        dataset.backgroundColor = defaultPalette[i]
        dataset.hoverBackgroundColor = defaultPalette[i]
    })

}