<template>
  <div class="editor-section-base elevated-0" :key="defaultIsOpen">
    <div class="editor-section-base__header" @click="toggleSection">
      <div>
        <span class="editor-section-base__icon" v-if="sectionType === 'danger'"><i
            class="fas fa-exclamation-triangle"></i></span>
        <span class="editor-section-base__icon" v-if="sectionType === 'about'"><i class="fas fa-info-circle"></i></span>
        <span class="editor-section-base__header-name">{{ title }}</span>
      </div>
      <div class="collapsable-icon__container">
        <!-- +/- Icons -->
        <span class="collapsable-icon">
          <font-awesome-icon :icon="['fas', 'plus']" v-if="isCollapsed" />
          <font-awesome-icon :icon="['fas', 'minus']" v-if="!isCollapsed" />
        </span>
      </div>
    </div>
    <div v-if="!isCollapsed" :class="'editor-section-base__slot bordered ' +
      (isTable ? 'editor-section-base__table' : '')
      ">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);

export default {
  name: "EditorSection",
  props: {
    title: {
      type: String,
    },
    isTable: {
      type: Boolean,
    },
    defaultIsOpen: {
      type: Boolean,
      default: false,
    },
    bus: {},
    sectionType: {
      type: String,
    },
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  created() {
    // Event bus allows parent to toggle all editor sections
    if (this.bus) {
      this.bus.$on("forceToggle", (args) => {
        this.toggleSection({ parentToggle: args });
      });
    }
  },
  mounted() {
    this.isCollapsed = !this.defaultIsOpen;
  },
  methods: {
    toggleSection(params) {
      if (typeof params.parentToggle === "boolean")
        this.isCollapsed = params.parentToggle;
      else this.isCollapsed = !this.isCollapsed;
    },
    selectItemFromSearch(entity, item, nestedId) {
      this.$parent.selectItemFromSearch(entity, item, nestedId);
    },
  },
};
</script>

<style scoped lang="scss">
.editor-section-base {
  margin: 20px 0;
  margin-bottom: 40px;
  padding: 5px 10px;

  &--no-margin-bottom {
    margin: 20px;
  }

  &__table {
    overflow: auto;
  }

  &__header {
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__icon {
    margin-right: 10px;
  }

  &__header-name {
    font-size: $text-size-6;
    font-weight: bold;

    >div {
      margin: 0 7px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

/* Table */
.table-container {
  overflow-x: auto;
  border-radius: $border-radius-0;
  box-shadow: $dark-shadow-0;

  @include respond(print) {
    box-shadow: none;
  }

  font-size: $text-size-3;
}

.collapsable-icon {
  margin-left: 10px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .editor-section-base {
    max-width: none;
  }
}
</style>
