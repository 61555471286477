<template>
  <div id="sell-options-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div center bordered"
      :class="{ blur: isBlurred }"
    >
      <span class="h1 padded">Payment</span>
      <br />

      <div class="padded button-wrapper">
        <BaseBtn
          @click="() => performSetPayment({ type: 'cash' })"
          label="Cash"
        />
        <BaseBtn
          @click="() => performSetPayment({ type: 'pre-order' })"
          label="Preorder"
        />
        <BaseBtn @click="handleNegativeAnswer" label="Cancel" :colorType="3" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "SellOptionsPopUp",
  data() {
    return {
      Constants: Constants,
      isBlurred: false,
      isCashLoading: false,
      isPreorderLoading: false,
    };
  },
  components: { BaseBtn },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({
      sellOptionsPopUp: "posModule/sellOptionsPopUp",
      loadingStatus: "orderModule/loadingStatus",
    }),
  },
  methods: {
    ...mapActions({
      setSellOptionsPopUp: "posModule/setSellOptionsPopUp",
      setPayment: "orderModule/setPayment",
    }),
    handleNegativeAnswer: function () {
      this.setSellOptionsPopUp({ isOpen: false });
    },
    performSetPayment: function (payment) {
      if (this.isCashLoading || this.isPreorderLoading) {
        return;
      }
      if (payment.type === "cash") {
        this.isCashLoading = true;
      }
      if (payment.type === "pre-order") {
        this.isPreorderLoading = true;
      }

      this.setPayment({
        payment: payment,
        positiveCallback: () => {
          this.isCashLoading = false;
          this.isPreorderLoading = false;
          if (this.sellOptionsPopUp.positiveCallback) {
            this.sellOptionsPopUp.positiveCallback();
          }
          this.setSellOptionsPopUp({ isOpen: false });
        },
        negativeCallback: () => {
          this.isCashLoading = false;
          this.isPreorderLoading = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  div {
    flex: 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .options-grid {
    display: block;
    .button-secondary {
      margin-bottom: 10px;
    }
  }
}
</style>
