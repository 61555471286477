<template>
  <div class="tax-setup-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'taxSetups', label: 'TaxSetup' },
          {
            name: 'taxSetupEditor',
            label: selectedTaxSetup.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedTaxSetup"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />
      <div class="section-base section-base--spaced">
        <div class="padded elevated-0 bordered tax-container">
          <div>
            <Input
              :model="selectedTaxSetup.name"
              :inputData="getInput('name')"
              @updateModel="selectedTaxSetup.name = $event"
              :isEdit="isEdit"
            />
            <Input
              :model="selectedTaxSetup.taxType"
              :inputData="getInput('taxType')"
              @updateModel="selectedTaxSetup.taxType = $event"
              :isEdit="isEdit"
            />
          </div>
          <div>
            <Input
              :model="selectedTaxSetup.isActive"
              :inputData="getInput('isActive')"
              @updateModel="selectedTaxSetup.isActive = $event"
              :isEdit="isEdit"
            />
            <Input
              :model="selectedTaxSetup.isCumulative"
              :inputData="getInput('isCumulative')"
              @updateModel="selectedTaxSetup.isCumulative = $event"
              :isEdit="isEdit"
            />
          </div>
          <div>
            <Input
              :model="selectedTaxSetup.effectiveDate"
              :inputData="getInput('effectiveDate')"
              @updateModel="selectedTaxSetup.effectiveDate = $event"
              :isEdit="isEdit"
              :validation="false"
            />
            <Input
              :model="selectedTaxSetup.endDate"
              :inputData="getInput('endDate')"
              @updateModel="selectedTaxSetup.endDate = $event"
              :isEdit="isEdit"
              :validation="false"
            />
          </div>
          <div class="tax-container__full-row">
            <Input
              :model="selectedTaxSetup.taxOrder"
              :inputData="getInput('taxOrder')"
              @updateModel="selectedTaxSetup.taxOrder = $event"
              :isEdit="isEdit && selectedTaxSetup.isCumulative"
            />
            <Input
              :model="selectedTaxSetup.taxRate"
              :inputData="getInput('taxRate')"
              @updateModel="selectedTaxSetup.taxRate = $event"
              :isEdit="isEdit"
            />
            <Input
              :model="selectedTaxSetup.accountNo"
              :inputData="getInput('accountNo')"
              @updateModel="selectedTaxSetup.accountNo = $event"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </div>
      <div class="section-base section-base--spaced">
        <div class="padded elevated-0 bordered rule-setup">
          <div class="thc-value">
            <Input
              :model="selectedTaxSetup.thcOperator"
              :inputData="getInput('thcOperator')"
              @updateModel="selectedTaxSetup.thcOperator = $event"
              :isEdit="isEdit"
            />
            <Input
              :model="selectedTaxSetup.thcPercent"
              :inputData="getInput('thcPercent')"
              @updateModel="selectedTaxSetup.thcPercent = $event"
              :isEdit="isEdit"
            />
          </div>
          <div class="divider" />
          <div class="taxSetup__inventories">
            <div class="taxSetup__inventories__add">
              <Input
                class="taxSetup__inventories__add__select"
                :model="selectedInventory"
                :inputData="getInput('thcInventoryTypes')"
                :isEdit="isEdit"
                @updateModel="selectedInventory = $event"
              />
              <BaseBtn
                @click="addInventoryType"
                label="Add"
                :disabled="!isEdit || !selectedInventory"
                size="sm"
              />
            </div>
            <div
              v-for="(inventory, index) of selectedTaxSetup.thcInventoryTypes"
              :key="`inventory_${index}`"
              class="taxSetup__selectedInventory"
            >
              <p
                class="taxSetup__selectedInventory__value"
                :class="{
                  'taxSetup__selectedInventory__value--even': index % 2 == 0,
                }"
              >
                {{ inventory }}
              </p>
              <BaseBtn
                :colorType="1"
                class="search-button search-button--right"
                size="sm"
                iconGroup="fas"
                iconName="times"
                @click="removeInventory(inventory)"
                :disabled="!isEdit"
              />
            </div>
          </div>
        </div>
      </div>
      <EditorSection title="Notes" sectionType="notes" :bus="bus">
        <Input
          :model="selectedTaxSetup.notes"
          :inputData="getInput('notes')"
          @updateModel="selectedTaxSetup.notes = $event"
          :isEdit="isEdit"
        />
      </EditorSection>
      <EditorSection
        :title="`About ${selectedTaxSetup.name || ''}`"
        sectionType="about"
        v-if="selectedTaxSetup._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedTaxSetup" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import INVENTORY_TYPES from "@/constants/InventoryTypes";
import {
  taxSetupInfoInputs,
  defaultSelectedTaxSetup,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "taxSetupEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      taxSetupInfoInputs,
      isEdit: false,
      defaultSelectedTaxSetup,
      taxSetups: [],
      taxSetupsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.TAXSETUP,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      inventoryTypes: INVENTORY_TYPES,
      selectedInventory: "",
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    BaseBtn,
  },
  mounted: function () {
    this.setPageTitle("TaxEditor");
    if (this.$route.query.id) {
      this.getTaxSetupById({
        _id: this.$route.query.id,
        positiveCallback: (entity) => {
          this.setPageTitle(`TaxEditor ${entity.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      const self = this;
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getTaxSetupById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      taxSetup: "taxSetupModule/taxSetup",
    }),
    selectedTaxSetup: {
      get() {
        this.setPageTitle("Tax Setup " + this.taxSetup.name);
        return this.prefillEntityBeforeRendering(this.taxSetup);
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getTaxSetupById: "taxSetupModule/getTaxSetupById",
      saveTaxSetup: "taxSetupModule/saveTaxSetup",
      deleteTaxSetup: "taxSetupModule/deleteTaxSetup",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getTaxSetups: "taxSetupModule/getTaxSetups",
    }),
    prefillEntityBeforeRendering(entity) {
      const taxType = _.find(
        Constants.listOfTaxTypes,
        (at) => at.code === entity.taxType
      );
      if (taxType) {
        entity.taxType = taxType;
      }

      if (entity.endDate && entity.endDate == "-") {
        entity.endDate = null;
      }

      return entity;
    },
    getInput(field) {
      return taxSetupInfoInputs.find((i) => i.id == field);
    },
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedTaxSetup._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedTaxSetup._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedTaxSetup.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedTaxSetup = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedTaxSetup[entity] = item;
    },
    addInventoryType() {
      if (
        !this.selectedTaxSetup.thcInventoryTypes.includes(
          this.selectedInventory
        )
      ) {
        this.selectedTaxSetup.thcInventoryTypes.push(this.selectedInventory);
      }
      this.selectedInventory = "";
    },
    removeInventory(inventory) {
      const index = this.selectedTaxSetup.thcInventoryTypes.indexOf(inventory);
      if (index > -1) {
        this.selectedTaxSetup.thcInventoryTypes.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_taxSetupEditor.scss";
</style>
