import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";

// import {
//   filterCriteria as msoCouponFilterCriteria,
//   searchColumnHeaders as msoCouponColumnHeaders,
// } from "@/views/MSOCoupon/inputs";

import {
  filterCriteria as msoCouponFilterCriteria,
  searchColumnHeaders as msoCouponColumnHeaders,
} from "../MSOCoupon/inputs";

// Page Header
export const pageHeader = {
  title: "Coupon",
  isEditorView: true,
  cancelPath: "coupons",
  addPath: "couponEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Coupons",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "couponEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    numCharsToSearch: 1,
    field: { type: "input", label: "name" },
    isPartial: true,
  },
  {
    label: "Accounting Code",
    code: "code",
    numCharsToSearch: 1,
    field: { type: "input", label: "code" },
    isPartial: true,
  },

  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Code",
    id: "code",
    size: "medium",
  },
  {
    id: "discountPercentage",
    title: "%",
  },
  {
    id: "discountAmount",
    title: "Discount Amount",
  },
  {
    id: "startDate",
    title: "Start Date",
    type: "date-extended"
  },
  {
    id: "endDate",
    title: "End Date",
    type: "date-extended"
  },
  {
    id: "applyDateRange",
    type: "checkbox",
    title: "Apply Date Range",
  },
  {
    id: "isOneTimeUse",
    type: "checkbox",
    title: "One Time Use",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    id: "discountPercentage",
    title: "%",
  },
  {
    id: "discountAmount",
    title: "Amount",
  },
  {
    id: "startDate",
    title: "Start Date",
  },
  {
    id: "endDate",
    title: "End Date",
  },
  {
    id: "applyDateRange",
    type: "checkbox",
    title: "Apply Date Range",
  },
  {
    id: "isOneTimeUse",
    type: "checkbox",
    title: "One Time Use",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Store",
    id: "store",
  },
];

export const couponInfoInputs = [
  {
    id: "msoCoupon",
    placeholder: "Select MSO Coupon",
    type: "search",
    title: "MSO Coupon",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.MSOCOUPON,
    columnHeaders: msoCouponColumnHeaders,
    filterCriteria: msoCouponFilterCriteria,
    filter: {
      "isActive": true,
      $and: [
        { "isArchived": false },
        { "isDeleted": false },
      ]
    },
  },
  {
    id: "name",
    placeholder: "Coupon Name",
    type: "input",
    title: "Coupon Name",
    focus: true,
  },
  {
    id: "code",
    placeholder: "Accounting Code",
    type: "input",
    title: "Accounting Code",
  },
  {
    id: "discountPercentage",
    placeholder: "Discount %",
    type: "number",
    title: "Discount %",
    suffix: "%",
    precision: Constants.numericInput.percentagePrecision,
  },
  {
    id: "discountAmount",
    type: "money",
    prefix: "$",
    title: "Discount Amount",
  },
  {
    id: "startDate",
    placeholder: "Start Date",
    type: "calendar",
    title: "Start Date",
  },
  {
    id: "endDate",
    placeholder: "End Date",
    type: "calendar",
    title: "End Date",
  },
  {
    id: "applyDateRange",
    type: "checkbox",
    title: "Apply Date Range",
  },
  {
    id: "isOneTimeUse",
    type: "checkbox",
    title: "One Time Use",
  },
  {
    id: "isForCannabisOnly",
    type: "checkbox",
    title: "Cannabis Only",
  },
  {
    id: "isActive",
    type: "checkbox",
    title: "Active",
  },
  {
    id: "minimumQuantity",
    type: "money",
    title: "Minimum Quantity",
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "maximumQuantity",
    type: "money",
    title: "Maximum Quantity",
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "minSpending",
    type: "money",
    title: "Min Order Spending",
    prefix: '$',
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "customerOnly",
    type: "checkbox",
    title: "Registered Customers Only",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const defaultSelectedCoupon = {
  _id: "NEW",
  name: "",
  discountPercentage: 0,
  discountAmount: 0,
  msoCoupon: undefined,
  startDate: "",
  endDate: "",
  applyDateRange: false,
  isOneTimeUse: false,
  notes: "",
  isActive: true,
  isArchived: false,
  isDeleted: false,
  isForCannabisOnly: false,
  goodFor: "",
  minimumQuantity: 0,
  maximumQuantity: 0,
  code: '',
  customerOnly: false,
  minSpending: 0,
};
