import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultQueue } from "@/views/Queue/inputs";

const state = {
  queue: JSON.parse(JSON.stringify(defaultQueue)),
  loadingStatus: {
    saveQueueCustomer: false,
    getQueue: false,
    getQueueCustomerById: false,
    deleteQueueCustomer: false,
    findCustomer: false,
  },
  queuePopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: { createdAt: -1 },
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setQueue: (state, payload) => {
    state.queue = payload;
  },
  setQueueCustomer: (state, payload) => {
    state.queue = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setQueuePopUp: (state, payload) => {
    state.queuePopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  queue: (state) => state.queue,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  queuePopUp: (state) => state.queuePopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveQueueCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveQueueCustomer", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveQueueCustomer,
      payload.customer,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveQueueCustomer", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          const errorMessagePayload = { text: error.message, type: "error" };
          if (error.errorCode === 1004) {
            errorMessagePayload.errorType = "no-workstation";
          }
          if (error.errorCode === 901) {
            errorMessagePayload.type = "state-error";
          }
          commit("setMessage", errorMessagePayload, { root: true });
        }
      }
    );
  },
  deleteQueueCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteQueueCustomer", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteQueueCustomer,
      payload.customer,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteQueueCustomer",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getQueue: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getQueue", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.getQueue}?`;
    if (state.selectedFilter) {
      if (state.selectedFilter.limit) {
        url += `&limit=${state.selectedFilter.limit}`;
      }
      if (state.selectedFilter.skip) {
        url += `&skip=${state.selectedFilter.skip}`;
      }
      if (state.selectedFilter.criteria) {
        url += `&filterCriteria=${state.selectedFilter.criteria.code}`;
      }
      if (state.selectedFilter.value) {
        url += `&filterValue=${state.selectedFilter.value}`;
      }
    }
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getQueue", status: false });
      var queue = [];

      if (response) {
        queue = response.data.body;
        commit("setQueue", queue);
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  findCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "findCustomer", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.findCustomer}`;
    HttpService.post(
      url,
      { filter: payload.filter },
      function (response, error) {
        commit("setLoadingStatus", { api: "findCustomer", status: false });
        if (response && response.data) {
          const customer = response.data.body[0];
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(customer);
          }
        }
      }
    );
  },
  getQueueCustomerById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getQueueCustomerById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getQueueCustomerById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getQueueCustomerById",
          status: false,
        });
        if (response?.data?.body) {
          commit("setQueueCustomer", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setQueuePopUp: ({ commit, state }, payload) => {
    commit("setQueuePopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
