import Constants from "@/resources/Constants";

export const inputs = [
  {
    id: "taxType",
    placeholder: "Tax Type",
    type: "select",
    options: Constants.listOfTaxTypes,
    title: "Tax Type",
  },
  {
    id: "date",
    placeholder: "Date",
    type: "calendar",
    title: "Date",
  },
  {
    id: "batch",
    placeholder: "",
    type: "input",
    title: "Batch #",
  },
  {
    id: "salePrice",
    placeholder: "",
    type: "money",
    title: "Sale Price",
    precision: 2,
    prefix: "$",
  },
  {
    id: "discount",
    placeholder: "",
    type: "money",
    title: "Discount",
    precision: 2,
    prefix: "$",
  },
  {
    id: "allInTax",
    type: "checkbox",
    title: "All-In Tax",
  },
];
