<template>
  <div class="discounts">
    <p>Click to add or remove Alpine rewards to this order</p>
    <div v-if="loyaltyDiscounts.length == 0">
        <div class="discounts__no_items">
          No discounts available
        </div>
      </div>
    <div class="discounts__grid">
      <div v-if="loyaltyDiscounts.length >= 0">
        <div class="discounts__item" @click="isSelected(disc) ? removeDiscount(disc) : addDiscount(disc)"
          :class="{ 'discounts__item--selected': isSelected(disc) }" v-for="disc in loyaltyDiscounts" :key="disc.id">
          <div class="discounts__item__title">{{ disc.pageTitle }}</div>
          <div class="discounts__item__details">
          </div>
        </div>
      </div>
    </div>
    <div class="discounts__actions">
      <BaseBtn @click="close" :label="'Close'" :colorType="3"/>
    </div>
  </div>
</template>

<script>

import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoyaltyDiscounts",
  data() {
    return {
      isBlurred: false,
      Constants: Constants,
      loyaltyDiscounts: [],
      selectedDiscounts: []
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  components: {
    BaseBtn,
  },
  created: function () {
    this.getAvailableDiscounts({
      positiveCallback: (result) => {
        this.loyaltyDiscounts = result?.discounts ?? [];
      },
      negativeCallback: (error) => {
        console.log(error);
      },
      body: { customer: this.customer, order: this.posOrder },
    });

    this.setSelectedDiscounts(this.posOrder)
  },
  computed: {
    ...mapGetters({
      getPosOrder: "orderModule/posOrder",
      currentStore: "storeModule/currentStore",
    }),
    posOrder() {
      return this.getPosOrder;
    },
  },
  methods: {
    ...mapActions({
      setDiscountsPopUp: "posModule/setDiscountsPopUp",
      applyDiscountToOrder: "orderModule/applyDiscountToOrder",
      removeDiscountFromOrder: "orderModule/removeDiscountFromOrder",
      getAvailableDiscounts: "loyaltyModule/getAvailableDiscounts",
      getCouponById: "couponModule/getCouponById",
    }),
    isSelected(discount) {
      return this?.selectedDiscounts?.some(disc => discount.id === disc.id || discount.posDiscountID === disc.alpineDiscount?.couponId);
    },
    async addDiscount(discount) {
      const couponId = discount.posDiscountID
      this.getCouponById({
        _id: couponId,
        positiveCallback: async (result) => {
          const amountDiscountedInDollars = Number(result.discountAmount ?? 0)
          const alpineDiscount = {...discount, couponId: result._id.toString() }
          const coupon = { alpineDiscount, ...result, type: 'alpine-discount', amountDiscountedInDollars};
          const order = await this.applyDiscountToOrder({
            discount: coupon,
            orderId: this.posOrder._id,
            order: this.posOrder,
          })
          this.setSelectedDiscounts(order)
        }
      });
    },
    close(){
      this.setDiscountsPopUp(false);
    },
    async removeDiscount(discount) {
      const disc = this.posOrder.discountsApplied.find(disc => discount.id === disc.id || discount.posDiscountID === disc.alpineDiscount?.couponId);
      const order = await this.removeDiscountFromOrder({
        discountId: disc._id,
        orderId: this.posOrder._id,
        order: this.posOrder,
      });
      this.setSelectedDiscounts(order)
    },
    setSelectedDiscounts(order){
      this.selectedDiscounts = order?.discountsApplied ?? [];
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_loyaltyDiscounts.scss";
</style>
