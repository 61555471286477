export const normalizeEntityBeforeSaving = (store) => {
  const entity = JSON.parse(JSON.stringify(store));
  if (entity.state && entity.state.abbreviation) {
    entity.state = entity.state.abbreviation;
  }
  if (entity.traceabilitySystem && entity.traceabilitySystem.code) {
    entity.traceabilitySystem = entity.traceabilitySystem.code;
  }
  if (entity.endOfFiscalYearMonth && entity.endOfFiscalYearMonth.code) {
    entity.endOfFiscalYearMonth = entity.endOfFiscalYearMonth.code;
  }
  if (entity.endOfFiscalYearDay && entity.endOfFiscalYearDay.code) {
    entity.endOfFiscalYearDay = entity.endOfFiscalYearDay.code;
  }
  if (entity.defaultDisposalRoom === "") {
    entity.defaultDisposalRoom = undefined;
  }
  if (
    entity.defaultDisposalRoom &&
    Object.keys(entity.defaultDisposalRoom).length === 0
  ) {
    entity.defaultDisposalRoom = undefined;
  }
  return entity;
};
