import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedTaxSetup } from "@/views/TaxSetup/inputs";
const state = {
  allActive: [],
  taxSetups: [],
  taxSetup: JSON.parse(JSON.stringify(defaultSelectedTaxSetup)),
  loadingStatus: {
    saveTaxSetup: false,
    getTaxSetups: false,
    getTaxSetupById: false,
    deleteTaxSetup: false,
  },
  taxSetupPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setTaxSetups: (state, payload) => {
    state.taxSetups = payload;
  },
  setTaxSetup: (state, payload) => {
    if (payload._id === "NEW")
      state.taxSetup = JSON.parse(JSON.stringify(defaultSelectedTaxSetup));
    else state.taxSetup = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setTaxSetupPopUp: (state, payload) => {
    state.taxSetupPopUp = payload;
  },
  setAllActive: (state, payload) => {
    state.allActive = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  taxSetups: (state) => state.taxSetups,
  taxSetup: (state) => state.taxSetup,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  taxSetupPopUp: (state) => state.taxSetupPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  allActive: (state) => state.allActive,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveTaxSetup: ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingStatus", { api: "saveTaxSetup", status: true });
      HttpService.post(
        Apis.backendBaseUrl + Apis.taxSetup.save,
        payload.taxSetup,
        function (response, error) {
          commit("setLoadingStatus", {
            api: "saveTaxSetup",
            status: false,
          });
          if (response) {
            commit(
              "setMessage",
              { text: "Saved", type: "success" },
              { root: true }
            );
            if (payload.positiveCallback) {
              payload.positiveCallback(response.data.body);
            }
            resolve(response.data);
          } else {
            console.log(error);
            reject(error);
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
          }
        }
      );
    });
  },
  deleteTaxSetup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteTaxSetup",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteTaxSetup,
      payload.taxSetup,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteTaxSetup",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getAllActiveTaxes: ({ commit }) => {
    return new Promise((resolve, reject) => {
      let url = `${Apis.backendBaseUrl}${Apis.taxSetup.getAllActive}`;
      HttpService.get(url, function (response, error) {
        let taxSetups = [];
        if (response) {
          taxSetups = response.data.body;
        }
        commit("setAllActive", taxSetups);
        resolve(taxSetups);
      });
    });
  },
  getTaxSetupById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getTaxSetupById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getTaxSetupById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getTaxSetupById",
          status: false,
        });
        if (response) {
          commit("setTaxSetup", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setTaxSetupPopUp: ({ commit, state }, payload) => {
    commit("setTaxSetupPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  partialTaxSave: (_, payload) => {
    return new Promise((resolve, reject) => {
      const url = `${Apis.backendBaseUrl}${Apis.taxSetup.partialSave}`;
      HttpService.post(url, payload, function (_, err) {
        if (err) {
          reject(err);
        }

        resolve();
      });
    });
  },
  filterTaxesForTest: (_, payload) => {
    return new Promise((resolve, reject) => {
      const url = `${Apis.backendBaseUrl}${Apis.taxSetup.filterTaxesForTest}`;
      HttpService.post(url, payload, function (response, err) {
        if (err) {
          reject(err);
        }
        const taxes = response.data;
        resolve(taxes);
      });
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
