<template>
  <div>
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered gift-card">
      <div class="gift-card__header">Gift Card</div>
      <div class="gift-card__body">
        <div class="input-method" v-if="!showBalance">
          <div
            class="input-method-option"
            @click="isMultiple = false"
            :class="{ 'input-method-option--selected': !isMultiple }"
          >
            Single
          </div>
          <div
            class="input-method-option"
            @click="isMultiple = true"
            :class="{ 'input-method-option--selected': isMultiple }"
          >
            Multiple
          </div>
        </div>
        <div v-if="isMultiple">
          <BaseInput
            class="gift-card__number"
            label="Start Card #"
            controlType="number"
            placeholder="000-000-000"
            v-model="barcode"
            :applyFocus="true"
          />
          <BaseInput
            class="gift-card__number"
            label="End Card #"
            controlType="number"
            placeholder="000-000-000"
            v-model="barcodeEnd"
          />
        </div>

        <BaseInput
          v-else
          class="gift-card__number"
          label="Card #"
          controlType="number"
          placeholder="000-000-000"
          v-model="barcode"
          :applyFocus="true"
        />
        <div class="gift-card__amount" v-if="showBalance">
          <div>Amount:</div>
          <div>{{ formattedValue }}</div>
        </div>
      </div>
      <div class="gift-card__footer">
        <BaseBtn label="Cancel" :colorType="3" @click="cancel" />
        <BaseBtn
          label="Save"
          :disabled="disableSave"
          @click="save"
          v-if="!showBalance"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Input/Base.vue";
import Utilities from "@/lib/Utilities";
import BaseBtn from "@/components/Base/Button/Button.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      barcode: "",
      amount: null,
      showBalance: true,
      isValid: false,
      isMultiple: false,
      barcodeEnd: "",
    };
  },
  components: {
    BaseInput,
    BaseBtn,
  },
  computed: {
    ...mapGetters({
      getGiftCardPopUp: "posModule/giftCardPopUp",
    }),
    disableSave() {
      if (!this.barcode) return true;

      if (this.isMultiple) {
        if (!this.barcodeEnd) {
          return true;
        }
      }

      if (!this.showBalance) {
        return false;
      }

      return false || !this.isValid;
    },
    formattedValue() {
      if (!this.amount) return "-";

      return Utilities.formatPrice(this.amount);
    },
  },

  watch: {
    barcode: _.debounce(function (newVal) {
      this.amount = null;
      if (this.showBalance) {
        this.getGiftCard(newVal);
      }
    }, 500),
  },
  methods: {
    ...mapActions({
      setGiftCardPopUp: "posModule/setGiftCardPopUp",
      performgetGiftCard: "giftCardModule/filter",
      setMessage: "setMessage",
    }),
    handleNegativeAnswer() {
      this.setGiftCardPopUp({ isOpen: false });
    },
    cancel() {
      this.handleNegativeAnswer();
    },
    handleGiftCardBalanceLookUp(giftCard) {
      if (!giftCard) {
        this.setMessage({
          text: `Gift Card does not exists`,
          type: "error",
        });
        return;
      }

      this.amount = giftCard.memoBalance;
    },
    handleGiftCardBuying(giftCard) {
      if (!giftCard) {
        this.isValid = true;
        return;
      }

      const isArchived = giftCard.isArchived;
      if (!isArchived) {
        this.setMessage({
          text: `A gift card with this barcode number was previously purchased by another customer. Please enter a new barcode number`,
          type: "error",
        });
        this.isValid = false;
        return;
      }
      this.isValid = true;
    },
    async getGiftCard() {
      this.isValid = false;
      let giftCard = await this.getGiftCardValue(this.barcode);

      if (this.showBalance) {
        this.handleGiftCardBalanceLookUp(giftCard);
      } else {
        this.handleGiftCardBuying(giftCard);
      }
    },
    async getGiftCardValue(barcode) {
      let giftCard = await this.performgetGiftCard({
        filter: { giftCard: barcode, isArchived: false },
        limit: 1,
      });
      giftCard = giftCard?.[0] || null;
      return giftCard;
    },
    async save() {
      if (this.isMultiple) {
        if (this.barcodeEnd <= this.barcode) {
          this.setMessage({
            text: `The last barcode # needs to be greater than the start barcode #`,
            type: "error",
          });
          return true;
        }

        const count = this.barcodeEnd - this.barcode + 1;
        for (let i = 0; i < count; i++) {
          const barcode = parseInt(this.barcode) + i;
          const giftCard = await this.getGiftCardValue(barcode);
          if (giftCard) {
            this.setMessage({
              text: `This giftcard #${barcode} has been already purchased! Please change giftcard.`,
              type: "error",
            });
            return;
          }
        }
      } else {
        const giftCard = await this.getGiftCardValue(this.barcode);
        if (giftCard) {
          this.setMessage({
            text: `This giftcard #${this.barcode} has been already purchased! Please change giftcard.`,
            type: "error",
          });
          return;
        }
      }
      this.getGiftCardPopUp.positiveCallback(this.barcode, this.barcodeEnd);
    },
  },
  beforeMount() {
    const properties = this.getGiftCardPopUp;
    this.showBalance = properties.isBalanceLookUp;
  },
};
</script>

<style lang="scss" scoped>
@import "./_giftCard.scss";
</style>
