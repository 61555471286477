import Entities from "@/resources/Entities";

const customerSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "Clear Points",
        key: "customer-clear-points",
      },
      {
        title: "Add Points",
        key: "customer-add-points",
      },
      {
        title: "View Journal Entries",
        children: [
          {
            title: "All",
            key: "journal-entries-all",
          },
          {
            title: "Payments",
            key: "journal-entries-payments",
          },
        ],
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Customer Sale History",
        key: "reports-customer-sale-history",
      },
      {
        title: "Customer Discount History ",
        key: "reports-customer-discount-usage",
      },
    ],
  },
];

const creditMemoSubMenulist = [
  {
    title: "Options",
    children: [
      {
        title: "Create CCP",
        key: "credit-memo-create-ccp",
      },
    ],
  },
];

const cashReconciliationSubMenulist = [
  {
    title: "Options",
    children: [
      {
        title: "Update User Cash Count",
        key: "cash-reconciliation-update-cash-count",
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Station Reconciliation",
        children: [
          {
            title: "Details",
            key: "cash-reconciliation-details",
          },
          {
            title: "Summary",
            key: "cash-reconciliation-summary",
          },
        ],
      },
    ],
  },
];

const groupSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "Set Receipt Order",
        key: "group-order-receipt",
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Group Listing ",
        key: "group-listing",
      },
    ],
  },
];

const onlineStoreGroupList = [
  {
    title: "Options",
    children: [
      {
        title: "Set Group Order",
        key: "set-group-order",
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Online Group Listing ",
        key: "group-listing-online",
      },
    ],
  },
];

const ordersSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "View Journal Entries",
        children: [
          {
            title: "All",
            key: "journal-entries-all",
          },
          {
            title: "Payments",
            key: "journal-entries-payments",
          },
        ],
      },
    ],
  },
];

const productsOrderSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "Merge This Product Into",
        key: "mergeProductCodes",
      },
      {
        title: "Change Group Id",
        key: "changeGroupCode",
      },
      {
        title: "Duplicate Product Records with New Supplier Id",
        key: "duplicateProductWithNewSupplier",
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Sale Pricing Listing ",
        key: "sale-pricing-listing",
      },
    ],
  },
];

const purchaseOrderSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "View Journal Entries",
        key: "journal-entries-all",
      },
    ],
  },
];
const supplierSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "Duplicate Supplier Record",
      },
      {
        title: "View Journal Entries",
        children: [
          {
            title: "All",
            key: "journal-entries-all",
          },
        ],
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Supplier Listing",
        key: "supplier-listing",
      },
      {
        title: "Supplier Promotions",
        key: "supplier-promotion-supplier",
      },
      {
        title: "Supplier Promotions By Group",
        key: "supplier-promotion-supplier-group",
      },
      {
        title: "Supplier Promotions By Group and Product",
        key: "supplier-promotion-supplier-group-product",
      },
    ],
  },
];

const supplierAgentSubMenuList = [
  {
    title: "Options",
    children: [

    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Supplier Agent Listing ",
        key: "supplier-agent-listing",
      },
    ],
  },
];

const itemSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "Change Product Id On Purchase Order",
        key: "changeProductCode",
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Item Count For This Batch",
        key: "reports-inventory-system-check-item",
      },
      {
        title: "Working Inventory - Low Stock",
        key: "reports-inventory-working-count",
      },
    ],
  },
];

const journalSubMenuList = [
  {
    title: "Options",
    children: [
      {
        title: "Post All Manual Entries",
        key: "postAllManualEntries",
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Journal Batch Balance Check",
        key: "reports-finance-journal-batch-check",
      },
      {
        title: "DF Support Staff",
        children: [
          {
            title: "Inventory System Check",
            key: "reports-inventory-system-check",
          },
          {
            title: "Inventory Pre Order Check",
            key: "reports-inventory-system-check-quick",
          },
          {
            title: "Tax System Check",
            key: "reports-tax-system-check",
          },
        ],
      },
    ],
  },
];
const chartOfAccountSubMenuList = [
  {
    title: "Options",
    children: [

    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Chart Of Account Listing",
        key: "chart-of-account-listing",
      },
    ],
  },
];
const couponSubMenulist = [
  {
    title: "Options",
    children: [

    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Active Coupon Listing",
        key: "coupon-listing-active",
      },
    ],
  },
];

const employeeSubMenuList = [
  {
    title: "Options",
    children: [

    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Employee Listing",
        key: "employee-listing-active",
      },
      {
        title: "Employee Notes",
        key: "employee-notes",
      },
      {
        title: "Employee Hours",
        key: "employee-hours",
      },
    ],
  },
];

const specialPricingSubMenulist = [
  {
    title: "Options",
    children: [

    ],
  },
  {
    title: "Reports",
    children: [
      {
        title: "Special Pricing Listing",
        key: "special-pricing-listing",
      },
    ],
  },
];

export const entitySubMenuList = {
  [Entities.EMPLOYEE]: employeeSubMenuList,
  [Entities.GROUP]: groupSubMenuList,
  [Entities.CUSTOMER]: customerSubMenuList,
  [Entities.PRODUCT]: productsOrderSubMenuList,
  [Entities.ITEM]: itemSubMenuList,
  [Entities.ONLINESTOREGROUP]: onlineStoreGroupList,
  [Entities.ORDER]: ordersSubMenuList,
  [Entities.ORGANIZATION]: [],
  [Entities.PURCHASEORDER]: purchaseOrderSubMenuList,
  [Entities.ROOM]: [],
  [Entities.SPECIALPRICING]: specialPricingSubMenulist,
  [Entities.STORE]: [],
  [Entities.SUPPLIER]: supplierSubMenuList,
  [Entities.MSOGROUP]: [],
  [Entities.MSOSUPPLIER]: [],
  [Entities.MSOPRODUCT]: [],
  [Entities.MSOCHARTOFACCOUNT]: [],
  [Entities.CHARTOFACCOUNT]: chartOfAccountSubMenuList,
  [Entities.SUPPLIERAGENT]: supplierAgentSubMenuList,
  [Entities.CREDITMEMO]: creditMemoSubMenulist,
  [Entities.COUPON]: couponSubMenulist,
  [Entities.MSOCOUPON]: [],
  [Entities.PAYMENT]: [],
  [Entities.TAXSETUP]: [],
  [Entities.STATEINVENTORY]: [],
  [Entities.QUEUE]: [],
  [Entities.FLIGHT]: [],
  [Entities.JOURNAL]: journalSubMenuList,
  [Entities.CASHRECONCILIATION]: cashReconciliationSubMenulist,
};
