export const checkInInputs = [
  {
    id: "pin",
    type: "input",
    title: "Employee #",
  },
  {
    id: "name",
    type: "text",
    title: "Employee Name",
  },
  {
    id: "cashDrawer",
    type: "select",
    title: "Cash Drawer #",
    options: ["1", "2"],
  },
  {
    id: "date",
    type: "date",
    title: "Date & Time",
    disabled: true,
    showTime: true,
  },
];
