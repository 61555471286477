<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Customer Sale History"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'salesReport', label: 'Sales' },
            {
              name: 'salesOrderStatusCustomerId',
              label: 'Customer Sale History',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByTime="true"
          @submit-filter="performGetReport"
          :isLoading="salesTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr><td colspan="3">Customer Sale History</td></tr>
                <tr><td colspan="3">{{ reportData.total.datePeriod }}</td></tr>
                <tr><td colspan="3">{{ reportData.total.customer }}</td></tr>
                <tr><td> </td></tr><tr><td> </td></tr>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Order</th>
                  <th align="left">Batch #</th>
                  <th align="right">Grams</th>
                  <th align="left">Product</th>
                  <th align="right">Price</th>
                  <th align="right">Quantity</th>
                  <th align="right">Sub Total</th>
                  <th align="right">Tax</th>
                  <th align="right">Delivery</th>
                  <th align="right">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                  <td>{{ record.date }}</td>
                  <td>{{ record.orderCode }}</td>
                  <td class="batch">{{ record.batchId }}</td>
                  <td align="right">{{ record.cannabisGramsUnit }}</td>
                  <td>{{ record.name }}</td>
                  <td align="right">{{ Utilities.formatPrice(record.price) }}</td>
                  <td align="right">{{ record.quantity }}</td>
                  <td align="right">{{ Utilities.formatPrice(record.lineTotal) }}</td>
                  <td align="right">{{ Utilities.formatPrice(record.tax) }}</td>
                  <td align="right">{{ Utilities.formatPrice(record.freight) }}</td>
                  <td align="right">{{ Utilities.formatPrice(record.total) }}</td>

                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="6">Total</td>
                <td align="right">{{ reportData.total.quantity }}</td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.lineTotal) }}</td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.tax) }}</td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.freight) }}</td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.total) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";
  import ReportHelper from "@/lib/report/index.js";

    export default {
      data() {
        return {
          reportData: undefined,
          Utilities,
          reportType: undefined,
          selectedPeriod: undefined,
          selectedReportFor: undefined,
          selectedReportSaleType: undefined,
          filterText: undefined,
        };
      },
      components: {
        PageHeader,
        ReportFilter,
      },
      created: function () {
        this.setPageTitle("Customer Sale History");
      },
      mounted: function () {
        const today = new Date();
        this.selectedPeriod = {
          from: new Date(new Date().setDate(today.getDate() - 7)),
          to: today,
        };
      },
      computed: {
        ...mapGetters({
          loadingStatus: "reports/loadingStatus",
          salesTransactions: "reports/salesTransactions",
      }),
      },
      methods: {
        ...mapActions({
          getSalesTransactions: "reports/getSalesTransactions",
        }),
        performGetReport(
          filter
        ) {
          let reportType = "salesOrderStatusCustomerId";
          this.getSalesTransactions({
            reportType,
            ...filter,
            positiveCallback: (data) => {
              this.reportData = data;
            },
          });
        },
        exportToCsv() {
          var table = document.getElementById("table-report");
          ReportHelper.exportToExcel(table, "customerSaleHistory");
        },
      },
    };
    </script>

  <style lang="scss">
  @import "./_salesOrderStatusCustomerId.scss";
  </style>