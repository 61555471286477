const listOfSrnInitials = ['qp.', 'mqp.', 'op.', 'cg.', 'mcg.']

export const parseSrn = (srn) => {
  if (!srn) return srn;

  return srn.split(",").pop().trim().toUpperCase();
};

export const isSrn = (maybeSrn) => {
  if (!maybeSrn) return false;
  if(typeof maybeSrn !== 'string') return false
  if( maybeSrn.length < 11) return false

  for(let acceptedInitial of listOfSrnInitials) {
    if(maybeSrn.toLowerCase().startsWith(acceptedInitial)) {
      return true
    }
  }

  return false
};
