<template>
  <div class="file-uploader">
    <div class="default-image-preview" :style="image ? `background-image: url('${image}')` : ''">
      <input :id="id" type="file" :accept="allowedTypes" class="custom-file-input" @change="(e) => {
          $emit('change', e, id);
          e.target.value = '';
        }
        " :disabled="disabled" />
      <label class="file-uploader__upload-button" :for="id">
        <font-awesome-icon v-if="!image" class="default-image-preview__icon-left" :icon="['fas', 'upload']" />
      </label>
      <button v-if="image" @click="$emit('remove-image')" class="file-uploader__remove-image-button" :disabled="disabled">
        <font-awesome-icon class="default-image-preview__icon-left" :icon="['fas', 'times']" />
      </button>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "FileUploader",
  props: {
    label: {
      type: String,
    },
    allowedTypes: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  components: {},
};
</script>

<style lang="scss">
.file-uploader {
  position: relative;
  display: inline-block;
  overflow: hidden;

  &__add-button {
    margin-top: 10px;
  }

  &__upload-button {
    cursor: pointer;
    padding: 10px;
    font-size: 1.8rem;

    &:hover {
      color: $dark-accent-color;
    }
  }

  &__remove-image-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 100%;
    background-color: $dark-text-color-1;
    color: white;
    height: 20px;
    width: 20px;
    cursor: pointer;

    &:hover {
      background-color: rgba($dark-danger-color, 0.8);
    }
  }

  @include respond(phone) {
    width: 100%;
  }
}

.light .file-uploader {
  &__upload-button {
    &:hover {
      color: $light-accent-color;
    }
  }

  &__remove-image-button {
    &:hover {
      background-color: rgba($light-danger-color, 0.8);
    }
  }
}

.file-uploader input[type="file"] {
  cursor: pointer;

  @include respond(phone) {
    width: 100%;
  }
}

.image-preview {
  width: 280px;
  border-radius: 2px;
}

.default-image-preview {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-elevated-0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  border-radius: 5px;

  input[type="file"] {
    display: none;
  }
}

.light .default-image-preview {
  background-color: $light-elevated-0;
}
</style>
