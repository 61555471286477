<template>
  <div class="journal-entries">
    <search-modal
      :showSearchModal="showSearchModal"
      @toggle-modal="$emit('toggle-modal')"
      :entity="entity"
      :filterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
      :type="type"
      :title="title"
      :filter="filter"
      :sort="sort"
      :hideFilter="hideFilter"
    />
  </div>
</template>

<script>
import Entities from "@/resources/Entities";
import { filterCriteria, columnHeaders } from "./inputs";

export default {
  name: "JournalEntries",
  props: {
    showSearchModal: Boolean,
    type: {
      type: String,
      default: "all",
    },
    filter: {
      type: Object,
    },
    sort: {
      type: Object,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterCriteria,
      columnHeaders,
      entity: Entities.JOURNAL,
    };
  },
  mounted: function () {},
  computed: {
    title() {
      switch (this.type) {
        case "purchaseOrder":
          return "Journal Entries: Purchase Order - All";
        case "supplier":
          return "Journal Entries: Supplier - All";
        case "order":
          return "Journal Entries: Order - All";
        case "orderPayments":
          return "Journal Entries: Order - Payments";
        case "customer":
          return "Journal Entries: Customer - All";
        case "customerPayments":
          return "Journal Entries: Customer - Payments";
        default:
          return undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_journal-entries.scss";
</style>
