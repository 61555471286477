import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const DEFAULT_SCHEDMA_GIFT_CARD = {
  _id: "NEW",
  ccpExpirationDate: null,
  ccpStartDate: null,
  pointQuantity2: 0,
  pointQuantity: 0,
  notes: "",
  discountAmountRefund: 0,
  memoBalance: 0,
  memoAmountUsed: 0,
  memoTotal: 0,
  memoType: "Gift Card",
  expenseAccountCode: null,
  accountNo: "",
  isArchived: false,
  isPostedToJournal: false,
  postedToJournalAt: null,
  archivedAt: null,
  item: null,
  order: null,
  purchaseOrder: null,
  supplier: null,
  customer: null,
  store: null,
  organization: null,
  createdBy: null,
  updatedBy: null,
  enteredDate: null,
  createdAt: new Date(),
};

const state = {
  giftCards: [],
  giftCard: { ...DEFAULT_SCHEDMA_GIFT_CARD },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setGiftCards: (state, payload) => {
    state.giftCards = payload;
  },
  setGiftCard: (state, payload) => {
    if (payload._id == "NEW") {
      state.giftCard = { ...DEFAULT_SCHEDMA_GIFT_CARD };
    } else {
      state.giftCard = payload;
    }
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  getGiftCards: (state) => state.giftCards,
  getGiftCard: (state) => state.giftCard,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveGiftCard: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.giftCard.save,
      payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  filter: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.giftCard.filter,
        payload,
        function (response, error) {
          if (response) {
            resolve(response.data.body);
          } else {
            console.log(error);
            reject(error);
          }
        }
      );
    });
  },
  getGiftCardById: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl + Apis.giftCard.getById + "?id=" + payload._id,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit("setGiftCard", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  getGiftCard: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl + Apis.giftCard.get + "?barcode=" + payload.barcode,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            resolve(data);
          } else {
            reject(error);
          }
        }
      );
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
