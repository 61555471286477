<template>
  <div class="counter">
    <div class="items-list">
      <div class="item" :class="{ 'item--selected': item._id == selectedItemId }" v-for="item of items" :key="item._id"
        @click="itemSelectedHandler(item)">
        <div class="item__left">
          <p class="item-name">{{ item.externalFields.product.name }}</p>
          <p class="item-extra">
            {{ extra(item.externalFields) }}
          </p>
          <p class="item-batch">
            <!-- <span>Batch: </span> -->
            <span>{{ item.batchId }}</span>
          </p>
        </div>
        <div class="item-grams" v-if="item.externalFields.product.cannabisGramsUnit">
          {{ item.externalFields.product.cannabisGramsUnit }}g
        </div>
      </div>
    </div>
    <div class="overview" v-canAccess="managementRoles"
      v-if="viewAll && itemSelected && itemSelected.employees.length > 0">
      <p class="overview__title">Count Overview</p>
      <div class="overview__row" v-for="(employee, index) of itemSelected.employees" :key="index">
        <p class="overview__row__name">{{ employee.name }} </p>
        <p class="overview__row__value">{{ employee.handQuantity }}</p>
      </div>
    </div>
    <div class="action">
      <p class="action-title">Quantity</p>
      <div class="quantity">
        <BaseBtn class="quantity-action" :disabled="false" iconGroup="fas" iconName="minus" @click="reduce" :colorType="1"
          size="sm" />

        <vue-numeric ref="quantity" @keyup.enter="update" class="input-text__field" :precision="0"
          v-model="quantity"></vue-numeric>

        <BaseBtn class="quantity-action" :disabled="false" iconGroup="fas" iconName="plus" @click="increase" size="sm" />
      </div>
    </div>
    <BaseBtn class="update" label="Update" @click="update" />
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapGetters } from 'vuex';
import VueNumeric from "vue-numeric";
import {
  managementRoles,
} from "@/constants/roles";

export default {
  components: {
    BaseBtn,
    VueNumeric
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    allItems: {
      type: Array,
      default: () => []
    },
    viewAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItemId: null,
      quantity: 0,
      managementRoles
    }
  },
  watch: {
    selectedItemId(newVal, oldVal) {
      this.updateQuantity()
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'loginModule/user'
    }),
    itemSelected() {
      return this.allItems.find(i => i._id == this.selectedItemId);
    },
    userId() {
      return this.getUser._id;
    },
  },
  methods: {
    extra(externalFields) {
      const info = [];
      if (externalFields.group.name) {
        info.push(externalFields.group.name)
      }

      if (externalFields.supplier.name) {
        info.push(externalFields.supplier.name)
      }

      return info.join(' - ')
    },
    increase() {
      this.quantity++;
    },
    reduce() {
      if (this.quantity != 0) {
        this.quantity--;
      }
    },
    update() {
      let quantity = this.quantity;
      let initialTotAcrossEmployees = 0;
      let currentEmployeeHandCount = 0;
      if (this.viewAll) {
        // if viewAll true, then calculate the difference since they can modify the total across multiple employees
        this.itemSelected.employees.forEach(e => {
          initialTotAcrossEmployees += e.handQuantity
        });

        const currentEmployee = this.itemSelected.employees.find(e => e._id == this.userId);
        if (currentEmployee) {
          currentEmployeeHandCount = currentEmployee.handQuantity
        }
      }

      const diff = quantity - initialTotAcrossEmployees;

      this.$emit('update', {
        itemId: this.selectedItemId,
        handQuantity: diff + currentEmployeeHandCount
      })
    },
    itemSelectedHandler(item) {
      this.selectedItemId = item._id
    },
    updateQuantity() {
      this.quantity = 0;
      if (!this.selectedItemId) return;
      if (!this.itemSelected) return;

      this.quantity = this.calculateQuantity(this.itemSelected.employees);
    },
    calculateQuantity(employees) {
      let tot = 0;
      if (!employees || employees.length == 0) return tot;

      if (this.viewAll) {
        employees.forEach(e => {
          tot += e.handQuantity
        });
        return tot;
      }

      const currentEmployee = employees.find(e => e._id == this.userId);
      if (!currentEmployee) return tot;
      return currentEmployee.handQuantity
    }
  },
  mounted() {
    this.$refs.quantity.$el.focus();
    if (this.items && this.items.length > 0) {
      this.selectedItemId = this.items[0]._id;
      this.updateQuantity()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './counter';
</style>