<template>
  <div class="queue-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :canEdit="false"
        :hideAddButton="true"
        :canArchive="false"
        :selectedEntity="selectedQueue"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'queues', label: 'Queue' },
          {
            name: 'queueEditor',
            label: `${selectedQueue.customerInfo.name} ${selectedQueue.customerInfo.lastName}`,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div
              class="
                grid-two-column
                two-columns-layout--responsive-horizontal-space-10
              "
            >
              <div
                v-for="input in queueInputs"
                :key="input.id"
                class="input-text"
              >
                <Input
                  :model="lodash.get(selectedQueue, input.id)"
                  :inputData="input"
                  :isEdit="isEdit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditorSection
        :title="`About ${selectedQueue.name || ''}`"
        sectionType="about"
        v-if="selectedQueue._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedQueue" />
      </EditorSection>
      <!-- <DangerZone
        v-if="selectedQueue._id !== 'NEW'"
        @delete="setMessagePopUp({isOpen: true, title: Constants.strings.warningMessageTitle, message: Constants.strings.deletionConfirmation, positiveCallback: performDelete})"
      /> -->
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";
import _ from "lodash";

import {
  queueInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
} from "./inputs";
import EditorSection from "@/components/EditorSection";

export default {
  name: "queueEditor",
  data() {
    return {
      lodash: _,
      Constants: Constants,
      queueInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.QUEUE,
      pageHeader,
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
  },
  mounted: function () {
    this.setPageTitle("Queue");
    if (this.$route.query.id) {
      this.getQueueCustomerById({ _id: this.$route.query.id });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getQueueCustomerById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "queueModule/loadingStatus",
      queue: "queueModule/queue",
    }),
    selectedQueue: {
      get() {
        this.setPageTitle(`Queue ${this.queue._id}`);
        return this.queue;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getQueueCustomerById: "queueModule/getQueueCustomerById",
      saveQueue: "queueModule/saveQueue",
      deleteQueue: "queueModule/deleteQueue",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "queueModule/getAdjacentQueue",
    }),
    performSave() {
      if (this.loadingStatus.saveQueue) {
        return;
      }

      const queueToSave = this.normalizeEntityBeforeSaving(this.selectedQueue);

      this.saveQueue({
        queue: queueToSave,
        positiveCallback: (queue) => {
          this.setPageTitle(`Queue ${queue.name}`);
          this.selectedQueue = queue;
          if (this.selectedQueue._id === "NEW") {
            router.push({
              name: "queueEditor",
              query: { id: queue._id },
            });
          }
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(queue) {
      const entity = JSON.parse(JSON.stringify(queue));
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteQueue) {
        return;
      }

      const queueToDelete = this.normalizeEntityBeforeSaving(
        this.selectedQueue
      );

      this.deleteQueue({
        queue: queueToDelete,
        positiveCallback: () => {
          router.push({
            name: "queue",
          });
        },
      });
    },
    addQueue() {
      this.isEdit = true;
      this.selectedQueue = this.defaultSelectedQueue;
      this.selectedQueue._id = "NEW";
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedQueue._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedQueue._id === "NEW") {
        this.isEdit = true;
      }
    },
  },
};
</script>
