<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Employee Notes"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'employeeReport', label: 'Employee' },
            {
              name: 'employeeNotes',
              label: 'Employee Notes',
            },
          ]"
          @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print"
        :filterByTime="true"
        @submit-filter="performGetReport"
        :isLoading="isLoading"
        :percentage="percentageCompleted"
        :filterBySaleType="false"
        :filterByStoreOrOrg="false" />
      <div class="report-container">
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Employee</th>
                <th align="left">Updated</th>
                <th align="left">Updated By</th>
                <th align="right">Hours</th>
                <th align="left">Note Type</th>
                <th align="left">Note</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(data, index) of formattedData" :key="index" :class="{ total2: data.isTotal }">
                <td v-if="!data.isTotal">{{ data.thisEmployee }}</td>
                <td v-else>Totals</td>
                <td>{{ data.date }}</td>
                <td>{{ data.noteUpdated }}</td>
                <td align="right"> {{ data.hours }}</td>
                <td>{{ data.noteType }}</td>
                <td>{{ data.thisNote }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="3" align="left">Grand Total</td>
              <td align="right"> {{ reportData.total.hours }}</td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Employee Notes");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
    formattedData() {
      const data = this.reportData;
      let rows = []
      for (let [employee, employeeValue] of Object.entries(data.list)) {
        for (let [noteId, note] of Object.entries(employeeValue.notes)) {
          rows.push(note)
        }
        rows.push({ ...employeeValue.total, isTotal: true })
      }

      return rows;
    }
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "employeeNotes";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "employeeNotes");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
  @import "./_employeeNotes.scss";