<template>
  <router-view class="base-page"></router-view>
</template>

<script>
export default {
  name: "pageBase",
  components: {},
};
</script>

<style scoped lang="scss">
.page-base {
  width: 100%;
  overflow-y: auto !important;
  max-height: calc(100%);
  height: calc(100%);
  min-height: calc(100%);

  @include respond(tab-port) {
    max-height: calc(100% - 4rem);
    height: calc(100% - 4rem);
    min-height: calc(100% - 4rem);
  }
}
</style>
