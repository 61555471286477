<template>
  <div class="padded elevated-0 bordered total-modifiers">
    <Input
      :inputData="getInput('delivery')"
      :isEdit="!disabled"
      :model="totalModifiers.delivery"
      @updateModel="totalModifiers.delivery = $event"
    />
    <Input
      :inputData="getInput('discount')"
      :isEdit="!disabled"
      :model="totalModifiers.discount"
      @updateModel="totalModifiers.discount = $event"
    />
    <Input
      :inputData="getInput('applyTaxBeforeDiscount')"
      :isEdit="!disabled"
      :model="purchaseOrder.applyTaxBeforeDiscount"
      @updateModel="purchaseOrder.applyTaxBeforeDiscount = $event"
    />

    <div class="field input-wrapper">
      <div class="input-text__label">Apply</div>
      <div class="field__content">
        <BaseBtn
          label="Apply"
          size="sm"
          :disabled="disabled"
          @click="$emit('apply-total-modifiers')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import { totalInputs } from "../../inputs";
import Input from "@/components/Input/Input.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "PurchaseOrderTotalModifiersForm",
  components: { Input, BaseBtn },
  props: {
    totalModifiers: {
      type: Object,
      default: () => {
        return {};
      },
    },
    purchaseOrder: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Utilities,
    };
  },
  computed: {},
  methods: {
    getInput(field) {
      return totalInputs.find((i) => i.id == field);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_purchaseOrderTotalModifiersForm.scss";
</style>
