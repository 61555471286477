<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader title="Buyer Sales for Product" :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'inventoryReport', label: 'Inventory' },
          {
            name: 'inventoryBuyerProduct',
            label: 'Buyer Sales for Product',
          },
        ]" @export="exportToCsv" :disableExport="!reportData" />
        <br class="no-print" />
        <ReportFilter
        class="no-print"
        :filterByTime="true"
        @submit-filter="performGetReport"
        :isLoading="isLoading"
        :percentage="percentageCompleted"
        :filterBySaleType="false" />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr>
                    <th align="left">Supplier</th>
                    <th align="left">Group</th>
                    <th align="left">Grams</th>
                    <th align="left">Product</th>
                    <th align="right">Units On Hand</th>
                    <th align="right">On Hand Wholesale</th>
                    <th align="right">Sales (units)</th>
                    <th align="right">Sales</th>
                    <th align="right">Units On Hand/Sales</th>
                    <th align="right">$ On Hand/$ Sales</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(data, index) of formattedData" :key="index" :class="{ total3: data.isTotal }">
                  <td>{{ data.supplier }}</td>
                  <td>{{ data.group }}</td>
                  <td>{{ data.grams }}</td>
                  <td>{{ data.product }}</td>
                  <td align="right"> {{ data.itemQuantity }}</td>
                  <td align="right"> {{ Utilities.formatPrice(data.actualCost) }}</td>
                  <td align="right"> {{ data.quantity }}</td>
                  <td align="right"> {{ Utilities.formatPrice(data.totalPreTax) }}</td>
                  <td align="right">
                    {{
                      Utilities.formatPercent(
                        (data.itemQuantity / data.quantity) * 100
                      )
                    }} %
                  </td>
                  <td align="right">
                    {{
                      Utilities.formatPercent(
                        (data.actualCost / data.totalPreTax) * 100
                      )
                    }} %
                  </td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="4">TOTALS</td>
                <td align="right"> {{ grandTotalItemQty }}</td>
                <td align="right"> {{ Utilities.formatPrice(grandTotalActualCost) }}</td>
                <td align="right"> {{ grandTotalQuantity }}</td>
                <td align="right"> {{ Utilities.formatPrice(grandTotalPreTax) }}</td>

                <td align="right">
                    {{
                      Utilities.formatPercent(
                        (grandTotalItemQty / grandTotalQuantity) * 100
                      )
                    }} %
                  </td>
                  <td align="right">
                    {{
                      Utilities.formatPercent(
                        (grandTotalActualCost / grandTotalPreTax) * 100
                      )
                    }} %
                  </td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        Utilities,
        };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Buyer Sales for Product");
    },
    computed: {
      ...mapGetters({
        isLoading: "reports/isLoading",
        getData: "reports/getData",
        percentageCompleted: 'reports/percentageCompleted'
    }),
        reportData() {
        return this.getData;
      },
      formattedData() {
      const data = this.reportData;
        let rows = []
        for (let [supplier, supplierValue] of Object.entries(data.list)) {
          for (let [groupId, group] of Object.entries(supplierValue.groups)) {
            for (let [productId, product] of Object.entries(group.products)) {
              rows.push(product)
            }
          }
        }
        return rows;
      },
      grandTotalItemQty() {
          const rows = this.formattedData;
          let tot = 0;
          rows.forEach((r) => tot += r.itemQuantity);
          return tot;
        },
      grandTotalActualCost() {
        const rows = this.formattedData;
        let tot = 0;
        rows.forEach((r) => tot += r.actualCost);
        return tot;
      },
      grandTotalPreTax() {
        const rows = this.formattedData;
        let tot = 0;
        rows.forEach((r) => tot += r.totalPreTax);
        return tot;
      },
      grandTotalQuantity() {
        const rows = this.formattedData;
        let tot = 0;
        rows.forEach((r) => tot += r.quantity);
        return tot;
      },
    },
    methods: {
      ...mapActions({
        generateReport: "reports/generate",
        clearData: "reports/clearData",
      }),
      performGetReport(filter) {
        let reportType = "inventoryBuyerProduct";
        this.generateReport({
          reportType,
          ...filter,
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "inventoryBuyerProduct");
      },
    },
    beforeDestroy() {
      this.clearData()
    }
  };
  </script>

  <style lang="scss">@import "./_inventoryBuyerProduct.scss";