<template>
  <div>
    <div v-if="isEdit">
      <div v-if="mode == 'view'">
        <inline-button label="Add New Traceability Credential Entry"
          type="plain-primary responsive-column form-height wide" @click="handleAddNew" />
      </div>
      <div v-if="mode == 'edit'">
        <div class="traceability-credentials__messages">
          <MessageBox>
            After manipulating credential entries, save the employee clicking the Save button on the header 👆
          </MessageBox>
        </div>
        <div class="grid-four-column">
          <div v-for="input in newTraceabilityInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
            <Input :model="currentTraceabilityCredentials[input.model]"
              @updateModel="currentTraceabilityCredentials[input.model] = $event" :inputData="getInput(input.id)"
              :isEdit="isEdit" />
          </div>
        </div>
        <div class="traceability-credentials__controls">
          <div class="traceability-credentials__controls-left">
            <inline-button label="Cancel" type="plain-info responsive-column form-height wide" @click="handleCancel" />
          </div>
          <div class="traceability-credentials__controls-right">
            <inline-button :isLoading="loadingStatus.checkCredentials" label="Test Access"
              type="plain-info responsive-column form-height wide" @click="handleTraceabilityCredentialsCheck" />
            <inline-button :label="handleButtonLabel" type="plain-primary responsive-column form-height wide"
              @click="handleTraceabilityCredentials" />
          </div>
        </div>
      </div>

    </div>
    <br />
    <div v-if="traceabilityCredentials.filter((el) => !el.isDeleted).length">
      <div class="table-container bordered table-full-screen">
        <table cellspacing="0" class="table-element">
          <thead>
            <tr class="header">
              <th class="cell"></th>
              <th class="cell">Store</th>
              <th class="cell">System</th>
              <th class="cell">Username</th>
              <th class="cell">Password</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row" v-show="!traceabilityInfo.isDeleted"
              v-for="(traceabilityInfo, index) in traceabilityCredentials" :key="index">
              <td class="cell horizontal-distance">
                <BaseBtn iconGroup="far" iconName="edit" class="editor-button" @click="editTraceabilityInfo(index)"
                  :disabled="!isEdit" />
                <BaseBtn iconGroup="fas" iconName="trash" :colorType="1" class="editor-button" :disabled="!isEdit"
                  @click="removeTraceabilityInfo(index)" />
              </td>

              <td class="cell">
                {{ traceabilityInfo.store ? traceabilityInfo.store.name : "-" }}
              </td>
              <td class="cell">{{ traceabilityInfo.traceabilitySystem }}</td>
              <td class="cell">{{ traceabilityInfo.username }}</td>
              <td class="cell hidetext">{{ traceabilityInfo.password }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <h3 class="emptyCredentialsMessage">
        No traceability login data available
      </h3>
    </div>
  </div>
</template>

<script>
import Constants from "@/resources/Constants";
import { mapActions, mapGetters } from "vuex";
import Input from "@/components/Input/Input.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { newTraceabilityInputs } from "../../inputs";
import MessageBox from "@/components/MessageBox/MessageBox.vue";

const NEW_TRACEABILITY_CREDENTIALS = {
  id: "NEW",
  store: null,
  traceabilitySystem: null,
  username: null,
  password: null,
  mfaSecret: null,
  isDeleted: false,
  actionMode: "add",
  index: null,
};

export default {
  name: "traceabilityCredentials",
  data() {
    return {
      Constants: Constants,
      newTraceabilityInputs,
      currentTraceabilityCredentials: { ...NEW_TRACEABILITY_CREDENTIALS },
      mode: 'view',
      credentialCheckResult: {
        errorMessage: undefined,
        successMessage: undefined
      }
    };
  },
  components: {
    Input,
    BaseBtn,
    MessageBox
  },
  props: {
    isEdit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    traceabilityCredentials: {
      type: Array,
      default: () => {
        return [];
      },
    },
    storesAvailable: {
      type: Array,
      default: () => {
        return [];
      },
    },
    employeeFirstName: {
      type: String,
      default: () => {
        return "";
      },
    },
    employeeLastName: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      checkCredentials: "stateModule/checkCredentials"
    }),
    handleTraceabilityCredentials() {
      const validationResult = this.validateTraceabilityInfo();
      if (!validationResult.succeeded) {
        this.$store.dispatch(
          "setMessage",
          {
            text: validationResult.message,
            type: "error",
          },
          { root: true }
        );
        return;
      }
      let currentIndex = this.currentTraceabilityCredentials.index;
      this.currentTraceabilityCredentials.isHandledInSession = true;
      const previousValue = this.traceabilityCredentials[currentIndex];

      switch (this.currentTraceabilityCredentials.actionMode) {
        case "add":
          this.traceabilityCredentials.push(
            this.normalize(this.currentTraceabilityCredentials)
          );
          break;
        case "modify":
          this.traceabilityCredentials[currentIndex] = this.normalize(
            this.currentTraceabilityCredentials
          );
          this.mode = 'view'
          break;
        default:
          return "Disabled";
      }
      this.currentTraceabilityCredentials = { ...NEW_TRACEABILITY_CREDENTIALS };
    },
    normalize(traceabilityCredentials) {
      if (traceabilityCredentials.store.code) {
        traceabilityCredentials.store = traceabilityCredentials.store.code;
      }
      if (traceabilityCredentials.store) {
        traceabilityCredentials.store = this.storesAvailable.find(
          (s) => s._id == traceabilityCredentials.store
        );
      }
      return traceabilityCredentials;
    },
    editTraceabilityInfo(index) {
      this.mode = 'edit'
      const traceabilityInfo = this.traceabilityCredentials[index];
      let systemInfo = Constants.listOfCompatibleTraceabilitySystems.find(
        (system) => system.code == traceabilityInfo.traceabilitySystem?.code
      );
      if (!systemInfo) systemInfo = traceabilityInfo.traceabilitySystem;

      this.currentTraceabilityCredentials.store = {
        code: traceabilityInfo.store?._id,
        label: traceabilityInfo.store?.name,
      };

      this.currentTraceabilityCredentials.traceabilitySystem = systemInfo;
      this.currentTraceabilityCredentials.username = traceabilityInfo.username;
      this.currentTraceabilityCredentials.password = traceabilityInfo.password;
      this.currentTraceabilityCredentials.id = traceabilityInfo._id;
      this.currentTraceabilityCredentials.actionMode = "modify";
      this.currentTraceabilityCredentials.index = index;
    },
    removeTraceabilityInfo(index) {
      this.setMessagePopUp({
        isOpen: true,
        title: "Are you sure you want to delete this traceability login?",
        message: "The removed account cannot be restored later!",
        positiveCallback: (data) => {
          this.traceabilityCredentials[index].actionMode = "remove";
          this.traceabilityCredentials[index].isDeleted = "true";
          this.currentTraceabilityCredentials = {
            ...NEW_TRACEABILITY_CREDENTIALS,
          };
        },
      });
    },
    validateTraceabilityInfo() {
      if (!this.currentTraceabilityCredentials.store) {
        return {
          succeeded: false,
          message: "Store is mandatory",
        };
      }
      if (!this.currentTraceabilityCredentials.traceabilitySystem) {
        return {
          succeeded: false,
          message: "Traceability system is mandatory",
        };
      }
      if (!this.currentTraceabilityCredentials.username) {
        return {
          succeeded: false,
          message: "Username is mandatory",
        };
      }
      if (!this.currentTraceabilityCredentials.password) {
        return {
          succeeded: false,
          message: "Password is mandatory",
        };
      }
      if (
        this.traceabilityCredentials.find(
          (el, ind) =>
            el.store?._id == this.currentTraceabilityCredentials.store &&
            this.currentTraceabilityCredentials.index != ind &&
            !el.isDeleted
        ) != undefined
      ) {
        return {
          succeeded: false,
          message: `A traceability login is already registred in ${this.currentTraceabilityCredentials.store?.name}`,
        };
      }
      if (
        this.currentTraceabilityCredentials.traceabilitySystem == "biotrack"
      ) {
        const regex = new RegExp(
          "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!+@$%^&*-]).{8,}$"
        );
        if (!regex.test(this.currentTraceabilityCredentials.password)) {
          return {
            succeeded: false,
            message:
              "Password shall be minimum eight character long, shall contain a special character and shall contain a number",
          };
        }
        if (
          this.currentTraceabilityCredentials.password
            .toLowerCase()
            .includes(this.employeeFirstName.toLowerCase())
        ) {
          return {
            succeeded: false,
            message: "Password can not contain the employee's first name",
          };
        }
        if (
          this.employeeLastName &&
          this.currentTraceabilityCredentials.password
            .toLowerCase()
            .includes(this.employeeLastName.toLowerCase())
        ) {
          return {
            succeeded: false,
            message: "Password can not contain the employee's last name",
          };
        }
        if (
          this.currentTraceabilityCredentials.password
            .toLowerCase()
            .includes(
              this.currentTraceabilityCredentials.username.toLowerCase()
            )
        ) {
          return {
            succeeded: false,
            message: "Password can not contain the traceability username",
          };
        }
      }
      return {
        succeeded: true,
        message: "Traceability credential is valid",
      };
    },
    getInput(field) {
      const fieldObject = this.newTraceabilityInputs.find((i) => i.id == field);
      if (field == "store") {
        fieldObject.options = this.storesAvailable.map((s) => {
          return { code: s._id, label: s.name };
        });
      }
      return fieldObject;
    },
    handleCancel() {
      this.mode = 'view'
      this.currentTraceabilityCredentials = { ...NEW_TRACEABILITY_CREDENTIALS };
    },
    handleAddNew() {
      this.mode = 'edit'
    },
    handleTraceabilityCredentialsCheck() {
      const validationResult = this.validateTraceabilityInfo();
      if (!validationResult.succeeded) {
        this.$store.dispatch(
          "setMessage",
          {
            text: validationResult.message,
            type: "error",
          },
          { root: true }
        );
        return;
      }
      const targetStoreId = this.currentTraceabilityCredentials.store?.code || this.currentTraceabilityCredentials.store
      const credentials = { ...this.currentTraceabilityCredentials, ...{ store: targetStoreId } }
      this.checkCredentials({
        credentials,
      })
    }
  },
  computed: {
    ...mapGetters({
      loadingStatus: "stateModule/loadingStatus"
    }),
    handleButtonLabel() {
      switch (this.currentTraceabilityCredentials.actionMode) {
        case "add":
          return "Add Entry";
        case "modify":
          return "Confirm Changes";
        default:
          return "Disabled";
      }
    },
  },
  watch: {
    isEdit: function (val) {
      this.currentTraceabilityCredentials = { ...NEW_TRACEABILITY_CREDENTIALS };
    },
  },
};
</script>

<style scoped lang="scss">
.emptyCredentialsMessage {
  margin: 20px;
}

.hidetext {
  -webkit-text-security: disc;
  /* Default */
}

.traceability-credentials {
  &__controls {
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  &__controls-left {
    display: flex;
    gap: 5px;
    justify-content: left;
  }

  &__controls-right {
    display: flex;
    gap: 5px;
    justify-content: right;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: 5px
  }
}
</style>
