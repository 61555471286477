<template>
  <div class="base-input">
    <label
      v-if="label"
      class="base-input__label"
      :class="{ 'base-input__label--focus': focused }"
      >{{ label }}</label
    >
    <div class="container">
      <div class="container__left">
        <vue-numeric
          separator=","
          :precision="0"
          v-model="modelVal"
          v-bind="$attrs"
          class="input-text__field"
          v-on="listeners"
        ></vue-numeric>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumeric from "vue-numeric";

export default {
  name: "AppInputControl",
  components: {
    VueNumeric,
  },
  data() {
    return {
      focused: false,
    };
  },
  props: {
    controlType: {
      type: String,
      default: "input",
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: "",
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    value: {},
    applyFocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    modelVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("updateModel", val);
      },
    },
  },
  mounted() {
    if (this.applyFocus) {
      this.$nextTick(() => this.$refs.input.focus());
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_input.scss";
</style>
