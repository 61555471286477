import REPORTS from "@/constants/ReportNames";

const aggregateList = (currentList, newList, others, report) => {

  let updatedList = {}

  switch (report) {
    case REPORTS.SALES_COUPON_SUMMARY:
      updatedList = aggregateSalesCouponSummary(currentList, newList);
      break;
    case REPORTS.SALES_PROFIT_MARGIN_BY_GROUP:
      updatedList = aggregateSalesProfitMargin(currentList, newList);
      break;
    case REPORTS.SALES_PROFIT_MARGIN_BY_GROUP_AND_PRODUCT:
      updatedList = aggregateSalesProfitMargin(currentList, newList);
      break;
    case REPORTS.SALES_PROFIT_MARGIN_BY_SUPPLIER:
      updatedList = aggregateSalesProfitMargin(currentList, newList);
      break;
    case REPORTS.SALES_PROFIT_MARGIN_BY_SUPPLIER_AND_GROUP:
      updatedList = aggregateSalesProfitMargin(currentList, newList);
      break;
    case REPORTS.SALES_PROFIT_MARGIN_BY_SUPPLIER_AND_GROUP_AND_PRODUCT:
      updatedList = aggregateSalesProfitMargin(currentList, newList);
      break;
    case REPORTS.SALES_PROMO_BY_SUPPLIER:
      updatedList = aggregateSalesPromo(currentList, newList);
      break;
    case REPORTS.SALES_PROMO_BY_SUPPLIER_AND_GROUP:
      updatedList = aggregateSalesPromo(currentList, newList);
      break;
    case REPORTS.SALES_PROMO_BY_SUPPLIER_AND_GROUP_AND_PRODUCT:
      updatedList = aggregateSalesPromo(currentList, newList);
      break;
    case REPORTS.CUSTOMER_ZIP_CODE_COUNT:
      updatedList = aggregateZipCode(currentList, newList);
      break;
    case REPORTS.INVENTORY_PURCHASE_GROUP:
      updatedList = aggregatePurchase(currentList, newList);
      break;
    case REPORTS.INVENTORY_PURCHASE_SUPPLIER:
      updatedList = aggregatePurchase(currentList, newList);
      break;
    case REPORTS.INVENTORY_PURCHASE_SUPPLIER_GROUP:
      updatedList = aggregatePurchaseBySupplierAndGroup(currentList, newList);
      break;
    case REPORTS.SALES_TAX_SUMMARY:
      updatedList = aggregateTaxSummary(currentList, newList);
      break;
    case REPORTS.SALES_TAX_SCHEDULE:
      updatedList = aggregateTaxSchedule(currentList, others);
      break;
    case REPORTS.TOP_PRODUCTS:
      updatedList = aggregateTopProducts(currentList, newList);
      break;
    case REPORTS.SALES_ORDER_STATUS_BUYER:
      updatedList = aggregateOnHandBySupplierAndGroup(currentList, newList);
      break;
    case REPORTS.SALES_ORDER_STATUS_BUYER_SUMMARY:
      updatedList = aggregateOnHandByGroup(currentList, newList);
      break;
    case REPORTS.INVENTORY_BUYER_GROUP:
      updatedList = aggregateOnHandByGroup(currentList, newList);
      break;
    case REPORTS.INVENTORY_BUYER_PRODUCT:
      updatedList = aggregateOnHandByProduct(currentList, newList);
      break;
    case REPORTS.INVENTORY_BUYER_SUPPLIER_GROUP:
      updatedList = aggregateOnHandBySupplierAndGroup(currentList, newList);
      break;

    default:
    updatedList = { ...currentList, ...newList };
  }

  if (shouldSortByTimestamp(others)) {
    switch (report) {
      case REPORTS.SALES_COUPON_SUMMARY:
        updatedList = sortCouponSummary(updatedList);
        break;
      default:
        updatedList = sortByTimeStamp(updatedList)
        break;
    }
  }
  return updatedList;
}

const shouldSortByTimestamp = (properties) => {
  if (properties && properties.isSortedByDate) return true;
  return false;
}

const sortCouponSummary = (list) => {
  for (let [coupon, couponValue] of Object.entries(list)) {
    const test = sortByTimeStamp(couponValue.days);
    couponValue.days = test;
  }
  return list;
}

const sortByTimeStamp = (list) => {
  const sortedKeys = Object.keys(list).sort((a, b) => new Date(a) - new Date(b));
  return sortedKeys.reduce(
    (obj, key) => {
      obj[key] = list[key];
      return obj;
    },
    {}
  );
}
const aggregateOnHandByProduct = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      for (let [groupId, groupValue] of Object.entries(value.groups)) {
        if (currentList[id].groups[groupId]) {

          for (let [productId, productValue] of Object.entries(groupValue.products)) {
            if (currentList[id].groups[groupId].products[productId]) {
              currentList[id].groups[groupId].products[productId].quantity += productValue.quantity;
              currentList[id].groups[groupId].products[productId].totalPreTax += productValue.totalPreTax;
            }
            else {
              currentList[id].groups[groupId].products[productId] = productValue;
            }
          }
        } else {
          currentList[id].groups[groupId] = groupValue;
        }
      }
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}
const aggregateOnHandBySupplierAndGroup = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].total.quantity += value.total.quantity;
      currentList[id].total.totalPreTax += value.total.totalPreTax;

      for (let [groupId, groupValue] of Object.entries(value.groups)) {
        if (currentList[id].groups[groupId]) {
          currentList[id].groups[groupId].quantity += groupValue.quantity;
          currentList[id].groups[groupId].totalPreTax += groupValue.totalPreTax;
        } else {
          currentList[id].groups[groupId] = groupValue;
        }
      }
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}

const aggregateOnHandByGroup = (currentList, others) => {
  for (let [groupId, value] of Object.entries(others)) {
    if (!currentList[groupId]) {
      currentList[groupId] = value;
      continue;
    }

    currentList[groupId].quantity += value.quantity
    currentList[groupId].totalPreTax += value.totalPreTax
  }

  return currentList;
}

const aggregateTaxSchedule = (currentList, others) => {
  for (let [id, value] of Object.entries(others)) {
    if (id != 'taxesList') {
      if (currentList[id]) {
        currentList[id].adult += value.adult;
        currentList[id].medical += value.medical;
        currentList[id].total += value.total;
      } else {
        currentList[id] = value;
      }
    } else {
      for (let [taxId, taxValue] of Object.entries(value)) {
        if (currentList[id][taxId]) {
          currentList[id][taxId].net += taxValue.net;
          currentList[id][taxId].tax += taxValue.tax;
          currentList[id][taxId].total += taxValue.total;
        } else {
          currentList[id][taxId] = taxValue
        }
      }

    }
  }
  return currentList;
}

const aggregateTopProducts = (currentList, newList) => {
  for (const [key, newProduct] of Object.entries(newList)) {
    const existingProduct = currentList[key]

    if (existingProduct && existingProduct.product.type == newProduct.product.type) {
      existingProduct.count += newProduct.count
    } else {
      currentList[key] = newProduct
    }
  }
  return currentList;
}

const aggregateTaxSummary = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].totalTax += value.totalTax;
      currentList[id].cannabisTax += value.cannabisTax;
      currentList[id].medicalTax += value.medicalTax;
      currentList[id].recreationalTax += value.recreationalTax;
      currentList[id].otherTax += value.otherTax;
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}

const aggregatePurchaseBySupplierAndGroup = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].total.quantity += value.total.quantity;
      currentList[id].total.subTotal += value.total.subTotal;
      currentList[id].total.discount += value.total.discount;
      currentList[id].total.lineTotal += value.total.lineTotal;
      currentList[id].total.tax += value.total.tax;
      currentList[id].total.delivery += value.total.delivery;
      currentList[id].total.total += value.total.total;

      for (let [groupId, groupValue] of Object.entries(value.groups)) {
        if (currentList[id].groups[groupId]) {
          currentList[id].groups[groupId].quantity += groupValue.quantity;
          currentList[id].groups[groupId].subTotal += groupValue.subTotal;
          currentList[id].groups[groupId].discount += groupValue.discount;
          currentList[id].groups[groupId].lineTotal += groupValue.lineTotal;
          currentList[id].groups[groupId].tax += groupValue.tax;
          currentList[id].groups[groupId].delivery += groupValue.delivery;
          currentList[id].groups[groupId].total += groupValue.total;
        } else {
          currentList[id].groups[groupId] = groupValue;
        }
      }
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}

const aggregatePurchase = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].quantity += value.quantity;
      currentList[id].subTotal += value.subTotal;
      currentList[id].discount += value.discount;
      currentList[id].lineTotal += value.lineTotal;
      currentList[id].tax += value.tax;
      currentList[id].delivery += value.delivery;
      currentList[id].total += value.total;
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}

const aggregateZipCode = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].newCount += value.newCount;
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}

const aggregateSalesProfitMargin = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].quantity += value.quantity;
      currentList[id].total += value.total;
      currentList[id].totalPreTax += value.totalPreTax;
      currentList[id].discount += value.discount;
      currentList[id].profitLoss += value.profitLoss;
      currentList[id].actualCost += value.actualCost;
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}


const aggregateSalesCouponSummary = (currentList, newList) => {
  for (let [couponName, { days, total }] of Object.entries(newList)) {
    if (currentList[couponName]) {
      currentList[couponName].days = { ...currentList[couponName].days, ...days };
      currentList[couponName].total.couponCount += total.couponCount;
      currentList[couponName].total.couponTotal += total.couponTotal;
    } else {
      currentList[couponName] = { days, total };
    }
  }
  return currentList;
}

const aggregateSalesPromo = (currentList, newList) => {
  for (let [id, value] of Object.entries(newList)) {
    if (currentList[id]) {
      currentList[id].quantity += value.quantity;
      currentList[id].subTotal += value.subTotal;
      currentList[id].productTotal += value.productTotal;
    } else {
      currentList[id] = value;
    }
  }
  return currentList;
}
export default aggregateList;