import _ from "lodash";
import Constants from "@/resources/Constants";

export const normalizeEntityBeforeSaving = function (
  entityToNormalize,
  stores
) {
  const entity = JSON.parse(JSON.stringify(entityToNormalize));

  if (entity.creditHoldDate === "") {
    delete entity.creditHoldDate;
  }
  if (entity.verificationExpirationDate === "") {
    delete entity.verificationExpirationDate;
  }
  if (entity.verificationDate === "") {
    delete entity.verificationDate;
  }
  if (entity.caregiverVerificationExpirationDate === "") {
    delete entity.caregiverVerificationExpirationDate;
  }
  if (entity.caregiverVerificationDate === "") {
    delete entity.caregiverVerificationDate;
  }
  if (entity.password === "*********") {
    delete entity.password;
  } else if (entity.password) {
    entity.hasOnlineStoreCredentials = true;
  }
  if (entity.accountType) {
    entity.accountType = entity.accountType.code;
  }
  if (entity.licenseExpirationMonth && entity.licenseExpirationMonth.code) {
    entity.licenseExpirationMonth = entity.licenseExpirationMonth.code;
  }
  if (entity.licenseExpirationYear && entity.licenseExpirationYear.code) {
    entity.licenseExpirationYear = entity.licenseExpirationYear.code;
  }

  if (this.organizationRole) {
    entity.roles = [this.organizationRole.code];
  }

  if (entity.store === undefined) {
    entity.store = _.find(stores, (s) => s.isAccessGranted);
  }

  delete entity.verifiedBy;
  delete entity.caregiverVerifiedBy;
  delete entity.organization;
  delete entity.createdBy;
  delete entity.updatedBy;
  delete entity.referredBy;
  delete entity.creditHoldBy;

  if (entity.noteList.length > 0) {
    entity.noteList.map(
      (n) =>
        (n.noteType =
          n.noteType && n.noteType.code ? n.noteType.code : (n.noteType ? n.noteType : undefined))
    );
  }

  return entity;
};

export const populateStores = function (stores) {
  const storesLocal = JSON.parse(JSON.stringify(stores));
  storesLocal.map((s) => {
    s.isAccessGranted = false;
    s.roles = [];
    s.role = undefined;
    const customerStore = _.find(
      this.selectedCustomer.stores,
      (es) => es._id === s._id
    );
    if (customerStore) {
      s.isAccessGranted = true;
      s.roles = customerStore.roles;
      // const organizationRole = _.filter(
      //   Constants.listOfStoreRoles,
      //   (sr) => sr.code === customerStore.roles[0]
      // );
      // if (organizationRole.length > 0) {
      //   s.role = organizationRole[0];
      // } else {
      //   s.role = Constants.listOfStoreRoles[0];
      // }
    }
  });
  return storesLocal;
};
