import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  disposals: [],
  loadingStatus: {
    saveDisposal: false,
    getDisposals: false,
    getDisposalById: false,
    deleteDisposal: false,
  },
  disposalPopUp: {
    isOpen: false,
  },
  filterCriteria: [
    { label: "ID", code: "_id", field: { type: "input", label: "ID" } },
    { label: "Email", code: "email", field: { type: "input", label: "Email" } },
    { label: "Name", code: "name", field: { type: "input", label: "Name" } },
    {
      label: "Last name",
      code: "lastName",
      field: { type: "input", label: "Last name" },
    },
  ],
  selectedFilter: undefined,
  selectedPreFilters: undefined,
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setDisposals: (state, payload) => {
    state.disposals = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setDisposalPopUp: (state, payload) => {
    state.disposalPopUp = payload;
  },
};

const getters = {
  disposals: (state) => state.disposals,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  disposalPopUp: (state) => state.disposalPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
};

const actions = {
  saveDisposal: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveDisposal", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveDisposal,
      payload.disposal,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveDisposal", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteDisposal: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteDisposal", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteDisposal,
      payload.disposal,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteDisposal", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getDisposals: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getDisposals", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.getDisposals}?`;
    if (state.selectedFilter) {
      if (state.selectedFilter.limit) {
        url += `&limit=${state.selectedFilter.limit}`;
      }
      if (state.selectedFilter.skip) {
        url += `&skip=${state.selectedFilter.skip}`;
      }
      if (state.selectedFilter.criteria) {
        url += `&filterCriteria=${state.selectedFilter.criteria.code}`;
      }
      if (state.selectedFilter.value) {
        url += `&filterValue=${state.selectedFilter.value}`;
      }
    }
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getDisposals", status: false });
      var disposals = {};

      if (response) {
        disposals = response.data.body;
        commit("setDisposals", disposals);
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getDisposalById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getDisposalById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getDisposalById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getDisposalById", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setDisposalPopUp: ({ commit, state }, payload) => {
    commit("setDisposalPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
