import Apis from "@/services/Apis";
import HttpService from "@/services/HttpService";
import { defaultSelectedReturnItem } from "@/views/ReturnItem/inputs";

const state = {
  returnItems: [],
  returnItem: JSON.parse(JSON.stringify(defaultSelectedReturnItem)),
  loadingStatus: {
    saveReturnItem: false,
    getReturnItems: false,
    getReturnItemById: false,
    deleteReturnItem: false,
    getAdjacent: false,
    postToJournal: false,
  },
  sort: { createdAt: -1 },
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setReturnItems: (state, payload) => {
    state.returnItems = payload;
  },
  setReturnItem: (state, payload) => {
    if (payload._id === "NEW")
      state.returnItem = JSON.parse(JSON.stringify(defaultSelectedReturnItem));
    else state.returnItem = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  returns: (state) => state.returns,
  loadingStatus: (state) => state.loadingStatus,
  returnItem: (state) => state.returnItem,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveReturnItem: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveReturnItem", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.returnItem.save,
      payload,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveReturnItem", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          const data = response.data.body;
          commit("setReturnItem", data);
          if (payload.positiveCallback) {
            payload.positiveCallback(data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  postToJournal: ({ commit, state, rootGetters }, payload) => {
    const ws = rootGetters["posModule/selectedWorkstation"];
    if (!ws) {
      return commit(
        "setMessage",
        {
          text: "No workstation selected",
          type: "error",
          errorType: "no-workstation",
        },
        { root: true }
      );
    }
    commit("setLoadingStatus", { api: "postToJournal", status: true });
    payload.workstation = ws;
    HttpService.post(
      Apis.backendBaseUrl + Apis.returnItem.postToJournal,
      payload,
      function (response, error) {
        commit("setLoadingStatus", { api: "postToJournal", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Posted", type: "success" },
            { root: true }
          );
          const data = response.data.body;
          commit("setReturnItem", data);
          if (payload.positiveCallback) {
            payload.positiveCallback(data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  printReturnItem: ({ commit, state, rootGetters }, payload) => {
    const ws = rootGetters["posModule/selectedWorkstation"];
    if (!ws) {
      return commit(
        "setMessage",
        {
          text: "No workstation selected",
          type: "error",
          errorType: "no-workstation",
        },
        { root: true }
      );
    }
    commit("setLoadingStatus", { api: "printReturnItem", status: true });
    payload.workstation = ws;
    HttpService.post(
      Apis.backendBaseUrl + Apis.returnItem.printReturnItem,
      payload,
      function (response, error) {
        commit("setLoadingStatus", { api: "printReturnItem", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Sent to print queue", type: "success" },
            { root: true }
          );
          const data = response.data.body;
          if (payload.positiveCallback) {
            payload.positiveCallback(data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getReturnItemById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getReturnItemById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.returnItem.getById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getReturnItemById",
          status: false,
        });
        if (response) {
          commit("setReturnItem", response.data);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getAdjacent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getAdjacent",
      status: true,
    });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + Apis.returnItem.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacent",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
