import { getDefaultChartData } from "@/views/dashboards/chart.helpers.js"
import { NO_DATA } from "@/views/dashboards/chart.constant.js"
import Utilities from "@/lib/Utilities";

const getSunday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day;
    return new Date(d.setDate(diff));
}

const getBeginningOfWeekOneYearAgo = () => {
    const today = new Date();
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    return getSunday(oneYearAgo);
}

const generateYearlyData = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const startingDate = getBeginningOfWeekOneYearAgo()

    const result = { weeks: [] }

    const currentDate = startingDate
    let counter = 0
    while (currentDate.getTime() <= today.getTime()) {
        const week = { days: [] }

        for (let i = 0; i < 7; i++) {
            week.days.push({ id: counter, date: currentDate.toISOString().split('T')[0], value: 0 })
            currentDate.setDate(currentDate.getDate() + 1);
            counter++
        }

        result.weeks.push(week)
    }

    // In some cases (e.g.: sunday), the logic could generate more than 53 weeks
    if (result.weeks.length > 53) {
        result.weeks.splice(0, result.weeks.length - 53)
    }


    return result
}

const alternativeColor = (theme) => theme === 'dark' ? 'rgb(81,81,85)' : 'rgb(238,239,242)'

const formatDate = (date) => {
    try {
        const dateObj = new Date(`${date}T00:00:00`);
        const dateSplit = dateObj.toString().split(" ");
        return `${dateSplit[0]} ${dateSplit[1]} ${dateSplit[2]}`;
    } catch (error) {
        return "";
    }
}

export const getDefaultCalendarHeatmapChartData = (theme) => {
    const calendarHeatmapChartData = getDefaultChartData('calendar-heatmap', theme)
    calendarHeatmapChartData.options = {
        getColor: (value, max = 1, collapsed) => {
            if (!value) {
                return Math.random() < 0.5 || collapsed ? NO_DATA[theme] : alternativeColor(theme)
            }
            const index = Math.floor((value / max) * (calendarHeatmapChartData.palette.length - 1));
            return calendarHeatmapChartData.palette[index];
        },
        getTooltip: (day, collapsed) => {
            if (!day) {
                return ''
            }

            if (collapsed) {
                return `${Utilities.formatPrice(day.value)} spent on the week of ${formatDate(day.date)}`
            } else {
                return `${Utilities.formatPrice(day.value)} spent on ${formatDate(day.date)}`
            }
        },
    }
    calendarHeatmapChartData.data = generateYearlyData()

    return calendarHeatmapChartData
}

export const populateCalendarHeatmapChartData = (newData, theme) => {
    const defaultCalendarHeatmapChartData = getDefaultCalendarHeatmapChartData(theme)
    if (!newData) return defaultCalendarHeatmapChartData

    const dataByDate = {}

    newData.forEach(day => {
        if (day?.date) {
            dataByDate[day?.date] = day
        }
    })

    defaultCalendarHeatmapChartData.data.weeks.forEach(week => {
        week.days.forEach(d => {
            if (dataByDate[d.date]) {
                d.value = dataByDate[d.date].totalSpent
            }
        })
    })
    const values = newData?.map(day => day?.totalSpent || 0)
    defaultCalendarHeatmapChartData.data.max = Math.max(...values)

    return defaultCalendarHeatmapChartData
}