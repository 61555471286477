import Constants from "@/resources/Constants";

// Returns Section
export const returnsInputs = [
  {
    id: "selectedReturn.quantity",
    type: "number",
    title: "Quantity to Return",
    precision: 0,
  },
  {
    id: "quantityAvailable",
    type: "text",
    title: "Quantity Available",
  },
  {
    id: "selectedReturn.room",
    type: "select",
    title: "Room of item to return",
    placeholder: "Select Room",
    options: [],
  },
  {
    id: "actualUnitCost",
    type: "text",
    title: "Unit Cost",
    prefix: "$",
  },
  {
    id: "subTotal",
    type: "text",
    title: "Sub Total",
    prefix: "$",
  },
  {
    id: "taxTotal",
    type: "text",
    title: "Tax",
    prefix: "$",
  },
  {
    id: "freight",
    type: "money",
    title: "Freight",
    prefix: "$",
  },
  {
    id: "returnTotal",
    type: "text",
    title: "Return Total",
    prefix: "$",
  },
  {
    id: "selectedReturn.isPostedToProducts",
    type: "checkBox",
    title: "Posted to Products",
    disabled: true,
  },
  {
    id: "selectedReturn.isPostedToState",
    type: "checkBox",
    title: "Posted to State",
    disabled: true,
  },
  {
    id: "selectedReturn.isPostedToJournal",
    type: "checkBox",
    title: "Posted to Journal",
    disabled: true,
  },
  {
    id: "selectedReturn.isArchived",
    type: "checkBox",
    title: "Archived",
    checkboxLabel: "Archived",
    disabled: true,
  },
  {
    id: "selectedReturn.stateNotes",
    placeholder: "State Notes",
    type: "textarea",
    title: "State Notes",
  },
];
