<template>
  <div
    class="padded elevated-0 bordered header-form"
    :class="{
      'bordered__highlighted--primary': selectedPurchaseOrder.isPostedToJournal,
      'bordered__highlighted--danger': selectedPurchaseOrder.isArchived,
    }"
  >
    <transition name="fade">
      <ContainerLabel
        v-if="selectedPurchaseOrder.isPostedToJournal"
        type="primary"
        text="POSTED TO JOURNAL"
      />
      <ContainerLabel
        v-if="selectedPurchaseOrder.isArchived"
        type="danger"
        text="ARCHIVED"
      />
    </transition>
    <div class="header-form__responsive-div">
      <div class="header-form__top-left-form">
        <div>
          <Input
            :inputData="getInput('supplier')"
            :isEdit="!disabled"
            :disabled="disabled"
            :model="selectedPurchaseOrder.supplier"
            @updateModel="selectedPurchaseOrder.supplier = $event"
          />
        </div>
        <div>
          <Input
            :inputData="getInput('supplierAgent')"
            :isEdit="!disabled"
            :disabled="disabled"
            :model="selectedPurchaseOrder.supplierAgent"
            @updateModel="selectedPurchaseOrder.supplierAgent = $event"
            :forcedItems="supplierAgentsForSelectedSupplier"
          />
        </div>
        <div>
          <Input
            :inputData="getInput('invoiceId')"
            :isEdit="!disabled"
            :disabled="disabled || selectedPurchaseOrder.isPostedToProducts"
            :model="selectedPurchaseOrder.invoiceId"
            @updateModel="selectedPurchaseOrder.invoiceId = $event"
          />
        </div>
        <div>
          <Input
            :inputData="getInput('batchType')"
            :isEdit="!disabled"
            :disabled="disabled"
            :key="storeId"
            :model="selectedPurchaseOrder.batchType"
            @updateModel="updateBatchType"
          />
        </div>
        <div class="header-form__manifest-input">
          <Input
            :inputData="getInput('manifestId')"
            v-on:keyup.native.enter="$emit('performGetManifestById')"
            :isEdit="isManifestIdEditable"
            :model="selectedPurchaseOrder.manifestId"
            @updateModel="selectedPurchaseOrder.manifestId = $event"
          />

          <BaseBtn
            iconGroup="fa"
            iconName="search"
            size="sm"
            :disabled="
              disabled ||
              !isManifestIdEditable ||
              !selectedPurchaseOrder.supplier._id ||
              !selectedPurchaseOrder.supplierAgent._id ||
              stateLoadingStatus.getManifestById
            "
            :isLoading="stateLoadingStatus.getManifestById"
            @click="$emit('performGetManifestById')"
          />
        </div>
      </div>
      <div class="header-form__totals-form">
        <PriceSummary :rows="priceSummary" />
        <div
          @click="$emit('total-modifier-toggled')"
          class="header-form__totals-row header-form__action-container"
        >
          Edit discount, delivery, or tax
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import { purchaseOrdeSection1Inputs } from "../../inputs";
import Input from "@/components/Input/Input.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import PriceSummary from "@/components/PriceSummary/PriceSummary.vue";
import { mapActions } from "vuex";

export default {
  name: "PurchaseOrderHeaderForm",
  components: { Input, ContainerLabel, BaseBtn, PriceSummary },
  props: {
    supplier: {
      type: Object,
      default: () => {
        return {};
      },
    },
    supplierAgent: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    totalModifierToggle: {
      type: Boolean,
      default: false,
    },
    selectedPurchaseOrder: {
      type: Object,
      default: () => {
        return {};
      },
    },
    totals: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentStore: {
      type: Object,
      default: () => {
        return {};
      },
    },
    stateLoadingStatus: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      Utilities,
      purchaseOrdeSection1Inputs,
    };
  },
  mounted() {
    this.assignRightBatchType(this.currentStore);
  },
  computed: {
    storeId() {
      return this.currentStore?._id || 'storeId'
    },
    isManifestIdEditable() {
      return !this.disabled && this.selectedPurchaseOrder?.items?.length == 0;
    },
    priceSummary() {
      const priceSummary = [];

      priceSummary.push({
        name: "Item Total",
        value: Utilities.formatPrice(this.totals.unitCost),
      });
      priceSummary.push({
        name: "Discount",
        value: Utilities.formatPrice(this.totals.discount),
      });
      priceSummary.push({
        name: "Sub Total",
        value: Utilities.formatPrice(this.totals.subTotal),
        isHighlighted: true,
      });
      priceSummary.push({
        name: "Tax",
        value: Utilities.formatPrice(this.totals.tax),
      });
      priceSummary.push({
        name: "Delivery",
        value: Utilities.formatPrice(this.totals.delivery),
      });
      priceSummary.push({
        name: "Total",
        value: Utilities.formatPrice(this.totals.total),
        isHighlighted: true,
      });

      return priceSummary;
    },
    supplierAgentsForSelectedSupplier() {
      try {
        const agents = this.selectedPurchaseOrder.supplier.agents;
        return agents ? agents : [];
      } catch (error) {
        return [];
      }
    },
  },
  watch: {
    currentStore(store) {
      this.assignRightBatchType(store);
    },
    selectedPurchaseOrder(po) {
      if (po._id == "NEW") {
        this.assignRightBatchType(this.currentStore);
      }
    },
  },
  methods: {
    ...mapActions({
      getSupplierById: "supplierModule/getSupplierById",
    }),
    assignRightBatchType(store) {
      if (this.selectedPurchaseOrder._id != "NEW") {
        return;
      }
      const batchTypeField = this.purchaseOrdeSection1Inputs.find(
        (i) => i.id == "batchType"
      );
      if (store.isMedical && !store.isRecreational) {
        const options = [{ label: "Medical", code: "medical" }];
        batchTypeField.options = options;
        this.selectedPurchaseOrder.batchType = options[0];
        return;
      }
      if (!store.isMedical && store.isRecreational) {
        const options = [
          {
            label: "Recreational",
            code: "recreational",
          },
        ];
        batchTypeField.options = options;
        this.selectedPurchaseOrder.batchType = options[0];
        return;
      }
      if (store.isMedical && store.isRecreational) {
        const options = [
          {
            label: "Medical & Recreational",
            code: "medical-and-recreational",
          },
          {
            label: "Recreational",
            code: "recreational",
          },
          {
            label: "Medical",
            code: "medical",
          },
        ];
        this.selectedPurchaseOrder.batchType = {
          label: "Medical & Recreational",
          code: "medical-and-recreational",
        };
        batchTypeField.options = options;
        return;
      }
    },
    getInput(field) {
      const input = this.purchaseOrdeSection1Inputs.find((i) => i.id == field);
      return input;
    },
    selectItemFromSearch: function (entity, item) {
      switch (entity) {
        case "supplier":
          this.getSupplierById({
            _id: item._id,
            positiveCallback: (s) => {
              this.selectedPurchaseOrder.supplier = s;
            },
          });
          this.selectedPurchaseOrder.supplierAgent = {};
          break;

        default:
          this.selectedPurchaseOrder[entity] = item;
          break;
      }
    },
    updateBatchType(event) {
      this.selectedPurchaseOrder.batchType = event;
      this.$emit("batch-type-changed", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_purchaseOrderHeaderForm.scss";
</style>
