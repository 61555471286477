<template>
  <div class="journal-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'journals', label: 'Journal' },
          {
            name: 'journalEditor',
            label: selectedJournal._id,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :canEdit="!selectedJournal.isPostedToChartOfAccounts"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedJournal"
        :disableDelete="selectedJournal.isPostedToChartOfAccounts"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
        @menu-option-click="menuOptionClick"
      />
      <div class="section-base">
        <div class="journal-editor__button-wrapper">
          <BaseBtn
            label="Create Reverse Entry"
            :colorType="2"
            :disabled="
              isEdit ||
              !selectedJournal.isManualCreation ||
              selectedJournal.isPostedToChartOfAccounts
            "
            @click="createReverseEntry"
          ></BaseBtn>
        </div>
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--primary':
              selectedJournal.isPostedToChartOfAccounts,
            'bordered__highlighted--danger':
              selectedJournal.isArchived || selectedJournal.isDeleted,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedJournal.isPostedToChartOfAccounts"
              type="primary"
              text="POSTED TO CHART OF ACCOUNTS"
            />
            <ContainerLabel
              v-if="selectedJournal.isArchived"
              type="danger"
              text="ARCHIVED"
            />
          </transition>
          <div class="journal-editor__sum bordered__highlighted--neutral">
            <div class="journal-editor__sum--title">All Journal Entries</div>
            <div class="journal-editor__sum--totals">
              <Input
                :model="totals.debits"
                :inputData="getInput('totals.debits')"
              />
              <Input
                :model="totals.credits"
                :inputData="getInput('totals.credits')"
              />
            </div>
          </div>
          <div class="journal-editor__chart-of-account">
            <Input
              class="journal-editor__chart-of-account--item"
              :model="selectedJournal.chartOfAccount"
              :inputData="getInput('chartOfAccount')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.chartOfAccount = $event"
            />
            <Input
              class="journal-editor__chart-of-account--item"
              :model="selectedJournal.debits"
              :inputData="getInput('debits')"
              :isEdit="isEdit"
              @updateModel="(event) => setValue('debits', event)"
              :validation="isValid('debits')"
            />
            <Input
              class="journal-editor__chart-of-account--item"
              :model="selectedJournal.credits"
              :inputData="getInput('credits')"
              :isEdit="isEdit"
              @updateModel="(event) => setValue('credits', event)"
              :validation="isValid('credits')"
            />
            <Input
              class="journal-editor__chart-of-account--notes"
              :model="selectedJournal.notes"
              :inputData="getInput('notes')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.notes = $event"
            />
          </div>
          <hr />
          <div class="journal-editor__batch">
            <Input
              :model="selectedJournal.batchNumber"
              :inputData="getInput('batchNumber')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.batchNumber = $event"
            />
            <Input
              :model="selectedJournal.batchNumberOriginal"
              :inputData="getInput('batchNumberOriginal')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.batchNumberOriginal = $event"
            />
            <Input
              :model="selectedJournal.origin"
              :inputData="getInput('origin')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.origin = $event"
            />
            <Input
              :model="selectedJournal.isPostedToChartOfAccounts"
              :inputData="getInput('isPostedToChartOfAccounts')"
            />
          </div>
          <hr />
          <div
            v-if="!selectedJournal.isPostedToChartOfAccounts"
            class="journal-editor__external-fields"
          >
            <Input
              :model="selectedJournal.order"
              :inputData="getInput('order')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.order = $event"
            />
            <Input
              :model="selectedJournal.customer"
              :inputData="getInput('customer')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.customer = $event"
            />
            <Input
              :model="selectedJournal.payment"
              :inputData="getInput('payment')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.payment = $event"
            />
            <Input
              :model="selectedJournal.purchaseOrder"
              :inputData="getInput('purchaseOrder')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.purchaseOrder = $event"
            />
            <Input
              :model="selectedJournal.supplier"
              :inputData="getInput('supplier')"
              :isEdit="isEdit"
              @updateModel="selectedJournal.supplier = $event"
            />
          </div>
          <div v-else class="journal-editor__external-fields--links">
            <div v-if="selectedJournal.supplier" class="journal-editor__link">
              <div class="journal-editor__link--title">Supplier</div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Name:</div>
                <div>
                  {{ selectedJournal.supplier.name }}
                </div>
              </div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Id:</div>
                <router-link
                  class="internal-link"
                  target="_blank"
                  :to="{
                    name: 'supplierEditor',
                    query: { id: selectedJournal.supplier._id },
                  }"
                >
                  <span>{{ selectedJournal.supplier._id }}</span>
                </router-link>
              </div>
            </div>
            <div
              v-if="selectedJournal.purchaseOrder"
              class="journal-editor__link"
            >
              <div class="journal-editor__link--title">Purchase Order</div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Id:</div>
                <router-link
                  class="internal-link"
                  target="_blank"
                  :to="{
                    name: 'purchaseOrderEditor',
                    query: { id: selectedJournal.purchaseOrder._id },
                  }"
                >
                  <span>{{ selectedJournal.purchaseOrder._id }}</span>
                </router-link>
              </div>
            </div>
            <div v-if="selectedJournal.order" class="journal-editor__link">
              <div class="journal-editor__link--title">Order</div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Id:</div>
                <router-link
                  class="internal-link"
                  target="_blank"
                  :to="{
                    name: 'orderEditor',
                    query: { id: selectedJournal.order._id },
                  }"
                >
                  <span>{{ selectedJournal.order._id }}</span>
                </router-link>
              </div>
            </div>
            <div v-if="selectedJournal.customer" class="journal-editor__link">
              <div class="journal-editor__link--title">Customer</div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Name:</div>
                <div>
                  {{ selectedJournal.customer.name }} {{ " " }}
                  {{ selectedJournal.customer.lastName }}
                </div>
              </div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Id:</div>
                <router-link
                  class="internal-link"
                  target="_blank"
                  :to="{
                    name: 'customerEditor',
                    query: { id: selectedJournal.customer._id },
                  }"
                >
                  <span>{{ selectedJournal.customer._id }}</span>
                </router-link>
              </div>
            </div>
            <div v-if="selectedJournal.payment" class="journal-editor__link">
              <div class="journal-editor__link--title">Payment</div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Id:</div>
                <router-link
                  class="internal-link"
                  target="_blank"
                  :to="{
                    name: 'paymentEditor',
                    query: { id: selectedJournal.payment._id },
                  }"
                >
                  <span>{{ selectedJournal.payment._id }}</span>
                </router-link>
              </div>
            </div>
            <div v-if="selectedJournal.item" class="journal-editor__link">
              <div class="journal-editor__link--title">Item</div>
              <div
                v-if="selectedJournal.adjustment"
                class="journal-editor__link--row"
              >
                <div class="journal-editor__link--label">Adjustment:</div>
                <div>
                  {{ selectedJournal.adjustment }}
                </div>
              </div>
              <div
                v-if="selectedJournal.disposal"
                class="journal-editor__link--row"
              >
                <div class="journal-editor__link--label">Disposal:</div>
                <div>
                  {{ selectedJournal.disposal }}
                </div>
              </div>
              <div
                v-if="selectedJournal.return"
                class="journal-editor__link--row"
              >
                <div class="journal-editor__link--label">Return:</div>
                <div>
                  {{ selectedJournal.return }}
                </div>
              </div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Product Name:</div>
                <div>
                  {{ selectedJournal.item.externalFields.product.name }}
                </div>
              </div>
              <div class="journal-editor__link--row">
                <div class="journal-editor__link--label">Id:</div>
                <router-link
                  class="internal-link"
                  target="_blank"
                  :to="{
                    name: 'itemEditor',
                    query: { id: selectedJournal.item._id },
                  }"
                >
                  <span>{{ selectedJournal.item._id }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditorSection
        :title="`About ${selectedJournal._id || ''}`"
        sectionType="about"
        v-if="selectedJournal._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedJournal" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import EditorSection from "@/components/EditorSection";
import BaseBtn from "@/components/Base/Button/Button.vue";

import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";
import { validateFields } from "@/utils/validation/journal";

import {
  journalInfoInputs,
  defaultSelectedJournal,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";

export default {
  name: "journalEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      journalInfoInputs,
      isEdit: false,
      defaultSelectedJournal,
      journals: [],
      journalsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.JOURNAL,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
      validationErrors: {},
      totals: {},
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    ContainerLabel,
    BaseBtn,
  },
  mounted: function () {
    this.setPageTitle("Journal");

    if (this.$route.query.id) {
      this.getJournalById({
        _id: this.$route.query.id,
      });
    } else {
      // new element
      this.isEdit = true;
    }

    this.getTotals({
      positiveCallback: (response) => {
        this.totals = response;
      },
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getJournalById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      journal: "journalModule/journal",
    }),
    selectedJournal: {
      get() {
        this.setPageTitle("Journal " + this.journal?._id);
        return this.journal;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getJournalById: "journalModule/getJournalById",
      saveJournal: "journalModule/saveJournal",
      deleteJournal: "journalModule/deleteJournal",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getJournals: "journalModule/getJournals",
      getTotals: "journalModule/getTotals",
      setReverseJournal: "journalModule/setReverseJournal",
      postManualJournalEntries: "journalModule/postManualJournalEntries",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    getInput(field) {
      return journalInfoInputs.find((i) => i.id == field);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedJournal._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedJournal._id === "NEW") {
        this.isEdit = true;
      }
      this.getTotals({
        positiveCallback: (response) => {
          this.totals = response;
        },
      });
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedJournal.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedJournal = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    isValid(field) {
      return _.get(this.validationErrors, field);
    },
    setValue(field, value) {
      _.set(this.selectedJournal, field, value);
      validateFields(this.selectedJournal, field, this.validationErrors);
    },
    selectItemFromSearch: function (entity, item) {
      _.set(this.selectedJournal, entity, item);
      if (entity === "chartOfAccount") {
        _.set(this.selectedJournal, "name", item.name);
      }
      if (entity === "purchaseOrder") {
        _.set(this.selectedJournal, "supplier", item.supplier);
        _.set(this.selectedJournal, "payment", undefined);
        //_.set(this.selectedJournal, "order", undefined);
        //_.set(this.selectedJournal, "customer", undefined);
      }
      if (entity === "order") {
        _.set(this.selectedJournal, "customer", item.customer);
        //_.set(this.selectedJournal, "supplier", undefined);
        _.set(this.selectedJournal, "payment", undefined);
        //_.set(this.selectedJournal, "purchaseOrder", undefined);
      }
      if (entity === "payment") {
        _.set(this.selectedJournal, "customer", item.customer);
        _.set(this.selectedJournal, "supplier", undefined);
        _.set(this.selectedJournal, "order", undefined);
      }
      this.selectedJournal[entity] = item;
    },
    clearItemFromSearch: function (id) {
      _.set(this.selectedJournal, id, undefined);
    },
    createReverseEntry: function () {
      this.isEdit = true;
      const credits = this.selectedJournal.credits;
      const debits = this.selectedJournal.debits;
      const valuesToCopy = _.pick(
        this.selectedJournal,
        "batchNumber",
        "order",
        "customer",
        "payment",
        "purchaseOrder",
        "supplier",
        "origin",
        "notes",
        "batchNumberOriginal"
      );
      this.setReverseJournal(
        _.merge(_.cloneDeep(defaultSelectedJournal), valuesToCopy)
      );
      this.selectedJournal.credits = debits;
      this.selectedJournal.debits = credits;
    },
    menuOptionClick(entity, key) {
      if (key === "postAllManualEntries") {
        this.setMessagePopUp({
          isOpen: true,
          title: "Journal Posting",
          message: "Would you like to post manual journal entries now?",
          positiveCallback: (data) => {
            this.handlePostAllManualEntries();
          },
        });
      }
      if (key === "reports-finance-journal-batch-check") {
        window.open("/reports/finance/financeJournalBatchCheck");
      }
      if (key === "reports-inventory-system-check") {
        window.open("/reports/inventory/inventorySystemCheck");
      }
      if (key === "reports-inventory-system-check-quick") {
        window.open("/reports/inventory/inventorySystemCheckQuick");
      }
      if (key === "reports-tax-system-check") {
        window.open("/reports/inventory/taxSystemCheck");
      }
    },
    handlePostAllManualEntries() {
      this.postManualJournalEntries({
        positiveCallback: () => {
          this.getJournalById({ _id: this.id });
          this.getTotals({
            positiveCallback: (response) => {
              this.totals = response;
            },
          });
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_journalEditor.scss";
</style>
