import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as chartOfAccountColumnHeaders,
  filterCriteria as chartOfAccountFilterCriteria,
} from "../ChartOfAccount/inputs";

import {
  searchColumnHeaders as purchaseOrderColumnHeaders,
  filterCriteria as purchaseOrderFilterCriteria,
} from "../PurchaseOrder/inputs";

import {
  searchColumnHeaders as supplierColumnHeaders,
  filterCriteria as supplierFilterCriteria,
} from "../Supplier/inputs";

import {
  searchColumnHeaders as customerColumnHeaders,
  filterCriteria as customerFilterCriteria,
} from "../Customer/inputs";

import {
  searchColumnHeaders as orderColumnHeaders,
  filterCriteria as orderFilterCriteria,
} from "../Order/inputs";

import {
  searchColumnHeaders as itemColumnHeaders,
  filterCriteria as itemFilterCriteria,
} from "../Item/inputs";

import {
  searchColumnHeaders as paymentColumnHeaders,
  filterCriteria as paymentFilterCriteria,
} from "../Payment/inputs";

import {
  searchColumnHeaders as creditMemoColumnHeaders,
  filterCriteria as creditMemoFilterCriteria,
} from "../CreditMemo/inputs";

// Page Header
export const pageHeader = {
  title: "Journal",
  isEditorView: true,
  cancelPath: "journals",
  addPath: "journalEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Journal Entries",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "journalEditor",
};

export const filterCriteria = [
  {
    label: "Account #",
    code: "accountNo",
    field: { type: "input", label: "accountNo" },
    isPartial: true,
  },
  {
    label: "Batch #",
    code: "batchNumber",
    field: { type: "input", label: "batchNumber" },
    isPartial: false,
  },
  {
    label: "Created",
    code: "createdAt",
    field: { type: "input", label: "createdAt" },
  },

  { label: "Id", code: "_id", field: { type: "input", label: "_id" } },
  {
    label: "Customer Name",
    code: "customer.name",
    field: { type: "input", label: "customer.name" },
    isPartial: true,
  },
  {
    label: "Order",
    code: "order",
    field: { type: "input", label: "order" },
  },
  {
    label: "Purchase Order",
    code: "purchaseOrder",
    field: { type: "input", label: "purchaseOrder" },
  },
  {
    label: "Supplier",
    code: "supplier",
    field: { type: "input", label: "supplier" },
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Posted",
    id: "isPostedToChartOfAccounts",
    type: "checkbox",
  },
  {
    title: "Created At",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Origin",
    id: "origin",
  },
  {
    title: "Batch #",
    id: "batchNumber",
  },
  {
    title: "Account #",
    id: "accountNo",
    size: "medium",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Debits",
    id: "debits",
    type: "money",
    size: "medium",
  },
  {
    title: "Credits",
    id: "credits",
    type: "money",
  },
  {
    title: "Notes",
    id: "notes",
    size: "large",
  },
  {
    title: "Order",
    id: "order",
    size: "large",
    link: true,
    url: "/orders/orderEditor?id=",
    urlId: "order._id",
  },
  {
    title: "Payment",
    id: "payment",
    size: "large",
  },
  {
    title: "Customer Id",
    id: "customer",
    size: "large",
    link: true,
    url: "/customers/customerEditor?id=",
    urlId: "customer._id",
  },
  {
    title: "Purchase Order",
    id: "purchaseOrder._id",
    size: "large",
    link: true,
    url: "/purchaseOrders/purchaseOrderEditor?id=",
    urlId: "purchaseOrder._id",
  },
  {
    title: "Item",
    id: "item._id",
    size: "large",
    link: true,
    url: "/items/itemEditor?id=",
    urlId: "item._id",
  },
  {
    title: "Return Item",
    id: "returnItem",
    size: "large",
  },
  {
    title: "Supplier",
    id: "supplier._id",
    size: "large",
  },
  {
    title: "Supplier Name",
    id: "supplier.name",
    size: "medium",
    link: true,
    url: "/suppliers/supplierEditor?id=",
    urlId: "supplier._id",
  },
  {
    title: "Credit Memo",
    id: "creditMemo",
    size: "large",
    link: true,
    url: "/creditMemos/creditMemoEditor?id=",
    urlId: "creditMemo._id",
  },
  {
    title: "Transaction",
    id: "_id",
  },
];

export const journalInfoInputs = [
  {
    title: "Entered Date",
    id: "createdAt",
    type: "calendar",
  },
  {
    title: "Entered By",
    id: "createdBy",
    type: "text",
  },
  {
    id: "paymentCashDrawer",
    title: "Cash Drawer #",
    type: "text",
  },
  {
    id: "computerIP",
    title: "Station Name",
    type: "text",
  },
  {
    title: "Posted",
    id: "isPostedToChartOfAccounts",
    type: "checkbox",
    disabled: true,
  },
  {
    title: "Batch #",
    id: "batchNumber",
    type: "text",
  },
  {
    title: "Original Batch #",
    id: "batchNumberOriginal",
    type: "input",
  },
  {
    title: "Origin",
    id: "origin",
    type: "select",
    options: [
      { label: "C - Customer Refund", code: "C" },
      { label: "F - Credit Memo", code: "F" },
      { label: "I - Invoice", code: "I" },
      { label: "M - Manual Adjustment", code: "M" },
      { label: "O - Purchase, Disposal, Adjustment, Return", code: "O" },
      { label: "P - Payment", code: "P" },
      { label: "V - Inventory Adjustment", code: "V" },
    ],
  },
  {
    title: "Account #",
    id: "chartOfAccount",
    type: "search",
    entity: Entities.CHARTOFACCOUNT,
    columnHeaders: chartOfAccountColumnHeaders,
    filterCriteria: chartOfAccountFilterCriteria,
    fieldsToDisplay: [
      { label: "Name", id: "name" },
      {
        label: "Account #",
        id: "accountNo",
        type: "link",
        to: {
          name: "chartOfAccountEditor",
          valueKey: "_id",
        },
      },
    ],
  },
  {
    title: "Credit",
    id: "accountNo.credit",
    type: "money",
  },
  {
    title: "Debit",
    id: "accountNo.debit",
    type: "money",
  },
  {
    title: "Credits",
    id: "totals.credits",
    type: "money",
    prefix: "$",
    disabled: true,
  },
  {
    title: "Debits",
    id: "totals.debits",
    type: "money",
    prefix: "$",
    disabled: true,
  },
  {
    title: "Credit",
    id: "credits",
    type: "money",
    prefix: "$",
  },
  {
    title: "Debit",
    id: "debits",
    type: "money",
    prefix: "$",
  },
  {
    title: "Notes",
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    class: "grid-row-two-column",
  },
  {
    title: "Purchase Order",
    id: "viewOnly.purchaseOrder",
    type: "link",
    placeholder: "Purchase Order",
    to: {
      name: "purchaseOrderEditor",
      valueKey: "purchaseOrder",
    },
    target: "_blank",
  },
  {
    title: "Purchase Order",
    id: "purchaseOrder",
    type: "search",
    entity: Entities.PURCHASEORDER,
    isClearable: true,
    columnHeaders: purchaseOrderColumnHeaders,
    filterCriteria: purchaseOrderFilterCriteria,
  },
  {
    title: "Order",
    id: "viewOnly.order",
    type: "link",
    placeholder: "Order Id",
    to: {
      name: "orderEditor",
      valueKey: "order",
    },
    target: "_blank",
  },
  {
    title: "Order",
    id: "order",
    type: "search",
    entity: Entities.ORDER,
    isClearable: true,
    columnHeaders: orderColumnHeaders,
    filterCriteria: orderFilterCriteria,
  },
  {
    title: "Item",
    id: "viewOnly.item",
    type: "link",
    placeholder: "Item Id",
    to: {
      name: "itemEditor",
      valueKey: "item",
    },
    target: "_blank",
  },
  {
    title: "Item",
    id: "item",
    type: "search",
    entity: Entities.ITEM,
    isClearable: true,
    columnHeaders: itemColumnHeaders,
    filterCriteria: itemFilterCriteria,
  },
  {
    title: "Supplier",
    id: "supplier",
    type: "search",
    entity: Entities.SUPPLIER,
    label: "name",
    isClearable: true,
    columnHeaders: supplierColumnHeaders,
    filterCriteria: supplierFilterCriteria,
  },
  {
    title: "Supplier",
    id: "viewOnly.supplier",
    type: "link",
    placeholder: "Supplier",
    to: {
      name: "supplierEditor",
      valueKey: "supplier",
    },
    target: "_blank",
  },
  {
    title: "Customer",
    id: "customer",
    type: "search",
    entity: Entities.CUSTOMER,
    label: "name",
    isClearable: true,
    columnHeaders: customerColumnHeaders,
    filterCriteria: customerFilterCriteria,
  },
  {
    title: "Customer",
    id: "viewOnly.customer",
    type: "link",
    placeholder: "Customer",
    to: {
      name: "customerEditor",
      valueKey: "customer",
    },
    target: "_blank",
  },
  {
    title: "Credit Memo",
    id: "viewOnly.creditMemo",
    type: "link",
    placeholder: "Credit Memo",
    to: {
      name: "creditMemoEditor",
      valueKey: "creditMemo",
    },
    target: "_blank",
  },
  {
    title: "Credit Memo",
    id: "creditMemo",
    type: "search",
    entity: Entities.CREDITMEMO,
    isClearable: true,
    columnHeaders: creditMemoColumnHeaders,
    filterCriteria: creditMemoFilterCriteria,
  },
  {
    title: "Updated At",
    id: "updatedAt",
    type: "calendar",
  },
  {
    title: "Chart of Account",
    id: "viewOnly.chartOfAccount",
    type: "link",
    placeholder: "Chart Of Account",
    to: {
      name: "chartOfAccountEditor",
      valueKey: "chartOfAccount",
      label: "accountNo",
    },
    target: "_blank",
  },
  {
    title: "Payment",
    id: "viewOnly.payment",
    type: "link",
    placeholder: "Payment",
    to: {
      name: "paymentEditor",
      valueKey: "payment",
    },
    target: "_blank",
  },
  {
    title: "Payment",
    id: "payment",
    type: "search",
    entity: Entities.PAYMENT,
    isClearable: true,
    columnHeaders: paymentColumnHeaders,
    filterCriteria: paymentFilterCriteria,
  },
  {
    id: "return",
    title: "Return",
    type: "text",
  },
];

export const defaultSelectedJournal = {
  _id: "NEW",
  computerIP: "",
  origin: "M",
  accountNo: "",
  chartOfAccount: {},
  name: "",
  batchNumber: undefined,
  batchNumberOriginal: undefined,
  debits: 0,
  credits: 0,
  notes: "",
  isPostedToChartOfAccounts: false,
  purchaseOrder: undefined,
  supplier: undefined,
  return: undefined,
  disposal: undefined,
  adjustment: undefined,
  item: undefined,
  customer: undefined,
  payment: undefined,
  order: undefined,
  creditMemo: undefined,
};
