<template>
  <div id="selected-product-pop-up" class="selected-product-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div center bordered"
      :class="{ blur: isBlurred }"
    >
      <div class="selected-product-pop-up__container">
        <div>
          <div class="selected-product-pop-up__header">
            <div>
              <span class="h1">{{ product.name }}</span>
            </div>
            <div
              v-if="forcedItem"
              class="selected-product-pop-up__header-subtitle text-color-1"
            >
              <div>Batch: {{ forcedItem.batchId }}</div>
              <div>-</div>
              <div>Barcode: {{ forcedItem.barcode }}</div>
            </div>
            <div class="selected-product-pop-up__header-subtitle text-color-1">
              <div>{{ getGroup }}</div>
              <div>-</div>
              <div>{{ getSupplier }}</div>
              <div v-if="isCannabis">-</div>
              <div v-if="isCannabis">
                {{ product.product.cannabisGramsUnit }}g
              </div>
            </div>
          </div>

          <div class="padded control-buttons">
            <BaseBtn
              :disabled="isReducible || isEditDisabled"
              iconGroup="fas"
              iconName="minus"
              @click="reduce"
              :colorType="1"
              size="sm"
            />

            <BaseInput
              placeholder="Quantity"
              v-model="selectedQuantity"
              @focus="setFocus"
              :applyFocus="true"
              controlType="number"
              ref="quantity"
              :disabled="isEditDisabled"
            />

            <BaseBtn
              :disabled="product.availableQuantity === 0 || isEditDisabled"
              iconGroup="fas"
              iconName="plus"
              @click="increase"
              size="sm"
            />
          </div>

          <div class="details">
            <div>
              <span class="text-color-1 text-thin">Batch</span>
              <br />
              <div
                v-if="product.product.items && product.product.items.length > 0"
              >
                <div v-for="i in product.product.items" :key="i.batch">
                  {{ `${i.batchId} x ${i.quantity}` }}
                </div>
              </div>
              <div v-else>-</div>
            </div>
            <div class="right">
              <span class="text-color-1 text-thin">Price</span>
              <br />
              {{
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(price)
              }}
            </div>
          </div>
        </div>
        <div v-if="!isEditDisabled" class="desktop-only-block">
          <Keypad class="instant__keypad" :input="input" />
        </div>
      </div>

      <div class="padded button-wrapper">
        <BaseBtn
          :disabled="
            selectedProductPopUp.mode !== 'edit' ||
            orderLoadingStatus.addRemoveItemToOrder
          "
          label="Remove"
          @click="confirmDeleteItem"
          :colorType="1"
        />
        <BaseBtn
          :disabled="orderLoadingStatus.addRemoveItemToOrder"
          @click="handleNegativeAnswer"
          label="Close"
          :colorType="3"
        />
        <BaseBtn
          @click="submit"
          label="Submit"
          :disabled="
            isSubmittable ||
            orderLoadingStatus.addRemoveItemToOrder ||
            isEditDisabled
          "
          :isLoading="orderLoadingStatus.addRemoveItemToOrder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utilities from "@/lib/Utilities";
import _ from "lodash";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import Keypad from "@/components/Keypad";

export default {
  name: "SelectedProductPopUp",
  data() {
    return {
      input: {},
      Utilities: Utilities,
      selectedQuantity: 1,
      product: {
        quantity: 0,
        availableQuantity: 0,
        product: { items: [] },
      },
      forcedItem: undefined,
      isBlurred: false,
    };
  },
  components: {
    BaseBtn,
    BaseInput,
    Keypad,
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );

    this.product._id = this.selectedProductPopUp.product._id;
    this.product.name = this.selectedProductPopUp.product.name;
    this.product.quantity = this.selectedProductPopUp.product.quantity
      ? this.selectedProductPopUp.product.quantity
      : 0;

    this.selectedQuantity = this.product.quantity ? this.product.quantity : 1;

    this.product.availableQuantity =
      this.selectedProductPopUp.product.availableQuantity;
    this.product.product = this.selectedProductPopUp.product;

    this.forcedItem = this.selectedProductPopUp.forcedItem;

    // this.getProductById({
    //   _id: this.product._id,
    //   positiveCallback: function (result) {
    //     // TODO use settings to see if WI only
    //     // this.$set(
    //     //   this.product,
    //     //   "availableQuantity",
    //     //   result.quantityWorkingInventory
    //     // );
    //   }.bind(this),
    // });

    console.log(this.product.product);
  },
  mounted() {
    try {
      //console.log(this.$refs.quantity);
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapGetters({
      selectedProductPopUp: "posModule/selectedProductPopUp",
      orderLoadingStatus: "orderModule/loadingStatus",
      order: "orderModule/order",
      currentStore: "storeModule/currentStore",
    }),
    price() {
      return this.selectedProductPopUp.order.type === "medical"
        ? this.product.product.medicalPrice
        : this.product.product.recreationalPrice;
    },
    isEditDisabled() {
      return (
        !this.currentStore.isShowQuantityBoxEnabled &&
        this.product?.product?.externalFields.group.unitOfMeasure == "unit"
      );
    },
    getSupplier() {
      let supplierName = "";
      if (this.product?.product?.externalFields.supplier) {
        supplierName = this.product?.product?.externalFields.supplier.name;
      }
      return supplierName;
    },
    getGroup() {
      let groupName = "";
      if (this.product?.product?.externalFields.group) {
        groupName = this.product?.product?.externalFields.group.name;
      }
      return groupName;
    },
    isCannabis() {
      try {
        return this.product?.product?.externalFields.group.isCannabis;
      } catch (error) {
        return false;
      }
    },
    isSubmittable() {
      const difference = this.selectedQuantity - this.product.quantity;
      return this.selectedQuantity <= 0 || difference == 0;
    },
    isReducible() {
      if (this.selectedQuantity <= 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getProductById: "productModule/getProductById",
      addRemoveItemToOrder: "orderModule/addRemoveItemToOrder",
      setSelectedProductPopUp: "posModule/setSelectedProductPopUp",
      setMessagePopUp: "setMessagePopUp",
    }),
    setFocus(e) {
      this.input = e.target;
    },
    reduce: function () {
      this.selectedQuantity -= 1;
    },
    increase: function () {
      this.selectedQuantity += 1;
    },
    submit: function () {
      const difference = this.selectedQuantity - this.product.quantity;
      this.modifyQuantity(difference);
    },
    performaddRemoveItemToOrder: function (options) {
      this.addRemoveItemToOrder({
        action: {
          productId: this.product._id,
          mode: options.mode,
          forcedItem: this.forcedItem,
          quantity: options.quantityToAdd,
        },
        positiveCallback: (order) => {
          if (this.selectedProductPopUp.mode === "add") {
            const payload = {
              isOpen: true,
              product: this.selectedProductPopUp.product,
              mode: "edit",
              positiveCallback: this.selectedProductPopUp.positiveCallback,
              order: this.selectedProductPopUp.order,
            };
            this.setSelectedProductPopUp(payload);
          }

          options.positiveCallback(order);
        },
      });
    },
    confirmDeleteItem: function () {
      this.isBlurred = true;
      this.setMessagePopUp({
        isOpen: true,
        title: "Warning!",
        message: "Do you really want to remove this item from the cart?",
        positiveCallback: this.performDeleteItem,
        negativeCallback: () => {
          this.isBlurred = false;
        },
      });
    },
    performDeleteItem: function () {
      this.isBlurred = false;
      this.addRemoveItemToOrder({
        action: {
          productId: this.product._id,
          mode: "remove",
        },
        positiveCallback: (order) => {
          this.updateQuantity(order);
          this.setSelectedProductPopUp({
            isOpen: false,
            product: this.selectedProductPopUp.product,
            mode: "add",
            positiveCallback: this.selectedProductPopUp.positiveCallback,
            order: this.selectedProductPopUp.order,
          });
        },
      });
    },
    handleNegativeAnswer: function () {
      this.selectedProductPopUp.isOpen = false;
      if (this.selectedProductPopUp.negativeCallback) {
        this.selectedProductPopUp.negativeCallback();
      }
    },
    modifyQuantity: function (quantityToAdd) {
      if (this.orderLoadingStatus.addRemoveItemToOrder) {
        return;
      }

      if (this.product + quantityToAdd < 0) {
        return;
      }

      if (
        this.product + quantityToAdd >
        this.selectedProductPopUp.product.availableQuantity
      ) {
        return;
      }

      if (quantityToAdd > 0) {
        this.performaddRemoveItemToOrder({
          mode: "add",
          quantityToAdd: Math.abs(quantityToAdd),
          positiveCallback: (order) => {
            this.updateQuantity(order, quantityToAdd);
            this.selectedProductPopUp.isOpen = false;
          },
        });
      } else {
        this.performaddRemoveItemToOrder({
          quantityToAdd: Math.abs(quantityToAdd),
          positiveCallback: (order) => {
            this.updateQuantity(order, quantityToAdd);
            // TODO probably useless, to remove 'if' below
            if (this.product.quantity === 0) {
              this.setSelectedProductPopUp({
                isOpen: true,
                product: this.selectedProductPopUp.product,
                forcedItem: this.forcedItem,
                mode: "add",
                positiveCallback: this.selectedProductPopUp.positiveCallback,
                order: this.selectedProductPopUp.order,
              });
            }
            this.selectedProductPopUp.isOpen = false;
          },
          mode: "subtract",
        });
      }
    },
    updateQuantity(order, quantityToAdd) {
      const currentProductInOrder = _.find(
        order.products,
        (i) => this.product._id === i._id
      );
      if (currentProductInOrder) {
        this.product.quantity = currentProductInOrder.quantity;
        this.product.product = currentProductInOrder;
      } else {
        this.product.quantity = 0;
        this.product.product.subTotal = 0;
        this.product.product.items = [];
      }
      if (this.selectedProductPopUp.positiveCallback) {
        this.selectedProductPopUp.positiveCallback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-number {
  min-width: 50px;
}

.button-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;

  & > button {
    width: 50%;
  }
}

.control-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.details {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.selected-product-pop-up {
  &__header {
    padding-bottom: 5px;
    width: 100%;
  }

  &__header-subtitle {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: $text-size-2;
    gap: 5px;
  }

  &__container {
    display: flex;
    gap: 1rem;
  }

  &__keypad {
    @include respond(tab-port) {
      display: none;
    }
  }
}
</style>
