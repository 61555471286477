import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";
import {
  filterCriteria as customerFilterCriteria,
  searchColumnHeaders as customerColumnHeaders,
} from '../../../Customer/inputs';

export const adjustmentsInputs = [
  {
    id: "quantityAvailable",
    type: "text",
    title: "Quantity Available",
  },
  {
    id: "selectedAdjustment.type",
    type: "select",
    title: "Adjustment Type",
    placeholder: "Select Type",
    options: Constants.listOfAdjustmentTypes,
  },
  {
    id: "selectedAdjustment.reason",
    type: "select",
    title: "Adjustment Reason",
    placeholder: "Select Reason",
    options: Constants.listOfAdjustmentReasons,
  },
  {
    id: "selectedAdjustment.isArchived",
    checkboxLabel: "Archived",
    title: "Archived",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedAdjustment.isPostedToState",
    checkboxLabel: "Posted to State",
    title: "Posted to State",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedAdjustment.isPostedToJournal",
    checkboxLabel: "Posted to Journal",
    title: "Posted to Journal",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedAdjustment.stateNotes",
    placeholder: "State Notes",
    type: "textarea",
    title: "State Notes",
    class: "grid-row-two-column",
  },
  {
    id: "selectedAdjustment.room",
    type: "select",
    title: "Room of item to adjust",
    placeholder: "Select Room",
    options: [],
  },
  {
    id: "selectedAdjustment.mode",
    type: "select",
    title: "Adjustment Mode",
    placeholder: "Select Mode",
    options: Constants.listOfAdjustmentModes,
  },
  {
    id: "selectedAdjustment.quantity",
    type: "number",
    title: "Adjustment Quantity",
    precision: 0,
  },
  {
    id: "actualUnitCost",
    type: "text",
    title: "Unit Cost",
    prefix: "$",
  },
  {
    id: "adjustmentTotal",
    type: "text",
    title: "Adjustment Total",
    prefix: "$",
  },
  {
    id: "selectedAdjustment.isPostedToProducts",
    checkboxLabel: "Posted to Inventory",
    title: "Posted to Inventory",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedAdjustment.manifest",
    title: "Manifest #",
    type: "input",
  },
  {
    id: "selectedAdjustment.batchSubLot",
    title: "Batch SubLot #",
    type: "input",
  },
  {
    id: "selectedAdjustment.customer",
    placeholder: "Select Customer",
    type: "search",
    title: "Customer",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.CUSTOMER,
    nestedId: 'selectedAdjustment.customer',
    columnHeaders: customerColumnHeaders,
    filterCriteria: customerFilterCriteria,
    isClearable: true,
    filter: {
      "isActive": true,
      $and: [
        { "isDeleted": false },
      ]
    },
  },
  {
    id: "selectedAdjustment.detailedDescription",
    placeholder: "Detailed Description",
    type: "textarea",
    title: "Detailed Description",
    class: "grid-row-two-column",
  },
];
