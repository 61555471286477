<template>
  <div class="settings responsive-menu">
    <div class="margined--horizontal responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" title="Check In" :canEdit="false" :hideAddButton="true" :links="[
          { name: 'home', label: 'Home' },
          { name: 'settings', label: 'Settings' },
        ]" routerPath="settings" />

      <div class="section-base">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-map-marker"></i>
            </div>
            <div>Location</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div class="two-columns-layout two-columns-layout--responsive">
              <div class="field">
                <div class="input-text__label">Current Store</div>
                <div class="field__content">{{ currentStore.name }}</div>
              </div>
              <div class="input-text" v-if="userStores.length > 1">
                <div class="reversed-flex-column">
                  <v-select v-model="selectedStore" class="input-text__select" placeholder="Select Store" label="name"
                    :options="userStores" />
                  <div class="input-text__label">Stores</div>
                </div>
              </div>
            </div>
            <div class="two-columns-layout two-columns-layout--responsive" v-if="userStores.length > 1">
              <div>
                <div class="input-text">
                  <div class="input-text__label">Change Store</div>
                  <inline-button label="Change Store" class="full-width"
                    type="plain-primary responsive-column form-height wide" @click="performChangeStore"
                    :isLoading="storeLoadingStatus.getCurrentStore" />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-base">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-user"></i>
            </div>
            <div>User</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered">
          <div class="user__row">
            <div class="field">
              <div class="input-text__label">Current User</div>
              <div class="field__content">
                {{ Utilities.composeNameAndLastName(user) }}
              </div>
            </div>
            <div class="field">
              <div class="input-text__label">Organization Role</div>
              <div class="field__content">
                {{ user.role }}
              </div>
            </div>
          </div>
          <div class="user__row">
            <Input controlType="text" :isPassword="true" label="New Password" placeholder="New Password"
              v-model="userModel.newPassword" />
            <Input controlType="text" :isPassword="true" label="Confirm New Password" placeholder="Confirm New Password"
              v-model="userModel.newPasswordConfirmation" />
            <BaseBtn size="sm" label="Change Password" @click="performUpdateEmployeePassword" />
          </div>
        </div>
      </div>

      <div class="section-base">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-palette"></i>
            </div>
            <div>Aspect</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <inline-button type="plain-primary responsive-column" label="Switch Theme" @click="performSwitchTheme" />
        </div>
      </div>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapActions, mapGetters } from "vuex";
import { multiStoreRoles } from "@/constants/roles";
import Utilities from "@/lib/Utilities";
import {
  pageHeader,
} from "./inputs";
import Input from "@/components/Base/Input/Base.vue"
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "settings",
  data() {
    return {
      selectedStore: undefined,
      multiStoreRoles,
      Utilities,
      pageHeader,
      userModel: {
        newPassword: "",
        newPasswordConfirmation: ""
      }
    };
  },
  components: {
    PageHeader,
    Input,
    BaseBtn
  },
  created: function () {
    this.setPageTitle("Settings");
  },
  computed: {
    ...mapGetters({
      storeLoadingStatus: "storeModule/loadingStatus",
      currentStore: "storeModule/currentStore",
      getStores: "storeModule/stores",
      getRole: "loginModule/role",
      getAllStores: "storeModule/stores",
      user: "loginModule/user",
    }),
    userStores() {
      return this.getStores;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
      switchTheme: "switchTheme",
      getUserStores: "storeModule/getUserStores",
      changeStore: "storeModule/changeStore",
      updateEmployeePassword: "employeeModule/updateEmployeePassword"
    }),
    performSwitchTheme: function () {
      this.switchTheme();
    },
    performChangeStore: function () {
      if (!this.selectedStore) {
        this.setMessage({
          text: "Please select a Store.",
          type: "error",
        });
        return;
      }
      this.changeStore(this.selectedStore);
    },
    performUpdateEmployeePassword() {
      console.log(this.userModel)
      if (this.userModel.newPassword !== this.userModel.newPasswordConfirmation) {
        return this.setMessage({
          text: "Password doesn't match.",
          type: "error",
        });
      }

      if (this.userModel.newPassword.length < 1) {
        return this.setMessage({
          text: "Password too short.",
          type: "error",
        });
      }

      this.updateEmployeePassword({
        passwordInfo: this.userModel,
        positiveCallback: () => {
          this.setMessage({
            text: "Password updated!",
            type: "success",
          });
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.user {
  &__row {
    display: flex;
    gap: 20px;
    align-content: bottom;
    align-items: flex-end;
  }
}
</style>
