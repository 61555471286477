<template>
  <div class="flight-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'flights', label: 'Flight' },
          {
            name: 'flightEditor',
            label: selectedFlight.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedFlight"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />
      <div class="section-base">
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--danger':
              selectedFlight.isDeleted || selectedFlight.isArchived,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedFlight.isDeleted"
              type="danger"
              text="DELETED"
            />
          </transition>
          <div class="flight-editor__grid">
            <Input
              :model="selectedFlight.name"
              :inputData="getInput('name')"
              :isEdit="isEdit"
              @updateModel="selectedFlight.name = $event"
            />
            <Input
              :model="selectedFlight.isActive"
              :inputData="getInput('isActive')"
              :isEdit="isEdit"
              @updateModel="selectedFlight.isActive = $event"
            />
          </div>
          <div class="flight-editor__grid">
            <Input
              :model="selectedFlight.menuUpload"
              :inputData="getInput('menuUpload')"
              :isEdit="isEdit"
              @updateModel="selectedFlight.menuUpload = $event"
            />

            <Input
              :model="selectedFlight.saleType"
              :inputData="getInput('saleType')"
              :isEdit="isEdit"
              @updateModel="selectedFlight.saleType = $event"
            />
          </div>
          <div>
            <div class="flight-editor__grid">
              <div>
                <Input
                  :model="selectedFlight.salePrice"
                  :inputData="getInput('salePrice')"
                  :isEdit="isEdit"
                  @updateModel="selectedFlight.salePrice = $event"
                />
              </div>
              <div>
                <Input
                  :model="calculateGrams"
                  :inputData="getInput('grams')"
                  :isEdit="isEdit"
                />
              </div>
            </div>
            <Input
              class="flight-editor__grid__column"
              :model="selectedFlight.applyDateRange"
              :inputData="getInput('applyDateRange')"
              :isEdit="isEdit"
              @updateModel="(event) => setValue('applyDateRange', event)"
              :validation="isValid('applyDateRange')"
            />
            <div class="flight-editor__grid">
              <Input
                class="flight-editor__grid__column"
                :model="selectedFlight.startDate"
                :inputData="getInput('startDate')"
                :isEdit="isEdit"
                @updateModel="(event) => setValue('startDate', event)"
                :validation="isValid('startDate')"
              />
              <Input
                class="flight-editor__grid__column"
                :model="selectedFlight.endDate"
                :inputData="getInput('endDate')"
                :isEdit="isEdit"
                @updateModel="(event) => setValue('endDate', event)"
                :validation="isValid('endDate')"
              />
            </div>
          </div>
          <Input
            :model="selectedFlight.imageNormal"
            :inputData="getInput('imageNormal')"
            :isEdit="isEdit"
            @updateModel="selectedFlight.imageNormal = $event"
          />
          <div class="flight-editor__divider" />
          <Input
            :model="selectedFlight.notes"
            :inputData="getInput('notes')"
            :isEdit="isEdit"
            @updateModel="selectedFlight.notes = $event"
          />
        </div>
      </div>
      <!-- Items Begin  -->
      <EditorSection
        title="Items"
        sectionType="items"
        :bus="bus"
        :defaultIsOpen="true"
      >
        <Items :isEdit="isEdit" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import Items from "./components/FlightItems/FlightItems.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import EditorSection from "@/components/EditorSection";

import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";

import { validateFields } from "@/utils/validation/flight";

import {
  flightInfoInputs,
  defaultSelectedFlight,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";

export default {
  name: "flightEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      flightInfoInputs,
      isEdit: false,
      defaultSelectedFlight,
      flights: [],
      flightsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.FLIGHT,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
      validationErrors: {},
    };
  },
  components: {
    PageHeader,
    Input,
    ContainerLabel,
    Items,
    EditorSection,
  },
  mounted: function () {
    this.setPageTitle("Flight");
    const id = this.$route.query.id;
    if (id && id !== "NEW") {
      this.getFlightById({ _id: id });
    } else {
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getFlightById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      flight: "flightModule/flight",
    }),
    selectedFlight: {
      get() {
        this.setPageTitle("Flight" + this.flight?.name);
        return this.flight;
      },
      set(entity) {
        return entity;
      },
    },
    calculateGrams: function () {
      let sum = 0;
      this.selectedFlight.items.forEach((item) => {
        sum += item.item.product?.cannabisGramsUnit * item.quantity || 0;
      });
      return sum;
    },
  },
  methods: {
    ...mapActions({
      getFlightById: "flightModule/getFlightById",
      saveFlight: "flightModule/saveFlight",
      deleteFlight: "flightModule/deleteFlight",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getFlights: "flightModule/getFlights",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    getInput(field) {
      return flightInfoInputs.find((i) => i.id == field);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedFlight._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedFlight._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedFlight.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedFlight = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    isValid(field) {
      return _.get(this.validationErrors, field);
    },
    setValue(field, value) {
      _.set(this.selectedFlight, field, value);
      validateFields(this.selectedFlight, field, this.validationErrors);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_flightEditor.scss";
</style>
