<template>
  <div>
    <div class="modal" id="employee-confirmation-pop-up" data-keyboard="false" data-backdrop="static">
      <div class="modal-overlay overlay-div" style="background-color: rgba(0, 0, 0, 0.3); backdrop-filter: blur(1px)"
        @click="closeModal"></div>
      <div ref="modal" id="modal" class="default-pop-up-center-div bordered" :class="modalClass">
        <div class="search-modal-content" ref="searchModalContent" id="search-modal-content">
          <slot class="modal__header" ref="modalHeader" id="modal-header" name="header">
            <div class="modal__header" ref="modalHeader" id="modal-header">
              <h2 name="header">
                {{ title }}
              </h2>
              <div class="modal-header-button-wrapper">
                <div class="close-button" @click="closeModal">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </div>
              </div>
            </div>
          </slot>

          <div class="modal-body">
            <slot name="body" :close="closeModal" :isFullScreen="isFullScreen">
              default body
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script id="modal">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);

export default {
  name: "Modal",
  data() {
    return {
      isFullScreen: false,
    };
  },
  props: {
    title: {
      type: String,
    },
    isOpen: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "auto",
    },
    modalClass: {
      type: String,
    },
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    getModalWidth() {
      var modal = document.getElementById("modal");
      return modal.offsetWidth;
    },
  },
  mounted: function () {
    document.body.classList.add("no-scroll");
  },
  destroyed() {
    document.body.classList.remove("no-scroll");
  },
  methods: {
    closeModal: function () {
      document.body.classList.remove("no-scroll");
      this.$emit("toggle-modal");
    },
    expandModal: function () {
      const modal = document.getElementById("modal");
      if (this.isFullScreen) {
        modal.classList.remove("full-screen");
      } else {
        modal.classList.add("full-screen");
      }
      this.isFullScreen = !this.isFullScreen;
      this.$emit("expand-modal", this.isFullScreen);
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      this.$emit("select-item", entity, item, nestedId);
    },
    handleButtonClicked: function () {
      this.$emit("handleButtonClicked");
    },
  },
};
</script>

<style lang="scss">
.modal {
  z-index: 10004;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-header-button-wrapper {
      display: flex;
      gap: 5px;
    }

    svg {
      font-size: 25px;
      cursor: pointer;

      &:hover {
        color: $dark-accent-color;
      }
    }
  }
}

.light .modal {
  &__header {
    svg {
      &:hover {
        color: $light-accent-color;
      }
    }
  }
}

.close-button {
  margin-left: 25px !important;
}

.modal-close {
  float: right;
  color: white;
}

.modal-header {
  margin-top: 0;
  color: $dark-info-color;
}

.modal-default-button {
  float: right;
}

.full-screen {
  width: 100% !important;
  height: 100%;
  margin: auto;
}

.entity-search-modal {
  padding: 0 !important;
  width: 1000px;
  height: 85vh !important;
  z-index: 10005;

  @media screen and (max-width: 400px) {
    min-height: 100%;
    border: none !important;
  }

  .search-modal-content {
    padding: 20px;
  }
}

.modal-overlay {
  z-index: 10003;
}

#modal {
  z-index: 10004;
}
</style>
