import { getDefaultChartData, assignPaletteToDataset } from "@/views/dashboards/chart.helpers.js"
import numeral from 'numeral'

export const getDefaultSalesChartData = (theme) => {
    const salesChartData = getDefaultChartData('bar', theme)

    // Set dollar prefix
    salesChartData.options.tooltips.callbacks.label = (tooltipItems, data) => {
        return numeral(tooltipItems.yLabel || 0).format('$0,0')
    }

    // Set stacked
    salesChartData.options.scales.yAxes[0].stacked = true
    salesChartData.options.scales.yAxes[0].ticks = {
        callback: (val, i) => {
            if (i % 2) return undefined
            return numeral(val || 0).format('$0a')
        }
    }
    salesChartData.options.scales.xAxes[0].stacked = true


    return salesChartData
}

export const populateSalesChartData = (salesChartData, newData, theme) => {
    const newLabels = []
    const newRecreationalSales = []
    const newMedicalSales = []
    for (var key in newData) {
        const record = newData[key]
        newLabels.push(record.dateString.split('-')[1])
        newRecreationalSales.push(record.recreational)
        newMedicalSales.push(record.medical)
    }

    const hasRecreationalSales = newRecreationalSales.some(n => n > 0)
    const hasMedicalSales = newMedicalSales.some(n => n > 0)

    salesChartData.data.labels = newLabels

    if (!salesChartData.data.datasets[0] || newLabels.length == 0) {
        salesChartData.data.datasets[0] = { label: 'Sales' }
    }

    if (!hasMedicalSales && salesChartData.data.datasets[1]) {
        salesChartData.data.datasets.splice(1, 1)
    }

    if (hasMedicalSales && hasRecreationalSales) {
        salesChartData.data.datasets[0].data = newRecreationalSales
        salesChartData.data.datasets[0].label = 'Recreational'

        if (!salesChartData.data.datasets[1]) {
            salesChartData.data.datasets[1] = {}
        }

        salesChartData.data.datasets[1].data = newMedicalSales
        salesChartData.data.datasets[1].label = 'Medical'
    }
    if (hasMedicalSales && !hasRecreationalSales) {
        salesChartData.data.datasets[0].data = newMedicalSales
    }
    if (!hasMedicalSales && hasRecreationalSales) {
        salesChartData.data.datasets[0].data = newRecreationalSales
    }

    assignPaletteToDataset(salesChartData.data.datasets, theme)
}
