<template>
  <div class="pos-base">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "PosBase",
};
</script>

<style scoped lang="scss">
.pos-base {
  height: 100%;
}
</style>
