<template>
  <div class="label-print-selection">
    <span class="h3 padded">Select a label printer</span>
    <div class="padded left">
      <div v-if="labelPrinters.length > 0" class="printers">
        <BaseBtn
          v-for="lp in labelPrinters"
          :key="lp._id"
          :label="lp.name"
          :colorType="lp._id == selectedLabelPrinter._id ? 5 : undefined"
          @click="setSelectedPrinter(lp)"
        />
      </div>
      <div v-else>No label printers for this store</div>
    </div>
    <span class="h3 padded">Number of copies</span>
    <BaseInput
      placeholder="Insert number of copies"
      type="number"
      v-model="printLabelQuantity"
      :applyFocus="true"
    />
    <BaseBtn
      :disabled="
        !selectedLabelPrinter._id ||
        !printLabelQuantity ||
        printLabelQuantity <= 0
      "
      class="label-print-selection__action"
      @click="printLabels"
      iconGroup="fas"
      iconName="print"
      label="Print"
    >
    </BaseBtn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";

export default {
  props: {
    item: {
      type: Object,
    },
    previousSelectedLabelPrinter: {
      type: Object,
      default: undefined
    },
  },
  data() {
    return {
      selectedLabelPrinter: {},
      printLabelQuantity: 1,
    };
  },
  components: {
    BaseBtn,
    BaseInput
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore",
    }),
    labelPrinters() {
      return this.currentStore.labelPrinters;
    },
  },
  methods: {
    ...mapActions({
      printLabel: "itemModule/printLabel",
      setMessage: "setMessage",
    }),
    setSelectedPrinter(lp){
      this.selectedLabelPrinter = lp;
      this.$emit("printerSelected", lp);
    },
    printLabels() {
      const payload = {
        _id: this.item._id,
        labelPrinter: this.selectedLabelPrinter,
        positiveCallback: (item) => {
          this.setMessage({
            title: "Label Printed",
            text: "Label Printed",
            type: "success",
          });
        },
      };

      this.printLabel(payload);
      this.$emit("closePrintModal");
    },
  },
  mounted: function () {
    if(this.previousSelectedLabelPrinter)
      this.selectedLabelPrinter = this.previousSelectedLabelPrinter
  },
};
</script>

<style lang="scss" scoped>
@import './_print.scss';
</style>