const { Role } = require("../constants/roles");
const Constants = {
  // In milliseconds
  expirations: {
    employeeConfirmationExpiration: 600000,
  },
  defaultPageTitle: "DispenseFirst",
  debounce: 275,
  strings: {
    removePatientFromQueueConfirmation:
      "Do you really wish to remove this customer from the queue?",
    warningMessageTitle: "Warning!",
    actionPropagation: "Action Propagation",
    deletionConfirmation: "Do you really wish to delete this record?",
    archiveConfirmation: "Do you really wish to archive this record?",
    unArchiveConfirmation: "Do you really wish to unarchive this record?",
    undeletionConfirmation: "Do you really wish to undelete this record?",
    terminationConfirmation: "Do you really wish to terminate this employee?",
    stateCommunicationConfirmation:
      "You're about to make a state transaction, do you wish to continue?",
    propagateBatchTypeChange:
      "Do you wish to propagate this batch type for the items on this PO?",
    unsavedChangesTitle: "You may have unsaved changes!",
    unsavedChangesConfirmation: "Are you sure you wish to continue?",
    logoutTitle: "Confirm Logout",
    logoutConfirmation: "Are you sure you wish to logout?",
    scheduleDisposalConfirmation:
      "Do you really wish to schedule this disposal?",
    unscheduleDisposalConfirmation:
      "Do you really wish to unschedule this disposal?",
    postReturnConfirmation: "Do you really wish to post this return?",
    reverseReturnConfirmation:
      "Do you really wish to reverse the posting for this return?",
  },
  order: {
    types: {
      medical: "medical",
      inState: "in-state",
      outOfState: "out-of-state",
    },
  },
  noteTypes: [
    { id: 1, value: 1, label: "Other", default: true },
    { id: 2, value: 2, label: "Consultation" },
    { id: 3, value: 3, label: "Denial" },
    { id: 4, value: 4, label: "Documents" },
    { id: 5, value: 5, label: "Dosage" },
    { id: 6, value: 6, label: "Education" },
    { id: 7, value: 7, label: "Health Problem" },
    { id: 8, value: 8, label: "Pop-Up Message" },
  ],
  followUpNoteDays: [
    { id: 1, value: 0, label: "No follow up", default: true },
    { id: 2, value: 1, label: "In one day" },
    { id: 3, value: 2, label: "In two days" },
    { id: 4, value: 3, label: "In three days" },
    { id: 5, value: 4, label: "In four days" },
    { id: 6, value: 5, label: "In five days" },
    { id: 7, value: 6, label: "In six days" },
    { id: 8, value: 7, label: "In one week" },
    { id: 8, value: 14, label: "In two weeks" },
    { id: 8, value: 21, label: "In three weeks" },
    { id: 8, value: 30, label: "In one month" },
    { id: 8, value: 180, label: "In six months" },
    { id: 8, value: 365, label: "In one year" },
  ],
  listOfStates: [
    {
      label: "Alabama",
      code: "AL",
    },
    {
      label: "Alaska",
      code: "AK",
    },
    {
      label: "American Samoa",
      code: "AS",
    },
    {
      label: "Arizona",
      code: "AZ",
    },
    {
      label: "Arkansas",
      code: "AR",
    },
    {
      label: "California",
      code: "CA",
    },
    {
      label: "Colorado",
      code: "CO",
    },
    {
      label: "Connecticut",
      code: "CT",
    },
    {
      label: "Delaware",
      code: "DE",
    },
    {
      label: "District Of Columbia",
      code: "DC",
    },
    {
      label: "Federated States Of Micronesia",
      code: "FM",
    },
    {
      label: "Florida",
      code: "FL",
    },
    {
      label: "Georgia",
      code: "GA",
    },
    {
      label: "Guam",
      code: "GU",
    },
    {
      label: "Hawaii",
      code: "HI",
    },
    {
      label: "Idaho",
      code: "ID",
    },
    {
      label: "Illinois",
      code: "IL",
    },
    {
      label: "Indiana",
      code: "IN",
    },
    {
      label: "Iowa",
      code: "IA",
    },
    {
      label: "Kansas",
      code: "KS",
    },
    {
      label: "Kentucky",
      code: "KY",
    },
    {
      label: "Louisiana",
      code: "LA",
    },
    {
      label: "Maine",
      code: "ME",
    },
    {
      label: "Marshall Islands",
      code: "MH",
    },
    {
      label: "Maryland",
      code: "MD",
    },
    {
      label: "Massachusetts",
      code: "MA",
    },
    {
      label: "Michigan",
      code: "MI",
    },
    {
      label: "Minnesota",
      code: "MN",
    },
    {
      label: "Mississippi",
      code: "MS",
    },
    {
      label: "Missouri",
      code: "MO",
    },
    {
      label: "Montana",
      code: "MT",
    },
    {
      label: "Nebraska",
      code: "NE",
    },
    {
      label: "Nevada",
      code: "NV",
    },
    {
      label: "New Hampshire",
      code: "NH",
    },
    {
      label: "New Jersey",
      code: "NJ",
    },
    {
      label: "New Mexico",
      code: "NM",
    },
    {
      label: "New York",
      code: "NY",
    },
    {
      label: "North Carolina",
      code: "NC",
    },
    {
      label: "North Dakota",
      code: "ND",
    },
    {
      label: "Northern Mariana Islands",
      code: "MP",
    },
    {
      label: "Ohio",
      code: "OH",
    },
    {
      label: "Oklahoma",
      code: "OK",
    },
    {
      label: "Oregon",
      code: "OR",
    },
    {
      label: "Palau",
      code: "PW",
    },
    {
      label: "Pennsylvania",
      code: "PA",
    },
    {
      label: "Puerto Rico",
      code: "PR",
    },
    {
      label: "Rhode Island",
      code: "RI",
    },
    {
      label: "South Carolina",
      code: "SC",
    },
    {
      label: "South Dakota",
      code: "SD",
    },
    {
      label: "Tennessee",
      code: "TN",
    },
    {
      label: "Texas",
      code: "TX",
    },
    {
      label: "Utah",
      code: "UT",
    },
    {
      label: "Vermont",
      code: "VT",
    },
    {
      label: "Virgin Islands",
      code: "VI",
    },
    {
      label: "Virginia",
      code: "VA",
    },
    {
      label: "Washington",
      code: "WA",
    },
    {
      label: "West Virginia",
      code: "WV",
    },
    {
      label: "Wisconsin",
      code: "WI",
    },
    {
      label: "Wyoming",
      code: "WY",
    },
  ],
  listOfSalutations: ["MR.", "MS.", "MRS.", "DR."],
  listOfOrganizationRoles: [
    {
      label: "Global Admin",
      code: Role.GLOBAL_ADMIN,
    },
    {
      label: "Global Inventory Manager",
      code: Role.GLOBAL_INVENTORY_MANAGER,
    },
    {
      label: "Store Employee",
      code: "",
    },
  ],
  listOfStoreRoles: [
    {
      label: "Store Super User",
      code: Role.SUPER_USER,
    },
    {
      label: "Admin",
      code: Role.ADMIN,
    },
    {
      label: "Consultant",
      code: Role.CONSULTANT,
    },
    {
      label: "Sales",
      code: Role.SALES,
    },
    {
      label: "Sales/No Printing",
      code: Role.SALES_NO_PRINTING,
    },
    {
      label: "Inventory & Purchasing",
      code: Role.INVENTORY_PURCHASING,
    },
    {
      label: "Sales & Inventory & Purchasing",
      code: Role.SALES_INVENTORY_PURCHASING,
    },
    {
      label: "State Auditor",
      code: Role.STATE_AUDITOR,
    },
  ],
  listOfEmploymentNoteTypes: [
    {
      label: "Employment Contract",
      code: "employment-contract",
    },
    {
      label: "Training - Uses of Cannabis",
      code: "training.users-of-cannabis",
    },
    {
      label: "Training - Regulatory Inspection",
      code: "training.regulatory-inspection",
    },
    {
      label: "Training - Law Enforcement",
      code: "training.law-enforcement",
    },
    {
      label: "Training - Maintain Agent Status",
      code: "training.maintain-agent-status",
    },
    {
      label: "Training - HIPPA",
      code: "training.hippa",
    },
    {
      label: "Other",
      code: "other",
    },
    {
      label: "W4 Form",
      code: "w4-form",
    },
  ],
  listOfCustomerNoteTypes: [
    { label: "Other", code: "other" },
    { label: "Consultation", code: "consultation" },
    { label: "Denial", code: "denial" },
    { label: "Documents", code: "documents" },
    { label: "Dosage", code: "dosage" },
    { label: "Education", code: "education" },
    { label: "Health Problem", code: "health-problem" },
    { label: "Pop-Up Message", code: "pop-up-message" },
  ],
  listOfSaleType: [
    {
      label: "Medical",
      code: "medical",
    },
    {
      label: "Recreational",
      code: "recreational",
    },
    {
      label: "Medical & Recreational",
      code: "medical-and-recreational",
    },
  ],
  listOfCustomerExclusive: [
    {
      label: "Medical",
      code: "medical",
    },
    {
      label: "Recreational",
      code: "recreational",
    },
  ],
  listOfSaleTypes: [
    {
      label: "Medical",
      code: "medical",
    },
    {
      label: "Recreational",
      code: "recreational",
    },
  ],
  listOfTaxTypes: [
    {
      label: "Medical",
      code: "medical",
    },
    {
      label: "Recreational",
      code: "recreational",
    },
    {
      label: "Non Cannabis",
      code: "non-cannabis",
    },
  ],
  listOfMonths: [
    { label: "01", code: "1" },
    { label: "02", code: "2" },
    { label: "03", code: "3" },
    { label: "04", code: "4" },
    { label: "05", code: "5" },
    { label: "06", code: "6" },
    { label: "07", code: "7" },
    { label: "08", code: "8" },
    { label: "09", code: "9" },
    { label: "10", code: "10" },
    { label: "11", code: "11" },
    { label: "12", code: "12" },
  ],
  listOfMonthDays: [
    { label: "01", code: "1" },
    { label: "02", code: "2" },
    { label: "03", code: "3" },
    { label: "04", code: "4" },
    { label: "05", code: "5" },
    { label: "06", code: "6" },
    { label: "07", code: "7" },
    { label: "08", code: "8" },
    { label: "09", code: "9" },
    { label: "10", code: "10" },
    { label: "11", code: "11" },
    { label: "12", code: "12" },
    { label: "13", code: "13" },
    { label: "14", code: "14" },
    { label: "15", code: "15" },
    { label: "16", code: "16" },
    { label: "17", code: "17" },
    { label: "18", code: "18" },
    { label: "19", code: "19" },
    { label: "20", code: "20" },
    { label: "21", code: "21" },
    { label: "22", code: "22" },
    { label: "23", code: "23" },
    { label: "24", code: "24" },
    { label: "25", code: "25" },
    { label: "26", code: "26" },
    { label: "27", code: "27" },
    { label: "28", code: "28" },
    { label: "29", code: "29" },
    { label: "30", code: "30" },
    { label: "31", code: "31" },
  ],
  listOfWeekDays: [
    { label: "Sunday", code: "sunday" },
    { label: "Monday", code: "monday" },
    { label: "Tuesday", code: "tuesday" },
    { label: "Wednesday", code: "wednesday" },
    { label: "Thursday", code: "thursday" },
    { label: "Friday", code: "saturday" },
    { label: "Saturday", code: "saturday" },
  ],
  listOfDurations: [
    { label: "10 min", code: "10" },
    { label: "15 min", code: "15" },
    { label: "30 min", code: "30" },
    { label: "45 min", code: "45" },
    { label: "1 h", code: "60" },
    { label: "1:30 h", code: "90" },
    { label: "2 h", code: "120" },
  ],
  listOfCompatibleTraceabilitySystems: [
    { label: "None", code: "none" },
    { label: "BioTrack", code: "biotrack" },
    { label: "Metrc", code: "metrc" },
  ],
  listOfAdjustmentTypes: [
    { label: "State and DF", code: "state-and-df", cannabisOnly: true },
    { label: "State Only", code: "state-only", cannabisOnly: true },
    { label: "DF Only", code: "df-only", cannabisOnly: false },
  ],
  listOfAdjustmentReasons: [
    {
      traceabilitySystem: "biotrack",
      label: "General Inventory Audit",
      code: "1",
    },
    { traceabilitySystem: "biotrack", label: "Theft", code: "2" },
    {
      traceabilitySystem: "biotrack",
      label: "Seizure by Federal, State, Local or Tribal Law Enforcement",
      code: "3",
    },
    {
      traceabilitySystem: "biotrack",
      label: "Correcting a mistake",
      code: "4",
    },
    {
      traceabilitySystem: "biotrack",
      label: "Moisture loss, e.g. wet other plant material",
      code: "5",
    },
    { traceabilitySystem: "biotrack", label: "Depletion", code: "6" },
    {
      traceabilitySystem: "biotrack",
      label: "Store Transfer",
      code: "8",
    },
    { traceabilitySystem: "metrc", label: "Theft", code: "2" },
    { traceabilitySystem: "metrc", label: "Typing/Entry Error", code: "7" },
    // This will be the only option if the system is running offline
    {
      traceabilitySystem: "none",
      label: "General Inventory Audit",
      code: "1",
    },
  ],
  listOfAdjustmentModes: [
    { label: "Add", code: "add" },
    { label: "Subtract", code: "subtract" },
  ],
  listOfDisposalMethods: [
    { label: "Compostable", code: "compostable" },
    { label: "Non Compostable", code: "non-compostable" },
  ],
  listOfDisposalTypes: [
    { label: "Internal", code: "internal" },
    { label: "External", code: "external" },
  ],
  listOfDisposalReasons: [
    { label: "Other", code: "0" },
    { label: "Waste", code: "1" },
    { label: "Unhealthy or Died", code: "2" },
    { label: "Infestation", code: "3" },
    { label: "Product Return", code: "4" },
    { label: "Mistake", code: "5" },
    { label: "Spoilage", code: "6" },
    { label: "Quality Control", code: "7" },
  ],
  inputMasks: {
    defaultPhone: "(###) ###-####",
    defaultZip: "#####",
    defaultSrn: "##########",
    defaultFein: "#########",
    defaultPin: "####",
    defaultAccountNo: "#####-##",
    hourMinute: "##:##",
    hexadecimalColor: "!#######",
    numberOfDays: "####",
    networkPort: "####",
    longNumber: "##########",
    shortNumber: "####",
  },
  numericInput: {
    moneyPrecision: 2,
    percentagePrecision: 0,
    defaultPrecision: 0,
    defaultWeightPrecision: 5,
    defaultCannabinoidsPrecision: 4,
    pointPrecision: 1,
  },
  dates: {
    defaultDateFormat: "L",
    extendedDateFormat: "MMMM Do YYYY, h:mm:ss a",
    extendedCalendarDateFormat: "MM/DD/YY hh:mm a",
    timeOnly: "hh:mm a",
  },
  countries: [
    { label: "Afghanistan", code: "AF" },
    { label: "Åland Islands", code: "AX" },
    { label: "Albania", code: "AL" },
    { label: "Algeria", code: "DZ" },
    { label: "American Samoa", code: "AS" },
    { label: "AndorrA", code: "AD" },
    { label: "Angola", code: "AO" },
    { label: "Anguilla", code: "AI" },
    { label: "Antarctica", code: "AQ" },
    { label: "Antigua and Barbuda", code: "AG" },
    { label: "Argentina", code: "AR" },
    { label: "Armenia", code: "AM" },
    { label: "Aruba", code: "AW" },
    { label: "Australia", code: "AU" },
    { label: "Austria", code: "AT" },
    { label: "Azerbaijan", code: "AZ" },
    { label: "Bahamas", code: "BS" },
    { label: "Bahrain", code: "BH" },
    { label: "Bangladesh", code: "BD" },
    { label: "Barbados", code: "BB" },
    { label: "Belarus", code: "BY" },
    { label: "Belgium", code: "BE" },
    { label: "Belize", code: "BZ" },
    { label: "Benin", code: "BJ" },
    { label: "Bermuda", code: "BM" },
    { label: "Bhutan", code: "BT" },
    { label: "Bolivia", code: "BO" },
    { label: "Bosnia and Herzegovina", code: "BA" },
    { label: "Botswana", code: "BW" },
    { label: "Bouvet Island", code: "BV" },
    { label: "Brazil", code: "BR" },
    { label: "British Indian Ocean Territory", code: "IO" },
    { label: "Brunei Darussalam", code: "BN" },
    { label: "Bulgaria", code: "BG" },
    { label: "Burkina Faso", code: "BF" },
    { label: "Burundi", code: "BI" },
    { label: "Cambodia", code: "KH" },
    { label: "Cameroon", code: "CM" },
    { label: "Canada", code: "CA" },
    { label: "Cape Verde", code: "CV" },
    { label: "Cayman Islands", code: "KY" },
    { label: "Central African Republic", code: "CF" },
    { label: "Chad", code: "TD" },
    { label: "Chile", code: "CL" },
    { label: "China", code: "CN" },
    { label: "Christmas Island", code: "CX" },
    { label: "Cocos (Keeling) Islands", code: "CC" },
    { label: "Colombia", code: "CO" },
    { label: "Comoros", code: "KM" },
    { label: "Congo", code: "CG" },
    { label: "Congo, The Democratic Republic of the", code: "CD" },
    { label: "Cook Islands", code: "CK" },
    { label: "Costa Rica", code: "CR" },
    { label: "Croatia", code: "HR" },
    { label: "Cuba", code: "CU" },
    { label: "Cyprus", code: "CY" },
    { label: "Czech Republic", code: "CZ" },
    { label: "Denmark", code: "DK" },
    { label: "Djibouti", code: "DJ" },
    { label: "Dominica", code: "DM" },
    { label: "Dominican Republic", code: "DO" },
    { label: "Ecuador", code: "EC" },
    { label: "Egypt", code: "EG" },
    { label: "El Salvador", code: "SV" },
    { label: "Equatorial Guinea", code: "GQ" },
    { label: "Eritrea", code: "ER" },
    { label: "Estonia", code: "EE" },
    { label: "Ethiopia", code: "ET" },
    { label: "Falkland Islands (Malvinas)", code: "FK" },
    { label: "Faroe Islands", code: "FO" },
    { label: "Fiji", code: "FJ" },
    { label: "Finland", code: "FI" },
    { label: "France", code: "FR" },
    { label: "French Guiana", code: "GF" },
    { label: "French Polynesia", code: "PF" },
    { label: "French Southern Territories", code: "TF" },
    { label: "Gabon", code: "GA" },
    { label: "Gambia", code: "GM" },
    { label: "Georgia", code: "GE" },
    { label: "Germany", code: "DE" },
    { label: "Ghana", code: "GH" },
    { label: "Gibraltar", code: "GI" },
    { label: "Greece", code: "GR" },
    { label: "Greenland", code: "GL" },
    { label: "Grenada", code: "GD" },
    { label: "Guadeloupe", code: "GP" },
    { label: "Guam", code: "GU" },
    { label: "Guatemala", code: "GT" },
    { label: "Guernsey", code: "GG" },
    { label: "Guinea", code: "GN" },
    { label: "Guinea-Bissau", code: "GW" },
    { label: "Guyana", code: "GY" },
    { label: "Haiti", code: "HT" },
    { label: "Heard Island and Mcdonald Islands", code: "HM" },
    { label: "Holy See (Vatican City State)", code: "VA" },
    { label: "Honduras", code: "HN" },
    { label: "Hong Kong", code: "HK" },
    { label: "Hungary", code: "HU" },
    { label: "Iceland", code: "IS" },
    { label: "India", code: "IN" },
    { label: "Indonesia", code: "ID" },
    { label: "Iran, Islamic Republic Of", code: "IR" },
    { label: "Iraq", code: "IQ" },
    { label: "Ireland", code: "IE" },
    { label: "Isle of Man", code: "IM" },
    { label: "Israel", code: "IL" },
    { label: "Italy", code: "IT" },
    { label: "Jamaica", code: "JM" },
    { label: "Japan", code: "JP" },
    { label: "Jersey", code: "JE" },
    { label: "Jordan", code: "JO" },
    { label: "Kazakhstan", code: "KZ" },
    { label: "Kenya", code: "KE" },
    { label: "Kiribati", code: "KI" },
    { label: "Korea, Republic of", code: "KR" },
    { label: "Kuwait", code: "KW" },
    { label: "Kyrgyzstan", code: "KG" },
    { label: "Latvia", code: "LV" },
    { label: "Lebanon", code: "LB" },
    { label: "Lesotho", code: "LS" },
    { label: "Liberia", code: "LR" },
    { label: "Libyan Arab Jamahiriya", code: "LY" },
    { label: "Liechtenstein", code: "LI" },
    { label: "Lithuania", code: "LT" },
    { label: "Luxembourg", code: "LU" },
    { label: "Macao", code: "MO" },
    { label: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { label: "Madagascar", code: "MG" },
    { label: "Malawi", code: "MW" },
    { label: "Malaysia", code: "MY" },
    { label: "Maldives", code: "MV" },
    { label: "Mali", code: "ML" },
    { label: "Malta", code: "MT" },
    { label: "Marshall Islands", code: "MH" },
    { label: "Martinique", code: "MQ" },
    { label: "Mauritania", code: "MR" },
    { label: "Mauritius", code: "MU" },
    { label: "Mayotte", code: "YT" },
    { label: "Mexico", code: "MX" },
    { label: "Micronesia, Federated States of", code: "FM" },
    { label: "Moldova, Republic of", code: "MD" },
    { label: "Monaco", code: "MC" },
    { label: "Mongolia", code: "MN" },
    { label: "Montserrat", code: "MS" },
    { label: "Morocco", code: "MA" },
    { label: "Mozambique", code: "MZ" },
    { label: "Myanmar", code: "MM" },
    { label: "Namibia", code: "NA" },
    { label: "Nauru", code: "NR" },
    { label: "Nepal", code: "NP" },
    { label: "Netherlands", code: "NL" },
    { label: "Netherlands Antilles", code: "AN" },
    { label: "New Caledonia", code: "NC" },
    { label: "New Zealand", code: "NZ" },
    { label: "Nicaragua", code: "NI" },
    { label: "Niger", code: "NE" },
    { label: "Nigeria", code: "NG" },
    { label: "Niue", code: "NU" },
    { label: "Norfolk Island", code: "NF" },
    { label: "Northern Mariana Islands", code: "MP" },
    { label: "Norway", code: "NO" },
    { label: "Oman", code: "OM" },
    { label: "Pakistan", code: "PK" },
    { label: "Palau", code: "PW" },
    { label: "Palestinian Territory, Occupied", code: "PS" },
    { label: "Panama", code: "PA" },
    { label: "Papua New Guinea", code: "PG" },
    { label: "Paraguay", code: "PY" },
    { label: "Peru", code: "PE" },
    { label: "Philippines", code: "PH" },
    { label: "Pitcairn", code: "PN" },
    { label: "Poland", code: "PL" },
    { label: "Portugal", code: "PT" },
    { label: "Puerto Rico", code: "PR" },
    { label: "Qatar", code: "QA" },
    { label: "Reunion", code: "RE" },
    { label: "Romania", code: "RO" },
    { label: "Russian Federation", code: "RU" },
    { label: "RWANDA", code: "RW" },
    { label: "Saint Helena", code: "SH" },
    { label: "Saint Kitts and Nevis", code: "KN" },
    { label: "Saint Lucia", code: "LC" },
    { label: "Saint Pierre and Miquelon", code: "PM" },
    { label: "Saint Vincent and the Grenadines", code: "VC" },
    { label: "Samoa", code: "WS" },
    { label: "San Marino", code: "SM" },
    { label: "Sao Tome and Principe", code: "ST" },
    { label: "Saudi Arabia", code: "SA" },
    { label: "Senegal", code: "SN" },
    { label: "Serbia and Montenegro", code: "CS" },
    { label: "Seychelles", code: "SC" },
    { label: "Sierra Leone", code: "SL" },
    { label: "Singapore", code: "SG" },
    { label: "Slovakia", code: "SK" },
    { label: "Slovenia", code: "SI" },
    { label: "Solomon Islands", code: "SB" },
    { label: "Somalia", code: "SO" },
    { label: "South Africa", code: "ZA" },
    { label: "South Georgia and the South Sandwich Islands", code: "GS" },
    { label: "Spain", code: "ES" },
    { label: "Sri Lanka", code: "LK" },
    { label: "Sudan", code: "SD" },
    { label: "Suriname", code: "SR" },
    { label: "Svalbard and Jan Mayen", code: "SJ" },
    { label: "Swaziland", code: "SZ" },
    { label: "Sweden", code: "SE" },
    { label: "Switzerland", code: "CH" },
    { label: "Syrian Arab Republic", code: "SY" },
    { label: "Taiwan, Province of China", code: "TW" },
    { label: "Tajikistan", code: "TJ" },
    { label: "Tanzania, United Republic of", code: "TZ" },
    { label: "Thailand", code: "TH" },
    { label: "Timor-Leste", code: "TL" },
    { label: "Togo", code: "TG" },
    { label: "Tokelau", code: "TK" },
    { label: "Tonga", code: "TO" },
    { label: "Trinidad and Tobago", code: "TT" },
    { label: "Tunisia", code: "TN" },
    { label: "Turkey", code: "TR" },
    { label: "Turkmenistan", code: "TM" },
    { label: "Turks and Caicos Islands", code: "TC" },
    { label: "Tuvalu", code: "TV" },
    { label: "Uganda", code: "UG" },
    { label: "Ukraine", code: "UA" },
    { label: "United Arab Emirates", code: "AE" },
    { label: "United Kingdom", code: "GB" },
    { label: "United States", code: "US" },
    { label: "United States Minor Outlying Islands", code: "UM" },
    { label: "Uruguay", code: "UY" },
    { label: "Uzbekistan", code: "UZ" },
    { label: "Vanuatu", code: "VU" },
    { label: "Venezuela", code: "VE" },
    { label: "Viet Nam", code: "VN" },
    { label: "Virgin Islands, British", code: "VG" },
    { label: "Virgin Islands, U.S.", code: "VI" },
    { label: "Wallis and Futuna", code: "WF" },
    { label: "Western Sahara", code: "EH" },
    { label: "Yemen", code: "YE" },
    { label: "Zambia", code: "ZM" },
    { label: "Zimbabw", code: "ZN" },
  ],
  expiringIn: [
    { label: "1 Day", code: "1" },
    { label: "3 Days", code: "3" },
    { label: "7 Days", code: "7" },
    { label: "30 Days", code: "30" },
    { label: "2 Months", code: "60" },
  ],
  dayOfWeek: [
    { label: "All", code: -1 },
    { label: "Monday", code: 1 },
    { label: "Tuesday", code: 2 },
    { label: "Wednesday", code: 3 },
    { label: "Thursday", code: 4 },
    { label: "Friday", code: 5 },
    { label: "Saturday", code: 6 },
    { label: "Sunday", code: 0 },
  ],
  listOfSpecialPricingTypes: [
    {
      label: 'Dollar',
      code: 'DOLLAR'
    },
    {
      label: 'Percentage',
      code: 'PERCENTAGE'
    },
  ]
};

module.exports = Constants;
