const Entities = {
  CUSTOMER: "customer",
  EMPLOYEE: "employee",
  GROUP: "group",
  PRODUCT: "product",
  MENU: "menu",
  ITEM: "item",
  ONLINESTOREGROUP: "onlineStoreGroup",
  ORDER: "order",
  ORGANIZATION: "organization",
  MSOGROUP: "msoGroup",
  MSOSUPPLIER: "msoSupplier",
  MSOPRODUCT: "msoProduct",
  MSOMenu: "msoMenu",
  MSOCHARTOFACCOUNT: "msoChartOfAccount",
  CHARTOFACCOUNT: "chartOfAccount",
  PURCHASEORDER: "purchaseOrder",
  ROOM: "room",
  SPECIALPRICING: "specialPricing",
  STORE: "store",
  SUPPLIER: "supplier",
  SUPPLIERAGENT: "supplierAgent",
  GIFTCARD: "giftCard",
  CREDITMEMO: "creditMemo",
  COUPON: "coupon",
  MSOCOUPON: "msoCoupon",
  PAYMENT: "payment",
  TAXSETUP: "taxSetup",
  STATEINVENTORY: "stateInventory",
  QUEUE: "queue",
  FLIGHT: "flight",
  JOURNAL: "journal",
  TIMESHEET: "timeSheet",
  RETURNITEM: "returnItem",
  FULLFILLMENT: "fulfillment",
  CASHRECONCILIATION: "cashReconciliation",
  INVENTORYCOUNT: "inventoryCount",
  BRAND: "brand",
};

module.exports = Entities;
