// Order Editor Inputs
import Constants from "@/resources/Constants";

// Page Header
export const pageHeader = {
  title: "Organization",
  isEditorView: true,
  buttonLabel: "Save",
};

// Main Section
export const mainInputs = [
  {
    id: "name",
    type: "input",
    title: "Organization Name",
    placeholder: "Organization Name",
    focus: true,
  },
  {
    id: "address",
    placeholder: "Address",
    type: "input",
    title: "Address",
    class: "grid-row-two-column",
  },
  {
    id: "city",
    placeholder: "City",
    type: "input",
    title: "City",
  },
  {
    id: "state",
    placeholder: "State",
    type: "select",
    title: "State",
    options: Constants.listOfStates,
  },
  {
    id: "zip",
    placeholder: "Zip",
    type: "mask",
    title: "Zip",
    mask: Constants.inputMasks.defaultZip,
  },
  {
    id: "county",
    placeholder: "County",
    type: "input",
    title: "County",
  },
  {
    id: "phone",
    placeholder: "Phone",
    type: "mask",
    title: "Phone",
    mask: Constants.inputMasks.defaultPhone,
  },
  {
    id: "email",
    type: "input",
    title: "Email",
    placeholder: "Email",
  },
  {
    id: "enforceMso",
    type: "checkbox",
    title: "Enforce MSO",
  },
  {
    id: "timeZone",
    type: "input",
    title: "Time Zone",
    placeholder: "Time Zone",
  },
];
