<template>
  <div>
    <div class="section-base report-filter padded elevated-0 bordered">
      <div class="report-filter__filters">
        <v-select
          v-if="filterByStoreOrOrg"
          :reduce="(option) => option.code"
          v-model="selectedReportFor"
          class="input-text__select"
          :options="reportFor"
          :clearable="false"
          label="name"
          optionValue="code"
        />

        <v-select
          v-if="filterBySaleType && multiSaleTypeStore"
          :reduce="(option) => option.code"
          v-model="selectedReportSaleType"
          class="input-text__select"
          :options="getReportSaleType"
          :clearable="false"
          label="name"
          optionValue="code"
        />

        <v-select
          v-if="filterByItemLocation"
          :reduce="(option) => option.code"
          v-model="selectedReportItemLocation"
          class="input-text__select"
          :options="reportItemLocation"
          :clearable="false"
          label="name"
          optionValue="code"
        />

        <v-select
          v-if="filterByItemType"
          :reduce="(option) => option.code"
          v-model="selectedReportItemType"
          class="input-text__select"
          :options="reportItemType"
          :clearable="false"
          label="name"
          optionValue="code"
        />

        <v-select
          v-if="filterByCustomerLoyalty"
          :reduce="(option) => option.code"
          v-model="selectedReportCustomerLoyalty"
          class="input-text__select"
          :options="reportCustomerLoyalty"
          :clearable="false"
          label="name"
          optionValue="code"
        />

        <v-select
          v-if="filterByAllThis"
          :reduce="(option) => option.code"
          v-model="selectedReportAllThis"
          class="input-text__select"
          :options="reportAllThis"
          :clearable="false"
          label="name"
          optionValue="code"
        />

        <BaseInput
          v-if="filterByText"
          :placeholder="filterTextPlaceHolder"
          controlType="text"
          v-model="filterText"
        />

        <BaseInput
          v-if="filterByRange"
          :placeholder="filterByRangePlaceholderFrom"
          controlType="text"
          v-model="filterRange.from"
        />
        <BaseInput
          v-if="filterByRange"
          :placeholder="filterByRangePlaceholderTo"
          controlType="text"
          v-model="filterRange.to"
        />

        <Calendar
          v-if="filterByDateFromOnly"
          v-model="selectedPeriod.from"
          :placeholder="'From'"
        />

        <Calendar
          v-if="filterByTime"
          v-model="selectedPeriod.from"
          :placeholder="'From'"
          hourFormat="12"
        />
        <Calendar
          v-if="filterByTime"
          v-model="selectedPeriod.to"
          :placeholder="'To'"
          hourFormat="12"
        />

        <Calendar
          v-if="filterByTimeAsOf"
          v-model="selectedPeriod.from"
          :placeholder="'From'"
          hourFormat="12"
        />

        <div
          v-if="filterButtons && filterButtons.length > 0"
          class="filter-buttons-container"
        >
          <inline-button
            :type="filterButton.isSelected ? 'primary' : 'plain-primary'"
            @click="() => emitFilterButtonClick(filterButton)"
            v-for="filterButton in filterButtons"
            :key="filterButton._id"
            :label="filterButton.label"
          />
        </div>

        <BaseBtn
          @click="submit"
          label="Submit"
          size="sm"
          :disabled="isLoading"
          :isLoading="isLoading"
        />
      </div>
    </div>
    <div class="percentage-bar">
      <div
        v-if="percentage != 100"
        class="percentage-bar__value"
        :style="{ width: `${percentageBar}` }"
      ></div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Calendar from "primevue/calendar";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EntityFilter",
  data() {
    return {
      selectedPeriod: {
        from: new Date(new Date().setDate(new Date().getDate() - 7)),
        to: new Date(),
      },
      filterRange: {
        from: "",
        to: "",
      },
      selectedReportFor: "store",
      reportFor: [
        { name: "Store", code: "store" },
        { name: "Organization", code: "organization" },
      ],
      selectedReportSaleType: "",

      selectedReportItemLocation: "working inventory",
      reportItemLocation: [
        { name: "working inventory", code: "working inventory" },
        { name: "store", code: "store" },
      ],

      selectedReportItemType: "cannabis",
      reportItemType: [
        { name: "cannabis", code: "cannabis" },
        { name: "non-cannabis", code: "non-cannabis" },
      ],

      selectedReportAllThis: "this",
      reportAllThis: [
        { name: "This Record", code: "this" },
        { name: "All Records", code: "all" },
      ],

      selectedReportCustomerLoyalty: "ccp",
      reportCustomerLoyalty: [
        { name: "ccp", code: "ccp" },
        { name: "discount", code: "discount" },
        { name: "employee", code: "employee" },
        { name: "loyalty", code: "loyalty" },
        { name: "veteran", code: "veteran" },
        // clutch specific code
        { name: "clutch", code: "clutch" },
      ],
      // multiSaleTypeStore is true only if the store is med and rec
      multiSaleTypeStore: false,
      filterText: "",
      filterBtnValue: "",
    };
  },
  props: {
    filterButtons: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    filterByTime: {
      default: false,
    },
    filterByTimeAsOf: {
      default: false,
    },
    filterByText: {
      default: false,
    },
    filterTextPlaceHolder: {
      type: String,
      default: "Search",
    },
    filterByRange: {
      type: Boolean,
      default: false,
    },
    filterByRangePlaceholderFrom: {
      type: String,
      default: "Search From",
    },
    filterByRangePlaceholderTo: {
      type: String,
      default: "Search To",
    },
    filterByStoreOrOrg: {
      type: Boolean,
      default: true,
    },
    filterBySaleType: {
      type: Boolean,
      default: true,
    },
    filterByItemLocation: {
      type: Boolean,
      default: false,
    },
    filterByCustomerLoyalty: {
      type: Boolean,
      default: false,
    },
    filterByAllThis: {
      type: Boolean,
      default: false,
    },
    filterByItemType: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    initialSelectedPeriod: {
      type: Object,
    },
    triggerFilterOnLoad: {
      type: Boolean,
      default: false,
    },
    filterByDateFromOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Calendar,
    BaseBtn,
    BaseInput,
  },
  watch: {
    currentStore(store) {
      this.setDefaultReportSaleType(store);
    },
    selectedReportSaleType(selectedReportSaleType) {
      this.setSaleType(selectedReportSaleType);
    },
  },
  created() {
    if (this.initialSelectedPeriod) {
      this.selectedPeriod = this.initialSelectedPeriod;
    }
    if (this.triggerFilterOnLoad) {
      this.submit();
    }
  },
  mounted() {
    this.setDefaultReportSaleType(this.currentStore);
  },
  computed: {
    ...mapGetters({ currentStore: "storeModule/currentStore" }),
    percentageBar() {
      if (!this.percentage) return `${0}%`;
      return `${this.percentage}%`;
    },
    getReportSaleType() {
      if (
        (this.currentStore.isMedical && this.currentStore.isRecreational) ||
        this.selectedReportFor == "organization"
      ) {
        return [
          {
            name: "medical and recreational",
            code: "medical and recreational",
          },
          { name: "medical", code: "medical" },
          { name: "recreational", code: "recreational" },
        ];
      }
      if (this.currentStore.isMedical) {
        return [{ name: "medical", code: "medical" }];
      }
      if (this.currentStore.isRecreational) {
        return [{ name: "recreational", code: "recreational" }];
      }
      return [
        { name: "medical and recreational", code: "medical and recreational" },
        { name: "medical", code: "medical" },
        { name: "recreational", code: "recreational" },
      ];
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
      setSaleType: "reports/setSaleType",
    }),
    clicked() {
      this.$emit("click");
      if (this.to && !this.disabled) {
        router.push(this.to);
      }
    },
    emitFilterButtonClick(filterButton) {
      this.filterBtnValue = filterButton.name;
      const payload = { filterButton: filterButton };
      this.$emit("filterButtonClicked", payload);
    },
    validatePeriod(period) {
      if (period.from > period.to) {
        this.setMessage({
          title: "Date error",
          text: `"From" date can't be grater then "To"`,
          type: "error",
        });
        return false;
      }
      return true;
    },
    validateFilterText() {
      if (!this.filterText.trim()) return false;
      return true;
    },
    validateFilterRange() {
      this.filterRange.from = this.filterRange.from.trim();
      this.filterRange.to = this.filterRange.to.trim();
      if (!this.filterRange.from && !this.filterRange.from) return false;

      return true;
    },
    getPayload() {
      let to = new Date(
        new Date(this.selectedPeriod.to).setHours(23, 59, 59, 999)
      );
      const period = {
        from: this.selectedPeriod.from,
        to: to,
      };

      const payload = {
        selectedReportSaleType: this.selectedReportSaleType,
        selectedReportFor: this.selectedReportFor,
      };

      if (this.filterByItemLocation) {
        payload.selectedReportItemLocation = this.selectedReportItemLocation;
      }

      if (this.filterByCustomerLoyalty) {
        payload.selectedReportCustomerLoyalty =
          this.selectedReportCustomerLoyalty;
      }

      if (this.filterByAllThis) {
        payload.selectedReportAllThis =
          this.selectedReportAllThis;
      }

      if (this.filterByItemType) {
        payload.selectedReportItemType = this.selectedReportItemType;
      }

      if (this.filterByTime) {
        const isValid = this.validatePeriod(period);
        if (!isValid) return;
        payload.selectedPeriod = period;
      }

      if (this.filterByTimeAsOf) {
        payload.selectedPeriod = period;
      }

      if (this.filterByDateFromOnly) {
        payload.selectedPeriod = period;
      }

      if (this.$route.query.customerId) {
        this.filterText = this.$route.query.customerId;
        this.filterByText = true;
      }

      if (this.$route.query.supplierId) {
        this.filterText = this.$route.query.supplierId;
        this.filterByText = true;
      }

      if (this.$route.query.itemId) {
        this.filterText = this.$route.query.itemId;
        this.filterByText = true;
      }

      if (this.$route.query.inventoryCountId) {
        this.filterText = this.$route.query.inventoryCountId;
        this.filterByText = true;
      }

      if (this.filterByText && this.validateFilterText()) {
        payload.filterText = this.filterText.trim();
      }

      if (this.filterByRange && this.validateFilterRange()) {
        const filterRangeData = {};
        if (this.filterRange.from) {
          filterRangeData.from = this.filterRange.from;
        }
        if (this.filterRange.to) {
          filterRangeData.to = this.filterRange.to;
        }
        payload.filterRange = filterRangeData;
      }

      if (this.filterButton && this.filterBtnValue) {
        payload.extraFilter = this.filterBtnValue;
      }
      return payload;
    },
    setDefaultReportSaleType(store) {
      if (!this.filterBySaleType) {
        return (this.selectedReportSaleType = "medical and recreational");
      }
      if (store.isMedical && store.isRecreational) {
        this.multiSaleTypeStore = true;
        return (this.selectedReportSaleType = "medical and recreational");
      }
      if (store.isMedical) {
        return (this.selectedReportSaleType = "medical");
      }
      if (store.isRecreational) {
        return (this.selectedReportSaleType = "recreational");
      }
    },
    submit() {
      const payload = this.getPayload();
      this.$emit("submit-filter", payload);
    },
  },
};
</script>

<style lang="scss">
@import "./_reportFilter.scss";
</style>
