import { getDefaultChartData } from "@/views/dashboards/chart.helpers.js"


export const getDefaultTopProductsChartData = (theme) => {
    const topProductsChartData = getDefaultChartData('topList', theme)
    topProductsChartData.options = {
        getKey: (entry) => entry.stringId + entry.type,
        getName: (entry) => entry.name,
        getColorIndex: (entry) => {
            if (!entry.isCannabis) {
                return 2
            }
            if (entry.type == 'recreational') {
                return 0
            }
            if (entry.type == 'medical') {
                return 1
            }
        },
        getLink: (entry) => {
            return `/products/productEditor?id=${entry.stringId}`
        }
    }
    return topProductsChartData
}

export const populateTopProductsChartData = (topProductsChartData, newData) => {
    if (!newData) return topProductsChartData
    const newDataArray = []
    for (const [productId, productEntry] of Object.entries(newData)) {
        newDataArray.push({ count: productEntry.count, entry: productEntry.product })
    }
    topProductsChartData.data = newDataArray.sort((a, b) => b.count - a.count)
    return topProductsChartData
}