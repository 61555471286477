<template>
  <div id="OrderTypeSelectionPopUp">
    <div v-on:click.stop="close" class="overlay-div"></div>
    <div class="default-pop-up-center-div center bordered">
      <span class="h1">Select Location</span>
      <br />
      <br />
      <div class="padded center">
        <div
          class="interspace button-secondary full-width-r"
          @click="handlePositiveCallback(Constants.order.types.inState)"
        >
          In State
        </div>
        <div
          class="interspace button-secondary full-width-r"
          @click="handlePositiveCallback(Constants.order.types.outOfState)"
        >
          Out of State
        </div>
        <br />
        <br />
        <div
          class="interspace button-info full-width-r"
          @click="() => this.setOrderTypeSelectionPopUp({ isOpen: false })"
        >
          Cancel
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Configuration from "@/resources/Configuration";
import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";

export default {
  name: "OrderTypeSelectionPopUp",
  data() {
    return { Constants: Constants };
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.setOrderTypeSelectionPopUp({ isOpen: false });
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({
      orderTypeSelectionPopUp: "orderModule/orderTypeSelectionPopUp",
      employee: "employeeModule/employee",
    }),
  },
  methods: {
    ...mapActions({
      setOrderTypeSelectionPopUp: "orderModule/setOrderTypeSelectionPopUp",
      setEmployeeConfirmationPopUp:
        "employeeModule/setEmployeeConfirmationPopUp",
      saveOrder: "orderModule/saveOrder",
    }),
    close: function () {
      this.setOrderTypeSelectionPopUp({ isOpen: false });
    },
    performSetEmployeeConfirmationPopUp: function (employeeConfirmationPopUp) {
      if (Utilities.isEmployeeConfirmationExpired(this.employee)) {
        this.setOrderTypeSelectionPopUp({
          isOpen: false,
          positiveCallback: this.orderTypeSelectionPopUp.positiveCallback,
        });
        this.setEmployeeConfirmationPopUp(employeeConfirmationPopUp);
      } else {
        this.setOrderTypeSelectionPopUp({
          isOpen: false,
          positiveCallback: this.orderTypeSelectionPopUp.positiveCallback,
        });
        employeeConfirmationPopUp.positiveCallback();
      }
    },
    performSaveOrder: function (orderType) {
      this.saveOrder({
        order: {
          _id: "NEW",
          type: orderType,
          source: "pos",
        },
        positiveCallback: (order) =>
          this.orderTypeSelectionPopUp.positiveCallback(order),
      });
    },
    handlePositiveCallback(type) {
      this.orderTypeSelectionPopUp.positiveCallback(type);
      this.setOrderTypeSelectionPopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss"></style>
