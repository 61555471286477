<template>
  <div class="top-list">
    <div class="top-list__list">
      <div class="top-list__values">
        <div v-for="row in data" :key="getKey(row.entry)" class="top-list__value">
          {{ row.count }}
        </div>
      </div>
      <div class="top-list__entries">
        <div v-for="row in data" :key="getKey(row.entry)" class="top-list__entry"
          @click="() => goToEntityPage(row.entry)">
          <div class="top-list__entry-text">
            {{ getName(row.entry) }}
          </div>
          <div class="top-list__row-bar"
            :style="`width: ${row.count / max * 100}%; background-color: ${getColor(row.entry)}`">
            {{ getName(row.entry) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopList",
  data() {
    return {
    }
  },
  props: {
    chartData: { type: Object, default: () => ({ data: [], options: {} }) },
    filter: { type: Function, default: (entry) => entry }
  },
  computed: {
    data() {
      return this.chartData.data.filter(this.filter).slice(0, 50)
    },
    max() {
      return Math.max(...this.data.map(o => o.count))
    }
  },
  methods: {
    getKey(entry) {
      return this.chartData.options.getKey(entry)
    },
    getName(entry) {
      return this.chartData.options.getName(entry)
    },
    getColor(entry) {
      let paletteIndex = 0
      if (this.chartData.options.getColorIndex) {
        paletteIndex = this.chartData.options.getColorIndex(entry)
      }
      return this.chartData.palette?.[paletteIndex] || 'white'
    },
    goToEntityPage(entry) {
      if (this.chartData.options.getLink) {
        window.open(this.chartData.options.getLink(entry), '_blank').focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./_top-list.scss";
</style>

