<template>
  <div class="value-graph__value" :class="`value-graph__value--${size}`">{{ value }}</div>
</template>

<script>
import numeral from 'numeral'

export default {
  name: "ValueGraph",
  data() {
    return {
    }
  },
  props: {
    chartData: { type: Object, default: () => ({}) },
    dataType: String,
    size: { type: String, default: 'md' },
  },
  computed: {
    value() {
      const v = this.chartData?.value || 0
      let returnValue = 0
      switch (this.dataType) {
        case 'money':
          returnValue = numeral(v).format('$0,0');

          break;

        default:
          returnValue = numeral(v).format('0.[000]a');
          break;
      }

      return returnValue

    }
  }
};
</script>

<style lang="scss" scoped>
@import "./_value-graph.scss";
</style>

