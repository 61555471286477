export const creditMemoFilterCriteria = [
  {
    label: "MemoType",
    code: "memoType",
    field: { type: "input", label: "memoType" },
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const creditMemoColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Date Created",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Customer #",
    id: "customer",
    type: "name",
  },
  {
    title: "Memo Type",
    id: "memoType",
  },
  {
    title: "Memo Balance",
    id: "memoBalance",
    type: "money",
  },
  {
    title: "Memo Total",
    id: "memoTotal",
    type: "money",
  },
  {
    title: "Memo Amount Used",
    id: "memoAmountUsed",
    type: "money",
  },
];

import {
  filterCriteria as giftCardFilterCriteria,
  columnHeaders as giftCardColumnHeaders,
} from "@/views/GiftCard/inputs";

import * as Entities from "@/resources/Entities";

export const inputs = [
  {
    id: "credit-memo",
    placeholder: "Select Credit Memo",
    type: "search",
    title: "Credit Memo",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.CREDITMEMO,
    columnHeaders: creditMemoColumnHeaders,
    filterCriteria: creditMemoFilterCriteria,
    isClearable: true,
    fieldsToDisplay: [{ label: "Type", id: "memoType" }],
    filter: {
     memoBalance: { $gt: 0 },
     isPostedToJournal: true,
     isArchived: false,

     //the memoType filter is being overwritten for POS-Payments
     memoType: { $in: ['Refund', 'Cash Mistake', 'Reward'] }
    },
    sort: {
     createdAt: 1,
    },
  },
  {
    id: "gift-card",
    placeholder: "Select Gift Card",
    type: "search",
    title: "Gift Card",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.GIFTCARD,
    columnHeaders: giftCardColumnHeaders,
    filterCriteria: giftCardFilterCriteria,
    isClearable: true,
  },
];
