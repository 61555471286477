<template>
  <div class="returns">
    <BaseModal v-if="isPrintLabelOpen" @toggle-modal="togglePrintLableModal" title="Print Label">
      <div class="label-print-selection">
        <span class="h3 padded">Select a label printer</span>
        <div class="padded left">
          <div v-if="labelPrinters.length > 0" class="printers">
            <BaseBtn v-for="lp in labelPrinters" :key="lp._id" :label="lp.name"
              :colorType="lp._id == selectedLabelPrinter._id ? 5 : undefined" @click="selectedLabelPrinter = lp" />
          </div>
          <div v-else>No label printers for this store</div>
        </div>
        <span class="h3 padded">Number of copies</span>
        <BaseInput placeholder="Insert number of copies" type="number" v-model="printLabelQuantity" :applyFocus="true" />
        <BaseBtn :disabled="!selectedLabelPrinter._id || !printLabelQuantity || printLabelQuantity <= 0"
          class="label-print-selection__action" @click="printLabels" iconGroup="fas" iconName="print" label="Print">
        </BaseBtn>
      </div>
    </BaseModal>

    <div class="padded elevated-0 bordered">
      <div class="
          two-columns-layout
          two-columns-layout--responsive
          two-columns-layout--border-bottom
          right
        ">
        <div class="button-wrapper">
          <div v-if="!isNotEmpty(selectedItem.selectedReturn)">
            <BaseBtn @click="() => setSelectedReturn()" label="New" iconGroup="fas" iconName="plus" :colorType="0"
              :disabled="!isEdit" />
          </div>
          <div class="button-wrapper" v-if="isNotEmpty(selectedItem.selectedReturn)">
            <BaseBtn @click="() => removeSelectedReturn()" label="Close" iconGroup="fas" iconName="times"
              :colorType="3" />
            <BaseBtn @click="() => archiveReturn(selectedItem.selectedReturn._id)
              " :label="selectedItem.selectedReturn.isArchived
                  ? 'Unarchive'
                  : 'Archive'
                  " iconGroup="fas" iconName="trash" :colorType="1" :disabled="!isEdit ||
                  selectedItem.selectedReturn.isPostedToJournal ||
                  selectedItem.selectedReturn.isPostedToProducts ||
                  selectedItem.selectedReturn.isPostedToState ||
                  selectedItem.selectedReturn._id === 'NEW'
                " />
            <BaseBtn @click="() => performSave()" label="Save" iconGroup="fas" iconName="save" :colorType="0" :disabled="!canEdit ||
              selectedItem.selectedReturn.isPostedToProducts ||
              selectedItem.selectedReturn.isPostedToJournal ||
              selectedItem.selectedReturn.isPostedToState
              " />
            <BaseBtn @click="() => performPostReturn()" label="Post Return" iconGroup="fas" iconName="paper-plane"
              :colorType="2"
              :disabled="!canEdit ||
                selectedItem.selectedReturn._id === 'NEW' ||
                selectedItem.selectedReturn.isPostedToProducts ||
                selectedItem.selectedReturn.isPostedToState ||
                selectedItem.selectedReturn.isPostedToJournal ||
                selectedItem.selectedReturn.isArchived
                " />
          </div>
        </div>
      </div>
      <div v-if="isNotEmpty(selectedItem.selectedReturn) && isEdit">
        <div>
          <div class="returns__inputs">
            <div class="returns__totals bordered__highlighted--neutral">
              <h4 class="returns__totals__title">Return Totals</h4>

              <Input :model="getValue('selectedReturn.room')" @updateModel="(event) => setValue('selectedReturn.room', event)"
                :inputData="getInput('selectedReturn.room')"
                v-if="selectedItem.selectedReturn.isPostedToJournal == false &&
                selectedItem.selectedReturn.isPostedToProducts == false &&
                selectedItem.selectedReturn.isPostedToState == false"
                :isEdit="canEdit" />
              <Input :model="getValue('selectedReturn.room')" @updateModel="(event) => setValue('selectedReturn.room', event)"
                :inputData="getInput('selectedReturn.room')"
                v-if="selectedItem.selectedReturn._id !== 'NEW' &&
                (selectedItem.selectedReturn.isPostedToJournal == true ||
                selectedItem.selectedReturn.isPostedToProducts == true ||
                selectedItem.selectedReturn.isPostedToState == true)" />

              <Input :model="getValue('selectedReturn.quantity')" @updateModel="(event) => setValue('selectedReturn.quantity', event)"
                :inputData="getInput('selectedReturn.quantity')"
                v-if="selectedItem.selectedReturn.isPostedToJournal == false &&
                selectedItem.selectedReturn.isPostedToProducts == false &&
                selectedItem.selectedReturn.isPostedToState == false"
                :isEdit="canEdit" />

              <Input :model="getValue('selectedReturn.quantity')" @updateModel="(event) => setValue('selectedReturn.quantity', event)"
                :inputData="getInput('selectedReturn.quantity')"
                v-if="selectedItem.selectedReturn._id !== 'NEW' &&
                (selectedItem.selectedReturn.isPostedToJournal == true ||
                selectedItem.selectedReturn.isPostedToProducts == true ||
                selectedItem.selectedReturn.isPostedToState == true)" />

              <Input :model="getValue('actualUnitCost')" @updateModel="(event) => setValue('actualUnitCost', event)"
                :inputData="getInput('actualUnitCost')" :isEdit="canEdit" />
              <Input :model="getValue('subTotal')" @updateModel="(event) => setValue('subTotal', event)"
                :inputData="getInput('subTotal')" :isEdit="canEdit" />
                <hr />

              <div class="returns__totals__row">
                <Input :model="getValue('freight')" @updateModel="(event) => setValue('freight', event)"
                  :inputData="getInput('freight')"
                  v-if="selectedItem.selectedReturn.isPostedToJournal == false &&
                  selectedItem.selectedReturn.isPostedToProducts == false &&
                  selectedItem.selectedReturn.isPostedToState == false"
                  :isEdit="canEdit" />

                <Input :model="getValue('freight')" @updateModel="(event) => setValue('freight', event)"
                  :inputData="getInput('freight')"
                  v-if="selectedItem.selectedReturn._id !== 'NEW' &&
                  (selectedItem.selectedReturn.isPostedToJournal == true ||
                  selectedItem.selectedReturn.isPostedToProducts == true ||
                  selectedItem.selectedReturn.isPostedToState == true)" />

                <Input :model="getValue('taxTotal')" @updateModel="(event) => setValue('taxTotal', event)"
                  :inputData="getInput('taxTotal')" :isEdit="canEdit" />
              </div>

              <Input :model="getValue('returnTotal')" @updateModel="(event) => setValue('returnTotal', event)"
                :inputData="getInput('returnTotal')" :isEdit="canEdit" />
              <hr />
            </div>
            <div>
              <div class="returns__row">
                <Input :model="getValue('selectedReturn.isArchived')" @updateModel="(event) => setValue('selectedReturn.isArchived', event)
                  " :inputData="getInput('selectedReturn.isArchived')" :isEdit="canEdit" />
                <Input :model="getValue('selectedReturn.isPostedToState')" @updateModel="(event) =>
                  setValue('selectedReturn.isPostedToState', event)
                  " :inputData="getInput('selectedReturn.isPostedToState')" :isEdit="canEdit" />
                <Input :model="getValue('selectedReturn.isPostedToProducts')" @updateModel="(event) =>
                  setValue('selectedReturn.isPostedToProducts', event)
                  " :inputData="getInput('selectedReturn.isPostedToProducts')" :isEdit="canEdit" />
                <Input :model="getValue('selectedReturn.isPostedToJournal')" @updateModel="(event) =>
                  setValue('selectedReturn.isPostedToJournal', event)
                  " :inputData="getInput('selectedReturn.isPostedToJournal')" :isEdit="canEdit" />
              </div>
            </div>
          </div>
          <div>
            <Input :model="getValue('selectedReturn.detailedDescription')" @updateModel="(event) =>
              setValue('selectedReturn.detailedDescription', event)
              " :inputData="getInput('selectedReturn.detailedDescription')" :isEdit="canEdit" />
          </div>
        </div>
        <About :embedded="true" v-if="selectedItem.selectedReturn._id !== 'NEW'"
          :entity="selectedItem.selectedReturn" />
      </div>
    </div>
    <br />
    <div v-if="selectedItem.returns && selectedItem.returns.length > 0" class="table-container bordered">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Archived</th>
            <th class="cell">Posted to State</th>
            <th class="cell">Posted to Products</th>
            <th class="cell">Posted to Journal</th>
            <th class="cell">Quantity</th>
            <th class="cell">Room</th>
            <th class="cell">Created At</th>
            <th class="cell">Id</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="returnItem in selectedItem.returns" :key="returnItem._id" class="row">
            <td class="cell">
              <inline-button label="Open" type="plain-primary responsive-column form-height wide"
                @click="() => setSelectedReturn(returnItem)" :disabled="!isEdit" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="returnItem.isArchived" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="returnItem.isPostedToState" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="returnItem.isPostedToProducts" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="returnItem.isPostedToJournal" />
            </td>
            <td class="cell">{{ returnItem.quantity }}</td>
            <td class="cell">{{ returnItem.room.name || returnItem.room.label }}</td>
            <td class="cell">
              {{
                moment(returnItem.createdAt).format(
                  Constants.dates.defaultDateFormat
                )
              }}
            </td>
            <td class="cell">
              <span>{{ returnItem._id }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import BaseInput from "@/components/Base/Input/Base.vue";

import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";
import Utilities from "@/lib/Utilities";

import { prefillEntityBeforeRendering } from "@/utils/prefill-entity-before-rendering/item";
import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/item";
import { returnsInputs } from "./inputs";


export default {
  name: "Returns",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      Entities,
      returnsInputs,
      isPrintLabelOpen: false,
      returnToPrint: {},
      selectedLabelPrinter: {},
      printLabelQuantity: 1
    };
  },
  components: {
    Input,
    BaseBtn,
    About,
    BaseModal,
    BaseInput
  },
  props: {
    isEdit: {
      type: Boolean,
    },
    rooms: {
      type: Array,
      default: () => ([])
    }
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters({
      item: "itemModule/item",
      currentStore: "storeModule/currentStore",
    }),
    labelPrinters() {
      return this.currentStore.labelPrinters
    },
    selectedItem: {
      get() {
        return prefillEntityBeforeRendering(this.item);
      },
      set(entity) {
        return entity;
      },
    },
    subTotal() {
      return (
        parseFloat(this.selectedItem.actualUnitCost) *
        parseFloat(this.selectedItem.selectedReturn.quantity)
      );
    },
    taxTotal() {
      return this.selectedItem.tax * this.subTotal;
    },
    returnTotal() {
      return (
        parseFloat(this.taxTotal) +
        parseFloat(this.selectedItem.freight) +
        parseFloat(this.subTotal)
      );
    },

    canEdit() {
      return this.isEdit && !this.selectedItem?.selectedReturn?.isArchived;
    },
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      setItem: "itemModule/setItem",
      saveItem: "itemModule/saveItem",
      loadingStatus: "itemModule/loadingStatus",
      getItemById: "itemModule/getItemById",
      printStoreTransferLabel: "itemModule/printStoreTransferLabel",
    }),
    printLabels() {
      const payload = {
        id: this.selectedItem._id,
        return: this.returnToPrint,
        labelPrinter: this.selectedLabelPrinter,
        printLabelQuantity: this.printLabelQuantity
      }
      this.printStoreTransferLabel(payload)
    },
    togglePrintLableModal(event) {
      const isOpen = event.isOpen;
      this.isPrintLabelOpen = isOpen;

      if (!isOpen) {
        this.returnToPrint = {}
      }
    },
    onPrintHandler(returnItem) {
      this.isPrintLabelOpen = true;
      this.returnToPrint = returnItem
    },
    getInput(field) {
      const input = this.returnsInputs.find((i) => i.id == field);
      if (field == "selectedReturn.room") {
        input.options = this.rooms
      }
      return input
    },

    getValue(field) {
      if (field === "subTotal") {
        return this.subTotal;
      } else if (field === "returnTotal") return this.returnTotal;
      else if (field === "taxTotal") return this.taxTotal;
      return _.get(this.selectedItem, field);
    },
    setValue(field, value) {
      _.set(this.selectedItem, field, value);
    },
    isNotEmpty(item) {
      return !_.isEmpty(item);
    },
    clearItemFromSearch: function (entity) {
      this.selectedItem[entity] = null;
    },
    setSelectedReturn(returnItem) {
      const NEW_RETURN = {
        _id: "NEW",
        isPostedToState: false,
        isPostedToProducts: false,
        isPostedToJournal: false,
        isArchived: false,
        quantity: 0,
        tax: 0,
        freight: 0,
        availableQuantity: 0,
        detailedDescription: "",
      };
      this.$set(this.selectedItem, "selectedDisposal", undefined);
      this.$set(this.selectedItem, "selectedAdjustment", undefined);
      if (!returnItem) {
        returnItem = NEW_RETURN
      } else {
        if (!returnItem.room.label) {
          returnItem.room = this.rooms.find(r => r.code == returnItem.room._id)
        }
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#return").offset().top,
          },
          300
        );
      }
      this.$set(this.selectedItem, "selectedReturn", returnItem);
    },
    archiveReturn() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.selectedItem.selectedReturn.isArchived
          ? Constants.strings.unArchiveConfirmation
          : Constants.strings.archiveConfirmation,
        positiveCallback: () => {
          this.selectedItem.archiveReturn = true;
          this.performSave();
        },
      });
    },

    removeSelectedReturn() {
      this.selectedItem.selectedReturn = {};
    },
    performPostReturn() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.selectedItem.selectedReturn.isPostedToJournal
          ? Constants.strings.reverseReturnConfirmation
          : Constants.strings.postReturnConfirmation,
        positiveCallback: () => {
          // for now we allow just posting without reversing.

          // if (this.selectedItem.selectedReturn.isPostedToJournal) {
          //   this.selectedItem.postReturnReverse = true;
          // } else {
          this.selectedItem.postReturn = true;
          // }

          this.performSave();
        },
      });
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      } else {
        this.selectedItem[entity] = item;
      }
    },
    performSave(type) {
      if (this.loadingStatus.saveItem) {
        return;
      }
      const itemToSave = normalizeEntityBeforeSaving(this.selectedItem);
      this.saveItem({
        item: itemToSave,
        positiveCallback: (item) => {
          const id = item.id ? item.id : item._id;
          this.setItem(item);
          const module =
            this.entity !== Entities.PRODUCT
              ? `${this.entity}s`
              : `${this.entity}`;
          if (this.$route.query.id !== id) {
            this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
          }
          this.$store.dispatch(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (!type?.archived) this.$emit("toggle-edit");
        },
        negativeCallback: () => {
          this.selectedItem.postReturn = false;
          //if (type && type.postReturn) {
          if (type && type.postAdjustment) {
            this.getItemById({
              _id: this.$route.query.id,
              positiveCallback: (item) => {
                this.setPageTitle(
                  `Item ${item.batchId} - ${item.product?.name}`
                );
              },
            });
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_returns.scss";
</style>

