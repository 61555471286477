<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Sales Order Status For Buyer" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesOrderStatusBuyer',
          label: 'Sales Order Status For Buyer',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByDateFromOnly="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Supplier</th>
                <th align="left">Group</th>
                <th align="right">Units On Hand</th>
                <th align="right">On Hand Wholesale</th>
                <th align="right">Sales (units)</th>
                <th align="right">Sales</th>
                <th align="right">Units On Hand/Sales</th>
                <th align="right">$ On Hand/$ Sales</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(data, index) of formattedData" :key="index" :class="{ total3: data.isTotal }">
                <td v-if="!data.isTotal">{{ data.supplier }}</td>
                <td v-else>TOTALS</td>
                <td>{{ data.group }}</td>
                <td align="right"> {{ data.itemQuantity }}</td>
                <td align="right"> {{ Utilities.formatPrice(data.actualCost) }}</td>
                <td align="right"> {{ data.quantity }}</td>
                <td align="right"> {{ Utilities.formatPrice(data.totalPreTax) }}</td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (data.itemQuantity / data.quantity) * 100
                    )
                  }} %
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (data.actualCost / data.totalPreTax) * 100
                    )
                  }} %
                </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="2" align="left">GRAND TOTALS</td>
              <td align="right"> {{ grandTotalItemQty }}</td>
              <td align="right"> {{ Utilities.formatPrice(grandTotalActualCost) }}</td>
              <td align="right"> {{ grandTotalQuantity }}</td>
              <td align="right"> {{ Utilities.formatPrice(grandTotalPreTax) }}</td>

              <td align="right">
                  {{
                    Utilities.formatPercent(
                      (grandTotalItemQty / grandTotalQuantity) * 100
                    )
                  }} %
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (grandTotalActualCost / grandTotalPreTax) * 100
                    )
                  }} %
                </td>
              </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Sales Order Status For Buyer");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
    formattedData() {
      const data = this.reportData;
      let rows = []
      for (let [supplier, supplierValue] of Object.entries(data.list)) {
        let tot = 0;
        let actualCost = 0;
        for (let [groupId, group] of Object.entries(supplierValue.groups)) {
          rows.push(group)
          tot += group.itemQuantity;
          actualCost += group.actualCost
        }
        rows.push({ ...supplierValue.total, itemQuantity: tot, actualCost, isTotal: true })
      }

      return rows;
    },
    grandTotalPreTax() {
      const rows = this.formattedData.filter(d => d.isTotal);
      let tot = 0;
      rows.forEach((r) => tot += r.totalPreTax);
      return tot;
    },
    grandTotalQuantity() {
      const rows = this.formattedData.filter(d => d.isTotal);
      let tot = 0;
      rows.forEach((r) => tot += r.quantity);
      return tot;
    },
    grandTotalActualCost() {
      const rows = this.formattedData.filter(d => d.isTotal);
      let tot = 0;
      rows.forEach((r) => tot += r.actualCost);
      return tot;
    },
    grandTotalItemQty() {
      const rows = this.formattedData.filter(d => d.isTotal);
      let tot = 0;
      rows.forEach((r) => tot += r.itemQuantity);
      return tot;
    }
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "salesOrderStatusBuyer";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "salesOrderStatusBuyer");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesOrderStatusBuyer.scss";
</style>