<template>
  <div class="queue responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :hideAddButton="true"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'queues', label: 'Queue' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";
import { mapActions, mapGetters } from "vuex";
import SocketHelper from "@/lib/SocketHelper";
import { listPageHeader } from "./inputs";

export default {
  name: "queue",
  data() {
    return {
      Entities,
      entity: Entities.QUEUE,
      listPageHeader,
      socket: undefined,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("Queue");

    // Connect to socket
    this.socket = SocketHelper.generateSocket();

    this.socket.on("connect", () => {
      // console.log(`Socket connected`);
    });

    this.socket.on("new_queue_customer", () => {
      this.setRefetchTableData(Math.random());
    });

    this.socket.onAny((event) => {
    });
  },
  computed: {
    ...mapGetters({
      sort: "queueModule/sort",
      pagination: "queueModule/pagination",
      initialFilter: "queueModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      setRefetchTableData: "stateModule/setRefetchTableData",
      updateSort: "queueModule/updateSort",
      updatePagination: "queueModule/updatePagination",
      updateInitialFilter: "queueModule/updateInitialFilter"
    }),
  },
  beforeRouteLeave(to, from, next) {
    this.socket.disconnect();
    next(true);
  },
};
</script>
