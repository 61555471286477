<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="Special Pricing Listing"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'inventoryReport', label: 'Inventory' },
          {
            name: 'inventoryListSpecialPricing',
            label: 'Special Pricing Listing',
          },
        ]"
        @export="exportToCsv"
        :disableExport="!reportData"
      />
      <br class="no-print" />
      <ReportFilter
        class="no-print"
        @submit-filter="performGetReport"
        :isLoading="inventoryTransactions"
      />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th align="left">Id</th>
                <th align="left">Date</th>
                <th align="left">Employee</th>
                <th align="left">Supplier</th>
                <th align="left">Group</th>
                <th align="left">Sale Type</th>
                <th align="left">Type</th>
                <th align="right">Size</th>
                <th align="right">Quantity</th>
                <th align="right">Limit</th>
                <th align="right">%</th>
                <th align="right">All-In Price</th>
                <th align="right">Pretax Price</th>
                <th align="right">Max Price</th>
                <th align="right">Min Price</th>
                <th align="center">Publish</th>
                <th align="center">Date Range</th>
                <th align="left">Start Date</th>
                <th align="left">End Date</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.records" :key="index">
                <td>{{ record.id }}</td>
                <td>{{ record.date }}</td>
                <td>{{ record.employee }}</td>
                <td>{{ record.supplier }}</td>
                <td>{{ record.group }}</td>
                <td>{{ record.saleType }}</td>
                <td>{{ record.type }}</td>
                <td align="right">{{ record.size }}</td>
                <td align="right">{{ record.quantity }}</td>
                <td align="right">{{ record.quantityLimit }}</td>
                <td align="right">{{ record.percentageAmount }}</td>
                <td align="right">{{ Utilities.formatPrice(record.price) }}</td>
                <td align="right">{{ Utilities.formatPrice(record.pricePreTax) }}</td>
                <td align="right">{{ Utilities.formatPrice(record.maximumPrice) }}</td>
                <td align="right">{{ Utilities.formatPrice(record.minimumPrice) }}</td>
                <td align="center">{{ record.isPublishedToOnlineStore ? 'Y' : 'N' }}</td>
                <td align="center">{{ record.applyDateRange ? 'Y' : 'N' }}</td>
                <td>{{ dateFormmatter(record.startDate) }}</td>
                <td>{{ dateFormmatter(record.endDate) }}</td>

              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
      filterDropdown: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Special Pricing Listing");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      inventoryTransactions: "reports/inventoryTransactions",
    }),
  },
  methods: {
    ...mapActions({
      getInventoryTransactions: "reports/getInventoryTransactions",
    }),
    dateFormmatter(date) {
      return Utilities.formatDateWithHour(date);
    },
    performGetReport(filter) {
      let reportType = "inventoryListSpecialPricing";
      this.getInventoryTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "inventoryListSpecialPricing");
    },
  },
};
</script>

<style lang="scss">
  @import "./_inventoryListSpecialPricing.scss";