<template>
  <div class="date-summary">
    <div class="date-summary__item">
      <p class="label">From:</p>
      <p class="value">{{ formatDate(from) }}</p>
    </div>
    <div class="date-summary__item" v-if="to">
      <p class="label">To:</p>
      <p class="value">{{ formatDate(to) }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Constants from "@/resources/Constants";

export default {
  props: {
    from: {
      type: String
    },
    to: {
      type: String
    }
  },
  data() {
    return {
      Constants
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      return moment(date).format(
        Constants.dates.extendedCalendarDateFormat
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.date-summary {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: $dark-elevated-0;
  border-radius: $border-radius-0;
  border: 1pt dashed $dark-accent-color;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.label {
  width: 30%;
  margin: 0;
}

.value {
  flex: 1;
  margin: 0;
}
</style>