<template>
  <div id="delivery-pop-up">
    <div
      class="overlay-div"
      v-if="deliveryPopUp.isOpen"
      v-on:click.stop="handleNegativeAnswer"
    ></div>
    <div
      v-if="deliveryPopUp.isOpen"
      class="default-pop-up-center-div center bordered"
    >
      <span class="h1 padded">Delivery Charge</span>
      <br />

      <div class="padded">
        <div class="padded--vertical">
          <input class="flat-input" placeholder="Delivery Charge Total $" />
        </div>
      </div>

      <div class="padded center">
        <div
          class="interspace button-secondary"
          @click="performAddElementToCart"
        >
          Save
        </div>
        <div class="interspace button-info" @click="handleNegativeAnswer">
          Cancel
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DeliveryPopUp",
  data() {
    return {};
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  components: {},
  computed: {
    ...mapGetters(["deliveryPopUp"]),
  },
  methods: {
    ...mapActions([]),
    handleNegativeAnswer: function () {
      this.deliveryPopUp.isOpen = false;
    },
  },
};
</script>
