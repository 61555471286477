<template>
  <div class="customer-info">
    <div class="customer-info__body">
      <router-link
        class="customer-info__customer-name"
        :to="`/customers/customerEditor?id=${customer._id}`"
        target="_blank"
      >
        {{ Utilities.composeNameAndLastName(customer) }}
        <font-awesome-icon
          class="customer-info__link-icon"
          :icon="['fas', 'external-link-alt']"
        />
      </router-link>

      <CustomerAnalytics
        :topProductsChartData="topProductsChartData"
        :dashboardLoadingStatus="loadingStatus"
        :spendingInformation="spendingInformation"
        :customer="customer"
        :showPointsLevel1="currentStore.isLevel1PointProgramEnabled"
        :showPointsLevel2="currentStore.isLevel2PointProgramEnabled"
        :calendarHeatmapChartData="calendarHeatmapChartData"
      />
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import CustomerAnalytics from "@/components/CustomerAnalytics/CustomerAnalytics.vue";
import {
  getDefaultTopProductsChartData,
  populateTopProductsChartData,
} from "@/views/dashboards/SalesOverview/helpers/topProducts.helper";
import { mapActions, mapGetters } from "vuex";
import {
  getDefaultCalendarHeatmapChartData,
  populateCalendarHeatmapChartData,
} from "@/views/dashboards/SalesOverview/helpers/calendarHeatmap.helper";

export default {
  name: "CustomerInfo",
  components: {
    CustomerAnalytics,
  },
  data() {
    return {
      Utilities,
      spendingInformation: {},
      topProductsChartData: undefined,
      calendarHeatmapChartData: undefined,
    };
  },
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.topProductsChartData = getDefaultTopProductsChartData(this.theme);
    this.calendarHeatmapChartData = getDefaultCalendarHeatmapChartData(
      this.theme
    );

    this.batchGenerate({
      requests: [
        {
          reportType: "topProducts",
          ...{
            selectedReportFor: "store",
            customer: this.customer._id,
          },
        },
      ],
    });

    this.getCustomerSpendingInformation({
      customerId: this.customer._id,
      positiveCallback: (result) => {
        this.spendingInformation = result;
      },
    });

    this.getCustomerOrdersByDay({
      customerId: this.customer._id,
      positiveCallback: (result) => {
        this.calendarHeatmapChartData = populateCalendarHeatmapChartData(
          result,
          this.theme
        );
      },
    });
  },
  watch: {
    "getData.topProducts.list"(newData) {
      populateTopProductsChartData(this.topProductsChartData, newData);
    },
  },
  computed: {
    ...mapGetters({
      theme: "theme",
      getData: "dashboardModule/getData",
      loadingStatus: "dashboardModule/loadingStatus",
      currentStore: "storeModule/currentStore",
    }),
  },
  methods: {
    ...mapActions({
      batchGenerate: "dashboardModule/batchGenerate",
      clearData: "dashboardModule/clearData",
      getCustomerSpendingInformation:
        "customerModule/getCustomerSpendingInformation",
      getCustomerOrdersByDay: "customerModule/getCustomerOrdersByDay",
    }),
  },
  beforeDestroy() {
    this.clearData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_customer-info.scss";
</style>
