<template>
  <button :aria-label="ariaLabel" class="base-button" :class="[
    baseButtonColor,
    {
      'base-button--only-icon': !hasLabel || isLoading,
      'button-primary': focus,
      'base-button--small-label': hasLabel && smallLabel
    },
    sizeButton,
  ]" v-bind="$attrs" v-on="$listeners">
    <div v-show="isLoading">
      <i class="fas fa-spin fa-circle-notch"></i>
    </div>
    <div v-show="!isLoading" :class="{ 'small-label': smallLabel }">
      <font-awesome-icon v-if="isIconValid" :icon="[iconGroup, iconName]" />
      <span :class="{
        'base-button__label': true,
        'responsive-label': isIconValid,
      }">
        {{ label }}</span>
      <slot />
    </div>
  </button>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Button",
  components: {
    FontAwesomeIcon,
  },
  props: {
    colorType: {
      type: Number,
      default: -1,
    },
    size: {
      type: String,
      default: "md",
    },
    iconGroup: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    ariaLabel: {
      type: String,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
    },
    smallLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    baseButtonColor() {
      switch (this.colorType) {
        case 1:
          return "base-button--red";
        case 2:
          return "base-button--violet";
        case 3:
          return "base-button--gray";
        case 4:
          return "base-button--orange";
        case 5:
          return "base-button--selected";
        default:
          return "";
      }
    },
    sizeButton() {
      switch (this.size) {
        case "sm":
          return "base-button--sm";
        default:
          return "";
      }
    },
    hasLabel() {
      return this.label && this.label.trim().length > 0;
    },
    isIconValid() {
      return (
        this.iconGroup.trim().length > 0 && this.iconName.trim().length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_button.scss";
</style>
