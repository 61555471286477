import * as Entities from "@/resources/Entities";
import {
  normalizeEntityBeforeSaving as employeeNormalizeEntityBeforeSaving,
  populateStores as employeePopulateStores,
} from "./employee";
import { normalizeEntityBeforeSaving as organizationNormalizeEntityBeforeSaving } from "./organization";
import { normalizeEntityBeforeSaving as customerNormalizeEntityBeforeSaving } from "./customer";
import { normalizeEntityBeforeSaving as itemNormalizeEntityBeforeSaving } from "./item";
import { normalizeEntityBeforeSaving as productNormalizeEntityBeforeSaving } from "./product";
import { normalizeEntityBeforeSaving as supplierNormalizeEntityBeforeSaving } from "./supplier";
import { normalizeEntityBeforeSaving as storeNormalizeEntityBeforeSaving } from "./store";
import { normalizeEntityBeforeSaving as groupNormalizeEntityBeforeSaving } from "./group";
import { normalizeEntityBeforeSaving as supplierAgentNormalizeEntityBeforeSaving } from "./supplier-agent";
import { normalizeEntityBeforeSaving as purchaseOrderAgentNormalizeEntityBeforeSaving } from "./purchase-order";
import { normalizeEntityBeforeSaving as specialPricingAgentNormalizeEntityBeforeSaving } from "./special-price";
import { normalizeEntityBeforeSaving as creditMemoNormalizeEntityBeforeSaving } from "./credit-memo";
import { normalizeEntityBeforeSaving as chartOfAccountNormalizeEntityBeforeSaving } from "./chart-of-account";
import { normalizeEntityBeforeSaving as taxSetupNormalizeEntityBeforeSaving } from "./taxSetup";
import { normalizeEntityBeforeSaving as journalNormalizeEntityBeforeSaving } from "./journal";
import { normalizeEntityBeforeSaving as msoProductNormalizeEntityBeforeSaving } from "./msoProduct";

const defaultNormalizeEntityBeforeSaving = (entity) => {
  return entity;
};
export const normalizeEntityBeforeSaving = {
  [Entities.EMPLOYEE]: employeeNormalizeEntityBeforeSaving,
  [Entities.GROUP]: groupNormalizeEntityBeforeSaving,
  [Entities.CUSTOMER]: customerNormalizeEntityBeforeSaving,
  [Entities.PRODUCT]: productNormalizeEntityBeforeSaving,
  [Entities.ITEM]: itemNormalizeEntityBeforeSaving,
  [Entities.ONLINESTOREGROUP]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOGROUP]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOSUPPLIER]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOPRODUCT]: msoProductNormalizeEntityBeforeSaving,
  [Entities.MSOCHARTOFACCOUNT]: defaultNormalizeEntityBeforeSaving,
  [Entities.CHARTOFACCOUNT]: defaultNormalizeEntityBeforeSaving,
  [Entities.ORDER]: defaultNormalizeEntityBeforeSaving,
  [Entities.ORGANIZATION]: organizationNormalizeEntityBeforeSaving,
  [Entities.PURCHASEORDER]: purchaseOrderAgentNormalizeEntityBeforeSaving,
  [Entities.ROOM]: defaultNormalizeEntityBeforeSaving,
  [Entities.SPECIALPRICING]: specialPricingAgentNormalizeEntityBeforeSaving,
  [Entities.STORE]: storeNormalizeEntityBeforeSaving,
  [Entities.SUPPLIER]: supplierNormalizeEntityBeforeSaving,
  [Entities.SUPPLIERAGENT]: supplierAgentNormalizeEntityBeforeSaving,
  [Entities.COUPON]: defaultNormalizeEntityBeforeSaving,
  [Entities.GIFTCARD]: creditMemoNormalizeEntityBeforeSaving,
  [Entities.CREDITMEMO]: creditMemoNormalizeEntityBeforeSaving,
  [Entities.CHARTOFACCOUNT]: chartOfAccountNormalizeEntityBeforeSaving,
  [Entities.MSOCOUPON]: defaultNormalizeEntityBeforeSaving,
  [Entities.FLIGHT]: defaultNormalizeEntityBeforeSaving,
  [Entities.TAXSETUP]: taxSetupNormalizeEntityBeforeSaving,
  [Entities.JOURNAL]: journalNormalizeEntityBeforeSaving,
  [Entities.TIMESHEET]: defaultNormalizeEntityBeforeSaving,
  [Entities.PAYMENT]: defaultNormalizeEntityBeforeSaving,
  [Entities.RETURNITEM]: defaultNormalizeEntityBeforeSaving,
  [Entities.CASHRECONCILIATION]: defaultNormalizeEntityBeforeSaving,
  [Entities.BRAND]: defaultNormalizeEntityBeforeSaving,
};

export const populateStores = {
  [Entities.EMPLOYEE]: employeePopulateStores,
  [Entities.GROUP]: employeePopulateStores,
  [Entities.CUSTOMER]: employeePopulateStores,
  [Entities.PRODUCT]: employeePopulateStores,
  [Entities.ITEM]: employeePopulateStores,
  [Entities.ONLINESTOREGROUP]: employeePopulateStores,
  [Entities.ORDER]: employeePopulateStores,
  [Entities.ORGANIZATION]: employeePopulateStores,
  [Entities.PURCHASEORDER]: employeePopulateStores,
  [Entities.ROOM]: employeePopulateStores,
  [Entities.SPECIALPRICING]: employeePopulateStores,
  [Entities.STORE]: employeePopulateStores,
  [Entities.SUPPLIER]: employeePopulateStores,
  [Entities.SUPPLIERAGENT]: employeePopulateStores,
  [Entities.COUPON]: employeePopulateStores,
  [Entities.FLIGHT]: employeePopulateStores,
  [Entities.JOURNAL]: employeePopulateStores,
};
