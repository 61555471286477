<template>
  <div class="customers responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'customers', label: 'Customers' },
      ]" :entity="entity" />
      <SearchTable :entity="entity" :filter="{ isDeleted: false }" :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "customers",
  data() {
    return {
      listPageHeader,
      entity: Entities.CUSTOMER,
    };
  },
  mounted: function () {
    this.setPageTitle("Customers");
  },
  components: {
    PageHeader,
    SearchTable,
  },
  computed: {
    ...mapGetters({
      sort: "customerModule/sort",
      pagination: "customerModule/pagination",
      initialFilter: "customerModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "customerModule/updateSort",
      updatePagination: "customerModule/updatePagination",
      updateInitialFilter: "customerModule/updateInitialFilter"
    }),
  }
};
</script>
