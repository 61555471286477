<template>
  <div class="header">
    <div>
      <h1 class="header__title">{{ title }}</h1>
      <BreadCrumb v-if="links && links.length > 0" :links="links" />
    </div>
    <div class="header__actions no-print">
      <Button v-if="hasAddListener" label="Add" iconGroup="fas" iconName="plus" :disabled="disableAdd"
        @click="$emit('add')" />
      <Button v-if="hasSaveListener" label="Save" iconGroup="fas" iconName="save" :disabled="disableSave"
        @click="$emit('save')" />
      <Button v-if="hasCancelListener" label="Cancel" iconGroup="fas" iconName="times" :disabled="disableCancel"
        :colorType="3" @click="$emit('cancel')" />
      <Button v-if="hasDeleteListener" label="Delete" iconGroup="fas" iconName="trash" :colorType="1"
        :disabled="disableDelete" @click="$emit('delete')" />
      <Button v-if="hasPrintAllListener" label="Print Batch Labels" :colorType="0" iconGroup="fas" iconName="print"
        :disabled="disableExport" @click="$emit('printAll')" />
      <Button v-if="hasExportListener" label="Export" :colorType="0" iconGroup="fas" iconName="file-export"
        :disabled="disableExport" @click="$emit('export')" />
      <slot />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Base/Button/Button.vue";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";

export default {
  data() {
    return {};
  },
  components: {
    Button,
    BreadCrumb,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    disableCancel: {
      type: Boolean,
      default: false,
    },
    disableExport: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => {
        [];
      },
    },
  },
  computed: {
    hasSaveListener() {
      return this.$listeners && this.$listeners.save;
    },
    hasAddListener() {
      return this.$listeners && this.$listeners.add;
    },
    hasDeleteListener() {
      return this.$listeners && this.$listeners.delete;
    },
    hasCancelListener() {
      return this.$listeners && this.$listeners.cancel;
    },
    hasExportListener() {
      return this.$listeners && this.$listeners.export;
    },
    hasPrintAllListener() {
      return this.$listeners && this.$listeners.printAll;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_header.scss";
</style>
