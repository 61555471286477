<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Sales Recap Transactions Detailed" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesRecapTransactions',
          label: 'Sales Recap Transactions Detailed',
        },
      ]" @export="exportToCsv" :disableExport="percentageCompleted < 100 || isExporting" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container position-relative" v-if="percentageCompleted > 0">
        <div class="fade-in-overlay"></div>
        <div class="report-body">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Date</th>
                <th align="left">Order #</th>
                <th align="left">Empl</th>
                <th align="left">Supplier</th>
                <th align="left">Group</th>
                <th align="left">Grams</th>
                <th align="left">Product</th>
                <th align="left">Product Accepted</th>
                <th align="right">Quantity</th>
                <th align="right">Actual Cost</th>
                <th align="right">Sale Price</th>
                <th align="right">Sub Total</th>
                <th align="right">Discount</th>
                <th align="right">Line Total</th>
                <th align="right">Tax</th>
                <th align="right">Delivery</th>
                <th align="right">Total</th>
                <th align="right">Profit/Loss</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in topRows" :key="index">
                <td>{{ new Date(record.date).toLocaleString("en-US") }}</td>
                <td>{{ record.orderCode }}</td>
                <td>{{ record.employee }}</td>
                <td>{{ record.supplier.substring(0, 7) }}</td>
                <td>{{ record.group }}</td>
                <td>{{ record.grams }}</td>
                <td>{{ record.product }}</td>
                <td>{{ new Date(record.dateItem).toLocaleString("en-US") }}</td>
                <td align="center">{{ record.quantity }}</td>
                <td align="right">{{ Utilities.formatPrice(record.actualCost) }}</td>
                <td align="right">
                  {{ Utilities.formatPrice(record.preTaxPrice) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.totalPreTax) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.discount) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.totalLineTotalPreTax) }}
                </td>
                <td align="right">{{ Utilities.formatPrice(record.tax) }}</td>
                <td align="right">
                  {{ Utilities.formatPrice(record.freight) }}
                </td>
                <td align="right">{{ Utilities.formatPrice(record.total) }}</td>
                <td align="right">{{ Utilities.formatPrice(record.profitLoss) }}</td>
              </tr>
            </tbody>
          </table>


        </div>
      </div>

      <div class="report-loading">
        <div v-show="isLoading">
          <i class="fas fa-spin fa-circle-notch"></i>
        </div>
        <div class="report-export-message" v-show="!isLoading && percentageCompleted == 100">
          <div>
            Showing only the first 100 transactions, to see more transaction export the report
          </div>
          <Button label="Export" :colorType="0" iconGroup="fas" iconName="file-export" @click="exportToCsv"
            :disabled="isExporting" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";
import Button from "@/components/Base/Button/Button.vue";

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export default {
  data() {
    return {
      Utilities,
      isExporting: false,
      filter: undefined
    };
  },
  components: {
    PageHeader,
    ReportFilter,
    Button
  },
  created: function () {
    this.setPageTitle("Sales Recap Transactions");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted',
    }),
    reportData() {
      return this.getData;
    },
    topRows() {
      return this.getData.records.slice(0, 100)
    }
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      setMessage: "setMessage",
      clearData: "reports/clearData",
    }),
    exportToCsv() {
      this.isExporting = true

      const fileName = `salesRecapTransactions_${this.filter.selectedPeriod.from.toLocaleDateString("en-US")}_${this.filter.selectedPeriod.to.toLocaleDateString("en-US")}`.replaceAll("/", "-")

      setTimeout(() => {
        ReportHelper.exportRecordsToExcel(this.getData.records, fileName)
        this.isExporting = false
      }, 100)
    },
    performGetReport(filter) {
      this.filter = filter

      const date1 = new Date(filter.selectedPeriod.from);
      const date2 = new Date(filter.selectedPeriod.to);

      if (monthDiff(date1, date2) > 3) {
        return this.setMessage({
          title: "Date Range Limit",
          text: "The maximum data range for this report is 3 months",
          type: 'error'
        })
      }

      let reportType = "salesRecapTransactionsDetailed";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesRecapTransactions.scss";
</style>
