import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedCustomer } from "@/views/Customer/inputs";

const state = {
  customers: [],
  customer: { ...defaultSelectedCustomer },
  loadingStatus: {
    saveCustomer: false,
    getCustomerById: false,
    deleteCustomer: false,
    createCustomer: false
  },
  customerPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setCustomer: (state, payload) => {
    if (payload._id === "NEW") state.customer = { ...defaultSelectedCustomer };
    else {
      state.customer = {
        ...defaultSelectedCustomer,
        ...payload,
      };
    }
  },
  setCustomers: (state, payload) => {
    state.customers = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setCustomerPopUp: (state, payload) => {
    state.customerPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  customers: (state) => state.customers,
  customer: (state) => state.customer,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  customerPopUp: (state) => state.customerPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveCustomer", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveCustomer,
      payload.customer,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveCustomer", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  // Similar to save customer but this api updates a user using email as unique id
  createCustomer: ({ commit }, payload) => {
    commit("setLoadingStatus", { api: "createCustomer", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.createCustomer,
      { customer: payload.customer },
      function (response, error) {
        commit("setLoadingStatus", { api: "createCustomer", status: false });
        if (response) {
          let message = []
          const metadata = response.data.body.metadata
          if (metadata.loyalty.customerCreated) {
            message.push("Loyalty customer created")
          }
          if (metadata.loyalty.customerUpdated) {
            message.push("Loyalty customer updated")
          }
          if (metadata.df.customerCreated) {
            message.push("DispenseFirst customer created")
          }
          if (metadata.df.customerUpdated) {
            message.push("DispenseFirst customer updated")
          }
          commit(
            "setMessage",
            { text: message.join(', '), type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteCustomer", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteCustomer,
      payload.customer,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteCustomer", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getCustomerById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCustomerById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getCustomerById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getCustomerById", status: false });
        if (response) {
          commit("setCustomer", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getCardExpiration: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCardExpiration", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getCardExpiration + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getCardExpiration", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            {
              text: error.message,
              type: error.errorCode == 901 ? "state-error" : "error"
            },
            { root: true }
          );
          console.log(error);
          if (payload.negativeCallback) {
            payload.negativeCallback(error);
          }
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setCustomerPopUp: ({ commit, state }, payload) => {
    commit("setCustomerPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  getAdjacentCustomer: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentCustomer", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "customer/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getAdjacentCustomer", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  clearCustomerPoints: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.clearCustomerPoints,
      payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Customer Updated", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
          commit("setCustomer", response.data.body);
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  addCustomerPoints: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.addCustomerPoints,
      payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Customer Updated", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
          commit("setCustomer", response.data.body);
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  },
  getCustomerItemHistory: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      const customerId = payload.customerId;
      if (!customerId || customerId == 'NEW') return resolve([])
      HttpService.get(
        Apis.backendBaseUrl + Apis.customerItemHistory + "?id=" + customerId,
        function (response, error) {
          if (response) {
            const data = response.data.body || []
            if (payload.positiveCallback) {
              payload.positiveCallback(data);
            }
            resolve(data)
          } else {
            reject([])
          }
        }
      );
    })
  },
  getCustomerSpendingInformation: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCustomerSpendingInformation", status: true });
    return new Promise((resolve, reject) => {
      const customerId = payload.customerId;
      if (!customerId || customerId == 'NEW') {
        commit("setLoadingStatus", { api: "getCustomerSpendingInformation", status: false });
        return resolve({})
      }
      HttpService.get(
        Apis.backendBaseUrl + Apis.customerSpendingInformation + "?id=" + customerId,
        function (response, error) {
          commit("setLoadingStatus", { api: "getCustomerSpendingInformation", status: false });
          if (response) {
            const data = response.data.body || []
            if (payload.positiveCallback) {
              payload.positiveCallback(data);
            }
            resolve(data)
          } else {
            reject([])
          }
        }
      );
    })
  },
  getCustomerLocationInformation: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCustomerLocationInformation", status: true });
    return new Promise((resolve, reject) => {
      const customerId = payload.customerId;
      if (!customerId || customerId == 'NEW') {
        commit("setLoadingStatus", { api: "getCustomerLocationInformation", status: false });
        return resolve({})
      }
      HttpService.get(
        Apis.backendBaseUrl + Apis.customerLocationInformation + "?id=" + customerId,
        function (response, error) {
          commit("setLoadingStatus", { api: "getCustomerLocationInformation", status: false });
          if (response) {
            const data = response.data.body || []
            if (payload.positiveCallback) {
              payload.positiveCallback(data);
            }
            resolve(data)
          } else {
            reject([])
          }
        }
      );
    })
  },
  getCustomerOrdersByDay: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCustomerOrdersByDay", status: true });
    return new Promise((resolve, reject) => {
      const customerId = payload.customerId;
      if (!customerId || customerId == 'NEW') {
        commit("setLoadingStatus", { api: "getCustomerOrdersByDay", status: false });
        return resolve({})
      }
      HttpService.get(
        Apis.backendBaseUrl + Apis.getCustomerOrdersByDay + "?id=" + customerId,
        function (response, error) {
          commit("setLoadingStatus", { api: "getCustomerOrdersByDay", status: false });
          if (response) {
            const data = response.data.body || []
            if (payload.positiveCallback) {
              payload.positiveCallback(data);
            }
            resolve(data)
          } else {
            reject([])
          }
        }
      );
    })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
