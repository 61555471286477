<template>
  <div class="check-mark">
    <span class="check-mark__unchecked-icon" v-show="!toggleVariable">
      <i class="fas fa-circle"></i>
    </span>

    <span class="check-mark__checked-icon" v-show="toggleVariable">
      <i class="fas fa-check-circle"></i>
    </span>
    <div class="text-spacer-0"></div>
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "CheckMark",
  props: ["toggleVariable", "label"],
};
</script>

<style lang="scss">
.check-mark {
  padding: 9.5px 10px;
  border-radius: $border-radius-0;
  display: inline-block;
  margin-right: 20px;
  box-sizing: border-box;

  &--full-width {
    width: 100%;
  }

  :last-child {
    margin-right: 0;
  }

  &__unchecked-icon {
    color: #383838;
  }

  .light &__unchecked-icon {
    color: #cfcfcf;
  }

  &__checked-icon {
    color: $dark-accent-color;
  }

  .light &__checked-icon {
    color: $light-accent-color;
  }
}
</style>
