<template>
  <div class="pill" :class="`pill__${color}`">
    {{ text }}
  </div>
</template>

<script>

export default {
  name: "Pill",
  data() {
    return {
    };
  },
  props: {
    text: {
      type: String,
    },
    color: {
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
.pill {
  padding: 0.1rem 0.25rem;
  text-transform: uppercase;
  font-size: $text-size-1;
  font-weight: 900;
  white-space: nowrap;
  border-radius: $border-radius-0;

  background-color: $dark-accent-color;
  color: $dark-background-color;

  &__purple {
    background-color: $dark-purple-1;
    color: $dark-text-color-0;
  }

  &__yellow {
    background-color: $dark-yellow-0;
    color: $dark-text-color-3;
  }

  &__orange {
    background-color: $dark-orange-0;
    color: $dark-text-color-3;
  }

  &__pink {
    background-color: $dark-pink-0;
    color: $dark-text-color-3;
  }
}

.light .pill {
  background-color: $light-accent-color;
  color: $light-background-color;

  &__purple {
    background-color: $light-purple-0;
    color: $light-text-color-0;
  }

  &__yellow {
    background-color: $light-yellow-0;
    color: $light-text-color-0;
  }

  &__orange {
    background-color: $light-orange-0;
    color: $light-text-color-0;
  }

  &__pink {
    background-color: $light-pink-0;
    color: $light-text-color-3;
  }
}
</style>
