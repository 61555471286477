<template>
  <div id="employee-confirmation-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered">
      <div class="center">
        <span class="h1 padded">Employee Confirmation</span>
      </div>
      <div class="inputs">
        <br />

        <div class="input-text">
          <div class="reversed-flex-column">
            <BaseInput label="PIN" placeholder="PIN" controlType="text" v-model="pin" ref="pin" @focus="setFocus"
              v-on:keyup.enter="handlePositiveAnswer" :isPassword="true" :applyFocus="true" />
          </div>
        </div>
      </div>

      <br />
      <div class="desktop-only-block">
        <Keypad :input="input" />
      </div>
      <div class="padded button-wrapper">
        <BaseBtn @click="handleNegativeAnswer" class="message-pop-up__button" label="Cancel" :colorType="3" />
        <BaseBtn @click="handlePositiveAnswer" class="message-pop-up__button" label="Submit"
          :isLoading="loadingStatus.getEmployeeByPin" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Input/Base.vue";
import { mapGetters, mapActions } from "vuex";
import Keypad from "@/components/Keypad";
import _ from "lodash";
import Constants from "@/resources/Constants";
import LocalStorageManager from "@/lib/LocalStorageManager";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "OrderTypeSelectionPopUp",
  data() {
    return {
      pin: "",
      input: {},
      Constants: Constants,
      drawersList: [],
      workstationList: [],
      drawer: 1,
    };
  },
  created: function () {
    //this.resetEmployee();
    if (
      this.currentStore.workstations &&
      this.currentStore.workstations.length > 0
    ) {
      this.workstationList = JSON.parse(
        JSON.stringify(this.currentStore.workstations)
      );

      this.workstationList = this.workstationList.filter(
        (ws) => ws.isFulfillment == false
      );

      if (this.selectedWorkstation) {
        const ws = this.workstationList.find(
          (ws) => ws._id == this.selectedWorkstation._id
        );
        ws.isSelected = true;
      }
    }
    window.addEventListener(
      "keydown",
      this.keyDownHandler
    );

    this.getCurrentStore();
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  components: {
    Keypad,
    BaseBtn,
    BaseInput,
  },
  computed: {
    ...mapGetters({
      employeeConfirmationPopUp: "employeeModule/employeeConfirmationPopUp",
      employee: "employeeModule/posEmployee",
      loadingStatus: "employeeModule/loadingStatus",
      currentStore: "storeModule/currentStore",
      selectedWorkstation: "posModule/selectedWorkstation",
    }),
    isWsSelected() {
      var result = false;
      this.workstationList.forEach((ws) => {
        if (ws.isSelected) {
          result = true;
        }
      });
      return result;
    },
    drawersListComputed: function () {
      const drawers = [];

      for (var i = 0; i < this.currentStore.numberOfDrawers; i++) {
        drawers.push(i + 1);
      }

      return drawers;
    },
  },
  mounted: function () {
    const storedDrawer = LocalStorageManager.getLocalStorageKey("drawer");
    if (storedDrawer) {
      this.drawer = storedDrawer;
    }
    this.$nextTick(function () {
      try {
        document.querySelectorAll("input[type=password]")[0].focus();
      } catch (error) { }
    });
  },
  methods: {
    ...mapActions({
      resetEmployee: "employeeModule/resetEmployee",
      getCurrentStore: "storeModule/getCurrentStore",
      getEmployeeByPin: "employeeModule/getEmployeeByPin",
      confirmEmployee: "employeeModule/confirmEmployee",
      setEmployeeConfirmationPopUp:
        "employeeModule/setEmployeeConfirmationPopUp",
      setMessage: "setMessage",
    }),
    keyDownHandler(e) {
      console.log('called');
      if (e.keyCode === 27) {
        this.handleNegativeAnswer();
      }
    },
    setFocus(e) {
      try {
        this.input = e.target;
      } catch (error) { }
    },
    handlePositiveAnswer: function () {
      if (this.loadingStatus.getEmployeeByPin) {
        return;
      }

      this.getEmployeeByPin({
        pin: this.pin,
        positiveCallback: () => {
          if (this.employeeConfirmationPopUp.positiveCallback) {
            this.employeeConfirmationPopUp.positiveCallback();
          }
          this.setEmployeeConfirmationPopUp({});
          this.pin = "";
        },
        negativeCallback: () => {
          this.setMessage({
            text: `User does not exist`,
            type: "error",
          });
        },
      });
    },
    handleNegativeAnswer: function () {
      this.setEmployeeConfirmationPopUp({});
    },
    setDrawer: function (drawer) {
      this.drawer = drawer;
    },
    // performGetEmployeeByPin: function () {
    //   if (this.pin.length === 0) {
    //     return;
    //   }
    //   this.getEmployeeByPin({ pin: this.pin, showMessage: true });
    // },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;

  &>button {
    width: 50%;
  }
}

.pin-input {
  width: 160px !important;
  text-align: center;
  font-weight: bold;
}

.drawer-input {
  width: 160px !important;
  text-align: center;
  display: inline-block;
}

.numeric-keyboard-container {
  text-align: center;
}

.numeric-key {
  display: inline-block;
  background-color: $dark-elevated-0;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  //margin: 5px;
  border-radius: 2px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.light .numeric-key {
  background-color: $light-elevated-0;
}

.inputs {
  max-width: 200px;
  margin: auto;
}

.workstation {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;

  &>* {
    margin-right: 10px;
  }
}
</style>
