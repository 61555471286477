<template>
  <div class="coupon-base">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "couponBase",
};
</script>
