export const normalizeEntityBeforeSaving = (specialPricing) => {
  const entity = JSON.parse(JSON.stringify(specialPricing));
  if (entity.saleType && entity.saleType.code) {
    entity.saleType = entity.saleType.code;
  }
  if (entity.startDate) {
    entity.startDate = new Date(entity.startDate);
  }
  if (entity.endDate) {
    entity.endDate = new Date(entity.endDate);
  }
  return entity;
};
