<template>
  <div class="payment-form">
    <div v-if="payment.type == 'cash' ||
      payment.type == 'credit-memo' ||
      payment.type == 'credit-card' ||
      payment.type == 'debit-card' ||
      payment.type == 'gift-card' ||
      payment.type == 'canpay-remote' ||
      payment.type == 'canpay-retail'
      ">
      <div class="payment-form__container">
        <div class="payment-form__row">
          <div class="payment-form__col">
            <div class="field">
              <div class="payment-form__label">Balance Due</div>
              <div class="field__content field__content--normal-line-height">
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(calculateBalanceDue)
                }}
              </div>
            </div>

            <BaseInput v-if="payment.type == 'gift-card'" class="instant__body__input" label="Gift Card Barcode"
              placeholder="Scan Gift Card" v-model="giftCardBarcode" @focus="setFocus" v-on:input="emitValueChange"
              :applyFocus="true" :disabled="payment.giftCard._id" />

            <BaseInput v-if="payment.type == 'credit-card'
              " class="instant__body__input" label="Authorization" controlType="money" placeholder="Authorization"
              v-model="payment.authorization" @focus="setFocus" />

            <Input v-show="payment.type == 'credit-memo'" :model="payment.creditMemo" :inputData="getInput('credit-memo')"
              :isEdit="payment._id == 'NEW'" />

            <div class="field" v-show="payment.type == 'gift-card'">
              <div class="payment-form__label">Gift Card Balance</div>
              <div class="field__content field__content--normal-line-height">
                {{ calculateGiftCardBalance }}
              </div>
            </div>

            <div class="field" v-show="payment.type == 'credit-memo'">
              <div class="payment-form__label">Credit Memo Balance</div>
              <div class="field__content field__content--normal-line-height">
                {{ calculateCreditMemoBalance }}
              </div>
            </div>

            <BaseInput class="instant__body__input" label="Payment Applied" controlType="money"
              placeholder="Payment Applied" v-model="payment.amountPaid" @focus="setFocus"
              :disabled="payment.type.includes('canpay') || payment.type.includes('debit')" type="number" />
            <span v-if="payment.type == 'debit-card'">It will be rounded to <strong>${{ roundedValue }}</strong></span>

            <BaseInput class="instant__body__input" label="CanPay Payment Code" controlType="money"
              placeholder="CanPay Payment Code" v-model="payment.canpayPin" @focus="setFocus" v-show="payment.type.includes('canpay') && !canpayPaymentConfirmed
                " />
            <BaseBtn v-show="payment.type.includes('canpay') && !canpayPaymentConfirmed
              " :isLoading="orderLoadingStatus.executeCanpayPayment"
              :disabled="orderLoadingStatus.executeCanpayPayment" @click="$emit('canpay-execute-payment')"
              label="Execute Payment" />

            <BaseBtn v-show="payment.type.includes('canpay') && canpayPaymentConfirmed"
              :isLoading="orderLoadingStatus.reimburseCanpayPayment" :disabled="orderLoadingStatus.reimburseCanpayPayment"
              @click="$emit('canpay-reimbursement')" label="Reimbursement" />
            <BaseBtn v-show="payment.type.includes('canpay') && canpayPaymentConfirmed"
              :isLoading="orderLoadingStatus.createCreditMemoFromPayment"
              :disabled="orderLoadingStatus.createCreditMemoFromPayment" @click="$emit('create-credit-memo')"
              label="Create Credit Memo" />

            <!-- <BaseInput v-if="payment.type == 'credit-card' || payment.type == 'debit-card'
              " class="instant__body__input" label="Amount to Charge" controlType="money"
              placeholder="Amount to charge" v-model="payment.cardTotal" @focus="setFocus" /> -->

            <BaseInput v-if="payment.type == 'cash'" class="instant__body__input" label="Cash Collected"
              controlType="money" placeholder="Cash Collected" v-model="payment.cashCollected" @focus="setFocus"
              :applyFocus="true" />
            <div class="field" v-if="(payment.type == 'cash' || payment.type == 'debit-card') && payment.changeDue > 0">
              <div class="payment-form__label">Change Due</div>
              <div class="field__content field__content--normal-line-height">
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(payment.changeDue)
                }}
              </div>
            </div>
          </div>
          <div class="payment-form__col">
            <div class="field">
              <div class="payment-form__label">Payment</div>
              <div class="field__content field__content--normal-line-height">
                {{ payment.type }}
              </div>
            </div>

            <div class="instant__reason">
              <textarea class="flat-textarea" rows="5" placeholder="Notes..." v-model="payment.notes"
                @focus="setFocus"></textarea>
            </div>
          </div>
          <div class="payment-form__col desktop-only-block">
            <Keypad class="instant__keypad" :input="input" />
          </div>
        </div>
        <div class="payment-form__buttons">
          <BaseBtn @click="cancelPaymentEditing" :colorType="3" label="Cancel" />
          <BaseBtn v-if="payment.type == 'debit-card'" :isLoading="isLoading" :disabled="isLoading || disableSave || hasDebitCardPayments()"
            iconGroup="fas" iconName="credit-card" @click="executeDebitPayment" label="Send to Terminal"
            v-shortkey="['enter']" @shortkey.native="executeDebitPayment" />
          <BaseBtn v-if="payment.type == 'debit-card'" :isLoading="isLoading"
            iconGroup="fas" iconName="stamp" @click="debitOverride.isOpen = true" label="Override Payment"/>
          <BaseBtn v-else :isLoading="isLoading" :disabled="isLoading || disableSave" @click="$emit('save')" label="Save"
            v-shortkey="['enter']" @shortkey.native="$emit('save')" />
        </div>
        <Label class="debit-processing" v-if="payment.type == 'debit-card' && isLoading" type="warning"
          label="Do NOT close window or refresh screen while transaction is processing. Wait for confirmation or error." />
      </div>
    </div>

    <BaseModal v-if="debitOverride.isOpen" @toggle-modal="toggleOverrideOpen" title="Override Debit Payment" :autoWidth="true">
    <div class="override-container">
      <div class="override-container__left">
        <BaseInput label="Employee Pin" placeholder="" v-model="debitOverride.pin" :isPassword="true" @focus="setFocus" :applyFocus="true"/>
        <BaseInput label="Reference Id" placeholder="RefId" v-model="debitOverride.referenceId" @focus="setFocus"/>
        <BaseInput label="Last 4 of Card #" controlType="number" placeholder="0000" v-model="debitOverride.cardLast4" @focus="setFocus"/>
        <BaseInput label="Amount" controlType="money" placeholder="" v-model="debitOverride.amount" @focus="setFocus" :disabled="true"/>
      </div>

      <div class="override-container__right">
        <Keypad class="instant__keypad" :input="input" />
        <BaseBtn label="Submit" @click="executeOverrideDebitPayment" :disabled="!canApplyOverride" />
      </div>
    </div>
  </BaseModal>

    <SearchModal v-if="searchModal.showSearchModal" :showSearchModal="searchModal.showSearchModal"
      @toggle-modal="toggleSearchModal" @select-item="searchSelectItem" :entity="searchModal.entity" :id="searchModal.id"
      :title="searchModal.title" :forcedFilterCriteria="searchModal.filterCriteria"
      :columnHeaders="searchModal.columnHeaders" :searchColumnHeaders="searchModal.searchColumnHeaders"
      :isFiltersDisabled="false">
      <div slot="header" class="pos__search-control-buttons" v-if="searchModal.entity == Entities.CUSTOMER">
        <BaseBtn label="Retail Sale" @click="startRetailOrder" />
      </div>
    </SearchModal>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import Keypad from "@/components/Keypad";
import BaseInput from "@/components/Base/Input/Base.vue";
import Label from "@/components/Base/Label/Label.vue";
import * as Entities from "@/resources/Entities";
import { inputs } from "./inputs";
import Input from "@/components/Input/Input.vue";
import _ from "lodash";
import Constants from "@/resources/Constants";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import { mapActions, mapGetters } from "vuex";
import { isManager } from '@/lib/rolesHelper'

export default {
  name: "PaymentForm",
  data() {
    return {
      input: {},
      giftCardBarcode: "",
      searchModal: {
        entity: undefined,
        showSearchModal: false,
        columnHeaders: [],
        searchColumnHeaders: [],
      },
      debitOverride: {
        employee: null,
        pin: "",
        isOpen: false,
        cardLast4: undefined,
        referenceId: '',
        amount: this.roundTo10(this.amountDue)
      }
    };
  },
  components: {
    BaseModal,
    BaseBtn,
    Keypad,
    BaseInput,
    Input,
    Label
  },
  props: {
    payment: { type: Object, default: () => { } },
    payments: { type: Object, default: () => []},
    customer: { type: Object, default: () => { } },
    amountDue: { type: Number, default: 0 },
    isLoading: { type: Boolean, default: false },
    orderLoadingStatus: { type: Object, default: () => { } },
    creditMemoLoadingStatus: { type: Object, default: () => { } },
  },
  computed: {
    ...mapGetters({
      getCurrentStore: "storeModule/currentStore",
    }),
    roundedValue() {
      return this.roundTo10(this.payment.amountPaid)
    },
    disableSave() {
      if (!this.payment.amountPaid) return true;
      if (isNaN(this.payment.amountPaid)) return true;
      const amountPaid = parseFloat(this.payment.amountPaid);
      if (amountPaid <= 0) return true;

      if (this.payment.type == 'gift-card' && !this.giftCardBarcode) return true;
      if (this.payment.type.includes('canpay') && !this.payment.canpayPin) return true;
      return (this.payment.type == 'credit-memo' && !this.payment.creditMemo._id);
    },
    calculateBalanceDue() {
      if (this.payment._id != "NEW") {
        return this.amountDue * 1 + this.originalPayment.amountPaid * 1;
      } else {
        return this.amountDue;
      }
    },
    calculateCreditMemoBalance() {
      let balance = 0;
      if (this.payment.creditMemo && this.payment.creditMemo.memoBalance) {
        balance = this.payment.creditMemo.memoBalance;
      }

      if (this.payment._id != "NEW") {
        balance += this.originalPayment.amountPaid;
      }

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(balance);
    },
    calculateGiftCardBalance() {
      let balance = 0;
      if (this.payment.giftCard && this.payment.giftCard.memoBalance) {
        balance = this.payment.giftCard.memoBalance;
      }

      if (this.payment._id != "NEW") {
        balance += this.originalPayment.amountPaid;
      }

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(balance);
    },
    canpayPaymentConfirmed() {
      return this.payment.canpayPaymentVerification ?? false;
    },
    canApplyOverride() {
      return (
        this.debitOverride.employee &&
        this.debitOverride.cardLast4 &&
        this.debitOverride.cardLast4.trim().length == 4 &&
        this.debitOverride.referenceId.trim().length > 0 &&
        this.debitOverride.amount > 0
      );
    },
  },
  created() {
    this.originalPayment = JSON.parse(JSON.stringify(this.payment));
    if (this.payment.giftCard && this.payment.giftCard._id) {
      this.giftCardBarcode = this.payment.giftCard._id;
    }
  },
  watch: {
    "payment.giftCard"(gc) {
      if (gc._id) {
        if (this.payment.giftCard.memoBalance < this.amountDue) {
          this.payment.amountPaid = this.payment.giftCard.memoBalance;
        }
      }
    },
    "payment.cashCollected"(cashCollected) {
      const changeDue = +cashCollected - +this.payment.amountPaid
      this.payment.changeDue = changeDue > 0 ? changeDue : 0
    },
    "debitOverride.pin"(val) {
      this.getEmployeeByPin({
        pin: val,
        showMessage: false,
        positiveCallback: (emp) => {
          this.debitOverride.employee = emp;
        },
      });
    },
    "debitOverride.cardLast4"(val) {
      if(val.toString().length > 4)
        this.debitOverride.cardLast4 = val.toString().slice(0,4)
    },
  },
  methods: {
    ...mapActions({
      getEmployeeByPin: "employeeModule/getEmployeeByPin",
      setMessage: "setMessage",
    }),
    executeDebitPayment() {
      this.payment.cardTotal = this.roundedValue;
      if (isNaN(this.payment.cardTotal)) {
        this.payment.cardTotal = 0;
      }
      this.$emit('executeDebitPayment')
    },
    hasDebitCardPayments() {
      return this.payments.find(
        (p) => p.type == "debit-card" && !p.isArchived
      ) != undefined;
    },
    executeOverrideDebitPayment() {
      if(!this.isEmployeeValid()) return;

      const payment = {
        type: 'debit-card',
        cardTotal: this.debitOverride.amount,
        amountPaid: this.debitOverride.amount,
        referenceId: this.debitOverride.referenceId,
        last4Digits: this.debitOverride.cardLast4,
        pin: this.debitOverride.pin,
        cashBack: this.payment.cashBack,
        changeDue: this.payment.changeDue,
      }

      if (isNaN(this.payment.cardTotal)) {
        this.payment.cardTotal = 0;
      }
     this.$emit('executeOverrideDebitPayment', {payment, employee: this.debitOverride.employee._id})
    },
    roundTo10(value) {
      if (!value) return 0
      const valueParsed = parseFloat(value);
      return Math.ceil(valueParsed / 10) * 10;
    },
    toggleOverrideOpen(event) {
      this.debitOverride.isOpen = event.isOpen;
    },
    cancelPaymentEditing() {
      Object.assign(this.payment, this.originalPayment);
      this.$emit("cancel");
    },
    setFocus(e) {
      this.input = e.target;
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      switch (entity) {
        case "credit-memo":
          this.payment.creditMemo = item;
          if (this.payment.creditMemo.memoBalance < this.amountDue) {
            this.payment.amountPaid = this.payment.creditMemo.memoBalance;
          }
          break;
        default:
          break;
      }
    },
    clearItemFromSearch: function (entity, item, nestedId) {
      switch (entity) {
        case "creditMemo":
          this.payment.creditMemo = undefined;
          break;
        default:
          break;
      }
    },
    toggleSearchModal() {
      this.searchModal.showSearchModal = !this.searchModal.showSearchModal;
    },
    searchSelectItem(entity, selectedEntity, nestedId) {
      switch (this.searchModal.entity) {
        case Entities.CREDITMEMO:
          break;

        default:
          break;
      }
    },
    getInput(field) {
      let input = undefined;
      switch (field) {
        case "credit-memo":
          input = inputs.find((i) => i.id == field);
          input.filter.memoType = { $nin: ['CCP', 'Points', 'Gift Card'] }
          if (this.customer) {
            input.filter.customer = this.customer._id;
          }
          break;

        default:
          break;
      }

      return input;
    },
    emitValueChange: _.debounce(function (e) {
      if (e) {
        this.$emit("gift-card-barcode", e);
      }
    }, Constants.debounce),
    isEmployeeValid() {
      if (
        !this.debitOverride.employee ||
        this.debitOverride.employee.isDeleted ||
        !this.debitOverride.employee.isEmployeed
      ) {
        this.setMessage({
          text: "Employee is not authorized",
          type: "error",
        });
        return false;
      }
  
      const isManagerRole = isManager(this.debitOverride.employee, this.getCurrentStore._id)
      if (!isManagerRole) {
        this.setMessage({
          text: "This employee is not a manager. Please ask a manager to apply override",
          type: "error",
        });
        return false;
      }
  
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "payment-form";
</style>
