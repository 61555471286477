<template>
  <div class="orders responsive-menu">
    <div class="responsive-menu__child">
      <Header title="Orders" :links="[
        { name: 'home', label: 'Home' },
        { name: 'orders', label: 'Orders' },
      ]">
        <Button v-if="isOpenOrdersSelected" label="Archive All" iconGroup="fas" iconName="trash" :colorType="1"
          @click="archiveAll()" />
      </Header>
      <SearchTable :key="key" :entity="entity" :filterButtons="filterButtons" @filterButtonsChange="updateFilterButtons"
        :sort="sort" @sortChangeEvent="updateSort" :pagination="pagination" @paginationChange="updatePagination"
        @initialFilterUpdate="updateInitialFilter" :initialFilter="initialFilter" :firstArrowEnabled="false"
        :lastArrowsEnalbled="false" :enableCardniality="false" />
    </div>
    <Modal v-if="showArchiveModal" @toggle-modal="showArchiveModal = false" title="Archive open orders">
      <ArchiveModal @onClose="showArchiveModal = false" @reload="reload" />
    </Modal>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Base/Button/Button.vue";
import Modal from "@/components/Modals/BaseModal/BaseModal.vue";
import ArchiveModal from './ArchiveModal/ArchiveModal.vue';
import { listPageHeader } from "./inputs";

export default {
  name: "orders",
  data() {
    return {
      Entities,
      entity: Entities.ORDER,
      listPageHeader,
      showArchiveModal: false,
      key: 1,
    };
  },
  components: {
    SearchTable,
    Header,
    Button,
    Modal,
    ArchiveModal
  },
  mounted: function () {
    this.setPageTitle("Orders");
  },
  computed: {
    ...mapGetters({
      sort: "orderModule/sort",
      pagination: "orderModule/pagination",
      filterButtons: "orderModule/filterButtons",
      initialFilter: "orderModule/initialFilter"
    }),
    isOpenOrdersSelected() {
      const filter = this.filterButtons.find(f => f.name == 'open-orders');
      if (!filter) return false;

      return filter.isSelected;
    }
  },
  methods: {
    ...mapActions({
      updateSort: "orderModule/updateSort",
      updatePagination: "orderModule/updatePagination",
      updateFilterButtons: "orderModule/updateFilterButtons",
      updateInitialFilter: "orderModule/updateInitialFilter",
      setMessagePopUp: "setMessagePopUp",
    }),
    performArchiveAll() {
      this.showArchiveModal = true;
    },
    archiveAll() {
      this.performArchiveAll();
    },
    reload() {
      this.key++;
    }
  }
};
</script>
