<template>
  <div id="store-selection-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered" :class="{ blur: isBlurred }">
      <div style="display: flex; justify-content: center">
        <div class="location-indicator">STORE</div>
      </div>
      <span class="h1 padded center">{{ currentStore.name }}</span>
      <div class="padded center" style="display: block">
        If you wish to change store go to <router-link style="display: inline;" @click.native="handlePositiveAnswer"
          :to="{ name: 'settings' }"> Settings</router-link>
      </div>

      <div class="button-wrapper padded space-between">
        <div style="width: 100%" class="button-secondary" @click="handlePositiveAnswer">
          Sounds good!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "StoreSelectionPopUp",
  data() {
    return {
      isBlurred: false,
    };
  },
  created: function () {
    window.addEventListener(
      "keyup",
      this.keyDownHandler
    );
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore"
    }),
  },
  methods: {
    ...mapActions({
      setStoreSelectionPopUp: "setStoreSelectionPopUp",
    }),
    keyDownHandler(e) {
      if (e.keyCode === 27) {
        this.handleNegativeAnswer();
      }
    },
    handleNegativeAnswer: function () {
      this.setStoreSelectionPopUp({ isOpen: false });
    },
    handlePositiveAnswer: function () {
      this.setStoreSelectionPopUp({ isOpen: false });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
