<template>
  <div class="credit-memo-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'creditMemos', label: 'Credit Memos' },
          {
            name: 'creditMemoEditor',
            label: `${selectedItem._id}`,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        :hideAddButton="false"
        :selectedEntity="selectedItem"
        @updateIsEdit="isEdit = $event"
        @toggle-sections="forceToggle"
        @toggle-edit="toggleEdit"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick"
      >
        <BaseBtn label="Print" @click="performPrintCreditMemo" />
      </PageHeader>

      <div class="section-base">
        <div class="section-base__header space-between">
          <div class="section-base__header-name"></div>
          <BaseBtn
            label="Post to Journal"
            :colorType="2"
            :disabled="
              !isEdit || selectedItem._id == 'NEW' || selectedItem.isDeleted
            "
            @click="postToJournalHandler"
            v-if="!selectedItem.isPostedToJournal"
          ></BaseBtn>
          <BaseBtn
            label="Reverse post to Journal"
            :colorType="2"
            :disabled="
              !isEdit || selectedItem._id == 'NEW' || selectedItem.isDeleted
            "
            @click="reversePostToJournalHandler"
            v-else
          ></BaseBtn>
        </div>
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--primary': selectedItem.isPostedToJournal,
            'bordered__highlighted--danger':
              selectedItem.isDeleted || selectedItem.isArchived,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedItem.isPostedToJournal"
              type="primary"
              text="POSTED TO JOURNAL"
            />
            <ContainerLabel
              v-if="selectedItem.isArchived"
              type="danger"
              text="ARCHIVED"
            />
            <ContainerLabel
              v-if="selectedItem.isDeleted"
              type="danger"
              text="DELETED"
            />
          </transition>
          <div class="credit-memo-editor__grid">
            <div class="credit-memo-editor__grid__column">
              <Input
                :model="selectedItem.giftCard"
                :inputData="getInput('giftCard')"
                :isEdit="false"
              />
              <Input
                :model="selectedItem.enteredDate"
                :inputData="getInput('createdAt')"
                :isEdit="false"
              />
            </div>
            <div class="credit-memo-editor__grid__column">
              <Input
                :model="selectedItem.memoTotal"
                @updateModel="selectedItem.memoTotal = $event"
                :inputData="getInput('memoTotal')"
                :isEdit="isEdit"
              />
              <Input
                :model="selectedItem.memoAmountUsed"
                :inputData="getInput('memoAmountUsed')"
                :isEdit="false"
              />
              <Input
                :model="selectedItem.memoBalance"
                :inputData="getInput('memoBalance')"
                :isEdit="false"
              />
            </div>
            <div class="credit-memo-editor__grid__column">
              <Input
                :model="selectedItem.ccpStartDate"
                @updateModel="selectedItem.ccpStartDate = $event"
                :inputData="getInput('ccpStartDate')"
                :isEdit="isEdit"
              />
              <Input
                :model="selectedItem.ccpExpirationDate"
                @updateModel="selectedItem.ccpExpirationDate = $event"
                :inputData="getInput('ccpExpirationDate')"
                :isEdit="isEdit"
                :selectedItem="selectedItem"
              />
            </div>
          </div>
          <div class="credit-memo-editor__divider" />
          <div class="credit-memo-editor__grid">
            <div class="credit-memo-editor__grid__column">
              <Input
                :model="selectedItem.memoType"
                @updateModel="selectedItem.memoType = $event"
                :inputData="memoTypes"
                :isEdit="isEdit"
              />
              <Input
                :model="selectedItem.customer"
                @updateModel="selectedItem.customer = $event"
                :inputData="getInput('customer')"
                :isEdit="isEdit"
              />
              <Input
                :model="selectedItem.supplier"
                @updateModel="selectedItem.supplier = $event"
                :inputData="getInput('supplier')"
                :isEdit="isEdit"
              />
            </div>
            <div class="credit-memo-editor__grid__column">
              <Input
                :model="selectedItem.pointQuantity"
                :inputData="getInput('pointQuantity')"
                :isEdit="false"
              />
              <Input
                :model="selectedItem.pointQuantity2"
                :inputData="getInput('pointQuantity2')"
                :isEdit="false"
              />
              <Input
                :model="selectedItem.discountAmountRefund"
                :inputData="getInput('discountAmountRefund')"
                :isEdit="false"
              />
            </div>
            <div class="credit-memo-editor__grid__column">
              <Input
                :model="selectedItem.accountNo"
                :inputData="getInput('accountNo')"
                :isEdit="false"
              />
              <Input
                :model="selectedItem.expenseAccountCode"
                :inputData="getInput('expenseAccountCode')"
                @updateModel="selectedItem.expenseAccountCode = $event"
                :isEdit="isEdit"
              />
              <Input
                :model="selectedItem.order"
                :inputData="getInput('order')"
                :isEdit="isEdit"
              />
              <!-- <Input
                :model="selectedItem.item.barcode"
                :inputData="getInput('creditMemoItemId')"
                :isEdit="false"
              /> -->
              <Input
                :model="selectedItem.purchaseOrder"
                :inputData="getInput('purchaseOrder')"
                :isEdit="isEdit"
              />
              <Input
                :model="selectedItem.orderRefund"
                :inputData="getInput('orderRefund')"
                :isEdit="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section-base">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">Notes</div>
        </div>
        <div class="padded elevated-0 bordered">
          <Input
            :model="selectedItem.notes"
            :inputData="getInput('notes')"
            @updateModel="selectedItem.notes = $event"
            :isEdit="isEdit"
          />
        </div>
      </div>
      <EditorSection
        :title="`About`"
        sectionType="about"
        v-if="selectedItem._id !== 'NEW'"
        :bus="bus"
      >
        <About
          :embedded="true"
          v-if="selectedItem._id !== 'NEW'"
          :entity="selectedItem"
        />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
    <BaseModal
      v-if="isCreateCCPOpen"
      @toggle-modal="toggleCreateCCPModal"
      title="Create CCP"
    >
      <CreateCCPModal @close="toggleCreateCCPModal" />
    </BaseModal>
  </div>
</template>

<script>
import Vue from "vue";
import Input from "@/components/Input/Input.vue";
import { mapGetters, mapActions } from "vuex";
import {
  pageHeader,
  creditMemoInfoInputs,
  filterCriteria,
  columnHeaders,
  MEMO_TYPES_NEW_CREDIT,
  MEMO_TYPES,
} from "./inputs";
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import CreateCCPModal from "./CreateCCPModal/index.vue";

export default {
  name: "CreditMemoEditor",
  data() {
    return {
      pageHeader,
      isEdit: false,
      isCollapsed: true,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      entity: Entities.CREDITMEMO,
      Constants,
      bus: new Vue(),
      isCreateCCPOpen: false,
    };
  },
  components: {
    PageHeader,
    Input,
    ContainerLabel,
    BaseBtn,
    About,
    EditorSection,
    BaseModal,
    CreateCCPModal,
  },
  watch: {
    id: function () {
      this.getCreditMemoInfo();
      document.getElementById("app-body").scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters({
      getCreditMemo: "creditMemoModule/getCreditMemo",
      store: "storeModule/currentStore",
    }),
    id() {
      return this.$route.query.id;
    },
    selectedItem: {
      get() {
        return this.prefillEntityBeforeRendering(this.getCreditMemo);
      },
      set(entity) {
        return entity;
      },
    },
    //creditMemoItemId() {
      //if (this.selectedItem.memoType == 'Refund' && (this.selectedItem.purchaseOrder || this.selectedItem.order)) return this.selectedItem.item.barcode;
      //return null
    //},
    memoTypes() {
      let memoTypesInput = this.getInput("memoType");
      if (this.selectedItem._id == "NEW") {
        memoTypesInput.options = MEMO_TYPES_NEW_CREDIT;
      } else {
        memoTypesInput.options = MEMO_TYPES;
      }
      return memoTypesInput;
    },
  },
  methods: {
    ...mapActions({
      getCreditMemoById: "creditMemoModule/getCreditMemoById",
      saveCreditMemo: "creditMemoModule/saveCreditMemo",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      postToJournal: "creditMemoModule/postToJournal",
      reversePostToJournal: "creditMemoModule/reversePostToJournal",
      printCreditMemo: "creditMemoModule/printCreditMemo",
    }),
    menuOptionClick(entity, key) {
      switch (key) {
        case "credit-memo-create-ccp":
          this.createCreditMemoCCP();
          break;
        default:
          break;
      }
    },

    canCreateCCP() {
      const isCcpDiscountEnabled = this.store.isCcpDiscountEnabled;
      const ccpDiscount = this.store.ccpDiscount;

      if (!isCcpDiscountEnabled) {
        this.setMessage({
          text: "CCP is not enabled in your store settings.",
          type: "error",
        });
        return false;
      }

      if (ccpDiscount <= 0) {
        this.setMessage({
          text: "CCP amount is not greater than 0 in store settings",
          type: "error",
        });
        return false;
      }

      return true;
    },
    toggleCreateCCPModal(event) {
      this.isCreateCCPOpen = event.isOpen;
    },
    createCreditMemoCCP() {
      if (!this.canCreateCCP()) return;

      this.isCreateCCPOpen = true;
    },
    getInput(field) {
      return creditMemoInfoInputs.find((i) => i.id == field);
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedItem[entity] = item;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedItem._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedItem._id === "NEW") {
        this.isEdit = true;
      }
    },
    prefillEntityBeforeRendering(creditMemo) {
      const createdAt = creditMemo.createdAt;
      creditMemo.enteredDate = createdAt
        ? new Date(createdAt).toLocaleDateString("en-US")
        : null;
      creditMemo.ccpStartDate = creditMemo.ccpStartDate
        ? new Date(creditMemo.ccpStartDate).toLocaleDateString("en-US")
        : null;
      creditMemo.ccpExpirationDate = creditMemo.ccpExpirationDate
        ? new Date(creditMemo.ccpExpirationDate).toLocaleDateString("en-US")
        : null;

      return creditMemo;
    },
    clearItemFromSearch: function (entity) {
      this.selectedItem[entity] = null;
    },
    postToJournalHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message:
          "Are you sure that you want to post this credit memo to the journal?",
        positiveCallback: () => {
          this.postToJournal(this.selectedItem).then(() => {
            this.getCreditMemoById({ _id: this.selectedItem._id });
          });
        },
      });
    },
    reversePostToJournalHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message:
          "Are you sure that you want to reverse the post of this credit memo to the journal?",
        positiveCallback: () => {
          this.reversePostToJournal(this.selectedItem).then(() => {
            this.getCreditMemoById({ _id: this.selectedItem._id });
          });
        },
      });
    },
    getCreditMemoInfo() {
      const id = this.$route.query.id;
      if (id && id != "NEW") {
        this.getCreditMemoById({ _id: id });
      } else {
        this.isEdit = true;
      }
    },
    performPrintCreditMemo() {
      this.printCreditMemo({ creditMemo: this.selectedItem });
    },
  },
  mounted() {
    this.getCreditMemoInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "./_creditMemoEditor.scss";
</style>
