// Purchase Order Editor
import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as supplierColumnHeaders,
  filterCriteria as supplierFilterCriteria,
} from "../Supplier/inputs";
import {
  searchColumnHeaders as supplierAgentColumnHeaders,
  filterCriteria as supplierAgentFilterCriteria,
} from "../SupplierAgent/inputs";
import { searchColumnHeaders as productColumnHeaders } from "../Product/inputs";

// Page Header
export const pageHeader = {
  title: "Purchase Order",
  isEditorView: true,
  cancelPath: "purchaseOrders",
  addPath: "purchaseOrderEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Purchase Orders",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "purchaseOrderEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Purchase Order Id",
    code: "_id",
    field: { type: "input", label: "Purchase Order Id" },
  },
  {
    label: "Manifest #",
    code: "manifestId",
    field: { type: "input", label: "Manifest #" },
    isPartial: true,
  },
  {
    label: "Supplier Id",
    code: "supplier",
    field: { type: "input", label: "Supplier" },
  },
  {
    label: "Supplier Name",
    code: "externalFields.supplier.name",
    field: { type: "input", label: "Supplier Name" },
    isPartial: true,
  },
  {
    label: "Supplier Invoice #",
    code: "invoiceId",
    field: { type: "input", label: "Supplier Invoice #" },
    isPartial: true,
  },
];

// Items Search Filter Criteria
export const itemsFilterCriteria = [
  {
    label: "Product Name",
    code: "product.name",
    field: { type: "input", label: "Product Name" },
  },
  {
    label: "Batch #",
    code: "batchId",
    field: { type: "input", label: "Batch #" },
  },
  {
    label: "Barcode #",
    code: "barcode",
    field: { type: "input", label: "Barcode #" },
  },
  {
    label: "Product Id",
    code: "product._id",
    field: { type: "input", label: "Product Id" },
  },
  {
    label: "Group Name",
    code: "externalFields.group.name",
    field: { type: "input", label: "Group Name" },
  },
  {
    label: "Inventory Type",
    code: "inventoryType",
    field: { type: "input", label: "Group Type" },
  },
];

// Columns (in order):
// • _id (column header clickable)
// • isPostedToGL (missing from collection)
// • isDeleted
// • isPaid (missing from collection)
// • createdAt (column header clickable)
// • ordersId
// • suppliersId
// • supplierName (must pull from suppliers) (column header clickable)
// • manifestId (column header clickable)
// • poTotal (must sum all items)
export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Date",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Supplier Name",
    id: "supplier.name",
    size: "large",
  },
  {
    title: "Batch Type",
    id: "batchType",
    size: "large",
  },
  {
    title: "Manifest #",
    id: "manifestId",
    size: "large",
  },
  {
    title: "Invoice #",
    id: "invoiceId",
  },
  {
    title: "Purchase Order Id",
    id: "_id",
  },
  {
    title: "Supplier Id",
    id: "supplier._id",
  },
  {
    title: "Store",
    id: "store",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Purchase Order Id",
    id: "_id",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Date",
    id: "createdAt",
  },
  {
    title: "Invoice #",
    id: "invoiceId",
  },
  {
    title: "Supplier Id",
    id: "supplier._id",
  },
  {
    title: "Supplier Name",
    id: "supplier.name",
  },
  {
    title: "Manifest #",
    id: "manifestId",
  },
];

// Purchase Order Information - Main Section
export const purchaseOrdeSection1Inputs = [
  {
    id: "supplier",
    type: "search",
    title: "Supplier",
    label: "name",
    entity: Entities.SUPPLIER,
    columnHeaders: supplierColumnHeaders,
    filterCriteria: supplierFilterCriteria,
  },
  {
    id: "supplierAgent",
    type: "search",
    title: "Supplier Agent",
    label: "name",
    entity: Entities.SUPPLIERAGENT,
    columnHeaders: supplierAgentColumnHeaders,
    filterCriteria: supplierAgentFilterCriteria,
  },
  {
    id: "manifestDate",
    type: "calendar",
    title: "Manifest Date",
  },
  {
    id: "manifestId",
    type: "input",
    title: "Manifest #",
    placeholder: "Manifest #",
    disabled: "isPostedToProducts",
    focus: true,
  },
  {
    id: "manifestId-button",
    type: "button",
    title: "Get Manifest",
  },
  {
    id: "invoiceId",
    type: "input",
    title: "Invoice #",
    placeholder: "Invoice #",
    disabled: "isPostedToProducts",
  },
  {
    id: "manualPO-button",
    type: "button",
    title: "Start Manual PO",
    disabled: "isPostedToProducts",
  },
  {
    id: "batchType",
    type: "select",
    title: "Batch Type",
    isClearable: false,
    options: Constants.listOfSaleType,
    label: "label",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const totalsConstantInputs = [
  {
    id: "unitCost",
    type: "text",
    title: "Item Total",
    value: "",
  },
  {
    id: "discount",
    type: "text",
    title: "Discount",
  },
  {
    id: "tax",
    type: "text",
    title: "Tax",
    value: "",
  },
  {
    id: "subTotal",
    type: "text",
    title: "Sub Total",
    value: "",
  },
  {
    id: "delivery",
    type: "text",
    title: "Delivery",
  },
  {
    id: "total",
    type: "text",
    title: "Total",
    value: "",
  },
];

export const totalInputs = [
  {
    id: "forceTaxRate-button",
    type: "button",
    title: "Force Tax Rate",
  },
  {
    id: "delivery",
    type: "money",
    title: "Delivery",
    prefix: "$",
    precision: Constants.numericInput.percentagePrecision,
  },
  {
    id: "setDelivery-button",
    type: "button",
    title: "Set Delivery",
  },
  {
    id: "discount",
    type: "money",
    title: "Discount",
    prefix: "$",
    precision: Constants.numericInput.percentagePrecision,
  },
  {
    id: "setDiscount-button",
    type: "button",
    title: "Set Discount",
  },
  {
    id: "applyTaxBeforeDiscount",
    type: "checkBox",
    title: "Apply Tax Before Discount",
  },
  {
    id: "apply-total",
    type: "button",
    title: "Apply",
  },
];

export const mainButtons = [
  {
    id: "markAllReceived-button",
    type: "button",
    title: "Mark All Received",
  },
  {
    id: "unmarkAllReceived-button",
    type: "button",
    title: "Unmark All Received",
  },
  {
    id: "postToJournal-button",
    type: "button",
    title: "Post to Journal",
  },
  {
    id: "reversePO-button",
    type: "button",
    title: "Reverse PO",
  },
  {
    id: "move-all-to-wi",
    type: "button",
    title: "Move All to WI",
  },
];

export const defaultPurchaseOrder = {
  forcedTaxRate: undefined,
  delivery: 0,
  discount: 0,
  _id: "NEW",
  manifest: undefined,
  items: [],
  manifestId: "",
  notes: "",
  supplier: {},
  supplierAgent: {},
  isPostedToProducts: false,
  batchType: "",
  isManual: true,
  applyTaxBeforeDiscount: false,
  manifestDate: "",
};

export const defaultPurchaseOrderItem = {
  product: {},
  batchId: "",
  barcode: "",
  description: "",
  groupType: "",
  quantityPurchased: 0,
  unitCost: 0,
  discount: 0,
  isReceived: false,
  isSample: false,
  isMenuPrintEnabled: true,
  isPublishedToOnlineStore: true,
  isPostedToProducts: false,
  subtotal: 0,
  tax: 0,
  freight: 0,
  total: 0,
  inventoryType: "",
  location: "vault",
  isNew: true,
  isTaxed: false,
  expirationDate: "",
  batchType: "",
  chemicalComposition: {
    totalCannabinoids: 0,
    indicaPercentage: 0,
    sativaPercentage: 0,
    thc: 0,
    thca: 0,
    cbd: 0,
    cbda: 0,
    cbg: 0,
    cbn: 0,
    cbc: 0,
  },
  externalFields: {},
};

export const itemDetailInputs = [
  {
    id: "product",
    type: "search",
    title: "Assign Product",
    label: "Product",
    entity: Entities.PRODUCT,
    columnHeaders: productColumnHeaders,
  },
  {
    id: "quantityPurchased",
    type: "number",
    title: "Quantity Purchased",
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "unitCost",
    type: "money",
    title: "Unit Cost",
    precision: Constants.inputMasks.moneyPrecision,
    prefix: "$",
  },
  {
    id: "batchId",
    placeholder: "Batch #",
    type: "input",
    title: "Batch #",
  },
  {
    id: "expirationDate",
    type: "calendar",
    title: "Expiration Date",
  },
  {
    id: "batchType",
    placeholder: "Batch Type",
    type: "select",
    isClearable: false,
    title: "Batch Type",
    options: Constants.listOfSaleType,
  },
  {
    id: "isSample",
    type: "checkBox",
    title: "Sample",
  },
  {
    id: "isReceived",
    type: "checkBox",
    title: "Received",
  },
  {
    id: "isPublishedToOnlineStore",
    type: "checkBox",
    title: "Public to online store",
  },
  {
    id: "isMenuPrintEnabled",
    type: "checkBox",
    title: "Print on Menu",
  },
  {
    id: "isTaxed",
    type: "checkBox",
    title: "Taxed",
  },
  {
    id: "receive",
    type: "button",
    title: "Receive Item",
  },
  {
    id: "unreceive",
    type: "button",
    title: "Unreceive Item",
  },
  {
    id: "close",
    type: "button",
    title: "Close",
  },
];

export const chemicalCompositionInputs = [
  {
    id: "thc",
    type: "number",
    title: "THC",
  },
  {
    id: "thca",
    type: "number",
    title: "THCA",
  },
  {
    id: "cbd",
    type: "number",
    title: "CBD",
  },
  {
    id: "cbda",
    type: "number",
    title: "CBDA",
  },
  {
    id: "totalCannabinoids",
    type: "number",
    title: "Total Cannabinoids",
  },
  {
    id: "indicaPercentage",
    type: "number",
    title: "Indica Percentage",
  },
  {
    id: "sativaPercentage",
    type: "number",
    title: "Sativa Percentage",
  },
  {
    id: "cbg",
    type: "number",
    title: "CBG",
  },
  {
    id: "cbn",
    type: "number",
    title: "CBN",
  },
  {
    id: "cbc",
    type: "number",
    title: "CBC",
  },
  {
    id: "get-chemical-composition",
    type: "button",
    title: "Get Chemical Composition",
  },
];
