<template>
  <div class="cash-reconciliation-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'cashReconciliations', label: 'Cash Reconciliations' },
        {
          name: 'cashReconciliationEditor',
          label: `${selectedItem._id}`,
          query: $route.query,
        },
      ]" :isEdit="isEdit" :isCollapsed="isCollapsed" :entity="entity" :hideAddButton="true"
        :selectedEntity="selectedItem" @updateIsEdit="isEdit = $event" @toggle-sections="forceToggle"
        @toggle-edit="toggleEdit" @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick" :canPost="canPost" @post="handlePosting"
        :canEdit="!selectedItem.isDeleted && !selectedItem.isPostedToJournal"
        :disable="selectedItem.isPostedToJournal == true" />
      <div class="section-base">
        <div class="reconciliation-type-selector">
          <div v-if="type == 'station'" style="display: flex; justify-content: flex-end;">
            <p v-if="isLoadingStations">Loading...</p>
            <Dropdown v-else v-model="selectedItem.workstation" :options="stations" optionLabel="name"
              optionValue="_id" aria-label="Select" placeholder="Select Station" @change="collectInfoByStation"
              :disabled="!isEdit" />
          </div>
          <Dropdown v-else-if="type == 'employee'" v-model="selectedItem.employee" :options="employees" optionLabel="name"
            optionValue="_id" aria-label="Select" placeholder="Select Employee" @change="collectInfoByEmployee"
            :disabled="!isEdit">
            <template #option="slotProps">
              <span>{{ slotProps.option.name }}
                {{ slotProps.option.lastName }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="section-base__header space-between" v-if="showInfo">
          <div style="position: relative" class="padded elevated-0 bordered cash-reconciliation-editor__base" :class="{
            'bordered__highlighted--primary': selectedItem.isPostedToJournal,
            'bordered__highlighted--danger':
              selectedItem.isDeleted || selectedItem.isArchived,
          }">
            <transition name="fade">
              <ContainerLabel v-if="selectedItem.isPostedToJournal" type="primary" text="POSTED TO JOURNAL" />
              <ContainerLabel v-if="selectedItem.isArchived" type="danger" text="ARCHIVED" />
              <ContainerLabel v-if="selectedItem.isDeleted" type="danger" text="DELETED" />
            </transition>
            <div class="cash-reconciliation-editor__grid">
              <div class="cash-reconciliation-editor__grid__column">
                <Input :model="selectedItem.cashAtOpening" :inputData="getInput('opening')"
                  @updateModel="update('cashAtOpening', $event)" :isEdit="isEdit" />
                <Input :model="selectedItem.cashCollected" :inputData="getInput('cashCollected')" :isEdit="false" />
                <Input :model="selectedItem.payoutTotal" :inputData="getInput('payoutTotal')"
                  @updateModel="update('payoutTotal', $event)" :isEdit="isEdit" />
                <Input :model="selectedItem.userCashCountTotal" :inputData="getInput('userCashCountTotal')"
                  @updateModel="update('userCashCountTotal', $event)" :isEdit="isEdit" />
                <Input :model="selectedItem.cashTotalExpected" :inputData="getInput('cashTotalExpected')"
                  :isEdit="false" />
                <Input :model="selectedItem.cashTotalDifference" :inputData="getInput('cashTotalDifference')"
                  :isEdit="false" />
              </div>
              <div class="cash-reconciliation-editor__grid__column">
                <PriceSummary class="cash-reconciliation-editor__summary" :rows="cashReconciliationSummary" />
                <DateSummary v-if="selectedItem._id !== 'NEW'" :from="selectedItem.startDate"
                  :to="selectedItem.endDate" />
              </div>
            </div>
            <div class="cash-reconciliation-editor__divider" />
            <div class="money-grid">
              <BaseNumberInput label="$1" placeholder="0" :value="selectedItem.cashOneDollar" :controlType="'number'"
                min="0" :disabled="!isEdit" step="1" @updateModel="update('cashOneDollar', $event)" />
              <BaseNumberInput label="$2" placeholder="0" :value="selectedItem.cashTwoDollar" :controlType="'number'"
                min="0" :disabled="!isEdit" step="1" @updateModel="update('cashTwoDollar', $event)" />
              <BaseNumberInput label="$5" placeholder="0" :value="selectedItem.cashFiveDollar" :controlType="'number'"
                :disabled="!isEdit" min="0" step="1" @updateModel="update('cashFiveDollar', $event)" />
              <BaseNumberInput label="$10" placeholder="0" :value="selectedItem.cashTenDollar" :controlType="'number'"
                :disabled="!isEdit" min="0" step="1" @updateModel="update('cashTenDollar', $event)" />
              <BaseNumberInput label="$20" placeholder="0" :value="selectedItem.cashTwentyDollar" :controlType="'number'"
                :disabled="!isEdit" min="0" step="1" @updateModel="update('cashTwentyDollar', $event)" />
              <BaseNumberInput label="$50" placeholder="0" :value="selectedItem.cashFiftyDollar" :controlType="'number'"
                :disabled="!isEdit" min="0" step="1" @updateModel="update('cashFiftyDollar', $event)" />
              <BaseNumberInput label="$100" placeholder="0" :value="selectedItem.cashOneHundredDollar" :disabled="!isEdit"
                :controlType="'number'" min="0" step="1" @updateModel="update('cashOneHundredDollar', $event)" />
            </div>
            <div class="cash-reconciliation-editor__divider" />
            <div class="money-grid">
              <BaseNumberInput label="$.01" placeholder="0" :value="selectedItem.cashOneCent" :controlType="'number'"
                min="0" :disabled="!isEdit" step="1" @updateModel="update('cashOneCent', $event)" />
              <BaseNumberInput label="$.05" placeholder="0" :value="selectedItem.cashFiveCent" :controlType="'number'"
                :disabled="!isEdit" min="0" step="1" @updateModel="update('cashFiveCent', $event)" />
              <BaseNumberInput label="$.10" placeholder="0" :value="selectedItem.cashTenCent" :controlType="'number'"
                min="0" :disabled="!isEdit" step="1" @updateModel="update('cashTenCent', $event)" />
              <BaseNumberInput label="$.25" placeholder="0" :value="selectedItem.cashTwentyFiveCent"
                :controlType="'number'" :disabled="!isEdit" min="0" step="1"
                @updateModel="update('cashTwentyFiveCent', $event)" />
              <BaseNumberInput label="$.50" placeholder="0" :value="selectedItem.cashFiftyCent" :controlType="'number'"
                :disabled="!isEdit" min="0" step="1" @updateModel="update('cashFiftyCent', $event)" />
              <BaseNumberInput label="Other" placeholder="0" :value="selectedItem.cashOther" :controlType="'number'"
                min="0" :disabled="!isEdit" step="1" @updateModel="update('cashOther', $event)" />
            </div>
          </div>
        </div>
      </div>
      <div class="table-tax-container bordered ordersInfo" v-if="showInfo">
        <table cellspacing="0" class="table-element">
          <thead>
            <tr class="header">
              <th class="cell">Employee</th>
              <th class="cell">Sales</th>
              <th class="cell">Refunds</th>
              <th class="cell">Total Sales</th>
              <th class="cell">Cash</th>
              <th class="cell">Credit Memo</th>
              <th class="cell">Debit / Credit</th>
              <th class="cell">Gift Card</th>
              <th class="cell">Total Payments</th>
              <th class="cell">Customer Count</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row" v-for="employeeRecord of this.selectedItem.employeesData" :key="employeeRecord._id">
              <td class="cell">{{ employeeRecord.employee.name }}</td>
              <td class="">
                {{ formatPrice(employeeRecord.total.saleTotal) }}
              </td>
              <td class="">
                {{ formatPrice(employeeRecord.total.refundTotal) }}
              </td>
              <td class="">
                {{
                  formatPrice(
                    employeeRecord.total.totalSales -
                    employeeRecord.total.refundTotal
                  )
                }}
              </td>
              <td class="">
                {{ formatPrice(employeeRecord.total.cashCollected) }}
              </td>
              <td class="">
                {{ formatPrice(employeeRecord.total.creditmemoCollected) }}
              </td>
              <td class="">
                {{ formatPrice(employeeRecord.total.debitcreditCardCollected) }}
              </td>
              <td class="">
                {{ formatPrice(employeeRecord.total.giftcardCollected) }}
              </td>
              <td class="">
                {{ formatPrice(employeeRecord.total.totalCollected) }}
              </td>
              <td class="">
                {{ employeeRecord.orders.length }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="
          !this.selectedItem.employeesData ||
          this.selectedItem.employeesData.length == 0
        " style="width: 100%; text-align: center">
          <p>No Data Found</p>
        </div>
      </div>
      <EditorSection :title="`About`" sectionType="about" v-if="selectedItem._id !== 'NEW'" :bus="bus">
        <About :embedded="true" v-if="selectedItem._id !== 'NEW'" :entity="selectedItem" />
      </EditorSection>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Input from "@/components/Input/Input.vue";
import { mapGetters, mapActions } from "vuex";
import {
  pageHeader,
  cashReconciliationInfoInputs,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import Constants from "@/resources/Constants";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import PriceSummary from "@/components/PriceSummary/PriceSummary.vue";
import Utilities from "@/lib/Utilities";
import BaseNumberInput from "@/components/Base/Number/Index.vue";
import Dropdown from "primevue/dropdown";
import moment from "moment";
import DateSummary from './DateSummary/DateSummary.vue'

export default {
  name: "CashReconciliationEditor",
  data() {
    return {
      pageHeader,
      isEdit: false,
      isCollapsed: true,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      entity: Entities.CASHRECONCILIATION,
      Constants,
      bus: new Vue(),
      Utilities,
      error: false,
    };
  },
  components: {
    PageHeader,
    Input,
    About,
    EditorSection,
    ContainerLabel,
    PriceSummary,
    BaseNumberInput,
    Dropdown,
    DateSummary
  },
  watch: {
    id: function () {
      this.getCashReconciliationInfo();
      document.getElementById("app-body").scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters({
      getCashReconciliation: "cashReconciliationModule/getCashReconciliation",
      store: "storeModule/currentStore",
      getEmployees: "employeeModule/employees",
      getCurrentStore: "storeModule/currentStore",
      getStationsWithActivity: 'cashReconciliationModule/getStationsWithActivity',
      getLoading:  'cashReconciliationModule/getLoading',
    }),
    canPost() {
      return this.selectedItem.isPostedToJournal == false;
    },
    id() {
      return this.$route.query.id;
    },
    selectedItem: {
      get() {
        return this.prefillEntityBeforeRendering(this.getCashReconciliation);
      },
      set(entity) {
        return entity;
      },
    },
    isLoadingStations() {
      return this.getLoading.getStationsWithActivity
    },
    workstationsWithActivty() {
      return this.getStationsWithActivity;
    },
    stations() {
      let workstations = this.store.workstations;
      let filteredArray = [...workstations];
      if (this.workstationsWithActivty && this.workstationsWithActivty.length > 0) {
        filteredArray = workstations.filter(value => this.workstationsWithActivty.includes(value._id));
      }
      return filteredArray;
    },
    employees() {
      return this.getEmployees;
    },
    cashReconciliationSummary() {
      return [
        {
          name: "Sales",
          value: Utilities.formatPrice(this.selectedItem.saleTotal),
          isHighlighted: false,
        },
        {
          name: "Refunds",
          value: Utilities.formatPrice(this.selectedItem.refundTotal),
          isHighlighted: false,
        },
        {
          name: "Total Sales",
          value: Utilities.formatPrice(this.selectedItem.totalSales - this.selectedItem.refundTotal),
          isHighlighted: true,
          isBold: true,
        },
        {
          name: "Cash",
          value: Utilities.formatPrice(this.selectedItem.cashCollected),
          isHighlighted: false,
        },
        {
          name: "Credit memo",
          value: Utilities.formatPrice(this.selectedItem.creditmemoCollected),
          isHighlighted: false,
        },
        {
          name: "Debit/Credit",
          value: Utilities.formatPrice(
            this.selectedItem.debitcreditCardCollected
          ),
          isHighlighted: false,
        },
        {
          name: "Gift Card",
          value: Utilities.formatPrice(this.selectedItem.giftcardCollected),
          isHighlighted: false,
        },
        {
          name: "Total Payments",
          value: Utilities.formatPrice(this.selectedItem.totalCollected),
          isHighlighted: true,
          isBold: true,
        },
      ];
    },
    type() {
      const store = this.getCurrentStore;
      return store.cashReconciliationType;
    },
    showInfo() {
      if (this.type == "station") {
        if (this.selectedItem?.workstation != null && !this.error) return true;
        return false;
      } else {
        // employee
        if (this.selectedItem?.employee != null && !this.error) return true;
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      getCashReconciliationById:
        "cashReconciliationModule/getCashReconciliationById",
      saveCashReconciliation: "cashReconciliationModule/saveCashReconciliation",
      setCashReconciliation: "cashReconciliationModule/setCashReconciliation",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      retrieveCashReconciliationInfo:
        "cashReconciliationModule/getCashReconciliationInfo",
      postCashReconciliation: "cashReconciliationModule/postCashReconciliation",
      retrieveEmployees: "employeeModule/getEmployees",
      retrieveStationsWithActivity: 'cashReconciliationModule/getStationsWithActivity',
      updateCashReconciliationUserCashCount: "cashReconciliationModule/updateCashReconciliationUserCashCount",
    }),
    handlePosting() {
      const payload = { id: this.selectedItem._id };
      const fromDate = moment(this.selectedItem.startDate).format(
        Constants.dates.extendedCalendarDateFormat
      );
      const toDate = moment(this.selectedItem.endDate).format(
        Constants.dates.extendedCalendarDateFormat
      );
      this.setMessagePopUp({
        isOpen: true,
        title: 'Are you sure you want to post?',
        message: `<b>From:</b>&#9;${fromDate}<br><br><b>To:</b>&#9;${toDate}`,
        positiveCallback: () => this.postCashReconciliation(payload),
      });
    },
    calculateTotCashCount() {
      let tot = 0;
      tot += this.selectedItem.cashOneDollar;
      tot += 2 * this.selectedItem.cashTwoDollar;
      tot += 5 * this.selectedItem.cashFiveDollar;
      tot += 10 * this.selectedItem.cashTenDollar;
      tot += 20 * this.selectedItem.cashTwentyDollar;
      tot += 50 * this.selectedItem.cashFiftyDollar;
      tot += 100 * this.selectedItem.cashOneHundredDollar;
      tot += 0.01 * this.selectedItem.cashOneCent;
      tot += 0.05 * this.selectedItem.cashFiveCent;
      tot += 0.1 * this.selectedItem.cashTenCent;
      tot += 0.25 * this.selectedItem.cashTwentyFiveCent;
      tot += 0.5 * this.selectedItem.cashFiftyCent;
      this.selectedItem.userCashCountTotal = tot;
    },
    resetCashCount() {
      this.selectedItem.cashOneDollar = 0;
      this.selectedItem.cashTwoDollar = 0;
      this.selectedItem.cashFiveDollar = 0;
      this.selectedItem.cashTenDollar = 0;
      this.selectedItem.cashTwentyDollar = 0;
      this.selectedItem.cashFiftyDollar = 0;
      this.selectedItem.cashOneHundredDollar = 0;
      this.selectedItem.cashOneCent = 0;
      this.selectedItem.cashFiveCent = 0;
      this.selectedItem.cashTenCent = 0;
      this.selectedItem.cashTwentyFiveCent = 0;
      this.selectedItem.cashFiftyCent = 0;
    },
    processAmounts(key) {
      if (key == "userCashCountTotal") {
        this.resetCashCount();
      } else {
        this.calculateTotCashCount();
      }
      this.selectedItem.cashTotalExpected =
        this.selectedItem.cashAtOpening +
        this.selectedItem.cashCollected -
        this.selectedItem.payoutTotal;
      this.selectedItem.cashTotalDifference =
        this.selectedItem.userCashCountTotal -
        this.selectedItem.cashTotalExpected;
    },
    async collectInfoByEmployee() {
      this.collectInfo({
        employeeId: this.selectedItem.employee,
        id: this.selectedItem._id,
      });
    },
    async collectInfo(payload) {
      this.error = true;
      await this.retrieveCashReconciliationInfo({ ...payload, type: this.type })
        .then((data) => {
          this.selectedItem.saleTotal = data.saleTotal;
          this.error = false;
          this.selectedItem.refundTotal = data.refundTotal;
          this.selectedItem.totalSales = data.totalSales;
          this.selectedItem.cashCollected = data.cashCollected;
          this.selectedItem.debitcreditCardCollected =
            data.debitcreditCardCollected;
          this.selectedItem.creditmemoCollected = data.creditmemoCollected;
          this.selectedItem.giftcardCollected = data.giftcardCollected;
          this.selectedItem.totalCollected = data.totalCollected;
          this.selectedItem.discountTotal = data.discountTotal;
          this.selectedItem.customerCountTotal = data.customerCountTotal;
          this.selectedItem.startDate = data.startDate;

          this.selectedItem.employeesData = data.employeesData;

          this.processAmounts();
        })
        .catch((err) => {
          this.error = true;
        });
    },
    async collectInfoByStation() {
      this.collectInfo({
        stationId: this.selectedItem.workstation,
        id: this.selectedItem._id,
      });
    },
    update(key, value) {
      const newValue = this.selectedItem[key] != value;
      this.selectedItem[key] = value;
      if (this.isEdit && newValue) {
        this.processAmounts(key);
      }
    },
    formatPrice(value) {
      return Utilities.formatPrice(value);
    },
    menuOptionClick(entity, key) {
      switch (key) {
        case "cash-reconciliation-details":
          this.$router.push({ name: "cashReconciliationDetails" });
          break;
        case "cash-reconciliation-summary":
          this.$router.push({ name: "cashReconciliationSummary" });
          break;
        case "cash-reconciliation-update-cash-count": {
          if (!this.selectedItem.isPostedToJournal) {
            this.setMessage({
              text: "This reconcilation has not been posted. Please edit the record.",
              type: "error",
            });
            return;
          }

          let warningMessage = "Are you sure that you wish to change the cash counted for this reconciliation?";

          const executeUpdate = function (cashUpdate) {
            this.updateCashReconciliationUserCashCount({
              cashReconciliationId: this.selectedItem._id,
              cashReconciliationUserCashCount: parseFloat(cashUpdate.replace(/,/g, '')),
              cashReconciliationExpectedCount: this.selectedItem.cashTotalExpected });
          }.bind(this);

          const proceedWithUpdate = function () {
            this.setMessagePopUp({
              isOpen: true,
              title: "Update User Cash Count?",
              message: `Current Amount is $${this.selectedItem.userCashCountTotal}. Please enter new amount:`,
              hasTextbox: true,
              placeholder: "Enter new amount:",
              isAnswerMandatory: false,
              isAknowledge: false,
              positiveCallback: executeUpdate,
            });
            }.bind(this);

            this.setMessagePopUp({
            isOpen: true,
            title: Constants.strings.warningMessageTitle,
            message: warningMessage,
            positiveCallback: proceedWithUpdate,
            });
          }
          break;

        default:
          break;
      }
    },
    getInput(field) {
      return cashReconciliationInfoInputs.find((i) => i.id == field);
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedItem[entity] = item;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedItem._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedItem._id === "NEW") {
        this.isEdit = true;
      }
    },
    prefillEntityBeforeRendering(cashReconciliation) {
      return cashReconciliation;
    },
    clearItemFromSearch: function (entity) {
      this.selectedItem[entity] = null;
    },
    async getCashReconciliationInfo() {
      const id = this.$route.query.id;
      if (id && id != "NEW") {
        this.getCashReconciliationById({ _id: id });
      } else {
        this.isEdit = true;
        this.retrieveStationsWithActivity();
      }
    },
  },
  mounted() {
    this.getCashReconciliationInfo();

    const storeId = this.getCurrentStore._id;
    this.retrieveEmployees({ filterCriteria: "store", filterValue: storeId });
  },
};
</script>

<style lang="scss" scoped>
@import "./_cashReconciliationEditor.scss";
</style>
