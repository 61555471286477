<template>
  <div class="employee-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'employees', label: 'Employees' },
        {
          name: 'employeeEditor',
          label: selectedEmployee.name,
          query: $route.query,
        },
      ]" :isEdit="isEdit" :selectedEntity="selectedEmployee" @updateIsEdit="isEdit = $event" :isCollapsed="isCollapsed"
        :entity="entity" @toggle-edit="toggleEdit" @toggle-sections="forceToggle"
        @show-search-modal="toggleSearchModal" @menu-option-click="menuOptionClick" />
      <!-- Main Customer Information  -->
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="inputs in employeeInfoInputs" :key="inputs.id">
              <div class="
                  grid-two-column
                  two-columns-layout--responsive-horizontal-space-10
                ">
                <div v-for="input in inputs.inputs" :key="input.id" :class="'input-text' + ' ' + input.class">
                  <Input :model="selectedEmployee[input.id]" @updateModel="selectedEmployee[input.id] = $event"
                    :inputData="input" :isEdit="isEdit" :data-cy="input.testAttribute" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Time Sheets -->
      <EditorSection :title="`View All Time Sheets for ${selectedEmployee.name}`" :bus="bus">
        <EmployeeTimeSheets v-if="selectedEmployee._id" :employeeId="selectedEmployee._id" />
      </EditorSection>

      <!-- Verification Begin  -->
      <EditorSection title="Verification" sectionType="verification" v-if="selectedEmployee._id !== 'NEW'" :bus="bus">
        <div class="space-between space-between--allow-wrapping">
          <div v-for="input in verificationInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
            <Input :model="selectedEmployee[input.id]" @updateModel="selectedEmployee[input.id] = $event"
              :inputData="input" :isEdit="isEdit" />
          </div>
          <inline-button label="Verify Employee" type="plain-primary responsive-column form-height wide"
            :isLoading="loadingStatus.saveEmployee" @click="() => saveEntity({ verifyIt: true })" :disabled="!isEdit" />
        </div>
      </EditorSection>
      <!-- Verification System End  -->

      <!-- Traceability System Begin  -->
      <EditorSection title="Traceability System" sectionType="traceabilitySystem" :bus="bus">
        <TraceabilityCredentials :isEdit="isEdit" :traceabilityCredentials="selectedEmployee.traceabilityCredentials"
          :employeeFirstName="selectedEmployee.name" :employeeLastName="selectedEmployee.lastName"
          :storesAvailable="storesAvailable"></TraceabilityCredentials>
      </EditorSection>
      <!-- Traceability System End  -->

      <!-- System Access Begin  -->
      <EditorSection title="System Access" data-cy="section-system-access" sectionType="systemAccess" :bus="bus">
        <div class="space-between space-between--allow-wrapping">
          <div v-for="input in systemAccessInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
            <Input :model="selectedEmployee[input.id]" @updateModel="selectedEmployee[input.id] = $event"
              :inputData="input" :isEdit="isEdit" :data-cy="input.testAttribute" />
          </div>
          <div :class="'input-text'" v-if="isGlobal">
            <Input :model="selectedEmployee['role']" @updateModel="selectedEmployee['role'] = $event"
              :inputData="getSystemAccessInput()" :isEdit="isEdit" />
          </div>
        </div>
      </EditorSection>
      <!-- System Access End  -->

      <!-- Store Access Begin  -->
      <EditorSection title="Store Access" sectionType="systemAccess" :bus="bus">
        <div class="
            two-columns-layout
            two-columns-layout--responsive
            two-columns-layout--border-bottom
          ">
          <div class="space-between space-between--allow-wrapping" v-if="isEdit">
            <v-select v-model="newStoreAccess.store" class="input-text__select" placeholder="Select Store"
              :options="stores" label="name" />
            <v-select v-model="newStoreAccess.role" class="input-text__select" placeholder="Select Role"
              :options="Constants.listOfStoreRoles" />

            <inline-button label="Add" type="plain-primary responsive-column form-height wide" @click="addStoreToEmployee"
              :disabled="!isNewStoreValid" />
          </div>
        </div>
        <div v-for="(storeEmployee, index) in selectedEmployee.stores" :key="index" class="
            two-columns-layout
            two-columns-layout--responsive
            two-columns-layout--border-bottom
          ">
          <div class="space-between space-between--allow-wrapping">
            <div>{{ storeEmployee.store.name }}</div>
            <div>{{ storeEmployee.store.address || "-" }}</div>
            <div>{{ getRoles(storeEmployee.role) }}</div>
            <inline-button v-if="isEdit" @click="removeStoreFromEmployee(index)" class="inline-button--plain-danger">
              <font-awesome-icon :icon="['fas', 'times']" /></inline-button>
          </div>
        </div>
      </EditorSection>
      <!-- Store Access End  -->

      <!-- Notes Access Begin  -->
      <EditorSection title="Notes" sectionType="notes" :bus="bus">
        <div class="editor-section-button-row margin-bottom-md">
          <inline-button label="Add" type="plain-primary responsive-column form-height wide" @click="addNote"
            :disabled="!isEdit" />
        </div>
        <div v-for="(note, i) in selectedEmployee.noteList" :key="note._id" class="two-columns-layout--border-bottom">
          <div class="
              two-columns-layout
              two-columns-layout--responsive
              two-columns-layout--responsive-horizontal-space-10-forced
            ">
            <div class="space-between space-between--allow-wrapping">
              <div class="flex-right">
                <inline-button label="Delete" class="desktop-only-inline-block"
                  type="plain-danger responsive-column form-height wide" @click="() => deleteNote(i)"
                  :disabled="!isEdit" />
              </div>
              <div v-for="input in notesInputs" :key="input.id" :class="'input-text ' + input.class">
                <Input :model="note[input.id]" @updateModel="note[input.id] = $event" :inputData="input"
                  :isEdit="isEdit" />
              </div>
            </div>
          </div>
          <About :embedded="true" v-if="note._id !== 'NEW'" :entity="note" />
          <inline-button label="Delete" class="mobile-only-block" type="plain-danger responsive-column form-height wide"
            @click="() => deleteNote(i)" :disabled="!isEdit" />
        </div>
      </EditorSection>

      <!-- Termination Begin  -->
      <EditorSection title="Termination" sectionType="termination" v-if="selectedEmployee._id !== 'NEW' && false" :bus="bus">
        <div>
          <div class="space-between space-between--allow-wrapping">
            <inline-button v-if="selectedEmployee.terminationDate === undefined" label="Terminate Employee"
              type="plain-danger responsive-column form-height wide" :isLoading="loadingStatus.saveEmployee" @click="
                setMessagePopUp({
                  isOpen: true,
                  title: Constants.strings.warningMessageTitle,
                  message: Constants.strings.terminationConfirmation,
                  positiveCallback: () => saveEntity({ terminateIt: true }),
                })
                " :disabled="!isEdit" />
            <div v-if="selectedEmployee.terminationDate" class="input-text">
              <div class="input-text__label">Termination Date</div>
              <div class="field__content">
                {{
                  moment(selectedEmployee.terminationDate).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                }}
              </div>
            </div>
            <div v-if="selectedEmployee.terminationDate" class="input-text">
              <div class="input-text__label">Terminated By</div>
              <div class="field__content">
                {{ Utilities.composeNameAndLastName(selectedEmployee) }}
              </div>
            </div>
          </div>
        </div>
      </EditorSection>
      <EditorSection :title="`About ${selectedEmployee.name || ''}`" sectionType="about"
        v-if="selectedEmployee._id !== 'NEW'" :bus="bus">
        <About v-if="selectedEmployee._id !== 'NEW'" :entity="selectedEmployee" />
      </EditorSection>
    </div>
    <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal" @toggle-modal="toggleSearchModal"
      :entity="entity" :forcedFilterCriteria="filterCriteria" :columnHeaders="columnHeaders" />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import TraceabilityCredentials from "@/views/Employee/components/TraceabilityCredentials/TraceabilityCredentials.vue";
import Input from "@/components/Input/Input.vue";
import { setNewEntity } from "@/utils/initialize-page";
import EmployeeTimeSheets from "@/components/EmployeeTimeSheetTable/EmployeeTimeSheets.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { multiStoreRoles, Role } from "@/constants/roles";

import {
  employeeInfoInputs,
  verificationInputs,
  traceabilityInputs,
  systemAccessInputs,
  storeAccessInputs,
  notesInputs,
  filterCriteria,
  columnHeaders,
  pageHeader,
  defaultSelectedEmployee,
} from "./inputs";

import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/index";

const NEW_STORE = {
  store: null,
  role: "",
};

export default {
  name: "employeeEditor",
  data() {
    return {
      Entities,
      entity: Entities.EMPLOYEE,
      sections: {
        verification: false,
      },
      Constants: Constants,
      moment: moment,
      Utilities: Utilities,
      multiStoreRoles,
      employeeInfoInputs,
      verificationInputs,
      traceabilityInputs,
      systemAccessInputs,
      storeAccessInputs,
      notesInputs,
      filterCriteria,
      organizationRole: Constants.listOfOrganizationRoles[0],
      isEdit: false,
      defaultIsOpen: false,
      defaultSelectedEmployee,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      pageHeader,
      newStoreAccess: { ...NEW_STORE },
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    Input,
    FontAwesomeIcon,
    TraceabilityCredentials,
    EmployeeTimeSheets,
  },
  mounted() {
    this.setPageTitle("Employee");
    if (this.$route.query.id) {
      this.getEmployeeById({
        _id: this.$route.query.id,
        positiveCallback: (employee) => {
          this.setPageTitle(
            `Employee ${Utilities.composeNameAndLastName(employee)}`
          );
          this.getStores({
            positiveCallback: this.populateStores,
          });
        },
      });
    } else {
      // new element
      this.isEdit = true;
      setNewEntity(this.entity);
      this.getStores({
        positiveCallback: this.populateStores,
      });
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getEmployeeById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    stores: {
      get() {
        if (this.isGlobal) {
          return this.storesAvailable;
        } else {
          const val2 = [this.currentStore];
          return val2;
        }
      },
      set(stores) {
        return stores;
      },
    },
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "employeeModule/loadingStatus",
      employee: "employeeModule/employee",
      storesAvailable: "storeModule/stores",
      getCurrentStore: "storeModule/currentStore",
      userRole: "loginModule/role",
    }),
    isGlobal() {
      const found = this.Constants.listOfOrganizationRoles.filter(
        (o) => o.code == this.userRole
      );
      return found && found.length > 0;
    },
    currentStore() {
      return this.getCurrentStore;
    },
    traceabilityInputsFiltered() {
      return this.traceabilityInputs.filter(
        (input) =>
          input.key === "any" ||
          input.key === this.selectedEmployee.traceabilitySystem
      );
    },
    selectedEmployee: {
      get() {
        this.setPageTitle("Employee " + this.employee.name);
        return this.prefillEntityBeforeRendering(this.employee);
      },
      set(entity) {
        return entity;
      },
    },
    isNewStoreValid() {
      return (
        this.newStoreAccess.role &&
        this.newStoreAccess.store &&
        this.newStoreAccess.store._id
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  methods: {
    ...mapActions({
      getEmployeeById: "employeeModule/getEmployeeById",
      saveEmployee: "employeeModule/saveEmployee",
      deleteEmployee: "employeeModule/deleteEmployee",
      getStores: "storeModule/getStores",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getAdjacentEmployee: "employeeModule/getAdjacentEmployee",
      setEmployee: "employeeModule/setEmployee",
    }),
    getSystemAccessInput() {
      const inputData = {
        id: "role",
        type: "select",
        title: "Organization Role",
        placeholder: "Select Role",
        options: Constants.listOfOrganizationRoles,
      };
      let input = JSON.parse(JSON.stringify(inputData));

      if (this.isGlobal && this.userRole == Role.GLOBAL_INVENTORY_MANAGER) {
        input.options = input.options.filter(
          (o) => o.code == Role.GLOBAL_INVENTORY_MANAGER
        );
      }

      return input;
    },
    getRoles(currentRole) {
      const empty = "-";
      if (!currentRole) return empty;
      const role = Constants.listOfStoreRoles.find(
        (r) => r.code === currentRole
      );
      return role ? role.label : empty;
    },
    populateStores(stores) {
      const storesLocal = JSON.parse(JSON.stringify(stores));
      const storesLocalArray = [];
      var selectedEmployee = this.selectedEmployee;
      storesLocal.forEach((sl) => {
        const storeLocal = {
          store: sl,
          isAccessGranted: false,
          roles: [],
          role: undefined,
          name: "",
        };

        const employeeStore = selectedEmployee.stores.find((es) => {
          es.store?._id === storeLocal.store?._id;
        });

        if (employeeStore) {
          storeLocal.isAccessGranted = true;
          storeLocal.roles = employeeStore.roles;
          const organizationRole = _.filter(
            Constants.listOfStoreRoles,
            (sr) => sr.code === employeeStore.role
          );
          if (organizationRole.length > 0) {
            storeLocal.role = organizationRole[0];
          } else {
            storeLocal.role = Constants.listOfStoreRoles[0];
          }
        }

        storesLocalArray.push(storeLocal);
      });
      this.stores = storesLocalArray;
    },
    saveEntity(options = {}) {
      this.selectedEmployee.verifyIt = options.verifyIt;
      this.selectedEmployee.terminateIt = options.terminateIt;
      const normalizedEntity = normalizeEntityBeforeSaving[this.entity](
        this.selectedEmployee,
        this.stores
      );
      this.saveEmployee({
        employee: normalizedEntity,
        positiveCallback: (data) => {
          this.isEdit = false;
          this.getEmployeeById({
            _id: data._id,
          });
        },
      });
    },
    prefillEntityBeforeRendering(employee) {
      if (employee._id !== "NEW") {
        employee.password = "*********";
        employee.confirmedPassword = "*********";
      }
      if (employee.employmentDate) {
        employee.employmentDate = moment(employee.employmentDate).format(
          this.Constants.dates.defaultDateFormat
        );
      }
      if (employee.safeAccessStartDate) {
        employee.safeAccessStartDate = moment(
          employee.safeAccessStartDate
        ).format(this.Constants.dates.defaultDateFormat);
      }
      if (employee.safeAccessEndDate) {
        employee.safeAccessEndDate = moment(employee.safeAccessEndDate).format(
          this.Constants.dates.defaultDateFormat
        );
      }
      if (employee.verificationExpirationDate) {
        employee.verificationExpirationDate = moment(
          employee.verificationExpirationDate
        ).format(this.Constants.dates.defaultDateFormat);
      }
      if (employee.verificationDate) {
        employee.verificationDate = moment(employee.verificationDate).format(
          this.Constants.dates.defaultDateFormat
        );
      }
      if (employee.roles?.length > 0) {
        const selectedRole = employee.roles[0];
        const organizationRole = _.filter(
          Constants.listOfOrganizationRoles,
          (sr) => sr.code === selectedRole
        );
        if (organizationRole.length > 0) {
          this.organizationRole = organizationRole[0];
        }
      }
      if (employee.noteList?.length > 0) {
        employee.noteList.forEach((n) => {
          const selectedNoteType = n.noteType;
          const noteType = _.find(
            Constants.listOfEmploymentNoteTypes,
            (nt) => nt.code === selectedNoteType
          );
          if (noteType) {
            n.noteType = noteType;
          }
        });
      }
      if (this.employee.stores) {
        this.stores = this.stores.filter((a) =>
          this.employee.stores.find((b) => a.id !== b.id)
        );
      }

      return employee;
    },
    addNote() {
      this.selectedEmployee.noteList.unshift({
        noteType: undefined,
        hours: 0,
        note: "",
        _id: "NEW",
      });
    },
    deleteNote(i) {
      this.selectedEmployee.noteList.splice(i, 1);
    },
    addStoreToEmployee() {
      let newStoreAssociated = {
        store: this.newStoreAccess.store,
        role: this.newStoreAccess.role.code,
      };

      const hasStoreAssociated = this.selectedEmployee.stores.find(
        (s) => s.store._id == this.newStoreAccess.store._id
      );
      if (hasStoreAssociated) {
        hasStoreAssociated.role = this.newStoreAccess.role.code;
        this.newStoreAccess = { ...NEW_STORE };
        return;
      }
      newStoreAssociated = JSON.parse(JSON.stringify(newStoreAssociated));
      this.selectedEmployee.stores.push(newStoreAssociated);
      this.newStoreAccess = { ...NEW_STORE };
    },
    removeStoreFromEmployee(index) {
      this.selectedEmployee.stores.splice(index, 1);
    },
    addNewEmployee: function () {
      this.isEdit = true;
      this.selectedEmployee.pin = parseInt(Math.random() * 100000);
      this.getStores({
        positiveCallback: this.populateStores,
      });
      this.selectedEmployee = this.defaultSelectedEmployee;
      this.selectedEmployee._id = "NEW";
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectEmployee: function (employee) {
      this.$router.push({
        name: "employeeEditor",
        query: { id: employee._id },
      });
      this.selectedEmployee = employee;
      this.showSearchModal = false;
    },
    toggleEdit() {
      if (!(this.selectedEmployee._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedEmployee._id === "NEW") {
        this.isEdit = true;
      }
    },
    menuOptionClick(entity, key) {
      if (key === "employee-listing-active") {
        window.open(`/reports/employee/employeeListActive`);
      }
      if (key === "employee-notes") {
        window.open(`/reports/employee/employeeNotes`);
      }
      if (key === "employee-hours") {
        window.open(`/reports/employee/employeeHours`);
      }
    },
  },
};
</script>
