<template>
  <div class="points">
    <Input
      :model="selectedItem.points"
      @updateModel="selectedItem.points = $event"
      :inputData="getInput('points')"
      :isEdit="true"
    />
    <div class="points__amount">
      <div>Amount:</div>
      <div>{{ memoBalance }}</div>
    </div>
    <div class="points__amount">
      <div>Order amount due:</div>
      <div>{{ Utilities.formatPrice(lineTotal) }}</div>
    </div>
    <BaseInput
      label="$ To Apply"
      placeholder="0"
      v-model="pointsToApply"
      :controlType="'number'"
      min="0"
      step="1"
      :disabled="disableMemoBalance"
    />
    <p v-if="pointsToApply > lineTotal" class="points__error">
      Can't apply more than the current order amount due
    </p>
    <BaseBtn label="Apply Discount" :disabled="disabled" @click="apply" />
  </div>
</template>

<script>
import _ from "lodash";
import Input from "@/components/Input/Input.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";

import { inputs } from "./inputs";
import { mapActions } from "vuex";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
      pointsToApply: null,
      selectedItem: {
        points: {},
      },
    };
  },
  components: {
    BaseBtn,
    Input,
    BaseInput,
  },
  props: {
    order: {
      type: Object,
    },
    lineTotal: {
      type: Number,
    },
  },
  computed: {
    disableMemoBalance() {
      if (
        !this.selectedItem ||
        !this.selectedItem.points ||
        this.selectedItem.points.memoBalance == null
      ) {
        return true;
      }
      
      return false;
    },
    disabled() {
      if (
        !this.selectedItem ||
        !this.selectedItem.points ||
        this.selectedItem.points.memoBalance == null
      )
        return true;

      if (this.pointsToApply > this.lineTotal) return true;

      if (this.selectedItem.points.memoBalance && this.pointsToApply > 0)
        return false;

      return true;
    },
    memoBalance() {
      if (
        !this.selectedItem ||
        !this.selectedItem.points ||
        this.selectedItem.points.memoBalance == null
      )
        return "-";
      return this.selectedItem.points.memoBalance;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
    }),
    getInput(field) {
      let input = inputs.find((i) => i.id == field);
      let pointsApplied =
        this.order?.discountsApplied
          ?.filter((d) => d.type == "points")
          .map((p) => p.discountId) || [];
      switch (field) {
        case "points":
          input.filter._id = { $nin: pointsApplied };
          if (this.order?.customer) {
            input.filter.customer = this.order.customer._id;
          }
          break;
        default:
          break;
      }

      return input;
    },
    updateAmountToAppy() {
      if (
        !this.selectedItem ||
        !this.selectedItem.points ||
        this.selectedItem.points.memoBalance == null
      ) {
        this.pointsToApply = null;
      }

      const memoBalance = this.selectedItem.points.memoBalance;
      const max = Math.min(memoBalance, this.lineTotal);
      this.pointsToApply = max;
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedItem[entity] = item;
      this.updateAmountToAppy();
    },
    clearItemFromSearch: function (entity) {
      this.selectedItem[entity] = {};
      this.pointsToApply = null;
    },
    isValid() {
      if (!this.pointsToApply || this.pointsToApply <= 0) {
        this.setMessage({
          text: "Points to apply not valid",
          type: "error",
        });
        return false;
      }

      if (this.pointsToApply > this.selectedItem.points.memoBalance) {
        this.setMessage({
          text: "Points to apply grater than balance",
          type: "error",
        });
        return false;
      }

      return true;
    },
    apply() {
      if (!this.isValid()) return;

      const discount = {
        type: "points",
        discountId: this.selectedItem.points._id,
        pointAmount: this.pointsToApply,
        pointBalance: this.selectedItem.points.memoBalance,
      };

      this.$emit("applyDiscount", discount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_points.scss";
</style>
