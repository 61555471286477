<template>
  <div class="product-browser-wrapper">
    <div class="product-browser-wrapper__order-type" v-if="currentStore.isMedical && currentStore.isRecreational">
      <BaseBtn iconGroup="fas" iconName="briefcase-medical" label="Medical"
        @click="$emit('set-order-type', { orderType: 'medical' })" :colorType="orderType == 'medical' ? -1 : 3" />
      <BaseBtn iconGroup="far" iconName="grin-beam" label="Recreational"
        :colorType="orderType == 'recreational' ? -1 : 3"
        @click="$emit('set-order-type', { orderType: 'recreational' })" />
    </div>
    <PosProductBrowser :isLoading="isLoading" :isCannabis="isCannabis" :selectedLevel1="selectedLevel1"
      :selectedLevel2="selectedLevel2" :itemHierarchy="itemHierarchy" :order="order"
      @set-level="(e) => $emit('set-level', e)" @cannabis-toggle="(e) => $emit('cannabis-toggle', e)"
      :forceOrderType="orderType" :isProductFixedHeight="isProductFixedHeight" />
  </div>
</template>

<script>
import PosProductBrowser from "@/views/Pos/components/PosProductBrowser/PosProductBrowser.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProductBrowserWrapper",
  components: { PosProductBrowser, BaseBtn },
  props: {
    selectedLevel1: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    selectedLevel2: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    itemHierarchy: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    order: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    isCannabis: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    orderType: {
      type: String,
    },
    isProductFixedHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore",
    })
  }
};
</script>

<style scoped lang="scss">
@import "./_productBrowserWrapper.scss";
</style>
