import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedMsoProduct } from "@/views/MSOProduct/inputs";
import { cloneDeep } from "lodash"

const state = {
  msoProducts: [],
  msoProduct: cloneDeep(defaultSelectedMsoProduct),
  loadingStatus: {
    saveMsoProduct: false,
    getMsoProducts: false,
    getMsoProductById: false,
    deleteMsoProduct: false,
  },
  msoProductPopUp: {
    isOpen: false,
  },
  filterButtons:[
    {
      isSelected: false,
      _id: 10,
      label: "All",
      name: "all",
      showAll: false,
      filter: {
      },
    },{
      isSelected: true,
      _id: 11,
      label: "Active",
      name: "active",
      showAll: false,
      filter: {
        isActive: true,
      },
    },
    {
      isSelected: false,
      _id: 12,
      label: "In Active",
      name: "in-active",
      showAll: false,
      filter: {
        isActive: false,
      },
    },
    {
      isSelected: false,
      _id: 13,
      label: "Archived",
      name: "archived",
      showAll: false,
      filter: {
        isArchived: true,
      },
    }
  ],
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setMsoProducts: (state, payload) => {
    state.msoProducts = payload;
  },
  setMsoProduct: (state, payload) => {
    if (payload._id === "NEW")
      state.msoProduct = cloneDeep(defaultSelectedMsoProduct)
    else state.msoProduct = { ...cloneDeep(defaultSelectedMsoProduct), ...payload };
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setMsoProductPopUp: (state, payload) => {
    state.msoProductPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
  updateFilterButtons: (state, payload) => {
    state.filterButtons = payload;
  },
};

const getters = {
  msoProducts: (state) => state.msoProducts,
  msoProduct: (state) => state.msoProduct,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  msoProductPopUp: (state) => state.msoProductPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
  filterButtons: (state) => state.filterButtons,
};

const actions = {
  saveMsoProduct: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveMsoProduct", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveMsoProduct,
      payload.msoProduct,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveMsoProduct",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteMsoProduct: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteMsoProduct",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteMsoProduct,
      payload.msoProduct,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteMsoProduct",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getMsoProductById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getMsoProductById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getMsoProductById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getMsoProductById",
          status: false,
        });
        if (response) {
          commit("setMsoProduct", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setMsoProductPopUp: ({ commit, state }, payload) => {
    commit("setMsoProductPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  },
  updateFilterButtons: ({ commit, state }, payload) => {
    commit("updateFilterButtons", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
