<template>
  <div class="mso-product-base">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "msoProductBase",
};
</script>
