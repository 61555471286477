<template>
  <div>
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered gift-card-cart">
      <div class="gift-card-cart__header">
        <h2>{{ product.name }}</h2>
      </div>
      <div class="gift-card-cart__body">
        <div v-for="card of giftCardsList" :key="card" class="gift-card">
          <div class="gift-card__barcode">{{ card }}</div>
          <BaseBtn
            :colorType="1"
            @click="remove(card)"
            iconGroup="fas"
            iconName="times"
          />
        </div>
      </div>
      <div class="gift-card-cart__footer">
        <BaseBtn label="Cancel" :colorType="3" @click="cancel" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      barcode: "",
    };
  },
  components: {
    BaseBtn,
  },
  computed: {
    ...mapGetters({
      getGiftCardCartPopUp: "posModule/giftCardCartPopUp",
    }),
    product() {
      return this.getGiftCardCartPopUp.product;
    },
    giftCardsList() {
      let list = [];
      const items = this.product.items || [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        list = list.concat(item.giftCardBarcodes);
      }
      if (list.length == 0){
      list = list.concat("000000");
      }

      return list;
    },
  },

  methods: {
    ...mapActions({
      setGiftCardCartPopUp: "posModule/setGiftCardCartPopUp",
      setMessagePopUp: "setMessagePopUp",
      addRemoveItemToOrder: "orderModule/addRemoveItemToOrder",
    }),
    handleNegativeAnswer() {
      this.setGiftCardCartPopUp({ isOpen: false });
    },
    cancel() {
      this.handleNegativeAnswer();
    },
    remove(barcode) {
      this.setMessagePopUp({
        isOpen: true,
        title: "Warning!",
        message: "Do you really want to remove this item from the cart?",
        positiveCallback: () => {
          this.performDeleteItem(barcode);
        },
        negativeCallback: () => {
          this.isBlurred = false;
        },
      });
    },
    performDeleteItem(barcode) {
      this.addRemoveItemToOrder({
        action: {
          productId: this.product._id,
          mode: "subtract",
          giftCardBarcode: barcode,
          quantity: 1,
        },
        positiveCallback: (order) => {
          this.setGiftCardCartPopUp({ isOpen: false });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_giftCardCart.scss";
</style>
