<template>
  <div class="mso-products responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'msoProducts', label: 'MSO Product' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort"
        :filterButtons="filterButtons" @filterButtonsChange="updateFilterButtons" :enableAdditionalFilter="true"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter"  />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "msoProducts",
  data() {
    return {
      listPageHeader,
      entity: Entities.MSOPRODUCT,
    };
  },
  mounted: function () {
    this.setPageTitle("MSOProducts");
  },
  components: {
    PageHeader,
    SearchTable,
  },
  computed: {
    ...mapGetters({
      sort: "msoProductModule/sort",
      pagination: "msoProductModule/pagination",
      initialFilter: "msoProductModule/initialFilter",
      filterButtons: "msoProductModule/filterButtons",
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "msoProductModule/updateSort",
      updatePagination: "msoProductModule/updatePagination",
      updateInitialFilter: "msoProductModule/updateInitialFilter",
      updateFilterButtons: "msoProductModule/updateFilterButtons",
    }),
  }
};
</script>
