<template>
  <div class="calendar-heatmap">
    <div class="calendar-heatmap__weeks">
      <div class="calendar-heatmap__day-labels">
        <div
          v-for="(day, i) of weekDays"
          :key="day"
          class="calendar-heatmap__day-label gray-text"
          :class="{ 'calendar-heatmap__day-label--secondary': i % 2 === 1 }"
        >
          {{ day }}
        </div>
      </div>
      <div
        v-for="(week, i) of weeks"
        :key="week.id"
        class="calendar-heatmap__week"
      >
        <div class="calendar-heatmap__month-label gray-text">
          {{ getNewMonthInWeek(i) }}
        </div>
        <div
          v-for="day of week.days"
          :key="day.id"
          class="calendar-heatmap__day"
          :style="getDayStyle(day)"
        >
          <div :style="getTooltipStyle(day)" class="calendar-heatmap__tooltip">
            {{ getTooltipText(day) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="palette" class="calendar-heatmap__legend">
      Less
      <div
        v-for="color of palette"
        :key="color"
        class="calendar-heatmap__legend-square"
        :style="{ backgroundColor: color }"
      ></div>
      More
    </div>
  </div>
</template>

<script>
const aggregateWeeks = (weeks) => {
  return weeks.map((week) => ({
    ...week,
    days: [
      week.days.reduce(
        (current, accumulator) => ({
          date: week.days[0]?.date,
          id: week.days[0]?.id,
          value: isNaN(current.value)
            ? 0 + accumulator.value
            : current.value + accumulator.value,
        }),
        { value: 0 }
      ),
    ],
  }));
};

export default {
  name: "CalendarHeatmap",
  data() {
    return {};
  },
  props: {
    chartData: { type: Object, default: () => ({ data: [], options: {} }) },
    collapsed: { type: Boolean, default: false },
  },
  computed: {
    palette() {
      if (!this.chartData.palette) return undefined;
      return [this.chartData.options.getColor(), ...this.chartData.palette];
    },
    weekDays() {
      if (this.collapsed) {
        return [];
      }
      const firstWeek = this.chartData.data?.weeks?.[0];
      return (
        firstWeek?.days?.map((day) =>
          new Date(day.date).toLocaleDateString("en-US", { weekday: "narrow" })
        ) || []
      );
    },
    weeks() {
      if (!this.collapsed) {
        return this.chartData.data.weeks;
      }
      return aggregateWeeks(this.chartData.data.weeks);
    },
  },
  methods: {
    getDayStyle(day) {
      const max = this.chartData.data.max;
      return {
        backgroundColor: this.chartData.options.getColor(
          day.value,
          max,
          this.collapsed
        ),
      };
    },
    getTooltipStyle(day) {
      const style = {};

      if (day.id < 50) {
        style.left = 0;
        style.transform = "translate(0, -190%)";
      }

      if (day.id > 50 && day.id < 300) {
        style.left = "50%";
        style.transform = "translate(-50%, -190%)";
      }

      if (day.id > 300) {
        style.right = 0;
        style.transform = "translate(0, -190%)";
      }

      return style;
    },
    getFormattedDate(date) {
      try {
        const dateObj = new Date(`${date}T00:00:00`);
        const dateSplit = dateObj.toString().split(" ");
        return `${dateSplit[0]} ${dateSplit[1]} ${dateSplit[2]}`;
      } catch (error) {
        return "";
      }
    },
    getTooltipText(day) {
      return this.chartData.options.getTooltip(day, this.collapsed);
    },
    getNewMonthInWeek(weekIndex) {
      const week = this.chartData.data.weeks[weekIndex];
      const monthOfFirstDay = new Date(week.days[0].date).getMonth();

      for (const day of week.days) {
        if (new Date(day.date).getMonth() !== monthOfFirstDay) {
          return new Date(day.date).toLocaleString("default", {
            month: "short",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_calendar-heatmap.scss";
</style>
