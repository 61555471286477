
const getImage = function (
  store,
  product,
  type = 'imageThumbnail',
  fallBackType = 'imageNormal'
) {
  try {
    if (product[type] && product[type].secure_url) {
      return product[type].secure_url;
    }
    if (product[fallBackType] && product[fallBackType].secure_url) {
      return product[fallBackType].secure_url;
    }
    return store.productImagePlaceholderThumbnail.secure_url;
  } catch (error) {
    return '';
  }
};
const getImagePlaceHolder = function (store, type = 'thumbnail') {
  try {
    const thumbnail = store?.productImagePlaceholderThumbnail;
    const normal = store?.productImagePlaceholder;

    if (!thumbnail && !normal) return '';

    if (type == 'normal') {
      if (!normal) return thumbnail.secure_url;
      return normal.secure_url;
    }

    return thumbnail.secure_url;
  } catch (err) {
    return '';
  }
};

export const getImageObj = (store, product) => {
  const src = getImage(store, product);
  const placeholder = getImagePlaceHolder(store);
  return {
    src,
    error: placeholder,
    loading: placeholder
  };
};