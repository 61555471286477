import Constants from "@/resources/Constants";
import { getDefaultExpirationDate } from "@/views/PurchaseOrder/get-default-expiration-date";
import _ from "lodash";

export const prefillPurchaseOrderBeforeRendering = (purchaseOrder) => {
  if (purchaseOrder.manifest && purchaseOrder.items) {
    prefillManifestBeforeRendering(
      purchaseOrder.manifest,
      purchaseOrder.batchType
    );
  }
  if (purchaseOrder.batchType) {
    const accountType = _.find(
      Constants.listOfSaleType,
      (at) => at.code === purchaseOrder.batchType
    );
    if (accountType) {
      purchaseOrder.batchType = accountType;
    }
  }

  if (purchaseOrder.items) {
    purchaseOrder.items.forEach((i) => {
      if (i.batchType) {
        const accountType = _.find(
          Constants.listOfSaleType,
          (at) => at.code === i.batchType
        );
        if (accountType) {
          i.batchType = accountType;
        }
      }
    });
  }

  return purchaseOrder;
};

export const prefillManifestBeforeRendering = (items, batchType) => {
  items.forEach((mi) => {
    mi.expirationDate = mi.stateExpirationDate || getDefaultExpirationDate();
    mi.isSample = false;
    mi.isPostedToProducts = false;
    mi.isReceived = false;
    mi.isMenuPrintEnabled = true;
    mi.isPublishedToOnlineStore = true;
    mi.isNew = true;
    mi.quantityPurchased = mi.quantity;
    mi.batchType = batchType;
    mi.unitCost = mi.price / mi.quantity;
  });

  return items;
};
