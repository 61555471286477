<template>
  <div class="loyalty-lookup">
    <div class="loyalty-lookup__fields">
      <div class="loyalty-lookup__input-container">
        <span class="input-text__label">Name</span>
        <input
          v-model="searchFields.firstName"
          ref="nameInput"
          placeholder="Name"
          class="input-text__field"
        />
      </div>
      <div class="loyalty-lookup__input-container">
        <span class="input-text__label">Last Name</span>
        <input
          v-model="searchFields.lastName"
          placeholder="Last Name"
          class="input-text__field"
        />
      </div>
      <div class="loyalty-lookup__input-container">
        <span class="input-text__label">Email</span>
        <input
          v-model="searchFields.email"
          placeholder="Email"
          class="input-text__field"
        />
      </div>
      <div class="loyalty-lookup__input-container">
        <span class="input-text__label">Phone</span>
        <TheMask
          placeholder="Phone"
          :masked="false"
          v-model="searchFields.phone"
          class="input-text__field"
          mask="(###) #######"
        />
      </div>
      <div class="loyalty-lookup__input-container">
        <span class="input-text__label">Card</span>
        <input
          v-model="searchFields.cardNumber"
          placeholder="Card"
          class="input-text__field"
        />
      </div>
      <div></div>
      <div></div>
    </div>
    <div>
      <BaseBtn
        v-shortkey="['enter']"
        @shortkey="performLookup"
        :isLoading="loadingStatus.lookup"
        iconGroup="fas"
        iconName="search"
        label="Search"
        @click="performLookup"
      />
    </div>
    <div v-if="showCustomers">
      <Table
        @select-item="selectCustomer"
        :data="customers"
        :columnHeaders="columnHeaders"
        :loadingStatus="loadingStatus.lookup"
      />
    </div>
    <div class="center" v-if="fetched && !showCustomers">
      No loyalty customers found
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import Table from "@/components/Table.vue";
import { TheMask } from "vue-the-mask";

const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Select",
  },
  { title: "Card", id: "cardNumber" },
  { title: "First Name", id: "firstName" },
  { title: "Last Name", id: "lastName" },
  { title: "Email", id: "email" },
  { title: "Phone", id: "phone" },
  { title: "Points", id: "points" },
];

export default {
  name: "LoyaltyLookup",
  components: {
    BaseBtn,
    Table,
    TheMask,
  },
  data() {
    return {
      searchFields: {
        cardNumber: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        mobilePhone: "",
      },
      customers: [],
      columnHeaders,
      fetched: false,
    };
  },
  props: {
    selectCustomer: {
      type: Function,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  created() {
    this.searchFields.firstName = this.name;
    this.searchFields.lastName = this.lastName;
    this.searchFields.email = this.email;
    this.searchFields.phone = this.phone;
    this.searchFields.mobilePhone = this.phone;
    this.$nextTick(function () {
      try {
        this.$refs.nameInput.focus();
      } catch (error) {}
    });
  },
  computed: {
    ...mapGetters({
      loadingStatus: "loyaltyModule/loadingStatus",
    }),
    showCustomers() {
      return this.customers?.length;
    },
  },
  methods: {
    ...mapActions({
      lookup: "loyaltyModule/lookup",
    }),
    performLookup() {
      // Always search for the number in both fields
      this.searchFields.mobilePhone = this.searchFields.phone;
      this.searchFields.email = this.email
      this.lookup({
        searchFields: this.searchFields,
        positiveCallback: (result) => {
          this.customers = result;
          this.fetched = true;
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_loyaltyLookup.scss";
</style>
