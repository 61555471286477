<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Coupon Usage" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'customerReport', label: 'Customer' },
        {
          name: 'customerCoupon',
          label: 'Coupon Usage',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" :filterByText="true" @submit-filter="performGetReport"
        :isLoading="customerTransactions" :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <td colspan="5">Coupon Usage</td>
              </tr>
              <tr>
                <td colspan="5">{{ reportData.total.datePeriod }}</td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <th align="left">Coupon</th>
                <th align="left">Coupon Code</th>
                <th align="left">Order</th>
                <th align="left">Date</th>
                <th align="left">Employee</th>
                <th align="left">Customer</th>
                <th align="left">Email</th>
                <th align="right">New Customer</th>
                <th align="right">Coupon Count</th>
                <th align="right">Discount</th>
                <th align="right">Pre-Tax Total</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(data, index) of formattedData" :key="index" :class="{ total2: data.isTotal }">
                <td v-if="!data.isTotal">{{ data.name }}</td>
                <td v-else>Totals</td>
                <td>{{ data.couponCode }}</td>
                <td>{{ data.order }}</td>
                <td>{{ data.date }}</td>
                <td>{{ data.employee }}</td>
                <td>{{ data.customer }}</td>
                <td>{{ data.customerEmail }}</td>
                <td align="right"> {{ data.isCustomerNew }}</td>
                <td align="right"> {{ data.couponCount }}</td>
                <td align="right"> {{ Utilities.formatPrice(data.couponTotal) }}</td>
                <td align="right"> {{ Utilities.formatPrice(data.totalPreTax) }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="7" align="left">Grand Totals</td>
              <td align="right"> {{ reportData.total.isCustomerNew }}</td>
              <td align="right"> {{ reportData.total.couponCount }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.couponTotal) }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.totalPreTax) }}</td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
      filterText: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Coupon Usage");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      customerTransactions: "reports/customerTransactions",
    }),
    formattedData() {
      const data = this.reportData;
      let rows = []
      for (let [name, nameValue] of Object.entries(data.records)) {
        for (let [orderId, order] of Object.entries(nameValue.orders)) {
          rows.push(order)
        }
        rows.push({ ...nameValue.total, isTotal: true })
      }

      return rows;
    }
  },
  methods: {
    ...mapActions({
      getSalesTransactions: "reports/getCustomerTransactions",
    }),
    performGetReport(filter) {
      let reportType = "customerCoupon";
      this.getSalesTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "customerCoupon");
    },
  },
};
</script>

<style lang="scss">
@import "./_customerCoupon.scss";
