import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedMsoSupplier } from "@/views/MSOSupplier/inputs";
const state = {
  msoSuppliers: [],
  msoSupplier: { ...defaultSelectedMsoSupplier },
  loadingStatus: {
    saveMsoSupplier: false,
    getMsoSuppliers: false,
    getMsoSupplierById: false,
    deleteMsoSupplier: false,
  },
  msoSupplierPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setMsoSuppliers: (state, payload) => {
    if (payload._id === "NEW")
      state.msoSuppliers = { ...defaultSelectedMsoSupplier };
    else state.msoSuppliers = payload;
  },
  setMsoSupplier: (state, payload) => {
    state.msoSupplier = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setMsoSupplierPopUp: (state, payload) => {
    state.msoSupplierPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  msoSuppliers: (state) => state.msoSuppliers,
  msoSupplier: (state) => state.msoSupplier,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  msoSupplierPopUp: (state) => state.msoSupplierPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveMsoSupplier: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveMsoSupplier", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveMsoSupplier,
      payload.msoSupplier,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveMsoSupplier",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteMsoSupplier: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteMsoSupplier",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteMsoSupplier,
      payload.msoSupplier,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteMsoSupplier",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getMsoSupplierById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getMsoSupplierById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getMsoSupplierById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getMsoSupplierById",
          status: false,
        });
        if (response) {
          commit("setMsoSupplier", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setMsoSupplierPopUp: ({ commit, state }, payload) => {
    commit("setMsoSupplierPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
