import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedMsoChartOfAccounts } from "@/views/MSOChartOfAccount/inputs";

const state = {
  msoChartOfAccounts: [],
  msoChartOfAccount: { ...defaultSelectedMsoChartOfAccounts },
  loadingStatus: {
    saveMsoChartOfAccount: false,
    getMsoChartOfAccounts: false,
    getMsoChartOfAccountById: false,
    deleteMsoChartOfAccount: false,
  },
  msoChartOfAccountPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setMsoChartOfAccounts: (state, payload) => {
    state.msoChartOfAccounts = payload;
  },
  setMsoChartOfAccount: (state, payload) => {
    if (payload._id === "NEW")
      state.msoChartOfAccount = { ...defaultSelectedMsoChartOfAccounts };
    else state.msoChartOfAccount = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setMsoChartOfAccountPopUp: (state, payload) => {
    state.msoChartOfAccountPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  msoChartOfAccounts: (state) => state.msoChartOfAccounts,
  msoChartOfAccount: (state) => state.msoChartOfAccount,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  msoChartOfAccountPopUp: (state) => state.msoChartOfAccountPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveMsoChartOfAccount: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "saveMsoChartOfAccount",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveMsoChartOfAccount,
      payload.msoChartOfAccount,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveMsoChartOfAccount",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteMsoChartOfAccount: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteMsoChartOfAccount",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteMsoChartOfAccount,
      payload.msoChartOfAccount,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteMsoChartOfAccount",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getMsoChartOfAccountById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getMsoChartOfAccountById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl +
        Apis.getMsoChartOfAccountById +
        "?id=" +
        payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getMsoChartOfAccountById",
          status: false,
        });
        if (response) {
          commit("setMsoChartOfAccount", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setMsoChartOfAccountPopUp: ({ commit, state }, payload) => {
    commit("setMsoChartOfAccountPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
