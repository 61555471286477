<template>
  <div class="mso-chart-of-account-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'msoChartOfAccounts', label: 'MSO Chart of Accounts' },
          {
            name: 'msoChartOfAccountEditor',
            label: selectedMsoChartOfAccount.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedMsoChartOfAccount"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div
            v-for="input in msoChartOfAccountsInfoInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="selectedMsoChartOfAccount[input.id]"
              @updateModel="selectedMsoChartOfAccount[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </div>
      <EditorSection
        title="Associated Chart of Accounts"
        v-if="selectedMsoChartOfAccount._id !== 'NEW'"
        :bus="bus"
      >
        <SearchTable
          :entity="Entities.CHARTOFACCOUNT"
          :hideFilters="true"
          :preFilter="preFilter"
        />
      </EditorSection>
      <EditorSection
        :title="`About ${selectedMsoChartOfAccount.name || ''}`"
        sectionType="about"
        v-if="selectedMsoChartOfAccount._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedMsoChartOfAccount" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SearchTable from "@/components/SearchTable";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import {
  pageHeader,
  msoChartOfAccountsInfoInputs,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "msoChartOfAccountEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      isEdit: false,
      organizationGroups: [],
      organizationGroupsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.MSOCHARTOFACCOUNT,
      pageHeader,
      msoChartOfAccountsInfoInputs,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("MSO Chart of Account");
    if (this.$route.query.id) {
      this.getMsoChartOfAccountById({
        _id: this.$route.query.id,
        positiveCallback: (msoChartOfAccount) => {
          this.setPageTitle(`MSO Chart of Account ${msoChartOfAccount.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getMsoChartOfAccountById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      msoChartOfAccount: "msoChartOfAccountModule/msoChartOfAccount",
    }),
    selectedMsoChartOfAccount: {
      get() {
        this.setPageTitle("MSO Group " + this.msoChartOfAccount.name);
        return this.msoChartOfAccount;
      },
      set(entity) {
        return entity;
      },
    },
    preFilter: function () {
      return `filterCriteria=msoChartOfAccount&filterValue=${this.id}`;
    },
  },
  methods: {
    ...mapActions({
      getMsoChartOfAccountById:
        "msoChartOfAccountModule/getMsoChartOfAccountById",
      saveMsoChartOfAccount: "organizationGroupModule/saveMsoChartOfAccount",
      deleteMsoChartOfAccount:
        "organizationGroupModule/deleteMsoChartOfAccount",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getMsoChartOfAccounts: "organizationGroupModule/getMsoChartOfAccounts",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedMsoChartOfAccount._id === "NEW"))
        this.isEdit = !this.isEdit;
      if (this.selectedMsoChartOfAccount._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedMsoChartOfAccount.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedMsoChartOfAccount = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedMsoChartOfAccount[entity] = item;
    },
  },
};
</script>
