<template>
  <div>
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <product-list
      :isModal="true"
      :allowSelection="productPopUp.allowSelection"
      @elementSelected="handleElementSelection"
      class="inventory-pop-up background-color"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "productPopUp",
  data() {
    return {};
  },
  created: function () {
    this.setSelectedPreFilters(this.productPopUp.preFilters);
    this.setSelectedFilter(undefined);
    window.addEventListener(
      "keyup",
      function (e) {
        if (this.isBlurred) {
          return;
        }
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  destroyed: function () {
    this.setSelectedPreFilters(undefined);
    this.setSelectedFilter(undefined);
  },
  computed: {
    ...mapGetters({
      productPopUp: "productModule/productPopUp",
    }),
  },
  methods: {
    ...mapActions({
      setProductPopUp: "productModule/setProductPopUp",
      setSelectedPreFilters: "productModule/setSelectedPreFilters",
      setSelectedFilter: "productModule/setSelectedFilter",
    }),
    handleNegativeAnswer: function () {
      if (this.productPopUp.negativeCallback) {
        this.productPopUp.negativeCallback();
      }
      this.setProductPopUp({ isOpen: false });
    },
    handleElementSelection(selectedElement) {
      if (this.productPopUp.positiveCallback) {
        this.productPopUp.positiveCallback({
          selectedElement: selectedElement,
        });
        this.setProductPopUp({ isOpen: false });
      }
    },
  },
};
</script>

<style lang="scss">
.inventory-pop-up {
  border-radius: $border-radius-0;
  position: fixed;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: $dark-background-color;
  overflow-y: auto;
}
</style>
