import axios from "axios";
import Vue from "vue";

export default {
  get: function (url, callback) {
    axios
      .get(url)
      .then((response) => {
        try {
          if (
            response.data &&
            response.data.value &&
            response.data.value.message
          ) {
            Vue.$log.error(
              "rest call error in callback:",
              response.data.value.message
            );
            callback(undefined, response.data.value.message);
            return;
          }
        } catch (error) {
          Vue.$log.debug("unable to read server error:", error);
        }

        try {
          callback(response);
        } catch (error) {
          Vue.$log.error("rest call error in callback:", error);
        }
      })
      .catch((error) => {
        if (error.response) {
          Vue.$log.error(
            "rest call failed (error status, error text):",
            error.response.status,
            error.response.statusText
          );
        } else {
          Vue.$log.error("rest call failed");
        }
        if (error.response?.data) {
          callback(undefined, error.response.data);
        } else {
          callback(undefined, error.response?.statusText);
        }
      });
  },
  post: function (url, body, callback) {
    axios
      .post(url, body)
      .then((response, err) => {
        try {
          if (
            response.data &&
            response.data.value &&
            response.data.value.message
          ) {
            Vue.$log.error(
              "rest call error in callback:",
              response.data.value.message
            );
            callback(undefined, response.data.value.message);
            return;
          }
        } catch (error) {
          Vue.$log.debug("unable to read server error:", error);
        }

        try {
          callback(response);
        } catch (error) {
          Vue.$log.error("rest call error in callback:", error);
        }
      })
      .catch((error) => {
        if (error.response) {
          Vue.$log.error(
            "rest call failed (error status, error text):",
            error.response.status,
            error.response.statusText
          );
        } else {
          Vue.$log.error("rest call failed");
          callback(undefined, "rest call failed");
        }
        if (error.response.data) {
          callback(undefined, error.response.data);
        } else {
          callback(undefined, error.response.statusText);
        }
      });
  },
};
