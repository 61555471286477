<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Inventory Reorder Status" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
        {
          name: 'inventoryReorderStatus',
          label: 'Inventory Reorder Status',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" @submit-filter="performGetReport" :isLoading="inventoryTransactions"
        :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <td colspan="3">Inventory Reorder Status</td>
              </tr>
              <tr>
                <td colspan="3">{{ reportData.total.datePeriod }}</td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <th align="left">Store</th>
                <th align="left">Product Id</th>
                <th align="left">Supplier</th>
                <th align="left">Group</th>
                <th align="left">Name</th>
                <th align="right">On Hand</th>
                <th align="right">Stock Minimum</th>
                <th align="right">Default Qty</th>
                <th align="right">Reorder</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.records" :key="index">
                <td>{{ record.store }}</td>
                <td>{{ record.productId }}</td>
                <td>{{ record.supplier }}</td>
                <td>{{ record.group }}</td>
                <td>{{ record.name }}</td>
                <td align="right"> {{ record.quantityOnHand }}</td>
                <td align="right"> {{ record.stockMinimum }}</td>
                <td align="right"> {{ record.storeDefaultQuantity }}</td>
                <td align="right"> {{ record.quantityToReorder }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="5">Totals</td>
              <td align="right">{{ reportData.total.quantityOnHand }}</td>
              <td align="right">-</td>
              <td align="right"> {{ reportData.total.storeDefaultQuantity }}</td>
              <td align="right"> {{ reportData.total.quantityToReorder }}</td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Inventory Reorder Status");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      inventoryTransactions: "reports/inventoryTransactions",
    }),
  },
  methods: {
    ...mapActions({
      getSalesTransactions: "reports/getInventoryTransactions",
    }),
    performGetReport(filter) {
      let reportType = "inventoryReorderStatus";
      this.getSalesTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "inventoryReorderStatus");
    },
  },
};
</script>

<style lang="scss">@import "./_inventoryReorderStatus.scss";