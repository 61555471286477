export default {
  setLocalStorageKey: function (cname, cvalue) {
    if (typeof Storage === "undefined") return;

    localStorage.setItem(cname, cvalue);
  },
  getLocalStorageKey: function (cname) {
    if (typeof Storage === "undefined") return;

    return localStorage.getItem(cname);
  },
  deleteLocalStorageKey: function (name) {
    if (typeof Storage === "undefined") return;

    localStorage.removeItem(name);
  },
};
