import store from "../../store/index";

export const pageHeader = {
  title: "Cash Reconciliation",
  isEditorView: true,
  cancelPath: "cashReconciliations",
  addPath: "cashReconciliationEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Cash Reconciliations",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "cashReconciliationEditor",
};

export const filterCriteria = [
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  { label: "Employee", code: "employee.name", field: { type: "input", label: "Employee" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Deleted",
    id: "isDeleted",
    type: "checkbox",
  },
  {
    title: "Cash Expected",
    type: "money",
    id: "cashTotalExpected",
  },
  {
    title: "Cash Counted",
    type: "money",
    id: "userCashCountTotal",
  },
  {
    title: "Cash Difference",
    type: "money",
    id: "cashTotalDifference",
  },
  {
    title: "Station",
    dynamicValue(item) {
      const itemId = item.workstation;
      if (!itemId) return "-";

      let currentStore = store.getters["storeModule/currentStore"];
      let workstations = currentStore.workstations;

      if (!workstations || workstations.length == 0) return itemId;

      const found = workstations.find((w) => w._id == itemId);
      if (!found) return "-";
      return found.name;
    },
  },
  {
    title: "Employee",
    id: "employee.name",
    nestedId: "employee.name",
  },
  {
    title: "Start Date",
    id: "startDate",
    type: "date-extended",
  },
  {
    title: "End Date",
    id: "endDate",
    type: "date-extended",
  },
  {
    title: "Id",
    id: "_id",
  },

];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const cashReconciliationInfoInputs = [
  {
    id: "opening",
    placeholder: "Opening",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Opening",
  },
  {
    id: "cashCollected",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "CashCollected",
  },
  {
    id: "payoutTotal",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Payouts",
  },
  {
    id: "userCashCountTotal",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Counted",
  },
  {
    id: "cashTotalExpected",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Expected",
  },
  {
    id: "cashTotalDifference",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Difference",
  },
  {
    id: "totalSales",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Sales",
  },
  {
    id: "refundTotal",
    placeholder: "0.00",
    type: "money",
    precision: 2,
    prefix: "$",
    title: "Refunds",
  },
  {
    id: "cashOneDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$1",
  },
  {
    id: "cashTwoDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$2",
  },
  {
    id: "cashFiveDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$5",
  },
  {
    id: "cashTenDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$10",
  },
  {
    id: "cashTwentyDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$20",
  },
  {
    id: "cashFiftyDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$50",
  },
  {
    id: "cashOneHundredDollar",
    placeholder: "0",
    type: "money",
    precision: 0,
    prefix: "",
    title: "$100",
  },
  {
    id: "createdAt",
    placeholder: "Entered Date",
    type: "calendar",
    title: "Entered Date",
  },

  {
    id: "isPostedToJournal",
    type: "checkbox",
    title: "Posted",
  },
  {
    id: "notes",
    type: "textarea",
    placeholder: "Notes here",
    title: "Notes",
  },
];

export const defaultSelectedCashReconciliation = {
  _id: "NEW",
  isDeleted: false,
  notes: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
};
