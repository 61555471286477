<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Deposit Slip" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'financeReport', label: 'Finance' },
        {
          name: 'financeDepositSlip',
          label: 'Deposit Slip',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
            </thead>
            <tr>
              <td>Sales</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.order) }}</td>
            </tr>
            <tr>
              <td>Sales Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.orderReversed) }}</td>
            </tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td>Total Sales</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.order +
                  reportData.total.orderReversed) }}</td>
            </tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Sales Refunds</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.orderRefund) }}</td>
            </tr>
            <tr>
              <td>Sales Refunds Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.orderRefundReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Total Sales less Refunds</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.order +
                  reportData.total.orderReversed +
                  reportData.total.orderRefund +
                  reportData.total.orderRefundReversed) }}</td>
            </tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.orderPayment) }}</td>
            </tr>
            <tr>
              <td>Payments Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.orderPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Total Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.orderPayment +
                  reportData.total.orderPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Cash Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.cashPayment) }}</td>
            </tr>

            <tr>
              <td>Cash Payments Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.cashPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Total Cash Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.cashPayment +
                  reportData.total.cashPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Gift Card Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.giftCardPayment) }}</td>
            </tr>
            <tr>
              <td>Gift Card Payments Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.giftCardPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td>Total Gift Card Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.giftCardPayment +
                  reportData.total.giftCardPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Credit Memo Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.creditMemoPayment) }}</td>
            </tr>
            <tr>
              <td>Credit Memo Payments Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.creditMemoPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

            <tr>
              <td>Total Credit Memo Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.creditMemoPayment +
                  reportData.total.creditMemoPaymentReversed) }}</td>
            </tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td>Debit Card Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.debitPayment) }}</td>
            </tr>
            <tr>
              <td>Debit Card Payments Reversed</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.debitPaymentReversed) }}</td>
            </tr>

            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td>Total Debit Card Payments</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.debitPayment +
                  reportData.total.debitPaymentReversed) }}</td>
            </tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Deposit Slip");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "financeDepositSlip";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "depositSlip");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_financeDepositSlip.scss";
</style>