<template>
  <div class="tax-setups responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'taxSetups', label: 'TaxSetup' },
        ]"
        :entity="entity"
      >
        <BaseBtn
          label="Order and Test"
          iconGroup="fas"
          iconName="calculator"
          @click="navigateToTest"
        />
      </PageHeader>
      <SearchTable :entity="entity" ref="searchTable" :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "taxSetup",
  data() {
    return {
      listPageHeader,
      entity: Entities.TAXSETUP,
    };
  },
  components: {
    PageHeader,
    SearchTable,
    BaseBtn,
  },
  mounted: function () {
    this.setPageTitle("TaxSetup");
  },
  computed: {
    ...mapGetters({
      sort: "taxSetupModule/sort",
      pagination: "taxSetupModule/pagination",
      initialFilter: "taxSetupModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      saveTaxSetup: "taxSetupModule/saveTaxSetup",
      updateSort: "taxSetupModule/updateSort",
      updatePagination: "taxSetupModule/updatePagination",
      updateInitialFilter: "taxSetupModule/updateInitialFilter"
    }),
    handleButtonClicked(action, id, item) {
      const newItem = JSON.parse(JSON.stringify(item));
      newItem._id = "NEW";
      if (action == "cloneEntity") {
        this.setMessagePopUp({
          isOpen: true,
          title: "Duplicate tax?",
          message: "Are you sure you want to duplicate this tax?",
          positiveCallback: async () => {
            try {
              const res = await this.saveTaxSetup({ taxSetup: newItem });
              this.$refs.searchTable.searchItems();
            } catch (err) {
              console.log(err);
            }
          },
        });
      }
    },
    navigateToTest() {
      this.$router.push({ name: "taxSetupOrderAndTest" });
    },
  },
};
</script>
