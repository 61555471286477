<template>
  <div class="clutch-coupon">
    <div class="clutch-coupon__inputs">
      <BaseInput label="Coupon Code" placeholder="Enter coupon" v-model="selectedItem.clutchCoupon.couponCode"
        ref="mainInput" />
      <BaseBtn label="Apply Coupon" :disabled="disabled" @click="apply" />
    </div>
    <div class="clutch-coupon__coupons">
      <div class="clutch-coupon__coupon elevated-0" v-for="coupon in order.clutchCoupons" :key="coupon.couponCode">
        <BaseBtn @click="cancelCoupon(coupon)" label="Cancel" :colorType="1" />
        {{ coupon.couponCode }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Input/Base.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  data() {
    return {
      selectedItem: {
        clutchCoupon: {
          couponCode: ''
        },
      },
    };
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseInput,
    BaseBtn,
  },
  computed: {
    disabled() {
      if (
        this.selectedItem.clutchCoupon.couponCode.length > 0
      )
        return false;

      return true;
    },
  },
  methods: {
    apply() {
      const discount = {
        type: 'clutch-coupon',
        couponCode: this.selectedItem.clutchCoupon?.couponCode,
        couponName: this.selectedItem.clutchCoupon?.couponCode,
        couponDiscountType: 'clutch-coupon',
      };
      this.$emit("applyDiscount", discount);
    },
    cancelCoupon(coupon) {
      this.$emit("cancelClutchCoupon", coupon);
    }
  },
  mounted() {
    this.$nextTick(function () {
      try {
        this.$refs.mainInput.focus()
      } catch (error) { }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../_discounts.scss";
@import "./_clutch-coupon.scss";
</style>
