<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Sales Discount Summary" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesDiscountSummary',
          label: 'Sales Discount Summary',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Date</th>
                <th align="right">PreTax Total</th>
                <th align="right">Discount</th>
                <th align="right">%</th>
                <th align="right">Customer</th>
                <th align="right">Points</th>
                <th align="right">CCP</th>
                <th align="right">Coupon</th>
                <th align="right">Instant</th>
                <th align="right">Loyalty</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[day, value] in Object.entries(reportData.list)" :key="day">
                <td>{{ value.dateString }}</td>
                <td align="right">
                  {{ Utilities.formatPrice(value.totalPreTax) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.discount) }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (value.discount / value.totalPreTax) * 100
                    )
                  }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.customer) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.points) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.ccp) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.coupon) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.instant) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(value.clutch) }}
                </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="1">Totals</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.totalPreTax) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.discount) }}
              </td>
              <td align="right">
                {{
                  Utilities.formatPercent(
                    (reportData.total.discount / reportData.total.totalPreTax) *
                    100
                  )
                }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.customer) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.points) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.ccp) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.coupon) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.instant) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.clutch) }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Sales Discount Summary");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "salesDiscountSummary";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "SalesDiscountSummary");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesDiscountSummary.scss";
</style>
