import FileSaver from 'file-saver'
import moment from "moment";

export default {
  formatText(text, forceText = false) {
    let updatedText = `${text}`;
    updatedText = updatedText.replace(/\n/g, " ");
    updatedText = updatedText.replace(/#/g, "no. ");
    updatedText = updatedText.replace(/, /g, "");
    updatedText = updatedText.replace(/,/g, "");
    updatedText = updatedText.replace(/∞/g, '0'); // excel cannot handle infity correctly
    updatedText = `${updatedText}`;
    if (forceText) {
      updatedText = `\t${updatedText}`;
    }
    return updatedText;
  },
  exportToExcel(table, fileName, from, to) {
    /* Declaring array variable */
    var rows = [];
    if (!table) return;

    for (var i = 0; i < table.rows.length; i++) {
      let row = table.rows[i];
      //rows would be accessed using the "row" variable assigned in the for loop
      //Get each cell value/column from the row
      if (!row.cells || row.cells.length == 0) continue;
      if (row.className == "skip-export") continue;

      const columns = [];
      for (let j = 0; j < row.cells.length; j++) {
        const forceText = row.cells[j].className == 'batch';
        const text = this.formatText(row.cells[j].innerText, forceText);
        columns.push(text);
      }

      /* add a new records in the array */
      rows.push(columns);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    /* add the column delimiter as comma(,) and each row splitted by new line character (\n) */
    rows.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    /* create a hidden <a> DOM node and set its download attribute */
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${this.generateName(fileName, from, to)}.csv`);
    document.body.appendChild(link);
    /* download the data file named "Stock_Price_Report.csv" */
    link.click();
  },
  generateName(name, from, to) {
    let fileName = name;
    if (from) {
      const fromDate = moment(from).format('MM-DD-YYYY')
      if (fromDate) {
        fileName += `__${fromDate}`
      }
    }
    if (to) {
      const toDate = moment(to).format('MM-DD-YYYY')
      if (toDate) {
        fileName += `__${toDate}`
      }
    }
    return fileName;
  },
  exportRecordsToExcel(records, fileName) {
    if (records?.length == 0) {
      return
    }

    const firstRecord = records[0]

    let csvContent = "";
    // Add columns 
    const columns = []
    for (const [key, value] of Object.entries(firstRecord)) {
      columns.push(key)
    }
    csvContent += `${columns.join(',')}\r\n`

    // Add rows
    for (let record of records) {
      let rowValues = []
      for (let column of columns) {
        rowValues.push(this.formatText(record[column]))
      }
      csvContent += `${rowValues.join(',')}\r\n`
    }

    var blob = new Blob([csvContent], { type: 'text/csv' });
    FileSaver.saveAs(blob, `${fileName}.csv`);
    return csvContent
  },
};
