import router from "@/router";

export const routeToEntity = (entity, entityData) => {
  if (entityData._id !== router.app?._route?.query?.id)
    router.push({
      name: `${entity}Editor`,
      query: { id: entityData._id },
    });
};

export const getRouteToEntity = (entity, entityData) => {
  return `/${entity}s/${entity}Editor?id=${entityData._id}`
};
