<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader title="Transact First Usage" :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'customerReport', label: 'Customer' },
          {
            name: 'customerTransactFirst',
            label: 'Transact First Usage',
          },
        ]" @export="exportToCsv" :disableExport="!reportData" />
        <br class="no-print" />
        <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
          :percentage="percentageCompleted" :filterBySaleType="false" />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Source</th>
                  <th align="left">Authorization Code</th>
                  <th align="left">SN Code</th>
                  <th align="left">Order</th>
                  <th align="left">Employee</th>
                  <th align="left">Customer</th>
                  <th align="left">Email</th>
                  <th align="right">New Customer</th>
                  <th align="right">Card Count</th>
                  <th align="right">Card Total</th>
                  <th align="right">Cash Back</th>
                  <th align="right">Order Total</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                  <td>{{ record.date }}</td>
                  <td>{{ record.type }}</td>
                  <td>{{ record.authorizationCode }}</td>
                  <td>{{ record.SN }}</td>
                  <td>{{ record.order }}</td>
                  <td>{{ record.employee }}</td>
                  <td>{{ record.customer }}</td>
                  <td>{{ record.customerEmail }}</td>
                  <td align="right"> {{ record.isCustomerNew }}</td>
                  <td align="right"> {{ record.cardCount }}</td>
                  <td align="right"> {{ Utilities.formatPrice(record.cardTotal) }}</td>
                  <td align="right"> {{ Utilities.formatPrice(record.cashBack) }}</td>
                  <td align="right"> {{ Utilities.formatPrice(record.orderTotal) }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="5" align="left">Totals</td>
                <td align="left"> Average Order Total:</td>
                <td align="right">
                  {{
                    Utilities.formatPrice(
                      (reportData.total.orderTotal / reportData.total.cardCount)
                    )
                  }}
                </td>
                <td colspan="2" align="right"> {{ reportData.total.isCustomerNew }}</td>
                <td align="right"> {{ reportData.total.cardCount }}</td>

                <td align="right"> {{ Utilities.formatPrice(reportData.total.cardTotal) }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.cashBack) }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.orderTotal) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        Utilities,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Transact First Usage");
    },

    computed: {
      ...mapGetters({
        isLoading: "reports/isLoading",
        getData: "reports/getData",
        percentageCompleted: 'reports/percentageCompleted'
      }),
      reportData() {
        return this.getData;
      },
    },
    methods: {
      ...mapActions({
        generateReport: "reports/generate",
        clearData: "reports/clearData",
      }),
      performGetReport(filter) {
        let reportType = "customerTransactFirst";
        this.generateReport({
          reportType,
          ...filter,
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "customerTransactFirst");
      },
    },
    beforeDestroy() {
      this.clearData()
    }
  };
  </script>

  <style lang="scss">
  @import "./_customerTransactFirst.scss";