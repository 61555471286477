import axios from "axios";
import router from "@/router";
import Apis from "@/services/Apis";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";
  // Adds custom headers
  config.headers["Organization-Id"] = localStorage.getItem("organizationId");
  config.headers["Store-Id"] = localStorage.getItem("storeId");
  config.headers["Workstation"] = localStorage.getItem("workstation");
  config.headers["Employee-Id"] = localStorage.getItem("employeeId")
    ? localStorage.getItem("employeeId")
    : localStorage.getItem("_id");
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url.indexOf(Apis.login) === -1 &&
      originalRequest.url.indexOf(Apis.refreshToken) === -1
    ) {
      originalRequest._retry = true;
      return axios
        .post(Apis.backendBaseUrl + Apis.refreshToken, {
          _id: localStorage.getItem("_id"),
          refreshToken: localStorage.getItem("refreshToken"),
        })
        .then((res) => {
          if (res.status === 200) {
            const newToken = res.data.body.token;
            localStorage.setItem("token", newToken);
            axios.defaults.headers.common.Authorization = "Bearer " + newToken;

            return axios(originalRequest);
          }
        });
    }

    // If refresh token returned an error log user off
    if (originalRequest.url.indexOf(Apis.refreshToken) !== -1) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("_id");
      localStorage.removeItem("user");
      router.push({ name: "login" });
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);
