export const filterCriteria = [
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  {
    label: "Batch #",
    code: "batchNumber",
    field: { type: "input", label: "batchNumber" },
    isPartial: true,
  },
  {
    label: "Created",
    code: "createdAt",
    field: { type: "input", label: "createdAt" },
  },
  {
    label: "Account #",
    code: "accountNo",
    field: { type: "input", label: "accountNo" },
    isPartial: true,
  },
  {
    label: "Customer Name",
    code: "externalFields.customer.name",
    field: { type: "input", label: "customer.name" },
    isPartial: true,
  },
  {
    label: "Order Id",
    code: "order",
    field: { type: "input", label: "order" },
  },
  {
    label: "Origin",
    code: "origin",
    field: { type: "input", label: "origin" },
    isPartial: true,
  },
  {
    label: "Purchase Order Id",
    code: "purchaseOrder",
    field: { type: "input", label: "purchaseOrder" },
  },
  {
    label: "Supplier Id",
    code: "supplier",
    field: { type: "input", label: "supplier" },
  },
];

export const columnHeaders = [
  {
    title: "Origin",
    id: "origin",
  },
  {
    title: "Batch #",
    id: "batchNumber",
  },
  {
    title: "Created At",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Notes",
    id: "notes",
  },
  {
    title: "Account #",
    id: "accountNo",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Debits",
    id: "debits",
  },
  {
    title: "Credits",
    id: "credits",
  },
  {
    title: "Posted to Chart of Accounts",
    id: "isPostedToChartOfAccounts",
    type: "checkbox",
  },
  {
    title: "Order Id",
    id: "order",
    link: true,
    url: "/orders/orderEditor?id=",
    urlId: "order._id",
  },
  {
    title: "Customer Id",
    id: "customer",
    link: true,
    url: "/customers/customerEditor?id=",
    urlId: "customer._id",
  },
  {
    title: "Purchase Order Id",
    id: "purchaseOrder._id",
    link: true,
    url: "/purchaseOrders/purchaseOrderEditor?id=",
    urlId: "purchaseOrder._id",
  },
  {
    title: "P.O. Item Id",
    id: "item",
    link: true,
    url: "/items/itemEditor?id=",
    urlId: "item._id",
  },
  {
    title: "Supplier Id",
    id: "supplier._id",
    link: true,
    url: "/suppliers/supplierEditor?id=",
    urlId: "supplier._id",
  },
  {
    title: "Credit Memo Id",
    id: "creditMemo",
    link: true,
    url: "/creditMemos/creditMemoEditor?id=",
    urlId: "creditMemo._id",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Station",
    id: "computerIP",
  },
  {
    title: "Updated At",
    id: "updatedAt",
    type: "date",
  },
];
