import * as Entities from "@/resources/Entities";

import {
  searchColumnHeaders as customerColumnHeaders,
  filterCriteria as customerFilterCriteria,
} from "../Customer/inputs";

// Page Header
export const pageHeader = {
  title: "GiftCard",
  isEditorView: true,
  cancelPath: "giftCards",
  addPath: "giftCardEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "GiftCards",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "giftCardEditor",
};

export const filterCriteria = [
  {
    label: "GiftCard #",
    code: "giftCard",
    field: { type: "input", label: "GiftCard #" },
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Date Issued",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Gift Card #",
    id: "giftCard",
    size: "large",
  },
  {
    title: "Balance",
    id: "memoBalance",
  },
  {
    title: "Amount Used",
    id: "memoAmountUsed",
  },
  {
    title: "Total",
    id: "memoTotal",
  },
  {
    title: "Order #",
    id: "order._id",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Customer #",
    id: "customer._id",
    size: "large",
  },
  {
    title: "Store",
    id: "store._id",
  },
];

export const giftCardInputs = [
  {
    id: "giftCard",
    placeholder: "Gift Card #",
    type: "input",
    title: "Gift Card #",
  },
  {
    id: "createdAt",
    placeholder: "Entered Date",
    type: "calendar",
    title: "Entered Date",
  },
  {
    id: "isDeleted",
    type: "checkbox",
    title: "Deleted",
  },
  {
    id: "customer",
    placeholder: "Customer #",
    type: "search",
    title: "Customer #",
    entity: Entities.CUSTOMER,
    columnHeaders: customerColumnHeaders,
    filterCriteria: customerFilterCriteria,
    isClearable: true,
  },
  {
    id: "memoAmountUsed",
    placeholder: "Amount Used",
    type: "money",
    title: "Amount Used",
    precision: 2,
    prefix: "$",
  },
  {
    id: "memoBalance",
    placeholder: "Balance",
    type: "money",
    title: "Balance",
    precision: 2,
    prefix: "$",
  },
  {
    id: "accountNo",
    placeholder: "A/C #",
    title: "A/C #",
  },
  {
    id: "notes",
    type: "textarea",
    placeholder: "Notes here",
    title: "Notes",
  },
];

export const defaultSelectedGiftCard = {
  _id: "NEW",
  memoType: "Gift Card",
  name: "",
  memoBalance: 0,
  memoAmountUsed: 0,
  isDeleted: false,
  notes: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
};
