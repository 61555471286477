const INVENTORY_TYPES = [
  "01 Non-Cannabis",
  "22 Solid Marijuana Infused Edible",
  "23 Liquid Marijuana Infused Edible",
  "24 Marijuana Extract for Inhalation",
  "25 Marijuana Infused Topical",
  "26 Sample Jar",
  "28 Useable Marijuana",
];
export default INVENTORY_TYPES;
