<template>
  <div id="entity-filter">
    <div :class="{ 'bordered elevated-0 padded': !noMargins }">
      <div
        class="entity-filter__wrapper space-between space-between--align-items-center space-between--responsive-column-reverse"
      >
        <slot></slot>
        <div
          class="filter-fields space-between space-between--align-start full-width-responsive"
        >
          <div class="flex flex--gap flex--column">
            <div class="flex flex--gap">
              <div class="input-text">
                <div class="reversed-flex-column">
                  <v-select
                    v-if="filter"
                    data-cy="filter-dropdown"
                    v-model="filter.criteria"
                    class="input-text__select"
                    placeholder="Select Filter Criteria"
                    :options="filterCriteria"
                    @input="emitCriteriaChange"
                    :disabled="disabled"
                  />
                  <div class="input-text__label">Filter Criteria</div>
                </div>
              </div>
              <div
                v-if="
                  selectedFilter &&
                  selectedFilter.criteria &&
                  selectedFilter.criteria.field.type === 'date'
                "
                class="input-text entity-filter__calendar"
              >
                <div class="reversed-flex-column">
                  <Calendar
                    v-model="filterValue"
                    :placeholder="`Enter ${selectedFilter.criteria.label}`"
                    :disabled="disabled"
                    hourFormat="12"
                    @focus="handleInputFocus"
                    ref="inputCriteria"
                    v-on:input="emitValueChange"
                  />
                  <div class="input-text__label">
                    {{ selectedFilter.criteria.label }}
                  </div>
                </div>
              </div>
              <div
                v-else-if="selectedFilter && selectedFilter.criteria"
                class="input-text"
              >
                <div class="reversed-flex-column">
                  <input
                    data-cy="filter-text-field"
                    :class="
                      'input-text__field' +
                      (disabled ? ' input-text__disabled' : '')
                    "
                    v-model="filterValue"
                    v-on:input="emitValueChange"
                    :placeholder="`Enter ${selectedFilter.criteria.label}`"
                    :disabled="disabled"
                    ref="inputCriteria"
                    @focus="handleInputFocus"
                    @keydown.tab.prevent="tabPressed"
                    @keydown.enter.prevent="enterPressed"
                  />
                  <div class="input-text__label">
                    {{ selectedFilter.criteria.label }} {{ label }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="link-text"
              v-if="!showAdditionalFilter && enableAdditionalFilter"
              @click="showAdditionalFilter = true"
            >
              Add filter
            </div>
            <div v-if="showAdditionalFilter" class="flex flex--gap">
              <div class="input-text">
                <div class="reversed-flex-column">
                  <v-select
                    v-if="filter2"
                    data-cy="filter-dropdown"
                    v-model="filter2.criteria"
                    class="input-text__select"
                    placeholder="Select Filter Criteria"
                    :options="filterCriteria"
                    @input="() => emitCriteriaChange('2')"
                    :disabled="disabled"
                  />
                  <div class="input-text__label">Filter Criteria</div>
                </div>
              </div>
              <div
                v-if="
                  selectedFilter2 &&
                  selectedFilter2.criteria &&
                  selectedFilter2.criteria.field.type === 'date'
                "
                class="input-text entity-filter__calendar"
              >
                <div class="reversed-flex-column">
                  <Calendar
                    v-model="filterValue"
                    :placeholder="`Enter ${selectedFilter2.criteria.label}`"
                    :disabled="disabled"
                    hourFormat="12"
                    @focus="handleInputFocus"
                    ref="inputCriteria"
                    v-on:input="(e) => emitValueChange(e, '2')"
                  />
                  <div class="input-text__label">
                    {{ selectedFilter2.criteria.label }}
                  </div>
                </div>
              </div>
              <div
                v-else-if="selectedFilter2 && selectedFilter2.criteria"
                class="input-text"
              >
                <div class="reversed-flex-column">
                  <input
                    data-cy="filter-text-field"
                    :class="
                      'input-text__field' +
                      (disabled ? ' input-text__disabled' : '')
                    "
                    v-model="filterValue2"
                    v-on:input="(e) => emitValueChange(e, '2')"
                    :placeholder="`Enter ${selectedFilter2.criteria.label}`"
                    :disabled="disabled"
                    ref="inputCriteria2"
                    @focus="handleInputFocus"
                    @keydown.tab.prevent="() => tabPressed('2')"
                    @keydown.enter.prevent="() => enterPressed('2')"
                  />
                  <div class="input-text__label">
                    {{ selectedFilter2.criteria.label }} {{ label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="filter-button-container">
          <div v-if="filterButtons" class="filter-buttons">
            <inline-button
              :type="filterButton.isSelected ? 'primary' : 'plain-primary'"
              @click="() => emitFilterButtonClick(filterButton)"
              v-for="filterButton in filterButtons"
              :key="filterButton._id"
              :label="filterButton.label"
            />
          </div>
          <div v-if="filterButtons2" class="filter-buttons">
            <inline-button
              v-for="filterButton2 in filterButtons2"
              :type="filterButton2.isSelected ? 'primary' : 'plain-primary'"
              @click="() => emitFilterButton2Click(filterButton2)"
              :key="filterButton2._id"
              :label="filterButton2.label"
            />
          </div>
        </div>
      </div>
      {{ preFiltersText }}
    </div>
  </div>
</template>

<script>
import router from "@/router";
import _ from "lodash";
import Constants from "@/resources/Constants";
import Calendar from "primevue/calendar";

export default {
  name: "EntityFilter",
  data() {
    return {
      filter: this.selectedFilter,
      filter2: this.selectedFilter2,
      filterButton: this.filterButtons?.find((filter) => filter.isSelected),
      filterButton2: this.filterButtons2?.find((filter) => filter.isSelected),
      filterValue: this.selectedFilter?.value,
      filterValue2: this.selectedFilter2?.value,
      label: "",
      showAdditionalFilter: false,
    };
  },
  props: {
    selectedFilter: {
      type: Object,
    },
    selectedFilter2: {
      type: Object,
    },
    filterCriteria: {
      type: Array,
    },
    preFilters: { type: Array },
    filterButtons: {
      type: Array,
      default: () => [],
    },
    filterButtons2: {
      type: Array,
      default: () => [],
    },
    customStyle: { type: String },
    disabled: { type: Boolean },
    noMargins: { type: Boolean, default: false },
    noFocus: { type: Boolean, default: false },
    entity: {
      type: String,
    },
    enableAdditionalFilter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Calendar,
  },
  mounted() {
    if (!this.noFocus) {
      this.$refs?.inputCriteria?.focus();
    }
  },
  computed: {
    preFiltersText: function () {
      if (!this.preFilters || this.preFilters.length === 0) {
        return "";
      }
      let text = "Showing results for ";
      this.preFilters.forEach((pf, i) => {
        let value = pf.filterValue;
        if (typeof pf.filterValue === "object" && pf.filterValue !== null) {
          value = pf.filterValue.name;
        }
        text += `${pf.criteria}: ${value}`;
        if (i < this.preFilters.length - 1) {
          text += ", ";
        }
      });
      return text;
    },
    initialCriteria: function () {
      return this.filterCriteria[0];
    },
  },
  methods: {
    enterPressed(filterNumber) {
      setTimeout(
        () => this.clearInput(filterNumber),
        Constants.debounce + 1250
      );
    },
    tabPressed(filterNumber) {
      setTimeout(this.clearInput(filterNumber), Constants.debounce + 1250);
    },
    clearInput() {
      this.filterValue = null;
    },
    clicked() {
      this.$emit("click");
      if (this.to && !this.disabled) {
        router.push(this.to);
      }
    },
    emitCriteriaChange(filterNumber) {
      if (filterNumber === "2" && this.filter2) {
        this.filter2.value = "";
        this.filterValue2 = "";
        setTimeout(() => {
          this.$refs?.inputCriteria2?.focus();
        }, 100);
      } else {
        this.filter.value = "";
        this.filterValue = "";
        setTimeout(() => {
          this.$refs?.inputCriteria?.focus();
        }, 100);
      }

      this.$emit("criteriaChange", {
        filter: this.filter,
        filter2: this.filter2,
      });
    },
    emitValueChange: _.debounce(function (e, filterNumber) {
      const filter = filterNumber === "2" ? this.filter2 : this.filter;
      const filterValueField =
        filterNumber === "2" ? "filterValue2" : "filterValue";

      if (filter.criteria.field.type === "date") {
        this[filterValueField] = e;
      } else if (filter.criteria.regex && this.filterValue) {
        this[filterValueField] = e.target.value.replace(
          filter.criteria.regex,
          ""
        );
      } else this[filterValueField] = e.target.value;
      let filterValue = this[filterValueField];
      if (filter.criteria.field.type === "date") {
        filterValue = this[filterValueField].toISOString();
      }

      // Add 4 chars constraints if entity is order
      if (
        this.entity == "order" &&
        filterValue.length > 0 &&
        filterValue.length < 4 &&
        filter.criteria.code != "code"
      ) {
        this.label = "(enter more than 4 characters)";
        return;
      }

      // Allow 3 chars for code
      if (
        this.entity == "order" &&
        filterValue.length > 0 &&
        filterValue.length < 3 &&
        filter.criteria.code == "code"
      ) {
        this.label = "(enter more than 3 characters)";
        return;
      }

      this.label = "";

      this.$emit(
        "valueChange",
        {
          filter: {
            ...this.filter,
            value: this.filterValue?.trim(),
          },
          filter2: {
            ...this.filter2,
            value: this.filterValue2?.trim(),
          },
        },
        true
      );
    }, Constants.debounce + 500),
    emitFilterButtonClick(filterButton) {
      if (filterButton.name === this.filterButton.name) return;
      this.filterButton = filterButton;

      const filter = {
        ...this.filter,
        value: this.filterValue?.trim(),
      }

      const filter2 = {
        ...this.filter2,
        value: this.filterValue2?.trim(),
      }

      const payload = { filter, filter2, filterButton: filterButton };
      this.$emit("filterButtonClicked", payload);
    },
    emitFilterButton2Click(filterButton) {
      if (filterButton.name === this.filterButton2.name) return;
      this.filterButton2 = filterButton;

      const filter = {
        ...this.filter,
        value: this.filterValue?.trim(),
      }

      const filter2 = {
        ...this.filter2,
        value: this.filterValue2?.trim(),
      }

      const payload = { filter, filter2, filterButton2: filterButton };
      this.$emit("filterButton2Clicked", payload);
    },
    handleInputFocus() {
      if (this.filter?.criteria?.clearOnFocus) {
        this.filterValue = "";
      }
      if (this.filter2?.criteria?.clearOnFocus) {
        this.filterValue2 = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_entityFilter.scss";

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  div {
    margin: 10px 5px;
  }
}

.entity-filter {
  &__wrapper {
    flex-wrap: nowrap;
  }
}
</style>
