import router from "@/router";
import * as Constants from "@/resources/Constants";

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
    disabled: (item) => {
      if (item._id === router.history.current.query.id) return true;
      else return false;
    },
  },
  {
    title: "Time In",
    id: "stampedIn",
    type: "date",
    dateFormat: Constants.dates.extendedCalendarDateFormat,
  },
  {
    title: "Time Out",
    id: "stampedOut",
    type: "date",
    dateFormat: Constants.dates.extendedCalendarDateFormat,
  },
  {
    title: "Employee Id",
    id: "employee._id",
    link: true,
    url: "/employees/employeeEditor?id=",
    urlId: "employee._id",
  },
  {
    title: "Employee Last Name",
    id: "employee.lastName",
  },
  {
    title: "Employee First Name",
    id: "employee.name",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const timeSheetInputs = [
  {
    id: "stampedIn",
    type: "calendar",
    title: "Stamped In",
    showTime: true,
  },
  {
    id: "stampedOut",
    type: "calendar",
    title: "Stamped Out",
    showTime: true,
  },
];
