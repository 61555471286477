import * as Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";
import {
  searchColumnHeaders as employeeColumnHeaders,
  filterCriteria as employeeFilterCriteria,
} from "../Employee/inputs";

// Page Header
export const pageHeader = {
  title: "Time Sheet",
  isEditorView: true,
  cancelPath: "timeSheets",
  addPath: "timeSheetEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Time Sheets",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "timeSheetEditor",
};

export const filterCriteria = [
  {
    label: "Employee Id",
    code: "employee",
    field: { type: "input", label: "Employee Id" },
  },
  {
    label: "Last Name",
    code: "externalFields.employee.lastName",
    field: { type: "input", label: "Last Name" },
    isPartial: true,
  },
  {
    label: "First Name",
    code: "externalFields.employee.name",
    field: { type: "input", label: "First Name" },
    isPartial: true,
  },
  {
    label: "Phone",
    code: "phone",
    field: { type: "input", label: "Phone" },
    isPartial: true,
  },
  {
    label: "Stamped In",
    code: "stampedIn",
    field: { type: "date", label: "Stamped In" },
  },
  {
    label: "Stamped Out",
    code: "stampedOut",
    field: { type: "date", label: "Stamped Out" },
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Time In",
    id: "stampedIn",
    type: "date",
    dateFormat: Constants.dates.extendedCalendarDateFormat,
    size: "medium",
  },
  {
    title: "Time Out",
    id: "stampedOut",
    type: "date",
    dateFormat: Constants.dates.extendedCalendarDateFormat,
    size: "medium",
  },
  {
    title: "Employee Id",
    id: "employee._id",
    link: true,
    url: "/employees/employeeEditor?id=",
    urlId: "employee._id",
  },
  {
    title: "Employee Last Name",
    id: "employee.lastName",
    size: "large",
  },
  {
    title: "Employee First Name",
    id: "employee.name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const timeSheetInputs = [
  {
    id: "employee._id",
    type: "link",
    title: "Employee Id",
    placeholder: "Employee Id",
    to: {
      name: "employeeEditor",
      valueKey: "employee._id",
    },
    target: "_blank",
  },
  {
    id: "employee.name",
    type: "text",
    title: "Employee First Name",
  },
  {
    id: "employee.lastName",
    type: "text",
    title: "Employee Last Name",
  },
  {
    id: "totalHours",
    type: "text",
    title: "Total Hours",
  },
  {
    id: "stampedIn",
    type: "calendar",
    title: "Stamped In",
    showTime: true,
  },
  {
    id: "stampedOut",
    type: "calendar",
    title: "Stamped Out",
    showTime: true,
  },
  {
    id: "employee",
    placeholder: "Select Employee",
    type: "search",
    title: "Employee",
    label: "name",
    entity: Entities.EMPLOYEE,
    columnHeaders: employeeColumnHeaders,
    filterCriteria: employeeFilterCriteria,
    fieldsToDisplay: [
      { label: "First Name", id: "name" },
      { label: "Last Name", id: "lastName" },
    ],
  },
];

export const defaultSelectedTimeSheet = {
  _id: "NEW",
  employee: {},
  stampedIn: undefined,
  stampedOut: undefined,
};
