<template>
  <div id="coupon-pop-up">
    <div
      class="overlay-div"
      v-if="couponPopUp.isOpen"
      v-on:click.stop="handleNegativeAnswer"
    ></div>
    <div
      v-if="couponPopUp.isOpen"
      class="default-pop-up-center-div center bordered"
    >
      <span class="h1 padded">Coupon Search</span>
      <br />

      <div class="padded">
        <div class="padded--vertical">
          <div class="table-container">
            <table cellspacing="0" class="table-element">
              <thead>
                <tr class="header">
                  <th class="cell"></th>
                  <th class="cell">
                    Memo #
                    <br />
                    <input type="text" class="flat-input--table" />
                  </th>
                  <th class="cell">Type</th>
                  <th class="cell">P</th>
                  <th class="cell">D</th>
                  <th class="cell">
                    Name
                    <br />
                    <input type="text" class="flat-input--table" />
                  </th>
                  <th class="cell">Enter Date</th>
                  <th class="cell">Remaining Balance</th>
                  <th class="cell">Amount Used</th>
                  <th class="cell">Memo Total</th>
                  <th class="cell">
                    Start Date
                    <br />
                    <input type="text" class="flat-input--table" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row">
                  <td class="cell">
                    <div
                      class="table-button--text"
                      @click="handlePositiveAnswer"
                    >
                      Select
                    </div>
                  </td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                </tr>

                <tr class="row">
                  <td class="cell">
                    <div class="table-button--text">Select</div>
                  </td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                  <td class="cell">test</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="bottom-buttons center">
        <div
          class="interspace button-info full-width-r"
          @click="handleNegativeAnswer"
        >
          Close
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import CheckBox from "@/components/CheckBox";

export default {
  name: "CouponPopUp",
  data() {
    return {
      Constants: Constants,
    };
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters(["couponPopUp"]),
  },
  methods: {
    ...mapActions([]),
    handlePositiveAnswer: function (selectedElement) {
      if (this.couponPopUp.positiveCallback) {
        this.couponPopUp.positiveCallback(selectedElement);
      }
      this.couponPopUp.isOpen = false;
    },
    handleNegativeAnswer: function () {
      if (this.couponPopUp.negativeCallback) {
        this.couponPopUp.negativeCallback();
      }
      this.couponPopUp.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
